import axios from "axios";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { AuthContext } from "../../Context/AuthProvider";
import Toaster from "../../jsx/components/Toaster";
import {AUTH, PLAN, USER} from "../../lib/config/URLConstant";
import { WEBREPORT } from "../../lib/config/URLConstant";
import { AxiosInstance } from "../../lib/config/axiosConfig";



const Register = ({ history }) => {
  const { tokenAuth } = useContext(AuthContext);
  const search = useLocation().search;
  const queryParams = new URLSearchParams(window.location.search);

  const name1 = atob(queryParams.get('name'));
  const name2 = atob(queryParams.get('email'));
  const name3 = atob(queryParams.get('companyname'));
  const name6 = atob(queryParams.get('idcompany'));
  const name4 = atob(queryParams.get('phone'));
  const name5 = atob(queryParams.get('role'));
  // // console.log(name1, name2, name3, name4, name5, name6);

  const initialValues = {
    name: name1,
    email: name2,
    password: "",
    company: name6,
    companyName: name3,
    phone: name4,
    role: name5,
  };

  const onSubmit = async (values) => {
    let values1 = {
      ...values,
      sendemail: values.email
    }
    // await AxiosInstance.post(`${USER}`, values)
    //     .then(async (res) => {
    //       if (res.data.code && res.data.code !== 200) {
    //         Toaster(res.data.msg, "error");
    //       } else {
    //         Toaster("User Added !!", "success");
    //       }
    //         })

    //         // Toaster("Reset password Email will be sent to User", "warning");
    //         // setUpdateTable(prev => !prev);
    //       }
    //     })
    //     .catch(err => {
    //       Toaster(err.message, "error");
    //     })

    try {axios
      .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/register`, values)
      .then(async (res) => {
        const {
          data: { code = "", msg = "", tokens = {}, user = {} },
        } = res;

        if (isEmpty(code) && isEmpty(msg)) {
          const newdata = {
            token: {
              token: tokens.access.token,
              userData: user,
            },
          };
          tokenAuth(newdata, user, async () => {
            Toaster("Registration Successfully", "success");
              // send mail code
              const url = `${WEBREPORT}/sendmail`;
              await AxiosInstance.post(url,{name:"signup", values1: values1})
              .then((response)=>{
                // // console.log(response.data);
  
                // update the plans
                AxiosInstance.post(`${PLAN}/updateplan`,{id: values.company, role: values.role, type: "adduser"})
                .then((response)=>{
                  // // console.log(response.data);
                })
              })
          });
         
        } else {
          Toaster(msg, "error");
          history.push("/login");
        }
      })
      .catch((error) => {
        if (error) {
          Toaster("Error 404", "error");
        }
      });
    }
      catch (error) {
        Toaster(error, "error");

      }

  };

  const phoneRegex = RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );

  const validationSchema = Yup.object({
    company: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    phone: Yup.string()
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    // validate,
    validationSchema,
  });

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">
                      {/* <img className="logo-compact" src={logoText} alt="" /> */}
                      Sign up your account
                    </h4>
                    <form action="" onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Name</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="formerrormessage">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formerrormessage">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          values={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="formerrormessage">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Company Name</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="companyName"
                          name="companyName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyName}
                        />
                        {formik.touched.companyName && formik.errors.companyName ? (
                          <div className="formerrormessage">
                            {formik.errors.companyName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Phone Number</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="phone"
                          name="phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="formerrormessage">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div className="text-center mt-4">
                        <input
                          type="submit"
                          value=" Sign me up"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p>
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
