import React, { Fragment, useEffect, useState } from 'react'
import {  Button, Card, Col, Modal, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PageTitle from '../../../layouts/PageTitle';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { WEBREPORT } from '../../../../lib/config/URLConstant';
import { Link } from 'react-router-dom';
import RctSectionLoader from '../utils/RctSectionLoader';

const RetestQueue = () => {

    const [data, setdata] = useState();
    const [loader, setloader] = useState(false);

    useEffect(() =>{
      setloader(true)
        AxiosInstance.get(`${WEBREPORT}/request/allretest`)
        .then((res)=>{
            setdata(res.data.reverse())
            setloader(false)
        })
        .catch((err)=> {
            console.log(err)
            setloader(false)
        })
    }, [])


  return (
    loader ? <RctSectionLoader /> :
    <div>
        <Fragment>
        <PageTitle activeMenu="Web App" motherMenu="My Requests" />
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Retest Queue</Card.Title>
              </Card.Header>
              {false?
                <Card.Body style={{display:'flex','alignItems': 'center','justifyContent': 'center'}}>
                  <Spinner animation="border" variant="primary" />
                </Card.Body>
              :
              <Card.Body>
                <Table className="mt-3" responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Vulnerability Name</strong>
                      </th>
                      <th>
                        <strong>Link</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        data?.map((data)=> {
                            return <>
                                <tr>
                                    <td> {data.title} </td>
                               
                                    <td> <Link to={`/vulnerability?reportId=${data.id}`} >
                                        Click here
                                     </Link></td>
                                </tr>
                            </>
                        })
                    }
                    
                   
                  </tbody>
                </Table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    <Tooltip title="Previous Page">
                    <span>
                    <IconButton 
                      aria-label="previouspage" color="primary">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    </span>
                    </Tooltip>
                    <Tooltip title="Next Page">
                    <span>
                    <IconButton
                      aria-label="nextpage" color="primary">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    </span>
                    </Tooltip>
                  </div>
              </div>
                <Modal
                    className="fade bd-example-modal-sm"
                    size="sm"
                >
                    <Modal.Header>
                      <Modal.Title>Delete Request</Modal.Title>
                      <Button
                          variant=""
                          className="close"
                      >
                          <span>&times;</span>
                      </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete Request ?</Modal.Body>
                    <Modal.Footer>
                      <Button
                      data-cy="delete-cancel"
                          variant="danger light"
                      >
                          Cancel
                      </Button>
                      <Button variant="primary"
                      data-cy="delete-confirm"
                        onClick={()=>this.handleDeleteRequest()}>Delete</Button>
                    </Modal.Footer>
                </Modal>
              </Card.Body>
              }
            </Card>
          </Col>
        </Row>
      </Fragment>
    </div>
  )
}

export default RetestQueue