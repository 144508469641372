import React, { useEffect, useState } from "react";

import Select from "react-select";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { NETWORK } from "../../../../lib/config/URLConstant";

export const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
 ];

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
   const {
      children = <CustomClearText />,
      getStyles,
      ipslist,
      inputIplist,
      innerProps: { ref, ...restInnerProps },
   } = props;


 

   return (
      <div
         {...restInnerProps}
         ref={ref}
         style={getStyles("clearIndicator", props)}
      >
         <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
   );
};

const ClearIndicatorStyles = (base, state) => ({
   ...base,
   cursor: "pointer",
   color: state.isFocused ? "blue" : "black",
});

export default function CustomClearIndicator(props) {

    const [value, setvalue] = useState()

    useEffect(() => {
        getValue()
    },[props.inputIplist])

   let data = [];
   for (let ip in props.ipslist){
      let obj = {
         value: props.ipslist[ip],
         label: props.ipslist[ip],
      }
      data.push(obj)
   }

   const getValue = ()=>{
    let data1 = [];
    for(let ip in props.inputIplist){
        let obj1 = {
            value: props.inputIplist[ip],
            label: props.inputIplist[ip],
          }
          data1.push(obj1);
        }
        setvalue(data1)
    }


   return (
      <Select
         closeMenuOnSelect={false}
         components={{ ClearIndicator }}
         value={value}
         styles={{ clearIndicator: ClearIndicatorStyles }}
         isMulti
         onChange={(e)=> props.changeHandler(e)}
         options={data}
      />
   );
}
