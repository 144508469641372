import React, {  Fragment, useState, useContext, useEffect } from "react";
import { Col, Row, Button,  Container } from "react-bootstrap";
import { AuthContext } from "../../../../Context/AuthProvider";
import PageTitle from "../../../layouts/PageTitle";
import { AddResourceModal } from "./AddResourceDocument";
import {  useLocation } from "react-router-dom";

import parse from 'html-react-parser';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
const Resource = (props) => {
    const id = props.dbId;

    const { user } = useContext(AuthContext);

    const role = user.role;

    // const [resource, setResource] = useState({id: '', body: "", title: ""});
    // const id = useQuery().get("id");
    useEffect(() => {
        // AxiosInstance.get(`/resources?id=`, {
        //     data: {
        //         id
        //     }
        // }).then(res => {
        //     setResource(res.data);
        // }).catch(err => {
        //     Toaster(err.response.data.message, "error");
        // })

        document.querySelector('#resourceBody').innerHTML = parse(props.body);

    }, [])

    const [showEditResourceModal, setShowEditResourceModal] = useState(false);

    const closeEditResourceModal = () => setShowEditResourceModal(false);

    useEffect(() => {
    }, [])

    return (
        <Fragment>
            <PageTitle title={`Resources | ${props.title}`} motherMenu="Resources" activeMenu={props.title}/>


            <Button onClick={() => props.handleClose()}><i className="fa fa-arrow-left" aria-hidden="true"></i></Button>
            { (role === 'editor' || role === 'cshAdmin') ? (
                <>
                    <AddResourceModal handleClose={closeEditResourceModal} showModal={showEditResourceModal} dbId={id} title={props.title} body={props.body} addOrUpdateResource={props.addOrUpdateResource} ></AddResourceModal>

                    <span className="float-right"><button className="btn btn-info mb-4" onClick={() => {
                            setShowEditResourceModal(true)
                        }}><i className="fas fa-edit"></i></button></span>
                </>
             ) : null }

            

            <Container>
                <Row>
                    <Col md={12}>
                        <h1>{props.title}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div id="resourceBody"></div>
                    </Col>
                </Row>

            </Container>
        </Fragment>
    )

}

export default Resource;