export const AUTH = 'auth';
export const USER = 'users';
export const WEB = 'web';
export const WEBSUBMIT = 'websubmit';
export const NETWORK = 'network';
export const MYPROFILE = 'my-profile';
export const WEBREPORT = 'webReport';
export const PDF = 'create-pdf';
export const PAYMENT = 'payment';
export const PLAN = 'plan';
export const COMPANIES = 'companies';
export const CHECKLIST = 'checklist';
export const NETWORKREPORT = 'networkreport';
export const RESOURCES = 'resources';
export const NOTIFICATION = 'notification';