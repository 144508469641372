import React, { useEffect, useState } from "react";
import { Card, Col, Table,Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { CHECKLIST } from "../../../../lib/config/URLConstant";
import {checklist} from "./checklistdata";
import { CSVLink, CSVDownload } from "react-csv";


const Viewchecklist = () => {
  const search = useLocation().search;
  const requestid = new URLSearchParams(search).get("requestid");
  const [ids, setIds] = useState();
  const [status, setstatus] = useState();
  const [data, setdata] = useState();

  useEffect(() => {
    fetchChecklist();
  }, [])

  const headers = [
    { label: "Name", key: "name" },
    { label: "Status", key: "status" }
  ]

  const fetchChecklist = ()=>{
    AxiosInstance.post(`${CHECKLIST}/getchecklist`,{
      requestid: requestid
    })
    .then((res)=>{
      let idarray = [];
      let statusarray = [];
      for (let id in res.data.checklist){
        if (res.data.checklist[id].id > checklist.length){
          checklist.push(
            {
              id: res.data.checklist[id].id,
              check: res.data.checklist[id].name,
            }
          )
      }
        idarray.push(
            res.data.checklist[id].id
          )
          statusarray.push(
            res.data.checklist[id].status
          )
      }
      setdata(res.data.checklist)
      // console.log(res.data.checklist)
      setIds(idarray);
      setstatus(statusarray);
      // // console.log(statusarray, idarray);
    // // console.log(idarray, statusarray)
    })
    .catch((err)=>{
      // // console.log(err)
    })
  }

  let i = 0;
  const getBadgeColor = (Currstatusname) => {
    if (Currstatusname === 'yes' ) {
      return "color-critical";
    }
    if (Currstatusname === 'no') {
      return "color-medium";
    }
    if (Currstatusname === 'na') {
      return "color-info";
    }
  };
  return (
    <div>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Checklist</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{minWidth:"68px"}}>
                    <strong>Sr No</strong>
                  </th>
                  <th>
                    <strong>Vulnerability Checklist</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {status && checklist.map((item) => {
                  let statusname = status && status[ids?.indexOf(item.id)];
                  if (ids.includes(item.id)) {
                      i += 1;
                    return (
                        <tr key={"tr-"+item.id}>
                          <td>{i}</td>
                          <td>{item.check}</td>
                          <td>
                            <div className="d-flex">
                              {<p style={{width:"100%"}}>
                                <Badge style={{width:"100%"}} variant={getBadgeColor(statusname)}>
                                {statusname.toUpperCase()}
                              </Badge>
                              </p>}
                            </div>
                          </td>
                        </tr>
                      );
                  }
                  else{
                      return null
                  }
                })}
              </tbody>
            </Table>
            {
              data && <CSVLink className="btn btn-primary"   filename={"checklist.csv"}
              data={data} headers={headers}>
              Download Checklist
          </CSVLink>
            }
            {/* <button className="btn btn-primary">Download checklist</button> */}

          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default Viewchecklist;
