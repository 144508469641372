import React from "react";
import ReactApexChart from "react-apexcharts";
class ApexRedialBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [props.riskPercentage],
      options: {
        chart: {
          height: 300,
          type: "radialBar",
          offsetY: -10,
          fontFamily: "montserrat",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "20px",
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 0,
                fontSize: "34px",
                color: "black",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          colors: "#ff8900",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.18,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 3,
          colors: "#000",
        },
        labels: [""],
      },
    };
  }
  
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={350}
        />
      </div>
    );
  }
}
export default ApexRedialBar;
