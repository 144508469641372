// // Disable edit icon for all status except completed and submitted.

import { map, size } from "lodash";
import React, { Component, Fragment } from "react";
import { Badge, Card, Col, Dropdown, Row, Table, Button, Modal, Spinner } from "react-bootstrap";
import { AuthContext } from "../../../../Context/AuthProvider";
import PageTitle from "../../../layouts/PageTitle";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { COMPANIES, WEB } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import status from "../../../../lib/constant/RequestStatus";
import AddCompany from "./AddCompany";

export default class Companies extends Component {
  static contextType = AuthContext;

  state = {
    webRequests: [],
    tempWebrequest: [],
    searchWebrequest: [],
    openDeleteModal:false,
    selectedRequestId:null,
    isLoading:true,
    limit:10,
    page:1,
    totalPages:0,
    totalResults:0,
    pagination:[],
    addCompanyShow: false
  };

  componentDidUpdate = (prevState) => {
    if (
      (size(prevState.webRequests) === 0 &&
      prevState.webRequests === this.state.webRequests) 
    ) {
      this.fetchWebRequests(this.state.limit,this.state.page);
      this.searchFetchWebRequests(1000000000, 1);
    }
  };

  componentDidMount = async () => {
    await this.fetchWebRequests(this.state.limit,this.state.page);
    this.searchFetchWebRequests(1000000000, 1);
  };

  fetchWebRequests = async (limit,page) => {
    const {user} = this.context;
    if (user.role!="tester"){
    let params = `sortBy='desc'&limit=${limit}&page=${page}`;

      await AxiosInstance.get(`${COMPANIES}?${params}`)
      .then(res=>{
        if(res.data.code && res.data.code!==200){
          Toaster(res.data.msg,"error");
        }else{
          let temppage = Array(Math.ceil(res.data.data.totalResults / this.state.limit))
                          .fill()
                          .map((_, i) => i + 1);
          this.setState({
            webRequests: [...res.data.data.results],
            limit: res.data.data.limit,
            page: res.data.data.page,
            totalPages: res.data.data.totalPages,
            totalResults: res.data.data.totalResults,
            pagination: temppage,
            tempWebrequest: [...res.data.data.results]
          });
          // // console.log(res.data, `${WEB}?${params}` )
        }
      })
      .catch(err=>{
        Toaster(err.message,"error");
      })
      this.setState({isLoading:false});
    }
    else{
      limit = 1000000000;
      await AxiosInstance.get(`${WEB}?limit=${limit}&page=${page}`)
      .then((res)=>{
        if(res.data.code && res.data.code!==200){
          Toaster(res.data.msg,"error");
        }else{
          let tempres = [];
          for (let result in res.data.data.results){
            if (res.data.data.results[result].tester == user.email){
                tempres.push(res.data.data.results[result]);
            }
          }
          this.setState({
            webRequests: tempres,
            totalResults: 1
          })
        }
        this.setState({isLoading:false});
      })
    }
    
  };
  searchFetchWebRequests = async (limit,page) => {
    const {user} = this.context;
    let params = `sortBy='desc'&limit=${limit}&page=${page}`;

    await AxiosInstance.get(`${COMPANIES}?${params}`)
    .then(res=>{
      if(res.data.code && res.data.code!==200){
        Toaster(res.data.msg,"error");
      }else{
        this.setState({
          searchWebrequest: [...res.data.data.results]
        });
      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
  };

  onEditClick = (index) =>{
    // const { history } = this.props;
    // history.push(window.open());
    // window.location.href=`/edit-company?requestId=${requestId}`
    this.props.history.push({
      pathname:  "/edit-company",
      state: {
        company: this.state.webRequests[index] 
      } 
   });
  }
  onDeleteClick = (requestId) =>{
    this.setState({openDeleteModal: true,selectedRequestId: requestId})
  }
  onSubmitReportClick = (webrequest) =>{
    window.location.href=`/submit-report?requestId=${webrequest.id}&userId=${webrequest.user_id}&appName=${webrequest.application_name}&company=${webrequest.company}`
  }
  onViewReportClick = (webrequest) =>{
    window.location.href=`/reports?requestId=${webrequest.id}`
  }
  onViewRequestClick = (webrequest) =>{
    window.location.href=`/view-web-request?requestId=${webrequest.id}`
  }

  handleDeleteRequest = async () =>{
    await AxiosInstance.delete(`${WEB}/${this.state.selectedRequestId}`)
    .then(res=>{
      this.setState({openDeleteModal: false})
      if(res.data.code!==200 && res.data.code!==204){
        Toaster(res.data.msg, "error");  
      }else{
        this.fetchWebRequests(this.state.limit,1);
        Toaster("Request Deleted Successfully", "success");
      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
  }

  onPreviousClick = () =>{
    this.setState({isLoading:true});
    this.fetchWebRequests(this.state.limit,this.state.page-1);
    window.scrollTo(0, 0);
    this.setState({page: this.state.page-1});
  }
  onNextClick = () =>{
    this.setState({isLoading:true});
    this.fetchWebRequests(this.state.limit,this.state.page+1);
    window.scrollTo(0, 0);
    this.setState({page: this.state.page+1});
    
  }
  showEntries = () =>{
    let start = this.state.page==1 ? this.state.page :
            ((this.state.page-1) * this.state.limit);
    let end = this.state.totalResults > (this.state.page) * this.state.limit
    ? (this.state.page) * this.state.limit
    : this.state.totalResults;
    return `Showing ${start} to ${end} of ${this.state.totalResults}`;
  }

  getBadgeColor = (currStatus) =>{
    if(currStatus===status.requested){
      return 'orange';
    }
    if(currStatus===status.inProgress){
      return 'sky';
    }
    if(currStatus===status.completed){
      return 'green';
    }
  }



  filterHandler = (e)=>{
      const search = e.target.value;
      if (search.length>0){
        const newList = this.state.searchWebrequest.filter((request)=>{
            return Object.values(request)
            .join(" ")
            .toLowerCase()
            .includes(search.toLowerCase());
        })
        this.setState({
            webRequests: newList
        })
      }
      else{
        this.setState({
          webRequests: this.state.tempWebrequest
        })
      }
  }

  close = ()=> {
    this.setState({
      addCompanyShow: !this.state.addCompanyShow
    })
    // // console.log(this.state)
  }

  render() {

    const { webRequests } = this.state;
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    return (
      <Fragment>
        <PageTitle activeMenu="Web App" motherMenu="My Requests" />
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>All Companies Details</Card.Title>
              </Card.Header>
              {this.state.isLoading?
                <Card.Body style={{display:'flex','alignItems': 'center','justifyContent': 'center'}}>
                  <Spinner animation="border" variant="primary" />
                </Card.Body>
              :
              <Card.Body>
                <div className="w-100 d-flex justify-content-between">
                {(this.context.user.role==="cshAdmin" || this.context.user.role==="tester")?
                    <div className="ml-2 d-flex align-items-center">
                      <h4>Filter</h4>
                      <input
                            data-cy="my-request-filter"
                            type="text"
                            className="ml-3"
                            onChange={this.filterHandler}
                          />
                    </div>:null
                  }
                  
                  <button 
                  onClick={this.close}
                  className="btn btn-primary float-right">
                        Invite a new company
                      </button>
                </div>
                <AddCompany show={this.state.addCompanyShow} close={this.close}/>
                <Table className="mt-3" responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Company Name</strong>
                      </th>
                      <th>
                        <strong>Application Name</strong>
                      </th>
                      <th>
                        <strong>Company</strong>
                      </th>
                      <th>
                        <strong>Pentest Scope</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    
                    {size(webRequests) !== 0 &&
                      map((webRequests), (webRequest, index) => (
                        <tr key={index}>
                          <td>{webRequest.company}</td>
                          <td className='clickable'>{webRequest.applicationName}</td>
                          {(this.context.user.role==="cshAdmin" || this.context.user.role==="tester")
                              ?
                              <td>{webRequest.company}</td>
                              :null
                          }
                          <td>{webRequest.pentest_scope}</td>
                          <td>
                            <Badge data-cy="request-status-badge" variant={this.getBadgeColor(webRequest.status)}>{webRequest.status}</Badge>
                          </td>
                          <td>
                            <Dropdown data-cy="my-request-dropdown-menu">
                              <Dropdown.Toggle
                                
                                variant="success"
                                className="light sharp i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>

                                { !['readOnly','user','admin'].includes(this.context.user.role) && <Dropdown.Item data-cy="my-request-edit-request" onClick={()=>this.onEditClick(index)}>Edit</Dropdown.Item>}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                  >
                    {this.showEntries()}
                    <Tooltip title="Previous Page">
                    <span>
                    <IconButton 
                      onClick={() => this.onPreviousClick()} 
                      disabled={this.state.page===1 || this.state.webRequests.length<=0}
                      aria-label="previouspage" color="primary">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    </span>
                    </Tooltip>
                    <Tooltip title="Next Page">
                    <span>
                    <IconButton
                      onClick= {() => this.onNextClick()}
                      disabled={this.state.page===this.state.totalPages || this.state.webRequests.length<=0}
                      aria-label="nextpage" color="primary">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    </span>
                    </Tooltip>
                  </div>
              </div>
                <Modal
                    className="fade bd-example-modal-sm"
                    size="sm"
                    show={this.state.openDeleteModal}
                >
                    <Modal.Header>
                      <Modal.Title>Delete Request</Modal.Title>
                      <Button
                          variant=""
                          className="close"
                          onClick={() => this.setState({openDeleteModal:false})}
                      >
                          <span>&times;</span>
                      </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete Request ?</Modal.Body>
                    <Modal.Footer>
                      <Button
                      data-cy="delete-cancel"
                          variant="danger light"
                          onClick={() => this.setState({openDeleteModal:false})}
                      >
                          Cancel
                      </Button>
                      <Button variant="primary"
                      data-cy="delete-confirm"
                        onClick={()=>this.handleDeleteRequest()}>Delete</Button>
                    </Modal.Footer>
                </Modal>
              </Card.Body>
              }
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
