import React from 'react'

const Sidebar = (props) => {
  const {step} = props;
  return (
    <div class="steps order-2 position-relative w-25">
    <div class="multisteps-form__progress">
        <span class={(step >= 1)?"multisteps-form__progress-btn js-active":"multisteps-form__progress-btn"} title="Application data"><i class="far fa-user"></i><span>Personal information</span></span>
        <span class={(step >= 2)?"multisteps-form__progress-btn js-active":"multisteps-form__progress-btn"}><i class="far fa-user"></i><span>Application Details</span></span>
        <span class={(step >= 3)?"multisteps-form__progress-btn js-active":"multisteps-form__progress-btn"}><i class="far fa-user"></i><span>Pricing</span></span>
        <span class={(step == 4)?"multisteps-form__progress-btn js-active":"multisteps-form__progress-btn"}><i class="far fa-user"></i><span>Thank You</span></span>
    </div>
</div>
  )
}

export default Sidebar