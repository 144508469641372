import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row,Badge } from "react-bootstrap";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { AuthContext } from "../../../../Context/AuthProvider";
import { WEB, WEBREPORT, USER } from "../../../../lib/config/URLConstant";
import {useLocation} from "react-router-dom";
import Toaster from "../../Toaster";
import RctSectionLoader from "../utils/RctSectionLoader";
import { IconButton, MenuItem, Select, Tooltip } from "@material-ui/core";
import status from "../../../../lib/constant/RequestStatus";
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CustomClearIndicator from "./MultiSelectViewRequest";

const ViewWebRequest = () =>{
  const { user } = useContext(AuthContext);
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('requestId');
  const [formValues, setFormValues] = useState();
  const [inputList, setInputList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showStatusDD, setShowStatusDD] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [previousSelectedStatus, setpreviousSelectedStatus] = useState();
  const [testers, setTesters] = useState([]);
  const [selectedTester, setSelectedTester] = useState([]);
  const [selectedTesterEmail, setSelectedTesterEmail] = useState();
  let testerArray=[];
  let testerArrayEmail=[];
  const [inputIplist, setinputIplist] = useState([]);

  const getBadgeColor = (currStatus) => {
    // test details status
    if (currStatus === "REQUESTED") {
      return "orange";
    }
    if (currStatus === "IN-PROGRESS") {
      return "sky";
    }
    if (currStatus === "COMPLETED") {
      return "green";
    }
  };
  const getRequestById = async () =>{
    await AxiosInstance.get(`${WEB}/${requestId}`)
    .then(res=>{
      setFormValues(res.data.data);
      getUsers(10,1 ,res.data.data);
      setpreviousSelectedStatus(res.data.data.status);
      setInputList(res.data.data.credentials.map(creds=> {
        creds.showPassword = false; 
        return creds;
      }));
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
    setShowLoader(false);
  }

  // code for sending email 

  const sendMail = (changedFrom, changed) =>{
    const url = `${WEBREPORT}/sendmail`
    let values1 = {
      companyid: formValues.company,
      requestid: requestId,
      testDetail: formValues
    }
    AxiosInstance.post(url,{name:"statusChanged", changedFrom:changedFrom , changed: changed, values1})
    .then((response)=>{
    })
  }
  //code for sedning slack message
  const sendSlack = (changedFrom, changed) =>{
    if (user.slackChannelId != undefined){
      AxiosInstance.post(`${WEB}/sendSlack`,{
        type:"reqstatus",
        slackChannelId: user.slackChannelId, 
        url:formValues.url, 
        applicationname:formValues.application_name, 
        changedFrom:changedFrom, 
        changed: changed
      })
      .then((response)=>{
        
      })
      .catch((error)=>{
        Toaster(error, "error")
      })
    }
  }


  const onStatusChange = (e) =>{
    setSelectedStatus(e.target.value);
  }
  var completeDate = ''
  if (selectedStatus == "COMPLETED") completeDate = new Date().toLocaleDateString();
  else completeDate = new Date().toLocaleDateString() + ' (testing in progress)';

  const saveStatus = async () =>{
    let values = {status:selectedStatus };

    await AxiosInstance.patch(`${WEB}/status/${requestId}`,values)
    .then(res=>{
      if(res.data.code!==200 && res.data.code!==201){
        Toaster(res.data.msg, "error");  
      }else{
        Toaster("Status updated", "success");
        setFormValues(res.data.data);
        setInputList(res.data.data.credentials.map(creds=> {
          creds.showPassword = false; 
          return creds;
        }));
        setSelectedStatus(res.data.data.status);
        setShowStatusDD(false);
        sendMail(previousSelectedStatus, selectedStatus);
        sendSlack(previousSelectedStatus, selectedStatus);

        AxiosInstance.post(`${WEB}/completeDate`, {_id: requestId, completeDate: completeDate, type: 'web'})
        .then((res)=>{
          // // console.log(res.data);
        })

        setpreviousSelectedStatus(selectedStatus);

      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
  }

  useEffect( ()=>{
    if(requestId){
      getRequestById();
    }
    
  },[requestId]);

  const setShowPassword = (inp,isShow) =>{
    setInputList(prev => prev.map(creds=> {
      if(inp._id===creds._id){
        creds.showPassword = isShow; 
      }
      return creds;
    }));
  }

  const getUsers = async (limit,page, formValuesTester) =>{
   if (user.role == "cshAdmin"){
    let params = `limit=10000000&page=${page}&role=tester`;

    await AxiosInstance.get(`${USER}/tester?${params}`)
    .then(res=>{
      if(res.data.code && res.data.code!==200){
        Toaster(res.data.msg,"error");
      }else{
        for(let i=0;i<res.data.results.length;i++){
          testerArray[i] = [res.data.results[i].name];
          testerArrayEmail[i] = [res.data.results[i].email];
        }
      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
    setTesters(testerArray);
    let alreadyselectedtester = []
    for (let tester in testerArrayEmail){
      formValuesTester?.tester?.map((tester1)=> {
        if (testerArrayEmail[tester][0] === tester1){
          alreadyselectedtester.push( testerArray[tester])
        }
      })
      // if(testerArrayEmail[tester] == formValuesTester.tester){
      //   setSelectedTester([...selectedTester ,testerArray[tester]]);
      // }
    }
    setSelectedTester(alreadyselectedtester)
    setinputIplist(alreadyselectedtester)
    setSelectedTesterEmail(testerArrayEmail);
   }
  }

  const assignTester = (tester) =>{
    let email = [];

    

    for (let i=0; i<testers.length; i++){
      tester.map((tester)=> {
          if (tester === testers[i][0]) {
            email.push(selectedTesterEmail[i][0])
            
          }
      })
    }
    AxiosInstance.post(`${WEB}/assigntester`, {_id: requestId, name: email})
        .then((res)=>{
              Toaster("Tester assigned Successfully", "success")
        })
  }

  const multiChangeHandler = (val)=>{
    let data = []
    for (let ip in val){
        data.push(val[ip].value)
        setSelectedTester([...selectedTester, val[ip].value[0]])
    }
    setinputIplist(data);
  }

  return (
    <Fragment>
      {showLoader && <RctSectionLoader />}
      {formValues && <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Request Details
              </h4>
              <a href={`/reports?requestId=${requestId}`} className="btn btn-primary text-white">
                  View Report
              </a>
            </div>
            <div className="card-body">
              {/* <Table responsive className="w-100"> */}
                <div id="example_wrapper" className="dataTables_wrapper w-100">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                    <tr>
                      <th>Fields</th>
                      <th>Values</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Status</td>
                        <td>{showStatusDD ?
                          <div>
                            <Select
                              data-cy="request-details-status-menu"
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              
                              value={selectedStatus || 'REQUESTED'}
                              onChange={onStatusChange}
                              autoWidth
                            >
                            <MenuItem data-cy="request-details-requested-menu" value={`${status.requested}`}> {status.requested} </MenuItem>
                            <MenuItem data-cy="request-details-inprogress-menu" value={`${status.inProgress}`}>{status.inProgress}</MenuItem>
                            <MenuItem data-cy="request-details-completed-menu" value={`${status.completed}`}>{status.completed}</MenuItem>
                          </Select>
                          
                            <Tooltip title="Save">
                            <IconButton
                            data-cy="save-icon"
                              onClick= {() => saveStatus(true)}
                              aria-label="save" color="primary">
                              <CheckIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                            <IconButton
                            data-cy="cancel"
                              onClick= {() => {
                                setShowStatusDD(false);
                                setSelectedStatus(formValues.status);
                              }}
                              aria-label="cancel" color="secondary">
                              <CloseIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                          </div>
                          :
                          <div>
                            <span className=''>
                            <Badge variant={getBadgeColor(formValues.status)}>
                            {formValues.status}
                            </Badge>
                            </span>
                            {(user.role==='cshAdmin' || user.role==='tester') &&
                              <Tooltip title="Update Status">
                              <IconButton
                              data-cy="pencil-icon"
                                onClick= {() => setShowStatusDD(true)}
                                aria-label="update" color="primary">
                                <CreateIcon fontSize="small" />
                              </IconButton>
                              </Tooltip>
                            }
                          </div>
                          }
                          </td>
                      </tr>
                      <tr>
                        <td>Application Name</td>
                        <td data-cy="request-app-name">{formValues.application_name}</td>
                      </tr>
                      <tr>
                        <td>Target URL</td>
                        <td data-cy="request-app-target">{formValues.url}</td>
                      </tr>
                      <tr>
                        <td>Application Hosted On</td>
                        <td data-cy="request-app-hosted-on">{formValues.hosted_location}</td>
                      </tr>
                      <tr>
                        <td>whitelisting Performed</td>
                        <td data-cy="request-app-whitelisted">{formValues.is_whitelisting}</td>
                      </tr>
                      <tr>
                        <td>Scope of the pentest</td>
                        <td data-cy="request-app-scope">{formValues.pentest_scope}</td>
                      </tr>

                      {inputList.map((inp, index) => (
                        <tr key={index}>
                          <td>Role {index + 1}</td>
                          <td>
                            <strong>Login URL:</strong> {inp.login_url}
                            <br />
                            <strong>Role Name:</strong> {inp.role}
                            <br />
                            <strong>Username:</strong> {inp.user_name}
                            <br />
                            <strong>Password:</strong> {inp.showPassword ? inp.password : '********'}
                            {!inp.showPassword ?
                              <Tooltip title="Show">
                                <IconButton
                                  onClick= {() => setShowPassword(inp,true)}
                                  aria-label="show" color="primary">
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            :
                              <Tooltip title="Hide">
                              <IconButton
                                onClick= {() => {
                                  setShowPassword(inp,false);
                                }}
                                aria-label="hide" color="primary">
                                <VisibilityOffIcon fontSize="small" />
                              </IconButton>
                              </Tooltip>
                            }
                          </td>
                        </tr>
                      ))}
                        {(user.role=="cshAdmin")?
                        <>
                        <tr>
                        <td data-cy="request-app-assignee">
                            Assign a tester
                        </td>
                        <td>

                        <CustomClearIndicator inputIplist={inputIplist} changeHandler={multiChangeHandler} ipslist={testers}></CustomClearIndicator>

                            {/* <select value={selectedTester[0]} data-cy="request-app-change-assignee" onChange={async (e)=>{
                              if (selectedTester.filter((item)=>{
                                return item === e.target.value;
                              }).length === 0 ){
                                console.log(e.target.value)
                                setSelectedTester([...selectedTester, e.target.value])
                              }
                            }
                            }>
                                {
                                  testers.map((e,i) =>{
                                    return <option key={i}>{e}</option>
                                  })
                                }
                            </select> */}
                        </td>
                      </tr>
                     
                      <tr>
                        <td></td>
                        <td>
                              <button 
                              onClick={()=> assignTester(selectedTester)}
                              className="btn btn-primary py-2">
                                  Assign
                              </button>
                        </td>
                      </tr></>:null}
                    </tbody>
                  </table>

                </div>
              {/* </Table> */}
            </div>
          </div>
        </div>
      </Row>
      }
    </Fragment>
  );
}

export default ViewWebRequest;