import React, { useState, useEffect } from "react";
import { Button, Figure, Spinner } from "react-bootstrap";
import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { WEB, WEBREPORT } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import { useLocation } from "react-router-dom";
import PreviewModal from "./PreviewModal";
import UploadImgModal from "./UploadImgModal";
import LenthNShow from "../utils/LenthNShow";
import Tooltip from '@material-ui/core/Tooltip';
import status from "../../../../lib/constant/ReportStatus";
import PreviewSubmit from "./PreviewSubmit";
import CkeditorComponent from "./CkeditorComponent";
import CryptoJS from "crypto-js";


const SubmitReport = () => {
  const initialValues = {
    title: '',
    threat: '',
    easeOfExploitation: 'High',
    severity: 'Critical',
    impact: 'High',
    owaspCategory: 'Injection',
    url: '',
    // observation: '',
    // description: '',
    // remediation: '',
    screenshots: []
  }
  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    threat: Yup.string().required("Required"),
    easeOfExploitation: Yup.string().required("Required"),
    severity: Yup.string().required("Required"),
    impact: Yup.string().required("Required"),
    owaspCategory: Yup.string().required("Required"),
    url: Yup.string().url().required("Required"),
    // observation: Yup.string().required("Required"),
    // description: Yup.string().required("Required"),
    // remediation: Yup.string().required("Required"),
  });
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('requestId');
  const requestUserId = new URLSearchParams(search).get('userId');
  const requestcompany = new URLSearchParams(search).get('company');
  const appName = new URLSearchParams(search).get('appName');
  const reportId = new URLSearchParams(search).get('reportId');
  const { user } = useContext(AuthContext);
  const [screenshots, setScreenshots] = useState([]);
  const [showImgModal, setShowImgModal] = useState(false);
  const [showUploadImgModal, setShowUploadImgModal] = useState(false);
  const [modalSS, setModalSS] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [reportID, setReportID] = useState(reportId);

  
  const [showPreview, setshowPreview ] = useState(false);

  // editor data
  const [observation, setobservation] = useState(""); 
  const [description, setdescription] = useState(); 
  const [remediation, setremediation] = useState(); 

  const handleEditorChange = (name, val)=>{
    try {
      if (name === "obser") setobservation(val)
      if (name === "desc") setdescription(val)
      if (name === "reme") setremediation(val)
    } catch (error) {
      console.log(error)
    }
    console.log(val)
  }

  useEffect(() => {
    if (reportId) {
      getWebReportById(reportId);
    } else {
      setShowLoader(false);
    }
  }, []);

  const uploadMultipleFiles = (newImgObj) => {
    setScreenshots([...screenshots, newImgObj]);
    setShowUploadImgModal(false);
  }
  const onImageClick = (screenshot) => {
    setModalSS(screenshot);
    setShowImgModal(true);
  }
  const removeImage = () => {
    let index = screenshots.findIndex(x => x.imgUrl === modalSS.imgUrl);
    if (index > -1) {
      screenshots.splice(index, 1);
    }
    setShowImgModal(false);
  }

  const onSubmit = async (values) => {
    setShowLoader(true);
    try {
      values = {
        ...values,
        request_id: requestId,
        updated_by_name: user.name,
        request_user_id: requestUserId,
        status: status.open,
        company: requestcompany,
        screenshots: screenshots,
        description: CryptoJS.AES.encrypt(description,"cshsecret").toString(),
        observation: CryptoJS.AES.encrypt(observation,"cshsecret").toString(),
        remediation: CryptoJS.AES.encrypt(remediation,"cshsecret").toString(),
      };
    } catch (error) {
      console.log(error, description, observation, remediation);
    }
    
    if (reportID) { // update report
      delete values.id;
      delete values.riskScore;
      delete values.createdAt
      await AxiosInstance.patch(`${WEBREPORT}/${reportID}`, values)
        .then(res => {
          if (res.data.code !== 200 && res.data.code !== 201) {
            Toaster(res.data.msg, "error");
          } else {
            Toaster("Vulnerability updated successfully", "success");
            setShowLoader(false);
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
    } else { // add report
      await AxiosInstance.post(`${WEBREPORT}`, values)
        .then(async (res) => {
          if (res.data.code !== 200 && res.data.code !== 201) {
            Toaster(res.data.msg, "error");
          } else
            setReportID(res.data.data.id);
          Toaster("vulnerability updated successfully", "success");

          // Code for email sending
          let values2 = {
            ...values,
            vulnername: values.title,
            status: status.open,
            severity: values.severity,
            companyid: requestcompany,
            sendemail: user.email,
            requestid: requestId
          };
          const url = `${WEBREPORT}/sendmail`
          await AxiosInstance.post(url, { name: "newvulner", values1: values2 })
            .then((response) => {
              // // console.log(response.data);
            })

          // code for sending slack message
          //             // // console.log(
          //             user.slackChannelId,
          //  values,
          //      values.severity,
          //  values.impact,
          //              values.easeOfExploitation,
          //              user.criticalNotification,
          //              user.highNotification,
          //              user.mediumNotification,
          //              user.lowNotification,
          //              user.infoNotification,)
          await AxiosInstance.post(`${WEBREPORT}/companyjiradetail`, { id: requestId })
            .then((ress) => {
              // console.log('companydeatils', ress.data);
              if (ress.data.slackChannelId != undefined) {
                AxiosInstance.post(`${WEB}/sendSlack`, {
                  type: "submit",
                  slackChannelId: ress.data.slackChannelId,
                  values: values,
                  severity: values.severity,
                  impact: values.impact,
                  easeOfExploitation: values.easeOfExploitation,
                  severitycriticalsetting: ress.data.criticalNotification,
                  severityhighsetting: ress.data.highNotification,
                  severitymediumsetting: ress.data.mediumNotification,
                  severitylowsetting: ress.data.lowNotification,
                  severityinfosetting: ress.data.infoNotification,
                })
                  .then((response) => {
                    // // console.log(response);
                  })
                  .catch((error) => {
                    Toaster(error, "error")
                  })
              }
              // code for adding issue to jira
              AxiosInstance.post(`${WEB}/getjiraConnectionToggle`, { id: ress.data.id })
              .then((res)=>{
                
                setShowLoader(false);
                  if (res.data.jiraverified === true && res.data.jiraconnection === true) {
                    AxiosInstance.post(`${WEB}/jiraissue`, {
                      testing: "false",
                      projecturl: ress.data.jiraprojecturl,
                      username: ress.data.jirausername,
                      apitoken: ress.data.jiraapitoken,
                      projectid: ress.data.jiraprojectid,
                      title: values.title,
                      severity: values.severity,
                      impact: values.impact,
                      easeOfExploitation: values.easeOfExploitation,
                      severitycriticalsetting: ress.data.criticalNotification,
                      severityhighsetting: ress.data.highNotification,
                      severitymediumsetting: ress.data.mediumNotification,
                      severitylowsetting: ress.data.lowNotification,
                      severityinfosetting: ress.data.infoNotification,
                      description: description,
                    })
                      .then((response) => {
                        // console.log(response);
      
      
                      })
                      .catch((error) => {
                        Toaster(error, "error")
                      })
                
                  }
              })
              .catch((err)=>{
                  // console.log(err);
              })

        })

    })
    .catch((err)=>{
      // console.log(err);
    })
  }
}

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getWebReportById = async (id) => {
    setShowLoader(true);
    await AxiosInstance.get(`${WEBREPORT}/${id}`)
      .then(res => {
        if (res.data.code !== 200 && res.data.code !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          formik.setValues(res.data.data.result);
          setdescription(CryptoJS.AES.decrypt(res.data.data.result.description,"cshsecret").toString(CryptoJS.enc.Utf8));
          setobservation(CryptoJS.AES.decrypt(res.data.data.result.observation,"cshsecret").toString(CryptoJS.enc.Utf8));
          setremediation(CryptoJS.AES.decrypt(res.data.data.result.remediation,"cshsecret").toString(CryptoJS.enc.Utf8));
          setScreenshots(res.data.data.result['screenshots']);
          
        }
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
    setShowLoader(false);
  }
  if (user.role === "user")
    return (
      <Route>
        <Redirect to="/page-error-403" />
      </Route>
    );

 
  const previewClose = ()=>{
    // console.log("yes")
    setshowPreview(!showPreview);
  }

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              Submit a Vulnerability for <b> {appName} </b>app
            </h4>
            <button className="btn btn-primary" onClick={()=> previewClose()}>
              Preview
            </button>
            <PreviewSubmit 
              showPreview={showPreview} 
              previewClose={previewClose}
              allTags={description}
              descallTags={observation}
              redemallTags={remediation} 
            />
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form action="" onSubmit={formik.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Title*</label>
                    <input
                      data-cy="submit-vuln-ttitle-name"
                      id='title'
                      type="text"
                      className="form-control"
                      name='title'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="formerrormessage">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Threat*</label>
                    <input
                      data-cy="submit-vuln-threat-name"
                      id='threat'
                      type="text"
                      className="form-control"
                      name='threat'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.threat}
                    />
                    {formik.touched.threat && formik.errors.threat ? (
                      <div className="formerrormessage">
                        {formik.errors.threat}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-label">Severity</label>
                    <select
                      data-cy="submit-vuln-severity"
                      id='severity'
                      name="severity"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.severity}
                    >
                      <option value='Critical'>Critical</option>
                      <option value='High'>High</option>
                      <option value='Medium'>Medium</option>
                      <option value='Low'>Low</option>
                      <option value='Info'>Info</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-label">Ease of Exploitation</label>
                    <select
                      data-cy="submit-vuln-threat-ease-exploitation"
                      id='easeOfExploitation'
                      name="easeOfExploitation"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.easeOfExploitation}
                      required
                    >
                      <option value='High'>High</option>
                      <option value='Medium'>Medium</option>
                      <option value='Low'>Low</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label className="text-label">Impact</label>
                    <select
                      data-cy="submit-vuln-threat-impact"
                      id='impact'
                      name="impact"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.impact}
                      required
                    >
                      <option value='High'>High</option>
                      <option value='Medium'>Medium</option>
                      <option value='Low'>Low</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label className="text-label">Owasp Category</label>
                    <select
                      data-cy="submit-vuln-threat-owasp-category"
                      id='owaspCategory'
                      name="owaspCategory"
                      className="form-control form-control-lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.owaspCategory}
                      required
                    >
                      <option value='Injection'>Injection</option>
                      <option value='Broken authentication'>Broken authentication</option>
                      <option value='Sensitive data exposure'>Sensitive data exposure</option>
                      <option value='XML external entities (XXE)'>XML external entities (XXE)</option>
                      <option value='Broken access control'>Broken access control</option>
                      <option value='Security misconfigurations'>Security misconfigurations</option>
                      <option value='Cross site scripting (XSS)'>Cross site scripting (XSS)</option>
                      <option value='Insecure deserialization'>Insecure deserialization</option>
                      <option value='Using components with known vulnerabilities'>Using components with known vulnerabilities</option>
                      <option value='Insufficient logging and monitoring'>Insufficient logging and monitoring</option>

                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Affected URL*</label>
                    <input
                      data-cy="submit-vuln-threat-affected-url"
                      id='url'
                      type="text"
                      name='url'
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.url}
                    />
                    {formik.touched.url && formik.errors.url ? (
                      <div className="formerrormessage">
                        {formik.errors.url}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-12 mb-2">
                      <label>Description*</label>
                      <CkeditorComponent setdata={handleEditorChange} name="desc" value={description} />
                  </div>
                  <div className="col-lg-12 mb-2">
                      <label>Observation*</label>
                      <CkeditorComponent setdata={handleEditorChange} name="obser" value={observation} />
                  </div>
                  <div className="col-lg-12 mb-2">
                      <label>Remediation*</label>
                      <CkeditorComponent setdata={handleEditorChange} name="reme" value={remediation} />
                  </div>
                  
                  
                  <div className="input-group mb-3 col-md-12">
                    <div className="flex-wrap input-group-prepend">
                      <span data-cy="submit-vuln-threat-upload-1" className="text-wrap input-group-text">Upload Screenshots of POC(with steps on screenshot)</span>
                      &nbsp;
                      <Button data-cy="submit-vuln-threat-upload-2" variant="primary light"
                        onClick={() => setShowUploadImgModal(true)}>
                        Add Screenshot
                      </Button>
                    </div>
                    <div className="form-group multi-preview">
                      <div className='row'>
                        {(screenshots || []).map(ss => (
                          <div className='col-md-3 col-sm-3 col-xs-3'>
                            <Figure>
                              <Figure.Image data-cy="submit-vuln-threat-image-upload-button" style={{ cursor: 'pointer' }}
                                src={`${ss.imgUrl}`} onClick={() => onImageClick(ss)} thumbnail
                              />
                              <Figure.Caption>
                                <Tooltip data-cy="submit-vuln-tooltip" title={`${ss.imgText}`}>
                                  <LenthNShow value={ss.imgText} n={10} />
                                </Tooltip>
                              </Figure.Caption>
                            </Figure>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  data-cy="submit-vuln-submit-vulnerability"
                  variant="primary"
                  className="mb-2 mr-2"
                  type="submit"
                  disabled={showLoader}
                >
                  {showLoader &&
                    <Spinner animation="border" variant="warning" />
                  }
                  {reportID ? <>Update Vulnerability</>
                    : <>Submit Vulnerability</>}
                </Button>
                <Button className="mb-2 mr-2" onClick={()=> previewClose()}>
              Preview
            </Button>

              </form>
            </div>
          </div>
        </div>
        {showUploadImgModal && <UploadImgModal
          showUploadImgModal={showUploadImgModal}
          setShowUploadImgModal={setShowUploadImgModal}
          uploadMultipleFiles={uploadMultipleFiles}
        />
        }
        {showImgModal && <PreviewModal
          showImgModal={showImgModal}
          setShowImgModal={setShowImgModal}
          modalSS={modalSS}
          removeImage={removeImage} />}
      </div>
    </div>
  );
};

export default SubmitReport;
