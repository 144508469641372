import React from 'react'
import logo from "../../../../images/logo-csh.png";


const ThankYou = (props) => {

  return (
    <div style={{height: '100vh'}} className=" d-flex justify-content-center align-items-center">
        	<div style={{height: '75vh',boxShadow: '0px 5px 79px 0px rgb(0, 0, 0, 29%)', width: '75%'}} className=" d-flex justify-content-center flex-column align-items-center">
              <div>
                <img style={{width: '300px'}} src={logo} alt="" />
              </div>
              <div className="mt-4 ">
                <h1>Thank You For Subscribing To Threat Scan</h1>
              </div>
              <div className="mt-4">
                <h4 style={{color: "black"}}>Please login to access Threat Scan.
</h4>
              </div>
              <a className="mt-4 btn btn-primary text-light" href="/login">Login</a>
          </div>
    </div>
  )
}

export default ThankYou