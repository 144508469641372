import React from "react";
import ReactApexChart from "react-apexcharts";
class VulnerabilitiesBar extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.vulCounts) {
      var series = [
        this.props.vulCounts.totalcriticalCount,
        this.props.vulCounts.totalhighCount,
        this.props.vulCounts.totalmediumCount,
        this.props.vulCounts.totallowCount,
        this.props.vulCounts.totalinfoCount,
      ];
    } else if(this.props.vulCountsWeb && this.props.vulCountsNet) {
      var series = [
        this.props.vulCountsWeb?.totalcriticalCount +
          this.props.vulCountsNet?.totalcriticalCount,
        this.props.vulCountsWeb?.totalhighCount +
          this.props.vulCountsNet?.totalhighCount,
        this.props.vulCountsWeb?.totalmediumCount +
          this.props.vulCountsNet?.totalmediumCount,
        this.props.vulCountsWeb?.totallowCount +
          this.props.vulCountsNet?.totallowCount,
        this.props.vulCountsWeb?.totalinfoCount +
          this.props.vulCountsNet?.totalinfoCount,
      ];
    }
    this.state = {
      series: series,
      options: {
        chart: {
          type: "donut",
          fontFamily: "Montserrat, Poppins",
        },
        legend: {
          position: "bottom",
        },

        responsive: [
          {
            breakpoint: 320,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: ["Critical", "High", "Medium", "Low", "Info"],
        colors: ["#ff3333", "#ff7a56", "#ffa64f", "#ffd759", "#4392de"],
      },
    };
  }

  render() {
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='donut'
        />
      </div>
    );
  }
}

export default VulnerabilitiesBar;
