import React from "react";
import RoleForm from "./RoleForm";

const Form = ({
  setShowTextBox,
  setshowTestModal,
  onCommentSubmit,
  onStatusChange,
  saveTestLimit,
  Limit
}) => {
  return (
    <>
      <div className='row'>
        <RoleForm
          setShowTextBox={setShowTextBox}
          setshowTestModal={setshowTestModal}
          onCommentSubmit={onCommentSubmit}
          onStatusChange={onStatusChange}
          saveTestLimit={saveTestLimit}
          Limit={Limit}
        />
      </div>
    </>
  );
};

export default Form;
