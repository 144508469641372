import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React, { useState, useEffect } from "react";
import formValidation from "./formValidation";
import FourthStep from "./StepFour";
import ThirdStep from "./StepThree";
import SecondStep from "./StepTwo";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { WEB } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";


// Step titles
const useStyles = (theme) => ({
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "pink",
    },
    "& $disabled": {
      color: "red",
    },
  },
});

const StepForm = () => {

  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('requestId');
  const [inputList, setInputList] = useState([
    { role: "", user_name: "", password: "", login_url: "" },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState({})
  const labels = ["Application Details", "User Roles Details", "Preview and Submit"];

  const date = new Date().toLocaleDateString();

  const initialValues = {
    // company_name: "",
    // primary_contact_name: "",
    // email: "",
    // phone: "",
    // secondary_phone: "",
    // type: "web",
    application_name: "",
    url: "",
    hosted_location: "",
    is_whitelisting: "",
    pentest_scope: "",
    password: "",
    login_url: "",
    user_name: "",
    credentials: inputList,
    date: date,
    completeDate: "pending",
    tester: 'none',
  };

  const fieldsValidation = {
    application_name: {
      error: "Required",
      validate: "text",
    },
    url: {
      error: "Required",
      // validate: "ipAddress",
    },
    hosted_location: {
      error: "Required",
      validate: "text",
    },
    is_whitelisting: {
      error: "Required",
      validate: "text",
    },
    pentest_scope: {
      error: "Required",
      validate: "text",
    },
    login_url: {
      error: "Required",
      // validate: "url",
    },
    role: {
      error: "Required",
      validate: "text",
    },
    user_name: {
      error: "Required",
      validate: "text",
    },
    password: {
      error: "Required",
      validate: "text",
    },
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const classes = useStyles();


  useEffect(() => {
  

    if (requestId) {
      getRequestById();
    }
    // check the the user plans limit

  }, []);




  const getRequestById = async () => {
    await AxiosInstance.get(`${WEB}/${requestId}`)
      .then(res => {
        setFormValues(res.data.data);
        setInputList(res.data.data.credentials);
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
  }

  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle form change
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";
    if (index >= 0) {
      setFormErrors({
        [name]: error,
        position: index,
      });
    } else {
      setFormErrors({
        [name]: error,
      });
    }
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
            setFormValues={setFormValues}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        );
      case 1:
        return (
          <ThirdStep
            handleNext={handleNext}
            setInputList={setInputList}
            inputList={inputList}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <FourthStep
            dataValues={formValues}
            setFormValues={setFormValues}
            handleBack={handleBack}
            inputList={inputList}
            requestId={requestId}
            selectedCompany={selectedCompany}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box style={{ margin: "0 0 2rem" }}>
        <h3 align='center'>Submit the details of your web app</h3>
        <p align='center'>Using the wizard below submit all the details of your web application<br/>
Status will be updated post verification. For further queries please contact support.</p>
      </Box>
        <div className="card">
          <div className="card-body">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              classes={{
                root: classes.root,
              }}
            >
              {labels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {handleSteps(activeStep)}
          </div>
        </div>
    </>
  );
};

export default StepForm;
