import React from "react";
export default function LenthNShow  ({value,n}){
    if(value){
      return <>
        {
          value.trim().length > n
          ? value.slice(0, n) + "..."
          : value.trim()
        }
      </>
    }
    return null 
  }