import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../../../../images/logo-csh.png";
import 'react-phone-number-input/style.css'


const ApplicationDetails = (props) => {


    const initialValues = {
        email: (props.user.email == "")?props.returnUser.email:props.user.email,
        company: (props.user.company=="")?props.returnUser.company:props.user.company,
        applicationName: '',
        applicationPurpose: '',
        applicationTotlPages: '',
        applicationTotalRoles: '',
        applicationFormFields: '',
        applicationTotalApi: '',
        applicationAverageNoMethodApi: '',
        applicationAverageNoParameterApi: '',
    };

    const validationSchema = Yup.object({
        applicationName: Yup.string().required("Required"),
        applicationPurpose: Yup.string().required("Required")

    });

    const formik = useFormik({
        initialValues,
        validationSchema,
    });


    const submitHandler = async () => {
        if (formik.errors.length == undefined) {
            props.changeApplicationDetails(formik.values);
            // // submiting company data
            // try {
            //     axios
            //         .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/registerCompany`, formik.values)
            //         .then(async (res) => {
            //             if (res.status == 201) {
            //                 Toaster(`Company Details Submit Successfully${res.statusText}`, "success");
            //                 props.changeApplicationDetails(formik.values);

                       

            //                 props.nextStep();
            //             }
            //             else {
            //                 Toaster("Fill all the details",res.msg, "error");
            //             }
            //             // // console.log('company response', res);
            //         })
            //         .catch((error) => {
            //             if (error) {
            //                 Toaster("Error 404", "error");
            //             }
            //         });
            // }
            // catch (error) {
            //     Toaster(error, "error");

            // }
            props.nextStep();

        }
    }
    return (
        <div>
            <div class="col-11 col-md-8 col-lg-6 multisteps-form__panel js-active" data-animation="slideHorz">
                <div className="form-logo mt-5">
                    <img width="150" src={logo} alt="" />
                </div>
                <div class="wizard-forms">
                    <div class="inner pb-100 clearfix">
                        <div class="wizard-title">
                            <h3>Application Details</h3>
                        </div>

                        <form action="" onSubmit={formik.handleSubmit}>
                            <div class="wizard-form-field mb-85">
                                <div class="wizard-form-input">
                                    <label>Name of the application</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationName}
                                        type="text"
                                        name="applicationName" />
                                    {formik.touched.applicationName && formik.errors.applicationName ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationName}
                                        </div>
                                    ) : null}
                                </div>
                                <div class="wizard-form-input">
                                    <label>Purpose of the application</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationPurpose}
                                        type="text"
                                        name="applicationPurpose" />
                                    {formik.touched.applicationPurpose && formik.errors.applicationPurpose ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationPurpose}
                                        </div>
                                    ) : null}
                                </div>


                                <div class="wizard-form-input">
                                    <label>Number of diffrent roles in application (Eg: Admin, Guest, User, HR, Reviewer etc)</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationTotalRoles}
                                        type="range"
                                        name="applicationTotalRoles"
                                        list="tickmarks"
                                        min="1"
                                        max="5"
                                    />
                                    <datalist id="tickmarks">
                                        <option value="1"></option>
                                        <option value="2"></option>
                                        <option value="3"></option>
                                        <option value="4"></option>
                                        <option value="5"></option>
                                    </datalist>
                                    {formik.touched.applicationTotalRoles && formik.errors.applicationTotalRoles ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationTotalRoles}
                                        </div>
                                    ) : null}
                                    {formik.values.applicationTotalRoles}
                                </div>

                                <div class="wizard-form-input">
                                    <label>Number of static/dynamic pages including Authenticated Pages</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationTotlPages}
                                        type="number"
                                        name="applicationTotlPages" />
                                    {formik.touched.applicationTotlPages && formik.errors.applicationTotlPages ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationTotlPages}
                                        </div>
                                    ) : null}
                                </div>
                                <div class="wizard-form-input">
                                    <label>Average number of Form Fields per page</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationFormFields}
                                        type="number"
                                        name="applicationFormFields" />
                                    {formik.touched.applicationFormFields && formik.errors.applicationFormFields ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationFormFields}
                                        </div>
                                    ) : null}
                                </div>
                                <div class="wizard-form-input">
                                    <label>Number of APIs</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationTotalApi}
                                        type="number"
                                        name="applicationTotalApi" />
                                    {formik.touched.applicationTotalApi && formik.errors.applicationTotalApi ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationTotalApi}
                                        </div>
                                    ) : null}
                                </div>
                                <div class="wizard-form-input">
                                    <label>Average Number of Methods per API</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationAverageNoMethodApi}
                                        type="number"
                                        name="applicationAverageNoMethodApi" />
                                    {formik.touched.applicationAverageNoMethodApi && formik.errors.applicationAverageNoMethodApi ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationAverageNoMethodApi}
                                        </div>
                                    ) : null}
                                </div>
                                <div class="wizard-form-input">
                                    <label>Average numner of parameters per API</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.applicationAverageNoParameterApi}
                                        type="number"
                                        name="applicationAverageNoParameterApi" />
                                    {formik.touched.applicationAverageNoParameterApi && formik.errors.applicationAverageNoParameterApi ? (
                                        <div className="formerrormessage">
                                            {formik.errors.applicationAverageNoParameterApi}
                                        </div>
                                    ) : null}
                                </div>




                            </div>
                            <div class="wizard-v3-progress">
                                <span>2 to 4 step</span>
                                <h3>50% to complete</h3>
                                <div class="progress">
                                    <div class="progress-bar" style={{ width: '50%' }}>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="vector-img-one">
                        <img src="assets/img/vb1.png" alt="" />
                    </div>
                    <div class="actions">
                        <ul>
                            <li onClick={() => submitHandler()} ><span class="js-btn-next" title="NEXT">NEXT <i class="fa fa-arrow-right"></i></span></li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ApplicationDetails