import { Add, Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Dropdown } from "react-bootstrap";
import RctSectionLoader from "../utils/RctSectionLoader";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Checkbox } from "./checkbox";
import Search from "./Search";
const Table = ({ Columns, Rows, getBadgeColor,setretestModal,saveBulkStatus,bulkSaveLoader }) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => Rows, [Rows]);
  const [currentPage, setCurrPage] = useState(0);
  // const [open,setOpen]=useState(false)
  // const [Del,setDelete]=useState(false)
  // const [edit,setEdit]=useState(false)
// useEffect(()=>{
//   getWebReportByRequestId(requestId)
// },[bulkSaveLoader])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    prepareRow,
    selectedFlatRows,
    pageOptions,
    gotoPage,
    page,
    pageCount,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5, pageIndex: currentPage },
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className=''>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className=''>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <div className='w-100 p-1'>
      <div className='flex-row p-2 justify-content-between align-center w-100'>
        <div className='flex items-center'>
          <Search filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <table {...getTableProps()} className='w-100'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ borderBottom: "1px solid" }}
              className='text-left'>
              {headerGroup.headers.map((column, idx) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${idx == 0 ? "pl-5 pb-3" : "p-3"}`} >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='w-full'>
          {page.map((row, idx) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className='text-left'>
                {row.cells.map((cell, idx) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`${idx == 0 ? "pl-5 pb-3" : "p-3"}`}
                      style={{ borderBottom: "1px solid" }}>
                      {cell.column.Header === "Severity" ? (
                        <Badge
                          data-cy='vuln-status-badge'
                          variant={getBadgeColor(cell.value)}>
                          {cell.render("Cell")}
                        </Badge>
                      ) : cell.column.Header === "Impact" ? (
                        <Badge
                          data-cy='vuln-status-badge'
                          variant={getBadgeColor(cell.value)}>
                          {cell.render("Cell")}
                        </Badge>
                      ) : cell.column.Header === "Status" ? (
                        <Badge
                          data-cy='vuln-status-badge'
                          variant={getBadgeColor(cell.value)}>
                          {cell.render("Cell")}
                        </Badge>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='d-flex justify-content-between align-content-center p-3'>
        <div className=' d-flex justify-content-center align-content-center'>
          <span className='text-lg font-semibold'>Show</span>

          <Dropdown className=' mx-2 '>
            <Dropdown.Toggle variant='primary' bsPrefix="p-0" className="pl-2 pr-0 py-0">
              {pageSize} <KeyboardArrowDownSharpIcon  fontSize="inherit"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[5, 10, 20].map((pagesize) => (
                <Dropdown.Item
                  onClick={(e) => {
                    setPageSize(pagesize);
                  }}
                  value={pagesize}
                  key={pagesize}>
                  {pagesize}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <span className='text-lg font-semibold'>entries</span>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <span className='font-bold text-skin-primary'>
            Showing {currentPage + 1} page of {pageOptions.length} pages
          </span>
          <div>
          <Tooltip title="previous page" arrow>
          <span>
            <IconButton
              className='rounded-circle'
              disabled={!canPreviousPage}
              color='primary'
              onClick={() => {
                setCurrPage(pageIndex - 1);
                previousPage();
              }}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            </span>
            </Tooltip>
            <Tooltip title="next page" arrow>
            <span>
            <IconButton
              className='rounded-circle hover-bg-green-500'
              disabled={!canNextPage}
              color='primary'
              onClick={() => {
                setCurrPage(pageIndex + 1);
                nextPage();
              }}>
              
              <KeyboardArrowRightIcon />
            </IconButton>
            </span>
            </Tooltip>
          </div><br/>
          <div>
          <Button disabled={selectedFlatRows.length === 0} className="mx-2 py-2" onClick={()=>{
            saveBulkStatus(selectedFlatRows)
          }}>{bulkSaveLoader?<RctSectionLoader/>:"Update"}</Button>
          <Button className="py-2" onClick={() => setretestModal(false)}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
