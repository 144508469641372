import React, { Fragment, useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import PageTitle from "../../../layouts/PageTitle";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { RESOURCES} from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";


import { useLocation } from "react-router-dom";
import { ResourceCardComponent } from "./CustomResourceCard";
import { AddResourceModal } from "./AddResourceDocument";

import Resource from "./Resource";
import { Col, Row } from "react-bootstrap";

const Resources = (props) => {
    const search = useLocation().search;

    const resourceid = new URLSearchParams(search).get("resourceid");


    const { user } = useContext(AuthContext);

    const role = user.role;

    const [show, setShow] = useState(false);

    const [id, setId] = useState("");
    const [body, setBody] = useState("");
    const [title, setTitle] = useState("");
    
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [searchedList, setSearchedList] = useState(data);

    const [showAddResourceModal, setShowAddResourceModal] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const closeAddResourceModal = () => setShowAddResourceModal(false);

    useEffect(() => {
        if (resourceid){
            AxiosInstance.get(`${RESOURCES}/resources?resourceid=${resourceid}`).then(res => {
                
                setId(res.data.id);
                setBody(res.data.body);
                setTitle(res.data.title);
                setShow(true);
             }).catch(err => {
                Toaster(err.response.data.message, "error");
            })
        }
        else{
            AxiosInstance.get(`${RESOURCES}/resource`).then(res => {
                let datasort = res.data.reverse();
                setData(datasort);
                setSearchedList(datasort);
                setShow(false);
             }).catch(err => {
                Toaster(err.response.data.message, "error");
            })
        }


    }, [refresh])

    const deleteResource = (id) => {
        AxiosInstance.delete(`${RESOURCES}/resource`, {
            data: {
                id
            }
        }).then(res => {
            Toaster('Resource deleted successfully', 'success');
            setrefresh(!refresh);

        }).catch(err => {
            Toaster(err.response.data.message, 'error');
        })
    }

    const addOrUpdateResource = (id, title, body, publish) => {
        if(id === "" || id === undefined || id === null) {
            AxiosInstance.post(`${RESOURCES}/resource`, {title, body, publish}).then(res => {
                Toaster('Resource added successfully', 'success');
                setData([...data, res.data]);
                setSearchedList([...searchedList, res.data]);
                handleClose();
                closeAddResourceModal();
            }).catch(err => {
                console.error(err);
                Toaster("Cannot add data", 'error');
            })
        } else {
            AxiosInstance.put(`${RESOURCES}/resource`, {title, body, id, publish}).then(res => {
                Toaster('Resource updated successfully', 'success');


                setBody(res.data.body);
                setTitle(res.data.title);

                handleClose();
                closeAddResourceModal();
            }).catch(err => {
                console.error(err)
                Toaster("Cannot update data", 'error');
            })
        } 
    }

    const handleClose = () => {
        props.history.push(`/resources`);
        setrefresh(!refresh);
        setSearchText("");
    }
    const handleOpen = (id, title, body) => {
        props.history.push(`/resources?resourceid=${id}`);
        setrefresh(!refresh);
    };

    var slicedData = [], size = 2;

    for(var i = 0; i < searchedList.length; i += size) {
        slicedData.push(searchedList.slice(i, i + size));
    }


    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setSearchedList(
            data.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase()) || item.body.toLowerCase().includes(e.target.value.toLowerCase())) 
        );
        // console.log("Searched List", searchedList);
    }

    if(show) {
        return (
            <Resource dbId={id} title={title} body={body} handleClose={handleClose} addOrUpdateResource={addOrUpdateResource} />
        )
    }
    return (
        <Fragment>
            <PageTitle title="Resources" motherMenu={'Resources'} />

            {/* { show ? <ResourceModal handleClose={handleClose} showModal={show} title={title} dbId={id}> { parse(body) } </ResourceModal> : null } */}

            { (role === 'editor' || role === 'cshAdmin') ? (
                <>
                    <AddResourceModal handleClose={closeAddResourceModal} showModal={showAddResourceModal} addOrUpdateResource={addOrUpdateResource} ></AddResourceModal>
                    <span className="float-right"><button className="btn btn-info mb-4" onClick={() => setShowAddResourceModal(true)}><i className="fa fa-plus" aria-hidden="true"></i></button></span>
                </>
             ) : null }
            
            
            <input
                id='searchResources'
                type="text"
                className="form-control"
                name='searchResources'
                onChange={e => handleSearch(e)}
                value={searchText}
                placeholder="Search Resources"
            />
            
            <div className="mt-2">
            { slicedData.map(((row, rindex) => {
                return (
                    <Row key={rindex}>
                        { row.map(((value, index) => {
                            if (value.publish == "draft" && user.role == "cshAdmin" || user.role == "editor"){
                                return (
                                    <Col xl='6' lg='6' key={index}>
                                        <ResourceCardComponent dbId={value.id} title={value.title} text={value.body} handleOpen={ handleOpen} deleteResource={deleteResource} />
                                    </Col>
                                )
                            }
                            else if (value.publish == "publish"){
                                return (
                                    <Col xl='6' lg='6' key={index}>
                                        <ResourceCardComponent dbId={value.id} title={value.title} text={value.body} handleOpen={ handleOpen} deleteResource={deleteResource} />
                                    </Col>
                                )
                            }
                            else{
                                return ""
                            }
                           
                        })) }
                    </Row>
                )
            })) }

            {/* <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ml-2" >

                {
                    searchedList.map((value, index) => {
                        return (
                            <ResourceCardComponent key={value.id} dbId={value.id} title={value.title} text={value.body} handleOpen={handleOpen} deleteResource={deleteResource} />
                        )
                    })
                }

            </Row> */}

            </div>
        </Fragment>
    )
}

export default Resources;

