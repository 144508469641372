
import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Card, Col, Nav, Row, Tab, Button } from 'react-bootstrap'
import { AuthContext } from "../../../../Context/AuthProvider";
import swal from "sweetalert";
import { PLAN } from "../../../../lib/config/URLConstant";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import CSVReader from "react-csv-reader";
import Toaster from "../../Toaster";
import uploadIcon from "../../../../images/upload.png"
import SampleCsv from "../../../../Csv/Sample_csv.csv"

const CSVSubmit = () => {
  return (
    <h4>Upload or Drag a CSV File containing all the IPs here</h4>
  )
}

const StepThree = ({
  ipList,
  handleBack,
  setIpList,
  handleNext,
  handleChange,
  values: { ip_list },
  formErrors,
  setCSVFile,
  setcsvDowload1,
  setmanualIpList,
  csvnamewizard,
  setcsvName,
  manualIpList,
  csv
}) => {
  const [isValid, setIsValid] = useState(false);


  useEffect(() => {
    console.log(csvnamewizard)
  }, []);




  // const fileInput = (e)=> {
  //   // console.log("yes")
  //   let files = e.target.files;
  //   // console.log(files);
  //   let reader = new FileReader();
  //   let result
  //   reader.onload=(e=>{
  //     result = reader.result;
  //     setCSVFile(e.target.result);
  //     // console.log(result.split("\n"));
  //   })
  //   reader.readAsText(files[0]);

  // }

  const [csvdata, setcsvdata] = useState("");
  const [csvDowload, setcsvDowload] = useState("none");
  const [csvname, setcsvname] = useState(csvnamewizard)

  const handleForce = (data, fileInfo, originalFile) => {
    // console.log(data, fileInfo, originalFile);
    setcsvname(fileInfo.name);
    let valid = true;
    if (data.length === 0) {
      Toaster("There should be at least one Ip Adress", "error")
      valid = false;
    }
    else if (data[0].ip == undefined) {
      Toaster("First column Name should be IP. We recommend to use our sample csv file to edit.", "error")
      valid = false;
    }
    else if (data[0].type == undefined) {
      Toaster("Third column name should be Type. We recommend to use our sample csv file to edit.", "error")
      valid = false;
    }
    else if (data[0].internal_external == undefined) {
      Toaster("Second column name should be Internal/External. We recommend to use our sample csv file to edit.", "error")
      valid = false;
    }
    for (let ip in data) {
      // if (data[ip].type == undefined) {
      //   Toaster(`Field Type is empty in Line no ${parseInt(ip) + 1}`, "error");
      //   valid = false;
      //   break;
      // }
      // if (data[ip].internal_external == undefined){
      //   Toaster(`Field Internal/External is empty in Line no ${parseInt(ip) + 1}`, "error");
      //   valid = false;
      //   break;
      // }
      if (data[ip].ip == undefined) {
        Toaster(`Field IP is empty in Line no ${parseInt(ip) + 1}`, "error");
        valid = false;
        break;
      }
      if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(data[ip].ip))) {
        Toaster(`Ip adress is inavlid in line no ${parseInt(ip) + 1}`, "error");
        valid = false;
        break;
      }
      else if (data[ip].internal_external != "internal" && data[ip].internal_external != "external") {
        Toaster(`Internal/External network type field should be either "internal" or "external" in line no ${parseInt(ip) + 1}`, "error");
        valid = false;
        break;
      }
    }

    if (valid) {
      const newdata = [...data.reduce((map, obj) => map.set(obj.ip, obj), new Map()).values()];
      setcsvdata(newdata);
      let reader = new FileReader();
      reader.readAsDataURL(originalFile);

      reader.onload = (e) => {
        setcsvDowload(e.target.result);
      }
      
    }
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  // form data

  const [ipsList, setipsList] = useState(manualIpList || [""]);
  const [tab, settab] = useState(csv?.length > 0 ? "CSV" : "Form");
  const focusInput = useRef();

  const validateIpList = (nextbutton) => {
    if (csvdata == "" && csvname == undefined) {
      if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipsList[ipsList.length - 1]))) {
        Toaster("Please enter a valid IP address or upload a csv file", "error")
      }
      else if (ipsList[ipsList.length - 1] == "") Toaster("Input box cannot be empty", "error")
      else if (nextbutton && ipsList.length > 5 || !nextbutton && ipsList.length === 5) Toaster("Cannot add more than 5 IP's. Please upload a CSV.", "error")
      else if ((ipsList.filter((e) => e == ipsList[ipsList.length - 1])).length > 1) Toaster("Ip address cannot be same", "error")
      else return true
    }
    else {
      return true
    }
  }

  const addRow = () => {
    if (validateIpList()) setipsList([...ipsList, ""])
  }


  const deleteRow = (index) => {
    if (ipsList.length > 1) {
      let temp = [...ipsList];
      temp.splice(index, 1)
      setipsList(temp)
    }
  }
  const addData = async (val, index) => {
    let data = []
    data = [...ipsList];
    data[index] = val;
    setipsList(data);
  }

  const tabData = [
    {
      name: 'Form',
      icon: 'clipboard',
      content: () => {
        return (
          <>
            <h4>Form Submit (Add upto 5 Ips)</h4>
            {
              ipsList.map((ip, index) => {
                return <>
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <h5>{index + 1}</h5>
                    <input value={ipsList[index]} onChange={(e) => addData(e.target.value, index)} className="form-control my-3 w-75" type="text" />
                    <i onClick={() => addRow()} className="fa fa-plus-circle text-primary"></i>
                    <i onClick={() => deleteRow(index)} className="fa fa-trash text-danger"></i>
                  </div>
                </>
              })
            }


          </>
        )
      },
    },
    {
      name: 'CSV',
      icon: 'table',
      content: () => {
        return (
          <>
            <h4>Upload or Drag a CSV File containing all the IPs here</h4>


            {/* <div style={{ height: '300px', backgroundColor: 'rgba(0,0,0,0.1)' }} className="mt-5 d-flex w-100 justify-content-center align-items-center ">

              <CSVReader
                cssClass="react-csv-input"
                label="Select CSV with secret Death Star statistics"
                onFileLoaded={handleForce}
                parserOptions={papaparseOptions}
              />

            </div> */}

            <input type="file" onChange={(e) => console.log(e)} />

          </>
        )
      },
    },
  ]

  const ipValidation = () => {
    if (validateIpList(true)) {
      if (csvdata == "" && ipsList == "" && csvname == undefined) {
        Toaster("Please fill the ips details.", "error")
      }
      else {
        handleNext();
      }
    }
  }



  return (
    <>
      <Fragment>
        {
          <div className='default-tab'>
            <Tab.Container defaultActiveKey={tab ? tabData[0].name.toLowerCase() : tabData[1].name.toLowerCase()}>
              <Nav as='ul' className='nav-tabs'>
                {tabData.map((data, i) => (
                  <Nav.Item onClick={() => settab(tab === data.name ? tab : data.name)} as='li' key={i}>

                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      <div>
                        <i className={`la la-${data.icon} mr-2`} />
                        <p >{data.name}</p>
                      </div>

                    </Nav.Link>

                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className='pt-4'>
                {
                  tab === "Form" ?
                    <>
                      <h4>Form Submit (Add upto 5 Ips)</h4>
                      {
                        ipsList.map((ip, index) => {
                          return <>
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <h5>{index + 1}</h5>
                              <input value={ipsList[index]} onChange={(e) => addData(e.target.value, index)} className="form-control my-3 w-75" type="text" />
                              <i style={{ cursor: 'pointer' }} onClick={() => addRow()} className="fa fa-plus-circle text-primary"></i>
                              <i style={{ cursor: 'pointer' }} onClick={() => deleteRow(index)} className="fa fa-trash text-danger"></i>
                            </div>
                          </>

                        })
                      }
                    </> : <>
                      <div className="d-flex  justify-content-between">
                        <h4>Upload or Drag a CSV File containing all the IPs here</h4>
                        <button className="btn btn-primary"><a href={SampleCsv} download> Download sample CSV </a></button>
                        {/* <h5> file from  here</a> </h5> */}
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center mt-5 d-flex w-100 justify-content-center align-items-center " style={{ position: 'relative', flexDirection: 'column', height: '300px', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                        <img src={uploadIcon} alt="" />
                        <div className="d-flex">
                          <p>Drag or drop or choose a file to </p>
                          <CSVReader
                            cssClass="react-csv-input customcdvupload"
                            label=""
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                          />
                        </div>
                        <p>upload your data. Only csv file types are supported.</p>
                        <p>{csvname}</p>
                      </div>

                    </>
                }
              </Tab.Content>
            </Tab.Container>
          </div>
        }

        <div className="col-xs-12 col-lg-12 mb-2">
          <div
            style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="default"
              onClick={handleBack}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained"
              style={{ marginRight: 10 }}
            >
              Back
            </Button>
            <Button
              data-cy="submit"
              variant="contained"
              color="primary"
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
              onClick={() => {
                setCSVFile(csvdata);
                setcsvDowload1(csvDowload)
                setcsvName(csvname)
                setmanualIpList(ipsList);
                ipValidation();
              }
              }
            >
              Next
            </Button>
          </div>
        </div>
      </Fragment>
    </>
  );
};
export default StepThree;






