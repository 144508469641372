import { responsiveFontSizes } from "@material-ui/core";
import React from "react";
import ReactApexChart from "react-apexcharts";
class StatusBar3 extends React.Component {
    constructor(props) {
        super(props);
        var categories=[];
        for(var i=0;i<5;i++){
            categories=[...categories,(this.props?.webRequests[i]?.networkname) ? (this.props?.webRequests[i]?.networkname == undefined) ? (this.props?.webRequests[i]?.title==undefined) ?"": this.props?.webRequests[i]?.title:(this.props?.webRequests[i]?.networkname==undefined)?"":this.props?.webRequests[i]?.networkname:this.props?.webRequests[i]?.application_name?(this.props?.webRequests[i]?.application_name == undefined) ? (this.props?.webRequests[i]?.title==undefined) ?"": this.props?.webRequests[i]?.title:(this.props?.webRequests[i]?.application_name==undefined)?"":this.props?.webRequests[i]?.application_name:""];
        }
        console.log(categories)
        this.state = {
            data: true,
            series: [{
                name: 'Critical',
                data: [this.props?.statudVulcounts?.criticalCount[0]==undefined? "":this.props?.statudVulcounts?.criticalCount[0], 
                this.props?.statudVulcounts?.criticalCount[1]==undefined? "0": this.props?.statudVulcounts?.criticalCount[1], 
                this.props?.statudVulcounts?.criticalCount[2]==undefined? "0": this.props?.statudVulcounts?.criticalCount[2], 
                this.props?.statudVulcounts?.criticalCount[3]==undefined? "0": this.props?.statudVulcounts?.criticalCount[3], 
                this.props?.statudVulcounts?.criticalCount[4]==undefined? "0": this.props?.statudVulcounts?.criticalCount[4]],
                color:'#FF3333'
              }, {
                name: 'High',
                data: [this.props?.statudVulcounts?.highCount[0]==undefined? "0": this.props?.statudVulcounts?.highCount[0], 
                this.props?.statudVulcounts?.highCount[1]==undefined? "0": this.props?.statudVulcounts?.highCount[1], 
                this.props?.statudVulcounts?.highCount[2]==undefined? "0": this.props?.statudVulcounts?.highCount[2], 
                this.props?.statudVulcounts?.highCount[3]==undefined? "0": this.props?.statudVulcounts?.highCount[3], 
                this.props?.statudVulcounts?.highCount[4]==undefined? "0": this.props?.statudVulcounts?.highCount[4]],
                color:'#FF7A56'
              }, {
                name: 'Medium',
                data: [this.props?.statudVulcounts?.mediumCount[0]==undefined? "0": this.props?.statudVulcounts?.mediumCount[0],
                 this.props?.statudVulcounts?.mediumCount[1]==undefined? "0": this.props?.statudVulcounts?.mediumCount[1], 
                 this.props?.statudVulcounts?.mediumCount[2]==undefined? "0": this.props?.statudVulcounts?.mediumCount[2], 
                 this.props?.statudVulcounts?.mediumCount[3]==undefined? "0": this.props?.statudVulcounts?.mediumCount[3], 
                 this.props?.statudVulcounts?.mediumCount[4]==undefined? "0": this.props?.statudVulcounts?.mediumCount[4]],
                color:'#FFA64F'
              }, {
                name: 'Low',
                data: [this.props?.statudVulcounts?.lowCount[0]==undefined?"0":this.props?.statudVulcounts?.lowCount[0], 
                this.props?.statudVulcounts?.lowCount[1]==undefined?"0":this.props?.statudVulcounts?.lowCount[1], 
                this.props?.statudVulcounts?.lowCount[2]==undefined?"0":this.props?.statudVulcounts?.lowCount[2], 
                this.props?.statudVulcounts?.lowCount[3]==undefined?"0":this.props?.statudVulcounts?.lowCount[3], 
                this.props?.statudVulcounts?.lowCount[4]==undefined?"0":this.props?.statudVulcounts?.lowCount[4]],
                color:'#FFD759'
              }
                ,
              {
                name: 'Info',
                data: [this.props?.statudVulcounts?.infoCount[0]==undefined? "0":this.props?.statudVulcounts?.infoCount[0],
                this.props?.statudVulcounts?.infoCount[1]==undefined? "0":this.props?.statudVulcounts?.infoCount[1], 
                this.props?.statudVulcounts?.infoCount[2]==undefined? "0":this.props?.statudVulcounts?.infoCount[2],
                 this.props?.statudVulcounts?.infoCount[3]==undefined? "0":this.props?.statudVulcounts?.infoCount[3],
                  this.props?.statudVulcounts?.infoCount[4]==undefined? "0":this.props?.statudVulcounts?.infoCount[4]],
                color:'#4392DE'
              }
              ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    fontFamily: 'Montserrat, Poppins',
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: categories,
                    labels: {
                        rotate: 0,
                    }
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                

                },
                fill: {
                    opacity: 1
                }
            },


        };
    }



  



    render() {
        // // // console.log(this.props?.statudVulcounts);
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default StatusBar3;
