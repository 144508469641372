/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { networkperm } from "../../../API/networkperm";
import { AuthContext } from "../../../Context/AuthProvider";

// import drump from "../../../images/card/drump.png";

class MM extends Component {

  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  componentWillUnmount() {
    //  this.mm("dispose");
    // // // console.log(this.mm);
  }
  render() {

    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkperm: false,
      path: ""
    };
  }
  static contextType = AuthContext;

  /// Open menu
  componentDidMount() {
    // sidebar open/close

    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    document.querySelector("#main-wrapper > div.deznav > div > div.mm-wrapper > ul > li > a")

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
    this.getNetworkPermissions()

    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    this.setState({
      path: path[path.length - 1]
    });

  }

  componentDidUpdate(){
    let path = window.location.pathname;
    path = path.split("/");
    if (this.state.path != path[1]){
      this.setState({
        path: path[path.length - 1]
      });
    }
    
  }

  getNetworkPermissions = async () => {
    let prem = await networkperm(this.context.user);
    this.setState({
      networkperm: prem
    })
  }
  render() {
    const { loggedIn, user } = this.context;
    var d = new Date();



    /// Active menu

    let deshBoard = [
      "",
      "my-wallet",
      "coin-details",
      "portfolio",
      "transactions",
      "market-capital",
    ],
      support = ["contact-support", 'support'],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
      ],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = ["table-bootstrap-basic", "table-datatable-basic"],
      pages = [
        "requests-web",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      cshpages = [
        "dashboard",
        "submit-webapp",
        "submit-network",
        "submit-android",
        "submit-ios",
        "reports",
        "submit-report",
      ],
      resources = ['resources'];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {user.role === "xadmin" && (
              <li className={`${deshBoard.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-networking"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "" ? "mm-active" : ""}`}
                      to={loggedIn ? "" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "my-wallet" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/my-wallet" : "/login"}
                    >
                      My Wallet
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "coin-details" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/coin-details" : "/login"}
                    >
                      Coin Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "portfolio" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/portfolio" : "/login"}
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "transactions" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/transactions" : "/login"}
                    >
                      Transactions
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "market-capital" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/market-capital" : "/login"}
                    >
                      Market Capital
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${app.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-television"></i>
                  <span className="nav-text">Apps</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "app-profile" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/app-profile" : "/login"}
                    >
                      Profile
                    </Link>
                  </li>
                  <li className={`${email.includes(this.state.path) ? "mm-active" : ""}`}>
                    <Link
                      className="has-arrow"
                      to={loggedIn ? "#" : "/login"}
                      aria-expanded="false"
                    >
                      Email
                    </Link>
                    <ul
                      aria-expanded="false"
                      className={`${email.includes(this.state.path) ? "mm-show" : ""}`}
                    >
                      <li>
                        <Link
                          className={`${this.state.path === "email-compose" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/email-compose" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Compose
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "email-inbox" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/email-inbox" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Inbox
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "email-read" ? "mm-active" : ""
                            }`}
                          // to="/email-read"
                          to={loggedIn ? "/email-read" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Read
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "app-calender" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/app-calender" : "/login"}
                    >
                      Calendar
                    </Link>
                  </li>
                  <li className={`${shop.includes(this.state.path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#" aria-expanded="false">
                      Shop
                    </Link>
                    <ul
                      aria-expanded="false"
                      className={`${shop.includes(this.state.path) ? "mm-show" : ""}`}
                    >
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-product-grid" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-product-grid" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Product Grid
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-product-list" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-product-list" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Product List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-product-detail" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-product-detail" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Product Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-product-order" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-product-order" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-checkout" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-checkout" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Checkout
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-invoice" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-invoice" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Invoice
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "ecom-customers" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/ecom-customers" : "/login"}
                          onClick={() => this.props.onClick()}
                        >
                          Customers
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${charts.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-controls-3"></i>
                  <span className="nav-text">Charts</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "chart-rechart" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/chart-rechart" : "/login"}
                    >
                      RechartJs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "chart-chartjs" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/chart-chartjs" : "/login"}
                    >
                      Chartjs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "chart-chartist" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/chart-chartist" : "/login"}
                    >
                      Chartist
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "chart-sparkline" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/chart-sparkline" : "/login"}
                    >
                      Sparkline
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "chart-apexchart" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/chart-apexchart" : "/login"}
                    >
                      Apexchart
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${bootstrap.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-internet"></i>
                  <span className="nav-text">Bootstrap</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "ui-accordion" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-accordion" : "/login"}
                    >
                      Accordion
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-alert" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-alert" : "/login"}
                    >
                      Alert
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-badge" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-badge" : "/login"}
                    >
                      Badge
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-button" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-button" : "/login"}
                    >
                      Button
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-modal" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-modal" : "/login"}
                    >
                      Modal
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-button-group" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-button-group" : "/login"}
                    >
                      Button Group
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-list-group" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-list-group" : "/login"}
                    >
                      List Group
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-media-object" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-media-object" : "/login"}
                    >
                      Media Object
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-card" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-card" : "/login"}
                    >
                      Cards
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-carousel" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-carousel" : "/login"}
                    >
                      Carousel
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-dropdown" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-dropdown" : "/login"}
                    >
                      Dropdown
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-popover" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-popove" : "/login"}
                    >
                      Popover
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-progressbar" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-progressbar" : "/login"}
                    >
                      Progressbar
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-tab" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-tab" : "/login"}
                    >
                      Tab
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-typography" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-typography" : "/login"}
                    >
                      Typography
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-pagination" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-pagination" : "/login"}
                    >
                      Pagination
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "ui-grid" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/ui-grid" : "/login"}
                    >
                      Grid
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${plugins.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-heart"></i>
                  <span className="nav-text">Plugins</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "uc-select2" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/uc-select2" : "/login"}
                    >
                      Select 2
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "uc-nestable" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/uc-nestable" : "/login"}
                    >
                      Nestedable
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "uc-noui-slider" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/uc-noui-slide" : "/login"}
                    >
                      Noui Slider
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "uc-sweetalert" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/uc-sweetalert" : "/login"}
                    >
                      Sweet Alert
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "uc-toastr" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/uc-toastr" : "/login"}
                    >
                      Toastr
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "map-jqvmap" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/map-jqvmap" : "/login"}
                    >
                      Jqv Map
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${widget.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  to={loggedIn ? "/widget-basic" : "/login"}
                  className="ai-icon"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">Widget</span>
                </Link>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${forms.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text forms">Forms</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "form-element" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/form-element" : "/login"}
                    >
                      Form Elements
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "form-wizard" ? "mm-active" : ""}`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/form-wizard" : "/login"}
                    >
                      Wizard
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "form-editor-summernote" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/form-editor-summernote" : "/login"}
                    >
                      Summernote
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "form-pickers" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/form-pickers" : "/login"}
                    >
                      Pickers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "form-validation-jquery" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/form-validation-jquery" : "/login"}
                    >
                      Jquery Validate
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${table.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">Table</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className={`${this.state.path === "table-bootstrap-basic" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/table-bootstrap-basic" : "/login"}
                    >
                      Bootstrap
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "table-datatable-basic" ? "mm-active" : ""
                        }`}
                      onClick={() => this.props.onClick()}
                      to={loggedIn ? "/table-datatable-basic" : "/login"}
                    >
                      Datatable
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.role === "xadmin" && (
              <li className={`${pages.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Pages</span>
                </Link>
                <ul aria-expanded="false">
                  {/* <li>
                  <Link
                    to={loggedIn ? "/register" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Register
                  </Link>
                </li> */}
                  {/* <li>
                  <Link
                    to={loggedIn ? "/login" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Login
                  </Link>
                </li> */}
                  <li className={`${error.includes(this.state.path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#" aria-expanded="false">
                      Error
                    </Link>
                    <ul aria-expanded="false">
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-400" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/page-error-400" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Error 400
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-403" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/page-error-403" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Error 403
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-404" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/page-error-404" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Error 404
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-500" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/page-error-500" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Error 500
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-503" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/page-error-503" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Error 503
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`${this.state.path === "page-lock-screen" ? "mm-active" : ""
                        }`}
                      to={loggedIn ? "/page-lock-screen" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Lock Screen
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* Our Dashboard and code begins */}
            {/* Dashboard Navigation. Add the navigation functionality  */}
            {/* {// // console.log(this.state.path)} */}
            {(user.role != "tester" && this.state.networkperm) ?
              <li className={`${this.state.path === "dashboard-overview" ? "mm-active" : ""}`}>
                <Link
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick()}
                  to={loggedIn ? "/dashboard-overview" : "/login"}
                >

                  <i className="flaticon-381-blueprint"></i>
                  <span data-cy="dashboard-menu" className="nav-text">Overview Dashboard</span>
                </Link>
              </li> : null
            }
            {(user.role != "tester") ?
              <li className={`${this.state.path === "dashboard" ? "mm-active" : ""}`}>
                <Link
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick()}
                  to={loggedIn ? "/dashboard" : "/login"}
                >

                  <i className="flaticon-381-cloud"></i>
                  <span data-cy="dashboard-menu" className="nav-text">Web Dashboard</span>
                </Link>
              </li> : null
            }
            {(user.role != "tester" && this.state.networkperm) ?
              <li className={`${this.state.path === "dashboard-network" ? "mm-active" : ""}`}>
                <Link
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick()}
                  to={loggedIn ? "/dashboard-network" : "/login"}
                >

                  <i className="flaticon-381-network-2"></i>
                  <span data-cy="dashboard-menu" className="nav-text">Network Dashboard</span>
                </Link>
              </li> : null
            }

            {/* Dashboard navigation ends */}
            {/* Submit a build starts */}
            {(user.role !== "readOnly" && user.role !== "tester") && <li className={`${this.state.path === "/submit-webapp" || this.state.path === "submit-network" ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-target"></i>
                <span data-cy="submit-app-menu" className="nav-text">Submit Tests</span>
              </Link>
              <ul aria-expanded="false">
                <li data-cy="web-menu">
                  <Link
                    to={loggedIn ? "/submit-webapp" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Web App
                  </Link>
                </li>

                <li>
                  {
                    this.state.networkperm ? <Link
                      to={loggedIn ? "/submit-network" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Network
                    </Link> : null
                  }
                </li>
                {/* <li className={`${error.includes(this.state.path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#" aria-expanded="false">
                    Mobile
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          this.state.path === "submit-android" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/submit-android" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        Android
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          this.state.path === "page-error-403" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/submit-ios" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        iOS
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>}
            {/* Submit an app code ends */}
            {/*My Requests */}
            <li className={`${pages.includes(this.state.path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-layer-1"></i>
                <span data-cy="my-request-menu" className="nav-text">All Tests</span>
              </Link>
              <ul aria-expanded="false">
                <li data-cy="my-request-web-menu">
                  <Link
                    to={loggedIn ? "/requests-web" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Web App
                  </Link>
                </li>
                <li data-cy="my-request-web-menu">
                  {
                    this.state.networkperm ? <Link
                      to={loggedIn ? "/request-network" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Network
                    </Link> : null
                  }
                </li>
                {/* <li>
                  <Link
                    to={loggedIn ? "/requests-network" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Network
                  </Link>
                </li> */}
                {/* <li className={`${error.includes(this.state.path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#" aria-expanded="false">
                    Mobile
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          this.state.path === "page-error-400" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/requests-android" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        Android
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          this.state.path === "page-error-403" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/requests-ios" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        iOS
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>

            {/* My Requests code ends */}
            {/*Companies */}
            {
              (user.role === "cshAdmin") ? <li className={`${pages.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                  <i className="flaticon-381-layer-1"></i>
                  <span data-cy="my-companies-menu" className="nav-text">Companies</span>
                </Link>
                <ul aria-expanded="false">
                  <li data-cy="companies-menu">
                    <Link
                      to={loggedIn ? "/companies" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Companies
                    </Link>
                  </li>
                  {/* <li>
                  <Link
                    to={loggedIn ? "/requests-network" : "/login"}
                    onClick={() => this.props.onClick3()}
                  >
                    Network
                  </Link>
                </li> */}
                  {/* <li className={`${error.includes(this.state.path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow" to="#" aria-expanded="false">
                    Mobile
                  </Link>
                  <ul aria-expanded="false">
                    <li>
                      <Link
                        className={`${
                          this.state.path === "page-error-400" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/requests-android" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        Android
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          this.state.path === "page-error-403" ? "mm-active" : ""
                        }`}
                        to={loggedIn ? "/requests-ios" : "/login"}
                        onClick={() => this.props.onClick3()}
                      >
                        iOS
                      </Link>
                    </li>
                  </ul>
                </li> */}
                </ul>
              </li> : null
            }

            {/* {// // console.log(this.state.path)} */}
            {(user.role === "cshAdmin" && this.state.networkperm) ?
              <li className={`${this.state.path === "retest-queue" ? "mm-active" : ""}`}>
                <Link
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick()}
                  to={loggedIn ? "/retest-queue" : "/login"}
                >

                  <i className="flaticon-381-radar"></i>
                  <span data-cy="dashboard-menu" className="nav-text">Retest Queue</span>
                </Link>
              </li> : null
            }
            {/* {// // console.log(this.state.path)} */}
            {(user.role === "cshAdmin" || user.role === "tester"  && this.state.networkperm) ?
              <li className={`${this.state.path === "retest-queue" ? "mm-active" : ""}`}>
                <Link
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick()}
                  to={loggedIn ? "/comments-queue" : "/login"}
                >

                  <i className="flaticon-381-radar"></i>
                  <span data-cy="dashboard-menu" className="nav-text">Comments</span>
                </Link>
              </li> : null
            }

            {/* My Requests code ends */}
            {/* Admin can only see this - Retest Queue starts */}
            {user.role === "xadmin" && (
              <li className={`${pages.includes(this.state.path) ? "mm-active" : ""}`}>
                <Link
                  className="has-arrow ai-icon"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Retest Queue</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      to={loggedIn ? "/submit-webapp" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Web App1
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={loggedIn ? "/submit-network" : "/login"}
                      onClick={() => this.props.onClick3()}
                    >
                      Network
                    </Link>
                  </li>
                  <li className={`${error.includes(this.state.path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#" aria-expanded="false">
                      Mobile
                    </Link>
                    <ul aria-expanded="false">
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-400" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/submit-android" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          Android
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${this.state.path === "page-error-403" ? "mm-active" : ""
                            }`}
                          to={loggedIn ? "/submit-ios" : "/login"}
                          onClick={() => this.props.onClick3()}
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            )}
            {/* Admin can only see this - Retest Queue ends */}
            {/* Get in touch/Support */}
            <li data-cy="support-menu" className={`${this.state.path === "support" ? "mm-active" : ""}`}>
              <Link to="support" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-incoming-call"></i>
                <span data-cy="support-menu" className="nav-text">Support</span>
              </Link>
            </li>
            {/* Support Code Ends */}
            {/* Get in touch/Support */}
            <li data-cy="resources-menu" className={`${resources.includes(this.state.path) ? "mm-active" : ""}`}>
              <Link to="resources" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-book"></i>
                <span data-cy="resources-menu" className="nav-text">Resources</span>
              </Link>
            </li>
            {/* Support Code Ends */}
            {/* sign up code begins */}
            {/* <li>
              <Link to={`/register`} onClick={() => this.props.onClick3()}>
                signup
              </Link>
            </li>

            <li>
              <Link
                to={loggedIn ? "/register" : "/login"}
                onClick={() => this.props.onClick3()}
              >
                Register
              </Link>
            </li> */}
            {!loggedIn && (
              <li className={`${pages.includes(this.state.path) ? "mm-active" : ""}`}>
                {" "}
                <Link
                  // to="/register"
                  to="/register"
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick3()}
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">signup</span>
                </Link>
              </li>
            )}

            {/* <li>
              <Link
                to={loggedIn ? "/register" : "/login"}
                onClick={() => this.props.onClick3()}
              >
                 <i className="flaticon-381-settings-2"></i>
                <span className="nav-text">signup</span>
              </Link>
            </li> */}
            {/* sign up code ends */}
            {/* sign up code begins */}
            {!loggedIn && (
              <li className={`${this.state.path === "reports" ? "mm-active" : ""}`}>
                <Link
                  to="/login"
                  className="ai-icon"
                  aria-expanded="false"
                  onClick={() => this.props.onClick3()}
                >
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">login</span>
                </Link>
              </li>
            )}
            {/* sign up code ends */}
            {/* Our Dashboard and code ends                   */}
          </MM>
          <div className="add-menu-sidebar">
            <p>Have questions? Get to our support or ask <u>Diana</u></p>
            <Link to="#">
              <svg
                width={24}
                height={12}
                viewBox="0 0 24 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.725 5.14889C23.7248 5.14861 23.7245 5.14828 23.7242 5.148L18.8256 0.272997C18.4586 -0.0922062 17.865 -0.0908471 17.4997 0.276184C17.1345 0.643169 17.1359 1.23675 17.5028 1.602L20.7918 4.875H0.9375C0.419719 4.875 0 5.29472 0 5.8125C0 6.33028 0.419719 6.75 0.9375 6.75H20.7917L17.5029 10.023C17.1359 10.3882 17.1345 10.9818 17.4998 11.3488C17.865 11.7159 18.4587 11.7172 18.8256 11.352L23.7242 6.477C23.7245 6.47672 23.7248 6.47639 23.7251 6.47611C24.0923 6.10964 24.0911 5.51414 23.725 5.14889Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>

          <div className="copyright">
            <p>
              <strong>Cyber Security Hive</strong> © {d.getFullYear()} All Rights Reserved
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
