import React, { useState, useContext } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { WEBREPORT } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import { AuthContext } from "../../../../Context/AuthProvider";

const Support = () => {
  const { user } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);
  const cardStyling = {
    'borderRadius': '2rem',
    'boxShadow': '20px 20px 25px 50px rgb(220 60 204 / 4%)'
  }
  const mailStyling = {
    'backgroundColor': '#fff',
    'borderRadius': '2rem',
    'boxShadow': '20px 20px 25px 50px rgb(220 60 204 / 4%)',
    'height': '53px',
    'width': '20rem'
  }
  const initialValues = {
    fullName: user.name,
    email: user.email,
    subject: '',
    messageBody: '',
  }
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().required("Required"),
    messageBody: Yup.string().required("Required"),
  });


  
  const sendMail = () => {
    const url = `${WEBREPORT}/sendmail`
    let values1 = {
      sendemail: "support@threatscan.io",
      name: formik.values.fullName,
      email: formik.values.email,
      subject: formik.values.subject,
      messageBody: formik.values.messageBody
    }
    AxiosInstance.post(url, { 
      name: "contactus", 
      values1
    })
      .then((response) => {
        Toaster("Message Sent", "success")
      })
  }

  const onSubmit = async (values) => {
    // code for sending email 
    // // console.log("yes");
    sendMail();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mb-1 text-center"
        // style={{'margin-top':'18%'}}
        >
          <div className="support-robo-img">
            <img src="https://res.cloudinary.com/dxjehekla/image/upload/v1652888082/threatscan/AI-diana-threatscan_c3rhja.png" alt="" />
          </div>
          <div>
            <h1><b>Lets Talk.</b></h1>
            <h3><b>Have you tried talking to <u>Diana</u>? Our AI based chatbot can answer all your queries? Still can't figure out? Get in touch with us on email or using contact form</b></h3>
          </div>
          <div className='d-flex justify-content-center my-4 my-md-0 pt-1'>
            <div className="mail-me-btn" style={mailStyling} >
              <span >
                <span className='pr-2'>
                  <MailOutlinedIcon fontSize='medium' />
                </span>
                Mail Me at<br />
                <span
                  className='text-primary align-bottom pb-2' style={{cursor:'pointer'}} onClick={() => window.location='mailto:support@threatscan.io'}
                >
                  support@threatscan.io
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <Card style={cardStyling}>
            <Card.Body >
              <Card.Title>Send us a message {`🚀`} </Card.Title>
              <Card.Text as="div">
              <span> 
                <div className="basic-form">
                  <form action="" onSubmit={formik.handleSubmit}>
                    <div className="form-group col-lg-12">
                      <label>Full Name*</label>
                      <input
                        id='fullName'
                        type="text"
                        className="form-control"
                        name='fullName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.fullName}
                      />
                      {formik.touched.fullName && formik.errors.fullName ? (
                        <div className="formerrormessage">
                          {formik.errors.fullName}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-lg-12">
                      <label>Email*</label>
                      <input
                        id='email'
                        type="text"
                        className="form-control"
                        name='email'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="formerrormessage">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-lg-12">
                      <label>Phone*</label>
                      <input
                        id='subject'
                        type="text"
                        className="form-control"
                        name='subject'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.subject}
                      />
                      {formik.touched.subject && formik.errors.subject ? (
                        <div className="formerrormessage">
                          {formik.errors.subject}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Reason for getting in touch*</label>
                      <select
                        id='reason'
                        type="text"
                        className="form-control"
                        name='reason'
                        defaultValue={""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                      > <option value={""} disabled></option>
                        <option value={"General queries"}>General queries</option>
                        <option value={"Bug resolution"}>Bug resolution</option>
                        <option value={"Status update"}>Status update</option>
                        <option value={"Other"}>Other</option>
                      </select>
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Tell us more about your Query*</label>
                      <textarea
                        id='messageBody'
                        row='3'
                        col='5'
                        className="form-control"
                        name='messageBody'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.messageBody}
                      />
                      {formik.touched.messageBody && formik.errors.messageBody ? (
                        <div className="formerrormessage">
                          {formik.errors.messageBody}
                        </div>
                      ) : null}
                    </div>
                    <Button
                      variant="primary light"
                      className="support-submit-btn mb-2 mr-2"
                      type="submit"
                      disabled={showLoader}
                      style={cardStyling}
                    >
                      {showLoader &&
                        <Spinner animation="border" variant="warning" />
                      }
                      Send Message
                    </Button>
                  </form>
                </div>
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Support;
