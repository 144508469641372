export const Columns = [
  {
    Header: "	Vulnerability Name",
    accessor: "title"
  },
  {
    Header: "Threat",
    accessor: "threat"
  },
  {
    Header: "Severity",
    accessor: "severity"
  }, {
    Header: "Impact",
    accessor: "impact"
  }, {
    Header: "Status",
    accessor: "status"
  }
];