import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import {Col, Row,  Button, Modal } from "react-bootstrap";



export const AddResourceModal = (props) => {



    const handleClose = props.handleClose;
    const showModal = props.showModal;


    let id = props.dbId;
    let [title, setTitle] = useState(props.title || '');
    let [body, setBody] = useState(props.body || '');
    let [publish, setPublish] = useState(props.publish || false);

    const addOrUpdateResource = props.addOrUpdateResource;

    return (
        <Modal className="fade" show={showModal} size='xl' scrollable="true">
            <Modal.Header>
                <Modal.Title>Add New Resource</Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => handleClose()}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-4'>

                    <label htmlFor="title">Title</label>
                    <input type="text" className="form-control" id="title" placeholder="Enter title" value={title} onChange={e => setTitle(e.target.value)} />

                </Row>
                <Col>
                    <label htmlFor="text">Body</label>
                    <Editor
                        id="remediation"
                        textareaName="remediation"
                        className="form-control"
                        value={body}
                        onEditorChange={(e)=>{ setBody(e) }}
                        // initialValue="<p>This is the initial content of the editor</p>"
                        init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                            "advlist autolink lists link image code charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | code |link | image | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help ",
                        }}
                        
                        
                    /> 
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger light"
                    onClick={() => handleClose()}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={() => {
                    addOrUpdateResource(id, title, body , "publish");
                }}>Publish</Button>
                <Button variant="primary" onClick={() => {
                    addOrUpdateResource(id, title, body, "draft");
                }}>Draft</Button>
            </Modal.Footer>
        </Modal>
    )
}