import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import swal from "sweetalert";
import moment from "moment";

const Comment = ({ comment = {}, deleteComment, setreplyinfo }) => {
  const { user } = useContext(AuthContext);
  
  const visible=()=>{
    return (<>

    </>)
  }
  return (
    <div className={`media mt-3 border p-3 d-flex flex-column ${ comment.replycomment !== "none" ? "bg-light" : "" }`}>
      <div className="d-flex align-items-center w-100 ">
        <a className="pull-left" href="#"><img className="media-object" width={20} src="https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659652_960_720.png" alt="" /></a>
        <h4 className="media-heading m-0 ml-2 mt-2">{comment.username}</h4>
        {
          comment.replycomment !== "none" ? <div className="d-flex justify-content-end w-100 ">
          <p className="m-0 mt-2">Replied to  </p>
          <p className="m-0 ml-2 mt-2 text-black elipiss"> {comment.basecomment} </p> 
        </div>: null
        }
      </div>
      <div className="media-body mt-3 ml-4">
        <p style={{ whiteSpace: "pre-wrap" }}> {comment.comment_text}</p>

        <ul className="list-unstyled list-inline media-detail pull-left">
          {user.id === comment.user_id &&
            <>
              <li className="">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text:
                        "Want to Delete Comment ?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) { deleteComment(comment.id); }
                    })
                  }
                >
                  <i className="fa fa-trash"></i>
                </span>
              </li>
              <li className="mx-2" onClick={() => setreplyinfo(comment.id, comment.comment_text)} >
                Reply
              </li>
            </>
          }
        </ul>
        <ul className="list-unstyled list-inline media-detail pull-right">
          <li>Commented At : <i className="fa fa-calendar mr-2"></i>{moment(comment.createdAt).format("DD/MM/YYYY  HH:MM:SS A")}</li>
          {/* <li><i className="fa fa-thumbs-up"></i>13</li> */}
        </ul>
      </div>
    </div>
  )
}

export default Comment;