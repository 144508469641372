import { Button, Table } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Fragment, useContext } from "react";
import { Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Toaster from "../../../components/Toaster";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { PLAN, WEB } from "../../../../lib/config/URLConstant";
import status from "../../../../lib/constant/RequestStatus";
import { AuthContext } from "../../../../Context/AuthProvider";
import { WEBREPORT } from "../../../../lib/config/URLConstant";


const StepFour = ({
  dataValues,
  handleBack,
  setFormValues,
  inputList,
  history,
  requestId=null,
  selectedCompany
}) => {
  const { password, login_url, user_name, role, ...values } = dataValues;
  const { user } = useContext(AuthContext);

  const submitHandler = async (e) => {
    e.preventDefault();

    // let url = `${PLAN}/fetchplan`;
    // if (user.role != "cshAdmin" && user.role != "tester") {
    //     AxiosInstance.post(url, { id: .company })
    //         .then((res) => {
    //             setDetails(res.data);
    //             // // console.log(res.data);
    //         })
    //         .catch((err) => {
    //             // // console.log(err);
    //         })
    // }

    if(requestId){
      updateRequest();
    }else{
      addRequest();
    }
  };
  const addRequest = async () =>{
    
    values.credentials = inputList;
    if(user?.role === "cshAdmin"){
      values.company = selectedCompany?.ite?.id
      values.companyName = selectedCompany?.ite?.company
    }
    else{
      values.company = user.company;
      values.companyName = user.companyName;
    }


    // console.log('values1', values);
    console.log('values2', user);
    await AxiosInstance
      .post(WEB, values)
      .then(async (res) => {
        const {
          data: { status, msg = "" },
        } = res;

        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Web request created successfully", "success");
          setFormValues({
            application_name: "",
            url: "",
            hosted_location: "",
            is_whitelisting: "",
            pentest_scope: "",
            login_url: "",
            credentials: [
              { role: "", user_name: "", password: "", login_url: "" },
            ],
          });

          // code for updating plan
          AxiosInstance.post(`${PLAN}/updateplan`,{
            id: values.company, type: "request"})
          .then((response)=>{
            // // console.log(response.data);
          })
          .catch((err)=>{
            Toaster(err, "error")
          })

          // Code for email sending
          let user1 = { 
            companyname: values.companyName,
            appname: values.application_name, 
            username: user.name,
            sendemail: user.email,
            companyid: values.company,
          }
          const url = `${WEBREPORT}/sendmail`
          await AxiosInstance.post(url,{name:"newrequest", values1: user1})
          .then((response)=>{
            // // console.log(response.data);
          })

          history.push("/requests-web");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  }
  const updateRequest = async () =>{
    values.credentials = inputList;
    delete values.updated_by
    delete values.user_id
    delete values.id
    delete values.createdAt
    const creds = values.credentials.map(cred=>{
      if(cred._id){
        delete cred._id;
      }
      return cred;
    });
    values.credentials = creds;
    if(!values.status){ values.status = status.requested; }
    await AxiosInstance
      .patch(`${WEB}/${requestId}`, values)
      .then((res) => {
        const {data: { status, msg = "" },} = res;
        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Web request Updated successfully", "success");
          setFormValues({
            application_name: "",
            url: "",
            hosted_location: "",
            is_whitelisting: "",
            pentest_scope: "",
            login_url: "",
            credentials: [
              { role: "", user_name: "", password: "", login_url: "" },
            ],
          });
          history.push("/requests-web");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  }

  return (
    <Fragment>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Please Confirm the Information submitted for web application
                pentest
              </h4>
            </div>
            <div className="card-body">
              <Table responsive="true" id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info">
                  
                    <thead>
                    <tr>
                      <th>Fields</th>
                      <th>Values</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Application Name</td>
                        <td>{values.application_name}</td>
                      </tr>
                      <tr>
                        <td>Target URL</td>
                        <td>{values.url}</td>
                      </tr>
                      <tr>
                        <td>Application Hosted On</td>
                        <td>{values.hosted_location}</td>
                      </tr>
                      <tr>
                        <td>whitelisting Performed</td>
                        <td>{values.is_whitelisting}</td>
                      </tr>
                      <tr>
                        <td>Scope of the pentest</td>
                        <td>{values.pentest_scope}</td>
                      </tr>

                      {inputList.map((inp, index) => (
                        <tr key={index}>
                          <td>Role {index + 1}</td>
                          <td>
                            <strong>Login URL:</strong> {inp.login_url}
                            <br />
                            <strong>Role Name:</strong> {inp.role}
                            <br />
                            <strong>Username:</strong> {inp.user_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="default"
            onClick={handleBack}
            style={{ marginRight: 10 }}
          >
            Back
          </Button>
          <Button
            data-cy="submit"
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};
export default withRouter(StepFour);
