import Button from "@material-ui/core/Button";
import React, { Fragment, useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { COMPANIES, PLAN } from "../../../../lib/config/URLConstant";
import { AuthContext } from "../../../../Context/AuthProvider";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Toaster from "../../Toaster";


const StepTwo = ({
  handleNext,
  handleChange,
  formValues,
  formErrors,
  setFormValues,
  setSelectedCompany,
  selectedCompany
}) => {
  const initialValues = {
    application_name: "",
    url: "",
    hosted_location: "",
    is_whitelisting: "Yes",
    pentest_scope: "User App",
  };

  const { user } = useContext(AuthContext);
  const [allow, setAllow] = useState(true);

  const validationSchema = Yup.object({
    application_name: Yup.string().required("Required").max(12, "Maximum 12 characters allowed"),
    url: Yup.string().required("Required url"),
    hosted_location: Yup.string().required("Required"),
    is_whitelisting: Yup.string().required("Required"),
    pentest_scope: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (values?.is_whitelisting === "Yes") {
      swal(
        "Somebody from cyber security hive will reach out shortly"
      ).then((value) => {
        setFormValues((prev) => ({
          ...prev,
          ...values,
        }));
        handleNext();
      })
    }
    else {
      setFormValues((prev) => ({
        ...prev,
        ...values,
      }));
      handleNext();
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    formik.setValues({
      application_name: formValues.application_name,
      url: formValues.url,
      hosted_location: formValues.hosted_location,
      is_whitelisting: formValues.is_whitelisting ? formValues.is_whitelisting : "Yes",
      pentest_scope: formValues.pentest_scope ? formValues.pentest_scope : "User App",
    });
  }, [formValues]);
  

  useEffect(() => {
    if (user.role != "cshAdmin" && user.role != "tester") {
      let url = `${PLAN}/fetchplan`;

      AxiosInstance.post(url, { id: user.company })
        .then((res) => {
          // // console.log(res);
          if (res.data.noOfTest < res.data.maxNoOfTest) {
            setAllow(true)
          }
          else {
            setAllow(false)
          }
        })
        .catch((err) => {
          // // console.log(err)
        })
    }
  }, [])


  const [companies, setCompanies] = useState([])

  useEffect(async () => {

    if (user.role === "cshAdmin") {
      let params = `sortBy='desc'&limit=100000&page=1`;

      await AxiosInstance.get(`${COMPANIES}?${params}`)
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            setCompanies(res.data.data.results)
            setSelectedCompany({
              ite: res?.data?.data?.results?.[0],
              index: 0
            })
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
    }
  }, [])


  return (
    <>
      {(allow) ?
        <Fragment>
          <form action="" onSubmit={formik.handleSubmit}>
            {
              user.role === "cshAdmin" ?
                <>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6 mb-2">
                      <div className="form-group">
                        <label className="text-label">Select Company</label>
                        <select onChange={(e)=> setSelectedCompany({
                          ite: companies?.[e.target.value],
                          index: e.target.value
                        })} value={selectedCompany?.index} defaultValue={companies?.[0]?.id} className="form-control"
                        >
                          {
                            companies?.map((ite, index)=> {
                              return <option value={index} key={`${index}_${ite?.company}`}> {ite?.company} </option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </>
                : null
            }
            <div className="row">
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Application Name*</label>
                  <input
                    data-cy="application-name"
                    type="text"
                    name="application_name"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.application_name}
                  />
                  {formik.touched.application_name && formik.errors.application_name ? (
                    <div className="formerrormessage">
                      {formik.errors.application_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Target IPs/URLs*</label>
                  <input
                    data-cy="target-ip"
                    type="text"
                    className="form-control"
                    id="url"
                    name="url"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.url}
                  />
                  {formik.touched.url && formik.errors.url ? (
                    <div className="formerrormessage">
                      {formik.errors.url}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Where is the Application Hosted on? *</label>
                  <input
                    data-cy="application-hosted"
                    type="text"
                    name="hosted_location"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.hosted_location}
                  />
                  {formik.touched.hosted_location && formik.errors.hosted_location ? (
                    <div className="formerrormessage">
                      {formik.errors.hosted_location}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">
                    Whitelisting of IP's Required*
                  </label>
                  <select
                    data-cy="application-whitelisted"
                    id='is_whitelisting'
                    name="is_whitelisting"
                    className="form-control form-control-lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.is_whitelisting}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">
                    Pentest Scope*
                  </label>
                  <select
                    data-cy="pentest-scope"
                    id='pentest_scope'
                    name="pentest_scope"
                    className="form-control form-control-lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.pentest_scope}
                  >
                    <option value="User App">User App</option>
                    <option value="Admin App">Admin App</option>
                    <option value="User and Admin App">User and Admin App</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div
                  style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
                >
                  <Button
                    data-cy="submit"
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Fragment> :
        <div style={{ height: "500px" }} className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="text-danger">No of test is full</h2>
          <Link
            to="/support"
          >

            <button className="btn btn-primary">Upgrade your plan</button>
          </Link>
        </div>
      }
    </>
  );
};

export default StepTwo;
