import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../../../../images/logo-csh.png";
import Countries from './countries.json';
import PhoneInput from 'react-phone-number-input'
import {
    isValidPhoneNumber,
} from 'libphonenumber-js';

import 'react-phone-number-input/style.css'


const PersonalInformation = (props) => {


    const initialValues = {
            userName: '',
            userEmail: '',
            userPassword: '',
            userCPassword: '',
            userCompanyName: '',
      };
      const [phoneNumber, setphoneNumber] = useState("");
      const [phoneError, setphoneError] = useState("");
      const [flag, setFlag] = useState(false);
      const [country, setCountry] = useState("United States");


      useEffect(() => {
            if(flag) {
                if(phoneNumber == undefined) {
                    setphoneError("Enter Correct phone number")
                }
                else if(!isValidPhoneNumber(phoneNumber)){
                    setphoneError("Enter Correct phone number")
                }
                else{
                    setphoneError("")
                }
                // // console.log(phoneNumber);
            }
            else{
                setFlag(true);
            }
      }, [phoneNumber]);


    
      const phoneRegex = RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      );
      const passwordRegex = RegExp(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      )
      const validationSchema = Yup.object({
        userCompanyName: Yup.string().required("Required"),
        userName: Yup.string().required("Required"),
        userEmail: Yup.string().email("Invalid email format").required("Required"),
        userPassword: Yup.string()
          .matches(passwordRegex, "Invalid password Must Contain One Capital, one special character and one number")
          .min(8, 'Password must be at least 8 characters long')
          .required("Password is required"),
        userCPassword: Yup.string()
          .oneOf([Yup.ref('userPassword'), null], 'Password must be same')
          .required("Password is required"),
      });

      const formik = useFormik({
        initialValues,
        // onSubmit,
        // validate,
        validationSchema,
      });

      const submitHandler= async()=>{
          if(formik.errors.length == undefined) {
            await props.chnageForm(formik.values, phoneNumber, country);
            await props.submitPersonalDetails(formik.values, phoneNumber, country);
          }
      }
  return (
    <>
            <div className="col-11 col-md-8 col-lg-6 multisteps-form__panel js-active" data-animation="slideHorz">
                <div className="form-logo mt-5">
                    <img width="150" src={logo} alt="" />
                </div>
                <div className="wizard-forms">
                    <div className="inner pb-10 clearfix">
                        <div className="wizard-title">
                            <h3>Sign Up for Threat Scan</h3>
                        <h5 style={{cursor:'pointer'}} onClick={()=>props.signinStep("signin")}>Already a user, Sign In </h5>                          
                        </div>
                        
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="wizard-form-field mb-20">
                            <div className="wizard-form-input">
                                <label>Name</label>
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.userName}
                                    type="text" 
                                    name="userName" />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <div className="formerrormessage">
                                        {formik.errors.userName}
                                    </div>
                                    ) : null}
                            </div>
                            <div className="wizard-form-input">
                                <label>Company Name</label>
                                <input
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   values={formik.values.userCompanyName}
                                    type="text" 
                                    name="userCompanyName" />
                                {formik.touched.userCompanyName && formik.errors.userCompanyName ? (
                                    <div className="formerrormessage">
                                        {formik.errors.userCompanyName}
                                    </div>
                                    ) : null}
                            </div>

                            <div className="wizard-form-input">
                                <label>Email</label>
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.userEmail}
                                    type="email" 
                                    name="userEmail" />
                                {formik.touched.userEmail && formik.errors.userEmail ? (
                                    <div className="formerrormessage">
                                        {formik.errors.userEmail}
                                    </div>
                                    ) : null}
                            </div>

                            <div className="wizard-form-input">
                                <label>Password</label>
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.userPassword}
                                    type="password" 
                                    name="userPassword" />
                                {formik.touched.userPassword && formik.errors.userPassword ? (
                                    <div className="formerrormessage">
                                        {formik.errors.userPassword}
                                    </div>
                                    ) : null}
                            </div>
                            <div className="wizard-form-input">
                                <label>Confirm Password</label>
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.userCPassword}
                                    type="password" 
                                    name="userCPassword" />
                                {formik.touched.userCPassword && formik.errors.userCPassword ? (
                                    <div className="formerrormessage">
                                        {formik.errors.userCPassword}
                                    </div>
                                    ) : null}
                            </div>



                            <div className="wizard-form-input custom-input">
                                <label>Phone Number</label><br/>
                                    <div>
                                        <PhoneInput
                                            className="customPhone"
                                            onChange={setphoneNumber}
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            defaultCountry="US"
                                        />
                                </div>
                                <p className="text-danger">{phoneError}</p>
                            </div>
                            <div className="wizard-form-input">
                                <label>Country</label>
                                <select
                                    onChange={(e)=> {
                                        setCountry(e.target.value);
                                    }}
                                    name="userCountry"
                                    className="coustomSelect">
                                    {Countries.map((val) => {
                                        return <option value={val.name}>{val.name}</option>

                                    })}
                                </select>
                            </div>
                            <div className="wizard-form-input">
                                <label>Already Signed Up, Click here to verify</label>
                            </div>

                           
                        </div>
                        {/* <div className="wizard-v3-progress">
                            <span>1 to 4 step</span>
                            <h3>25% to complete</h3>
                            <div className="progress">
                                <div className="progress-bar" style={{ width: '25%' }}>
                                </div>
                            </div>
                        </div> */}
                    
                    </form>
                    </div>
                    <div className="actions">
                        <ul>
                            <li onClick={()=> submitHandler()} ><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li>
                        </ul>
                    </div>
                    
                </div>
                
            </div>
    </>
  )
}

export default PersonalInformation