// // Disable edit icon for all status except completed and submitted.

import { map, reverse, size } from "lodash";
import React, { Component, Fragment } from "react";
import { Badge, Card, Col, Dropdown, Row, Table, Button, Modal, Spinner } from "react-bootstrap";
import { AuthContext } from "../../../../Context/AuthProvider";
import PageTitle from "../../../layouts/PageTitle";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { PAYMENT, WEB } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import status from "../../../../lib/constant/RequestStatus";
export default class BootstrapTable extends Component {
  static contextType = AuthContext;

  state = {
    allwebrequest: [],
    webRequests: [],
    tempWebrequest: [],
    searchWebrequest: [],
    openDeleteModal: false,
    selectedRequestId: null,
    isLoading: true,
    limit: 10,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    pagination: [],
    deletecompanyid: '',
  };

  componentDidUpdate = (prevState) => {
    if (
      (size(prevState.webRequests) === 0 &&
        prevState.webRequests === this.state.webRequests)
    ) {
      this.fetchWebRequests(this.state.limit, this.state.page);
      this.searchFetchWebRequests(1000000000, 1);
    }
  };

  componentDidMount = async () => {
    await this.fetchWebRequests(this.state.limit, this.state.page);
    this.searchFetchWebRequests(1000000000, 1);
  };

  fetchWebRequests = async (limit, page) => {
    const { user } = this.context;
    if (user.role != "tester") {
      let url = "";
      let data;
      if (user.role == 'cshAdmin') {
        url = `${WEB}/get/admin`
        data = {}
      }
      else {
        url = `${WEB}/get`
        data = {
          company: user.company
        }
      }

      // // console.log(url, user.role)
      await AxiosInstance.post(`${url}`, {
        data
      })
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            let temppage = Array(Math.ceil(res.data.data.length / this.state.limit))
              .fill()
              .map((_, i) => i + 1);
            // // console.log('temp',temppage);
            this.setState({
              allwebrequest: [...res.data.data.reverse()],
              webRequests: [...res.data.data.slice(0, 10)],
              totalPages: temppage.length,
              tempWebrequest: [...res.data.data.reverse()]
            });
            // // console.log(res.data.data, `${WEB}` )
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
      this.setState({ isLoading: false });
    }
    else {
      let data;
      let url;
      if (user.role == 'tester') {
        url = `${WEB}/get/admin`
        data = {}
      }
      else {
        url = `${WEB}/get`
        data = {
          company: user.company
        }
      }
      limit = 1000000000;
      await AxiosInstance.post(url, {
        data
      })
        .then((res) => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            // console.log(res.data)
            let tempres = [];
            for (let result in res.data.data) {
              for (let test in res.data.data[result].tester) {
                if (user.email === res.data.data[result].tester[test]) {
                  tempres.push(res.data.data[result]);
                }
              }
            }
            this.setState({
              webRequests: tempres,
              totalResults: 1
            })
          }
          this.setState({ isLoading: false });
        })
    }

  };
  searchFetchWebRequests = async () => {
    const { user } = this.context;
    let url = "";
    let data;
    if (user.role == 'cshAdmin') {
      url = `${WEB}/get/admin`
      data = {}
    }
    else {
      url = `${WEB}/get`
      data = {
        company: user.company
      }
    }

    await AxiosInstance.post(`${url}`, { data })
      .then(res => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          this.setState({
            searchWebrequest: [...res.data.data.reverse()]
          });
        }
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
  };

  onEditClick = (requestId) => {
    // const { history } = this.props;
    // history.push(window.open());
    this.props.history.push(`/submit-webapp?requestId=${requestId}`)
  }
  onDeleteClick = (requestId, companyid) => {
    this.setState({ openDeleteModal: true, selectedRequestId: requestId, deletecompanyid: companyid })
  }
  onSubmitReportClick = (webrequest) => {
    this.props.history.push(`/submit-report?requestId=${webrequest.id}&userId=${webrequest.user_id}&appName=${webrequest.application_name}&company=${webrequest.company}`)
  }
  onSubmitChecklist = (webrequest) => {
    this.props.history.push(`/submitchecklist?requestId=${webrequest.id}`)
  }
  onViewReportClick = (webrequest) => {
    this.props.history.push(`/reports?requestId=${webrequest.id}`)
  }
  onViewRequestClick = (webrequest) => {
    this.props.history.push(`/view-web-request?requestId=${webrequest.id}`)
  }

  handleDeleteRequest = async () => {
    await AxiosInstance.delete(`${WEB}/${this.state.selectedRequestId}`)
      .then(res => {
        this.setState({ openDeleteModal: false })
        if (res.data.code !== 200 && res.data.code !== 204) {
          Toaster(res.data.msg, "error");
        } else {
          this.fetchWebRequests(this.state.limit, 1);
          Toaster("Request Deleted Successfully", "success");
          AxiosInstance.post(`${PAYMENT}/updatePlan`, {
            type: "deletetest",
            id: this.state.deletecompanyid
          })
            .then((res) => {
              // // console.log(res);
            })
            .catch((err) => {
              // // console.log(err)
            })
        }
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
  }

  onPreviousClick = () => {
    window.scrollTo(0, 0);
    this.setState({
      page: this.state.page -= 1,
      pagination: this.state.pagination - 1,
      webRequests: this.state.allwebrequest.slice(0, 10)
    });
  }
  onNextClick = () => {
    // this.setState({isLoading:true});
    // this.fetchWebRequests(this.state.limit,this.state.page+1);
    window.scrollTo(0, 0);
    this.setState({
      page: this.state.page + 1,
      pagination: this.state.pagination + 1,
      webRequests: this.state.allwebrequest.slice(10)
    });

  }
  showEntries = () => {
    let start = this.state.page == 1 ? this.state.page :
      ((this.state.page - 1) * this.state.limit);
    let end = this.state.totalResults > (this.state.page) * this.state.limit
      ? (this.state.page) * this.state.limit
      : this.state.totalResults;
    return `Showing ${start} to ${end} of ${this.state.totalResults}`;
  }

  getBadgeColor = (currStatus) => {
    if (currStatus === status.requested) {
      return 'orange';
    }
    if (currStatus === status.inProgress) {
      return 'sky';
    }
    if (currStatus === status.completed) {
      return 'green';
    }
  }


  filterHandler = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const newList = this.state.searchWebrequest.filter((request) => {
        return Object.values(request)
          .join(" ")
          .toLowerCase()
          .includes(search.toLowerCase());
      })
      this.setState({
        webRequests: newList
      })
    }
    else {
      this.setState({
        webRequests: this.state.tempWebrequest.reverse()
      })
    }
  }


  render() {
    // // console.log(this.state.webRequests)
    const { webRequests } = this.state;
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    return (
      <Fragment>
        <PageTitle activeMenu="Web App" motherMenu="My Requests" />
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>All Web Application Test Requests</Card.Title>
              </Card.Header>
              {this.state.isLoading ?
                <Card.Body style={{ display: 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                  <Spinner animation="border" variant="primary" />
                </Card.Body>
                :
                <Card.Body>
                  <div className="ml-2 d-flex align-items-center">
                    <h4>Filter</h4>
                    <input
                      data-cy="my-request-filter"
                      type="text"
                      className="ml-3"
                      onChange={this.filterHandler}
                    />
                  </div>

                  <Table className="mt-3" responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>App Name</strong>
                        </th>
                        <th>
                          <strong>URL</strong>
                        </th>
                        {(this.context.user.role === "cshAdmin" || this.context.user.role === "tester") ? <th>
                          <strong>Company</strong>
                        </th> : null}
                        <th>
                          <strong>Pentest Scope</strong>
                        </th>
                        <th>
                          <strong>Status</strong>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {size(webRequests) !== 0 &&
                        map((webRequests), (webRequest, i) => (
                          <tr key={i}>
                            <td>{webRequest.application_name}</td>
                            <td className='clickable'>{webRequest.url}</td>
                            {(this.context.user.role === "cshAdmin" || this.context.user.role === "tester")
                              ?
                              <td>{webRequest.companyName}</td>
                              : null
                            }
                            <td>{webRequest.pentest_scope}</td>
                            <td>
                              <Badge data-cy="request-status-badge" variant={this.getBadgeColor(webRequest.status)}>{webRequest.status}</Badge>
                            </td>
                            <td>
                              <Dropdown data-cy="my-request-dropdown-menu">
                                <Dropdown.Toggle

                                  variant="success"
                                  className="light sharp i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item data-cy="my-request-view-request" onClick={() => this.onViewRequestClick(webRequest)}>View Test</Dropdown.Item>
                                  {!['readOnly', 'user', 'admin'].includes(this.context.user.role) && <Dropdown.Item data-cy="my-request-edit-request" onClick={() => this.onEditClick(webRequest.id)}>Edit</Dropdown.Item>}
                                  {!['readOnly', 'user', 'admin', 'tester'].includes(this.context.user.role) && <Dropdown.Item data-cy="my-request-delete-request" onClick={() => this.onDeleteClick(webRequest.id, webRequest.company)}>Delete</Dropdown.Item>}
                                  {!['readOnly', 'user', 'admin'].includes(this.context.user.role) && <Dropdown.Item data-cy="my-request-submit-vulnerability" onClick={() => this.onSubmitReportClick(webRequest)}>Submit Vulnerability</Dropdown.Item>}
                                  {!['readOnly', 'user', 'admin'].includes(this.context.user.role) && <Dropdown.Item data-cy="my-request-submit-vulnerability" onClick={() => this.onSubmitChecklist(webRequest)}>Submit Checklist</Dropdown.Item>}
                                  <Dropdown.Item data-cy="my-request-view-report" onClick={() => this.onViewReportClick(webRequest)}>View Report</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <p>Showing {this.state.page} page of {this.state.totalPages} pages</p>
                      <Tooltip title="Previous Page">
                        <span>
                          <IconButton
                            onClick={() => this.onPreviousClick()}
                            disabled={this.state.page === 1 || this.state.webRequests.length <= 0}
                            aria-label="previouspage" color="primary">
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Next Page">
                        <span>
                          <IconButton
                            onClick={() => this.onNextClick()}
                            disabled={this.state.page === this.state.totalPages || this.state.webRequests.length <= 0}
                            aria-label="nextpage" color="primary">
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <Modal
                    className="fade bd-example-modal-sm"
                    size="sm"
                    show={this.state.openDeleteModal}
                  >
                    <Modal.Header>
                      <Modal.Title>Delete Request</Modal.Title>
                      <Button
                        variant=""
                        className="close"
                        onClick={() => this.setState({ openDeleteModal: false })}
                      >
                        <span>&times;</span>
                      </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete Request ?</Modal.Body>
                    <Modal.Footer>
                      <Button
                        data-cy="delete-cancel"
                        variant="danger light"
                        onClick={() => this.setState({ openDeleteModal: false })}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary"
                        data-cy="delete-confirm"
                        onClick={() => this.handleDeleteRequest()}>Delete</Button>
                    </Modal.Footer>
                  </Modal>
                </Card.Body>
              }
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
