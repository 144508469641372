import React, { Fragment, useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Dropdown, Modal, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PageTitle from '../../../layouts/PageTitle';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { WEBREPORT } from '../../../../lib/config/URLConstant';
import { Link } from 'react-router-dom';

const CommentsQueue = () => {

  const [data, setdata] = useState()
  const [filterValues, setfilterData] = useState([])
  const [drop, setdrop] = useState("All")

  useEffect(() => {
    AxiosInstance.get(`${WEBREPORT}/request/allcomments`)
      .then((res) => {
        setdata(res.data.reverse());
        setfilterData(res.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const filterData = async (type) => {
    let dummydata =[]
    if (type === "All") {
      await data.map((item) => {
        if (item.replycomment !== "none") return
        else return dummydata.push(item)
      })
      await setfilterData(dummydata)
    }
    else if (type === "Unreplied") {
      await data.map((item) => {
        if (item.replycomment !== "none") {}
        else if (item.commentreplied === false) dummydata.push(item)
        
      })
      await setfilterData(dummydata)
    }
    else {
      await data.map((item) => {
        if (item.replycomment !== "none") return
        if (item.commentreplied === true) return dummydata.push(item)
        else return 
      })
      await setfilterData(dummydata)
    }

  }


  return (
    <div>
      <Fragment>
        <PageTitle activeMenu="Web App" motherMenu="My Requests" />
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title className="w-100">
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      All Comments
                    </div>
                    <div>
                      <Dropdown alignRight
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // className="form-control form-control-lg float-right w-25"
                      // label="STATUS"
                      >
                        <Dropdown.Toggle variant='custom-1 text-black'>
                          {drop}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e) => { filterData("All"); setdrop("All") }} value={"ALL"}>ALL</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => { filterData("Unreplied"); setdrop("Unreplied") }} value={"Unreplied"}>Unreplied</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => { filterData("Archive"); setdrop("Archive") }} value={"Archive"}>Archive</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Card.Title>
              </Card.Header>
              {false ?
                <Card.Body style={{ display: 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                  <Spinner animation="border" variant="primary" />
                </Card.Body>
                :
                <Card.Body>
                  <Table className="mt-3" responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Comment</strong>
                        </th>
                        <th cassName="pl-3">
                          <strong>Comment Replied</strong>
                        </th>
                        <th>
                          <strong>Link</strong>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filterValues?.map((data) => {
                          if (data.replycomment !== "none") return
                          return <>
                            <tr>
                              <td style={{ maxWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> {data?.comment_text} </td>
                              <td className="pl-3"><Badge className="p-3" variant={`${data?.commentreplied ? "success" : "primary badge-xs light"}`}>
                                {
                                  data?.commentreplied ? "Yes" : "No"
                                }
                              </Badge>  </td>
                              <td> <Link to={`/vulnerability?reportId=${data?.report_id}`} >
                                Click here
                              </Link></td>
                            </tr>
                          </>
                        })
                      }


                    </tbody>
                  </Table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <Tooltip title="Previous Page">
                        <span>
                          <IconButton
                            aria-label="previouspage" color="primary">
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Next Page">
                        <span>
                          <IconButton
                            aria-label="nextpage" color="primary">
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <Modal
                    className="fade bd-example-modal-sm"
                    size="sm"
                  >
                    <Modal.Header>
                      <Modal.Title>Delete Request</Modal.Title>
                      <Button
                        variant=""
                        className="close"
                      >
                        <span>&times;</span>
                      </Button>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete Request ?</Modal.Body>
                    <Modal.Footer>
                      <Button
                        data-cy="delete-cancel"
                        variant="danger light"
                      >
                        Cancel
                      </Button>
                      <Button variant="primary"
                        data-cy="delete-confirm"
                        onClick={() => this.handleDeleteRequest()}>Delete</Button>
                    </Modal.Footer>
                  </Modal>
                </Card.Body>
              }
            </Card>
          </Col>
        </Row>
      </Fragment>
    </div>
  )
}

export default CommentsQueue