import React, { useContext,useState } from 'react';
import { isEmpty } from "lodash";
import {  Button, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AxiosInstance } from '../../../../../lib/config/axiosConfig';
import { USER } from '../../../../../lib/config/URLConstant';
import Toaster from '../../../Toaster';
import { AuthContext } from "../../../../../Context/AuthProvider";
import { Spinner } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";


const PasswordTab = () =>{
  const context = useContext(AuthContext);
  const {user, logOut} = context;
  const errorStyle= {
    'paddingLeft': '0.5rem !important',
    'marginTop': '-1.0rem !important',
    'paddingBottom': '0.5rem !important'
  }
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
      `Password must be at least 8 characters 
      and contains at least 1 letter and 1 number`),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords does not match'),
  })
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrPass, setShowCurrPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    let{currentPassword,newPassword}=values;  
      await AxiosInstance
      .patch(`${USER}/changepassword/${user.id}`, {currentPassword,newPassword})
      .then((res) => {
        const {data: { status, msg = "" },} = res;
        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Password updated successfully", "success");
          Toaster("You need to login again", "success");
          setTimeout(()=>{
            logOut();
          },2000);
        }
      })
      .catch((err) => {
        Toaster(err.response.data.message, "error");
      });
      setIsLoading(false);
  }
  const handleClickShowCurrentPassword = () =>{
    setShowCurrPass(prev => !prev);
  }
  const handleMouseDownCurrentPassword = () =>{
    setShowCurrPass(prev => !prev);
  }
  const handleClickShowNewPassword = () =>{
    setShowNewPass(prev => !prev);
  }
  const handleMouseDownNewPassword = () =>{
    setShowNewPass(prev => !prev);
  }
  const handleClickShowConfirmPassword = () =>{
    setShowConfirmPass(prev => !prev);
  }
  const handleMouseDownConfirmPassword = () =>{
    setShowConfirmPass(prev => !prev);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
  <div className="card-body">
    <div className="basic-form">
      <form action="" onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="input-group mb-3 col-xs-12 col-sm-12">
            <input
              id='currentPassword'
              type={showCurrPass?"text":"password"}
              className="form-control"
              name='currentPassword'
              placeholder='Current Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.currentPassword}
            />
            <div className="input-group-append">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowCurrentPassword}
                onMouseDown={handleMouseDownCurrentPassword}
              >
                  {showCurrPass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>
          {formik.touched.currentPassword && formik.errors.currentPassword ? (
              <div className="formerrormessage" style={errorStyle}>
                {formik.errors.currentPassword}
              </div>
            ) : null}
          <div className="input-group mb-3 col-xs-12 col-sm-12">
            <input
              id='newPassword'
              type={showNewPass?"text":"password"}
              className="form-control"
              name='newPassword'
              placeholder='New Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.newPassword}
            />
            <div className="input-group-append">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword}
                onMouseDown={handleMouseDownNewPassword}
              >
                  {showNewPass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="formerrormessage" style={errorStyle}>
                {formik.errors.newPassword}
              </div>
            ) : null}
          <div className="input-group mb-3 col-xs-12 col-sm-12">
            <input
              id='confirmPassword'
              type={showConfirmPass?"text":"password"}
              className="form-control"
              name='confirmPassword'
              placeholder='Confirm Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.confirmPassword}
            />
            <div className="input-group-append">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownConfirmPassword}
              >
                  {showConfirmPass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="formerrormessage" style={errorStyle}>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
        </div>
        <Button
          variant="contained"
          color="primary"
          type='submit'
        >
          {isLoading && 
            <Spinner animation="border" variant="warning" />
          }
          Change Password
        </Button>
      </form>
    </div>
  </div>
  );
}

export default PasswordTab;