import React, { useContext, useEffect, useState } from 'react'

import "../../../../css/pricingCss/bootstrap.min.css"
import "../../../../css/pricingCss/animate.min.css"
import "../../../../css/pricingCss/fontawesome-all.css"
import "../../../../css/pricingCss/style.css"
import "../../../../css/pricingCss/colors/switch.css"
import "../../../../css/pricingCss/colors/color-2.css"
import "../../../../css/pricingCss/colors/color-3.css"
import "../../../../css/pricingCss/colors/color-4.css"
import "../../../../css/pricingCss/colors/color-5.css"
import PersonalInformation from './PersonalInformation'
import ApplicationDetails from './ApplicationDetails'
import Sidebar from './Sidebar'
import PlansDetails from './PlansDetails'
import { AUTH } from '../../../../lib/config/URLConstant'
import Toaster from '../../Toaster'
import axios from 'axios'
import { AuthContext } from '../../../../Context/AuthProvider'
import ThankYou from './ThankYou'
import Signin from './Signin'
import { isEmpty } from 'lodash'
import swal from "sweetalert";




const PricingForm = (props) => {

    const [returnUser, setreturnUser] = useState({
        userid: props.location?.state?.user?.id, 
        email: props.location?.state?.user?.email,
        company: props.location?.state?.user?.company,
        country: props.location?.state?.user?.country
    })

    const [state, setState] = useState({
        user: {
            name: "",
            email: "",
            password: "",
            company: "",
            phone: "",
            country: '',
            companyId: ''
        }
    })

    const [step, setStep] = useState(1);

    const [userid, setuserid] = useState("");

    const { tokenAuth } = useContext(AuthContext);
    const [applicationDetail, setApplicationDetail] = useState({
        company: '',
        applicationName: '',
        applicationPurpose: '',
        applicationTotlPages: '',
        applicationTotalRoles: '',
        applicationFormFields: '',
        applicationTotalApi: '',
        applicationAverageNoMethodApi: '',
        applicationAverageNoParameterApi: '',
    })

    const [finalPrice, setfinalPrice] = useState();

    useEffect(() => {
        if (returnUser.userid != undefined) {
            setStep(2)
        }
    }, [])

    const chnageForm = (val, phone, country) => {
        // // console.log("yes", val.userName);
        setState({
            user: {
                name: val.userName,
                email: val.userEmail,
                password: val.userPassword,
                company: val.userCompanyName,
                phone: phone,
                country: country,
            }
        })
    }

    const changeUserCompanyId = (val)=>{
        setState({user: {
            ...state.user,
            companyId: val
        }})
    }

    const changeApplicationDetails = (val) => {
        setApplicationDetail({
            company: val.company,
            applicationName: val.applicationName,
            applicationPurpose: val.applicationPurpose,
            applicationTotlPages: val.applicationTotlPages,
            applicationTotalRoles: val.applicationTotalRoles,
            applicationFormFields: val.applicationFormFields,
            applicationTotalApi: val.applicationTotalApi,
            applicationAverageNoMethodApi: val.applicationAverageNoMethodApi,
            applicationAverageNoParameterApi: val.applicationAverageNoParameterApi,
        })
    }

    const nextStep = () => {
        setStep(
            step + 1
        )
        // // console.log(applicationDetail, "yes");
    }
    const previousStep = () => {
        setStep(
            step - 1
        )
        // // console.log(step, "yes");
    }

    const submitPersonalDetails = async (val, phone, country) => {
        // submitting user sign up details
        let data = {
            name: val.userName,
            email: val.userEmail,
            password: val.userPassword,
            company: val.userCompanyName,
            phone: phone,
            country: country,
        }
        try {
            axios
                .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/leadregister`, data)
                .then(async (res) => {;
                    if (res) {
                        
                             // submitting user sign up details
                             let data1 = {
                                name: val.userName,
                                email: val.userEmail,
                                password: val.userPassword,
                                company: 'none',
                                companyName: val.userCompanyName,
                                phone: phone,
                                // country: country,
                                role: 'admin'
                            }
                            try {
                                axios
                                    .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/register`, data1)
                                    .then(async (res) => {
                                        // // console.log(res)
                                        if(res.data == false){
                                            Toaster("Email id already taken", "error");
                                            swal({
                                                title: "Email id already taken",
                                                text:
                                                  "Sign in instead",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                              }).then((willDelete) => {
                                                if (willDelete) {
                                                  signinStep("signin")
                                                }
                                              })
                                        }
                                        else{
                                            const {
                                                data: { code = "", msg = "", tokens = {}, user = {} },
                                            } = res;
                                            setuserid(res.data.user.id);
                                            if (isEmpty(code) && isEmpty(msg)) {
                                                const newdata = {
                                                    token: {
                                                        token: tokens.access.token,
                                                        userData: user,
                                                    },
                                                };
                                                tokenAuth(newdata, user, () => {
                                                    // // console.log("yes");
                                                    Toaster("Registration Successfully", "success");             
                                                    nextStep();
                                                });
                                            }
                                            else {
                                                // // console.log(res)
                                                Toaster(msg, "error");
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        if (error) {
                                            Toaster( "Error 404", error);
                                        }
                                    });
                            }
                            catch (error) {
                                Toaster(error, "error");
                    
                            }

                    }
                    else {
                        Toaster(res, "error");
                    }
                })
                .catch((error) => {
                    if (error) {
                        // // console.log(error);
                        Toaster( "Error 404", error);
                    }
                });
        }
        catch (error) {
            Toaster(error, "error");
            // // console.log(error);

        }
    }
    

    const submitPrice = (val) => {
        setfinalPrice(val * 100);
    }

    const signinStep = (val) => {
        if (val == "signin"){
            setStep(5)
        }
        else if(val == "signup"){
            setStep(1)
        }
        else if(val == "login"){
            window.location.href= '/login'
        }
        else{
            setStep(2)
        }
    }


    switch (step) {
        case 1: {
            return (
                <>
                    <div className="wrapper wizard  multisteps-form position-relative">
                         {/* <Sidebar
                        step={step}
                        /> */}
                        <form className="multisteps-form__form w-100 order-1" action="#" id="wizard">
                            <div style={{minHeight:'100vh'}} className="d-flex justify-content-center align-items-center form-area position-relative">
                                <PersonalInformation
                                    submitPersonalDetails={submitPersonalDetails}
                                    chnageForm={chnageForm}
                                    userDetail={state.user}
                                    signinStep={signinStep}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )
        }
        case 2: {
            return (
                <>
                    <div className="wrapper wizard d-flex clearfix multisteps-form position-relative">
                        {/* <Sidebar
                            step={step}
                        /> */}
                        <form className="multisteps-form__form w-100 order-1" action="#" id="wizard">
                            <div style={{minHeight:'100vh'}} className="d-flex justify-content-center align-items-center form-area position-relative">
                                <ApplicationDetails
                                    user={state.user}
                                    changeUserCompanyId={changeUserCompanyId}
                                    nextStep={nextStep}
                                    changeApplicationDetails={changeApplicationDetails}
                                    returnUser={returnUser}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )
        }
        case 3: {
            return (
                <>
                    <div className="wrapper wizard d-flex clearfix multisteps-form position-relative">
                        {/* <Sidebar
                            step={step}
                        /> */}
                        <form className="multisteps-form__form w-100 order-1" action="#" id="wizard">
                            <div style={{minHeight:'100vh'}} className="d-flex justify-content-center align-items-center form-area position-relative">
                                <PlansDetails
                                    user={state.user}
                                    nextStep={nextStep}
                                    previousStep={previousStep}
                                    applicationDetail={applicationDetail}
                                    submitPrice={submitPrice}
                                    userid = {userid}
                                    returnUser={returnUser}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )
        }
        case 4: {
            return (
                <>
                    <div className="wrapper wizard d-flex clearfix multisteps-form position-relative">
                        {/* <Sidebar
                            step={step}
                        /> */}
                        <form className="multisteps-form__form w-100 order-1" action="#" id="wizard">
                            <div style={{minHeight:'100vh'}} className="d-flex justify-content-center align-items-center form-area position-relative">
                                <ThankYou
                                    finalPrice={finalPrice}
                                    nextStep={nextStep}
                                    applicationDetail={applicationDetail}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )
        }
        case 5: {
            return (
                <>
                    <div className="wrapper wizard d-flex clearfix multisteps-form position-relative">
                        {/* <Sidebar
                            step={step}
                        /> */}
                        <form className="multisteps-form__form w-100 order-1" action="#" id="wizard">
                            <div style={{minHeight:'100vh'}} className="d-flex justify-content-center align-items-center form-area position-relative">
                                <Signin
                                     signinStep={signinStep}
                                />
                            </div>
                        </form>
                    </div>
                </>
            )
        }
        default: {
            return (
                <div>
                </div>
            )
        }
    }
}

export default PricingForm