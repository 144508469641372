import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, USER } from '../../lib/config/URLConstant';
import Toaster from '../components/Toaster';

const ResetPassword = () => {

    const queryParams = new URLSearchParams(window.location.search);

    const token = queryParams.get('token');
    const email = queryParams.get('email');
    const [session, setSession] = useState(true);
    const [password, setpassword] = useState('');
    const [cpassword, setcpassword] = useState('');

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_SERVER}${AUTH}/verify-token`, {
            token: token,
            type: 'resetPassword'
        })
            .then((res) => {
                setSession(true);
                // // console.log(res);
            })
            .catch((err) => {
                setSession(false);
                Toaster("Token Expires", "error")
                // // console.log(err);
            })
    }, [])
    
    const passwordRegex = RegExp(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      )

    onsubmit = () => {
        if (!passwordRegex.test(password)){
            Toaster("Invalid password Must Contain One Capital, one special character and one number", "error")
        }
        else if (password === cpassword){
            axios.post(`${process.env.REACT_APP_API_SERVER}${USER}/resetpassword`,{
                password: password,
                email: email
            })
            .then((res)=>{
                if (res.status == 200){
                    Toaster("Password Reset Successful", "success");
                    window.location.href = '/login'
                }
            })
            .catch((err)=>{
                // // console.log(err)
            })
        }
        else{
            Toaster("Password is not same", "error")
        }
    }

    return (
        <div>
            {(session)?<div className="authincation h-100 p-meddle">
                <div className="container h-100">
                    {" "}
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <h4 className="text-center mb-4">
                                                Reset Password
                                            </h4>
                                            <form>
                                                <div className="form-group">
                                                    <label>
                                                        <strong>Password</strong>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        onChange={(e)=> setpassword(e.target.value)}
                                                    />
                                                    <label>
                                                        <strong>Confirm Password</strong>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        onChange={(e)=> setcpassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    <input
                                                        value="SUBMIT"
                                                        className="btn btn-primary btn-block"
                                                        onClick={()=> onsubmit()}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>: 
            <div style={{height:'100vh'}} className="d-flex align-items-center justify-content-center">
                <h2 className="text-center text-danger">Your token expires</h2>
            </div>
            }
        </div>
    )
}

export default ResetPassword