import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../../../Context/AuthProvider'
import { AxiosInstance } from '../../../../lib/config/axiosConfig'
import { WEB } from '../../../../lib/config/URLConstant'
import Toaster from '../../Toaster'

const Jiraintegration = (props) => {

    const { user } = useContext(AuthContext);

    const location = useLocation();

    const userId = location?.state?.userId;

    useEffect(() => {
        GetUserData();
    }, [])

    const GetUserData = async () => {
        AxiosInstance.post(`${WEB}/getUserData`, { company: user.company })
            .then((res) => {
                setDetails({
                    username: res.data.jirausername,
                    apitoken: res.data.jiraapitoken,
                    projecturl: res.data.jiraprojecturl,
                    projectid: res.data.jiraprojectid,
                });
            })
    }


    const [details, setDetails] = useState({
        username: "",
        apitoken: "",
        projecturl: "",
        projectid: ""
    })


    const changeHandler = (type, value) => {
        setDetails({
            ...details,
            [type]: value,
        })
        // // console.log(details);
    }

    const submitHandler = () => {
        AxiosInstance.post(`${WEB}/submitjirainformation`, { company: user.company, username: details.username, apitoken: details.apitoken, projecturl: details.projecturl, projectid: details.projectid, company: user.company })
            .then((res) => {
                Toaster("Details Updated Successfully", "success");
                AxiosInstance.post(`${WEB}/jiraissue`, {
                    testing: "true",
                    projecturl: details.projecturl,
                    username: details.username,
                    apitoken: details.apitoken,
                    projectid: details.projectid,
                    title: "Test Issue",
                })
                    .then((ress) => {
                        if (ress.data === "Verified") {
                            AxiosInstance.post(`${WEB}/jiraconnectionverified`, { id: user.company, value: true })
                                .then((response) => {
                                    Toaster("Connection successful", "success")

                                    props.history.push("/integrations");
                                })
                                .catch((error) => {
                                    // console.log(error);
                                })
                        }
                        else{
                            Toaster("Connection unsuccessful", "error")
                        }
                    })
                    .catch((err) => {
                        AxiosInstance.post(`${WEB}/jiraconnectionverified`, { id: user.company, value: false })
                            .then((response) => {
                                // console.log(response);
                            })
                            .catch((error) => {
                                // console.log(error);
                            })
                        Toaster("Please check the information provided and try to reconnect", "error")
                        // console.log(err);
                    })
            })
    }

    return (
        <div className="jira-integration-container bg-white px-5 py-3">
            <div className="container">
                <div className="row">
                    <h3>Enter Your Jira Cloud Details</h3>
                </div>
            </div>
            <form className="mt-5">
                <div>
                    <label htmlFor="exampleInputEmail1">Enter your Username / Email address</label>
                    <input value={details.username} onChange={(e) => changeHandler('username', e.target.value)} type="text" className="form-control" placeholder="" />
                    <p className="text-danger"></p>
                </div>
                <div>
                    <label htmlFor="exampleInputEmail1">Enter your Api Token</label>
                    <input value={details.apitoken} onChange={(e) => changeHandler('apitoken', e.target.value)} type="text" className="form-control" placeholder="" />
                    <p className="text-danger"></p>
                </div>
                <div>
                    <label htmlFor="exampleInputEmail1">Enter your Project Url</label>
                    <input value={details.projecturl} onChange={(e) => changeHandler('projecturl', e.target.value)} type="text" className="form-control" placeholder="" />
                    <p className="text-danger"></p>
                </div>
                <div>
                    <label htmlFor="exampleInputEmail1">Enter your Project Id</label>
                    <input value={details.projectid} onChange={(e) => changeHandler('projectid', e.target.value)} type="text" className="form-control" placeholder="" />
                    <p className="text-danger"></p>
                </div>
                <div>
                    <p onClick={() => submitHandler()} className="px-4 py-2 btn btn-primary"> Submit </p>
                </div>
            </form>
            <div>
            </div>
        </div>
    )
}

export default Jiraintegration