import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect, useContext } from "react";
import formValidation from "./formValidation";
import FourthStep from "./StepFour";
import ThirdStep from "./StepThree";
import SecondStep from "./StepTwo";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { NETWORK, PLAN, WEB } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import { AuthContext } from "../../../../Context/AuthProvider";


// Step titles
const useStyles = (theme) => ({
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "pink",
    },
    "& $disabled": {
      color: "red",
    },
  },
});

const StepForm = () => {

  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('requestId');
  const [ipList, setIpList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const labels = ["Network Details", "IP's Details", "Preview and Submit"];

  const date = new Date().toLocaleDateString();
  const { user } = useContext(AuthContext);

  const initialValues = {
    // company_name: "",
    // primary_contact_name: "",
    // email: "",
    // phone: "",
    // secondary_phone: "",
    // type: "web",
    network_name: "",
    
    test_type: "",
    
    ip_list: ipList,
    completeDate: "pending",
    tester: 'none',
    date: date,
    completeDate: "pending",
    tester: 'none',
    status: "REQUESTED"
  };

  const fieldsValidation = {
    network_name: {
      error: "Required",
      validate: "text",
    },
    
    test_type: {
      error: "Required",
      validate: "text",
    },
    ip_list: {
      error: "Required",
    }
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [csvfile, setCSVFile] = useState();
  const [csvDowload, setcsvDowload] = useState();
  const [manualIpList, setmanualIpList] = useState();
  const [csvnamewizard, setcsvName] = useState();
  const [dateedit, setdateedit] = useState();
  const [selectedCompany, setSelectedCompany] = useState({})
  // const [editdata, seteditdata] = useState({
  //   company: '',
  //   companyName: '',
  //   status: '',
  //   date: '',
  //   completeDate: '',
  //   tester: '',
  //   user_id: '',
  //   updated_by: user.id,
  // });
  const classes = useStyles();


  useEffect(() => {
  

    if (requestId) {
      getRequestById();
    }
    // check the the user plans limit

  }, []);




  const getRequestById = async () => {
    await AxiosInstance.post(`${NETWORK}/request`, {
      requestId: requestId
    })
      .then(res => {
        setFormValues(res.data);
        setFormValues({
          network_name: res.data.networkname,
          test_type: res.data.scope,
          ip_list: res.data.top_ips
        })
        if (res.data.csv.length > 0) {
          setCSVFile(res.data.csv);
          setcsvName(res.data.csvName)
        }
   
        setmanualIpList(res.data.manualIpList)
        setdateedit(res.data.date)

        // console.log('check', res.data);
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
  }

  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle form change
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";
    if (index >= 0) {
      setFormErrors({
        [name]: error,
        position: index,
      });
    } else {
      setFormErrors({
        [name]: error,
      });
    }
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
            setFormValues={setFormValues}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        );
      case 1:
        return (
          <ThirdStep
            handleNext={handleNext}
            setIpList={setIpList}
            ipList={ipList}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            csv={csvfile}
            formErrors={formErrors}
            setCSVFile={setCSVFile}
            setmanualIpList={setmanualIpList}
            manualIpList={manualIpList}
            setcsvDowload1={setcsvDowload}
            setcsvName={setcsvName}
            csvnamewizard={csvnamewizard}
          />
        );
      case 2:
        return (
          <FourthStep
            dataValues={formValues}
            setFormValues={setFormValues}
            handleBack={handleBack}
            inputList={ipList}
            requestId={requestId}
            csvfile={csvfile}
            csvDowload={csvDowload}
            manualIpList={manualIpList}
            dateedit={dateedit}
            csvnamewizard={csvnamewizard}
            selectedCompany={selectedCompany}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box style={{ margin: "0 0 2rem" }}>
        <h3 align='center'>Submit network penetest</h3>
        <p align='center'>Using the wizard below submit all the details of your network<br/>
Status will be updated post verification. For further queries please contact support.</p>
      </Box>
        <div className="card">
          <div className="card-body">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              classes={{
                root: classes.root,
              }}
            >
              {labels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {handleSteps(activeStep)}
          </div>
        </div>
    </>
  );
};

export default StepForm;
