import {  useContext } from 'react';
import {  Card, Button} from "react-bootstrap";
import parse from 'html-react-parser';
import { AuthContext } from "../../../../Context/AuthProvider";
import { useEffect } from 'react';




export const ResourceCardComponent = (props) => {
    const { user } = useContext(AuthContext);
    const role = user.role;

    const charLimit = 100
    
    const subStr = props.text.substring(0, charLimit) + "..."

    useEffect(() => {
        document.querySelector('#resourceCardId'+ props.dbId).innerHTML = parse(subStr);
    }, [subStr])
    
    return (
        <Card key={props.dbId} >
            <Card.Header>
                <Card.Title>{props.title}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text id={'resourceCardId' + props.dbId}>
                    
                </Card.Text>
            </Card.Body>
            <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                <Button className='btn btn-info' onClick={() => props.handleOpen(props.dbId, props.title, props.text)}  >
                    Read More
                </Button>
                {(role === 'editor' || role === 'cshAdmin') ? (
                    <Button className='btn btn-danger' onClick={() => props.deleteResource(props.dbId)} > <i className="fa fa-trash"></i> </Button>
                    ) : null}
            </Card.Footer>
        </Card>
    )
}

export default ResourceCardComponent;