import React from "react";
import { Formik, Field, Form, FieldArray,ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import status from "../../../../lib/constant/ReportStatus";

const RoleForm = ({setshowTestModal,
  onCommentSubmit,
  onStatusChange,
  saveTestLimit,
  Limit}) => {
  const initialValues = {
    urls: "",
    is_whitelisting: "Yes",
    roles: [
      {
        login_url: "",
        role: "",
        username: "",
        password: "",
      },
    ],
  };
  
  const Validate = Yup.object({
    urls: Yup.string().required("Required"),
    roles: Yup.array(
      Yup.object({
        login_url: Yup.string()
          // .matches(
          //   /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
          //   "Enter valid url"
          // )
          .required("Required"),
        role: Yup.string().required("Required"),
        username: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
      })
    ),
  });
  return (
    <div>
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onCommentSubmit(values)
      }}
      validationSchema={Validate}>
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form className='p-1' autoComplete='off' onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-xs-12 col-lg-6 mb-2'>
              <div className='form-group'>
                <label className='text-label' htmlFor='urls'>
                  Target IPs/URLs*
                </label>
                <input
                  type='text'
                  data-cy='traget-urls'
                  id='urls'
                  name='urls'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.urls}
                />
                <small className='text-danger'>
                  {touched.urls && errors.urls ? errors.urls : null}
                </small>
              </div>
            </div>
            <div className='col-xs-12 col-lg-6 mb-2'>
              <div className='form-group'>
                <label className='text-label' htmlFor='is_whitelisting'>
                  Whitelisting of IP's Required*
                </label>
                <select
                  data-cy='application-whitelisted'
                  id='is_whitelisting'
                  name='is_whitelisting'
                  className='form-control form-control-lg '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.is_whitelisting}>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </div>
            </div>
          </div>
         <h1 className="mb-4" style={{fontSize:"16px"}}>User Roles Details</h1>
          <FieldArray name='roles'>
            {({ remove, push }) => (
              <div>
                {values.roles.length > 0 &&
                  values.roles.map((role, index) => (
                    <div className='row' key={index}>
                      <div className='col-xs-12 col-lg-6 mb-2'>
                        <div className='form-group'>
                          <label htmlFor={`roles.${index}.login_url`}>
                            Login URL*
                          </label>
                          <Field
                            className='form-control form-control-lg'
                            name={`roles.${index}.login_url`}
                            type='text'
                          />
                          
                          <ErrorMessage
                          name={`roles.${index}.login_url`}
                          component="small"
                          className="field-error text-danger"
                        />
                        </div>
                      </div>
                      <div className='col-xs-12 col-lg-6 mb-2'>
                        <div className=' form-group'>
                          <label htmlFor={`roles.${index}.role`}>Role*</label>
                          <Field
                            className='form-control form-control-lg'
                            name={`roles.${index}.role`}
                            type='text'
                          />
                           <ErrorMessage
                          name={`roles.${index}.role`}
                          component="small"
                          className="field-error text-danger"
                        />
                        </div>
                      </div>
                      <div className='col-xs-12 col-lg-6 mb-2'>
                        <div className='form-group'>
                          <label htmlFor={`roles.${index}.username`}>
                            Username*
                          </label>
                          <Field
                            className='form-control form-control-lg'
                            name={`roles.${index}.username`}
                            type='text'
                          />
                           <ErrorMessage
                          name={`roles.${index}.username`}
                          component="small"
                          className="field-error text-danger"
                        />
                        </div>
                      </div>
                      <div className='col-xs-12 col-lg-6 mb-2'>
                        <div className='form-group'>
                          <label htmlFor={`roles.${index}.password`}>
                            Password*
                          </label>
                          <Field
                            className='form-control form-control-lg'
                            name={`roles.${index}.password`}
                            type='password'
                          />
                           <ErrorMessage
                          name={`roles.${index}.password`}
                          component="small"
                          className="field-error text-danger"
                        />
                        </div>
                      </div>
                      {index > 0 ? (
                        <div className='col'>
                          <button
                            type='button'
                            className='btn btn-outline-primary py-2 mb-2'
                            onClick={() => remove(index)}>
                            REMOVE
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ))}
                <div className=' d-flex flex-row w-100 justify-content-start'>
                  <button
                    type='button'
                    className='btn btn-outline-primary py-2'
                    onClick={() =>
                      push({
                        login_url: "",
                        role: "",
                        username: "",
                        password: "",
                      })
                    }>
                    ADD ANOTHER ROLE
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
          <div className='d-flex flex-row w-100 justify-content-end'>
            <button
              disabled={!_.isEmpty(errors) || isSubmitting}
              className='btn btn-primary mx-2 py-2'
              type='submit'>
              update
            </button>
            <button
              type='button'
              className='btn btn-outline-primary py-2'
              onClick={() => {
                setshowTestModal(false);
              }}>
              cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
  )
}

export default RoleForm