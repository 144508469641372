import React, { Fragment} from 'react';
import { Row, Col, Card, Tab, Nav} from 'react-bootstrap';
import ProfileTab from './profile-tab';
import PasswordTab from './password-tab';

const ProfileWrapper = () =>{
  const tabData = [
    {
      name: 'Profile',
      icon: 'user',
    },
    {
      name: 'Password',
      icon: 'key',
    },
  ]

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='custom-tab-1'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => {
                      if (data.name === "Profile")
                        return <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                <ProfileTab />
                              </Tab.Pane>
                      if (data.name === "Password")
                        return <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                <PasswordTab />
                              </Tab.Pane>  
                    })
                  }                
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default ProfileWrapper;