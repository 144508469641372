import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const SlackOauth = () => {

  const token = new URLSearchParams(useLocation().search).get('code');

  useEffect(() => {

    getToken();

  }, [])

  const getToken = () => {
    let url = "https://slack.com/api/oauth.v2.access"
    let config = {
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }

    let bodyFormData = new FormData();

    bodyFormData.append('code', token);
    bodyFormData.append('client_id', "204400178055.3074283545030");
    bodyFormData.append('client_secret', "192694e91400e4cd480e3badd1f7853b");


    axios.post(url, bodyFormData , config)
    .then((res)=>{
      console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  return (
    <div>SlackOauth</div>
  )
}

export default SlackOauth