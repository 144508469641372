import React, { useState, useEffect, useContext, } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { map, reverse, size } from "lodash";
import { USER } from "../../../../../lib/config/URLConstant";
import { AxiosInstance } from "../../../../../lib/config/axiosConfig";
import Toaster from '../../../Toaster';
import { Spinner } from 'react-bootstrap';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import swal from "sweetalert";
import { AuthContext } from "../../../../../Context/AuthProvider";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const UsersList = ({loggedinuser, openEditPopup, updateTable}) =>{
  const initPaginate = {
    limit:10,
    page:1,
    totalPages:0,
    totalResults:0,
    pagination:[],
    results:[],
  }
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState(initPaginate);
  const { user } = useContext(AuthContext);

  useEffect(()=>{
    getUsers(10,1);
  },[updateTable]);

  const getUsers = async (limit,page) =>{
    setIsLoading(true);
    let params = `limit=${limit}&page=${page}${loggedinuser.role=='cshAdmin' || loggedinuser.role=='tester' ? ``:`&company=${loggedinuser.company}`}`;

    await AxiosInstance.get(`${USER}?${params}`)
    .then(res=>{
      if(res.data.code && res.data.code!==200){
        Toaster(res.data.msg,"error");
      }else{
        let temppage = Array(Math.ceil(res.data.totalResults / paginate.limit))
                        .fill()
                        .map((_, i) => i + 1);
        setPaginate({...res.data, pagination:temppage});
        // // console.log(res.data);
      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
    setIsLoading(false);
  }

  const deleteUser = async(userId) =>{
    setIsLoading(true);
    await AxiosInstance.delete(`${USER}\\${userId}`)
    .then(res=>{
      if(res.data.code && res.data.code!==200){
        Toaster(res.data.msg,"error");
      }else{
        Toaster("User Deleted !!","success");
        getUsers(10,1);
      }
    })
    .catch(err=>{
      Toaster(err.message,"error");
    })
    setIsLoading(false);
  }

  const onEditClick = (user) =>{
    openEditPopup(user);
  }

  const onDeleteClick = (userId) =>{
    swal({
      title: "Are you sure?",
      text:
         "Once deleted, you will not be able to recover user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
   }).then((willDelete) => {
      if (willDelete) {
        deleteUser(userId);
      }
   })
  }

  const onPreviousClick = () =>{
    getUsers(paginate.limit,paginate.page-1);
    setPaginate((prev)=>({...prev,page: paginate.page-1}));
  }
  const onNextClick = () =>{
    getUsers(paginate.limit,paginate.page+1);
    setPaginate((prev)=>({...prev,page: paginate.page+1}));
  }
  const showEntries = () =>{
    if(paginate){
      let start = paginate.page==1 ? paginate.page :
      ((paginate.page-1) * paginate.limit);
      let end = paginate.totalResults > (paginate.page) * paginate.limit
      ? (paginate.page) * paginate.limit
      : paginate.totalResults;
      return `Showing ${start} to ${end} of ${paginate.totalResults}`;
    }
  }

  return (
    <>
    <Table responsive>
      <thead>
        <tr>
          <th className="width80">
            <strong>#</strong>
          </th>
          <th>
            <strong>Name</strong>
          </th>
          <th>
            <strong>Email</strong>
          </th>
          {/* <th>
            <strong>Tittle</strong>
          </th> */}
          {(loggedinuser.role=="cshAdmin" || loggedinuser.role=="tester")?<th>Company</th>:null}
          <th>
            <strong>Role</strong>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading ?
        <tr>
        <td>
        <Spinner animation="border" variant="primary" />
        </td>
        </tr>
        :
        size(paginate.results) !== 0 &&
          map(reverse(paginate.results), (user1, i) => (
        <tr>
          <td>
          <strong>{++i}</strong>
          </td>
          <td>{user1.name}</td>
          <td>{user1.email}</td>
          {/* <td>{user1.title}</td> */}
          {(loggedinuser.role=="cshAdmin" || loggedinuser.role=="tester")?<td>{user1.company}</td>:null}
          <td>{user1.role}</td>
          {
            (user.role == "admin"?
            <td>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="light sharp i-false"
              >
                {svg1}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>onEditClick(user1)}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={()=>onDeleteClick(user1.id)}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
            : null
            )
          }
        </tr>))
        }
      </tbody>
    </Table>
    {paginate && <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
      <div className="dataTables_info"></div>
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="example5_paginate"
        >
          {showEntries()}
          <Tooltip title="Previous Page">
          <span>
          <IconButton 
            onClick={() => onPreviousClick()} 
            disabled={paginate.page===1 || paginate.results.length<=0}
            aria-label="previouspage" color="primary">
            <KeyboardArrowLeftIcon />
          </IconButton>
          </span>
          </Tooltip>
          <Tooltip title="Next Page">
          <span>
          <IconButton
            onClick= {() => onNextClick()}
            disabled={paginate.page===paginate.totalPages || paginate.results.length<=0}
            aria-label="nextpage" color="primary">
            <KeyboardArrowRightIcon />
          </IconButton>
          </span>
          </Tooltip>
        </div>
    </div>
    }
  </>
  )
}
export default UsersList;