import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Table, Badge, Button } from "react-bootstrap";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { AuthContext } from "../../../../Context/AuthProvider";
import { WEB, WEBREPORT, USER, NETWORK } from "../../../../lib/config/URLConstant";
import { Link, useLocation } from "react-router-dom";
import Toaster from "../../Toaster";
import RctSectionLoader from "../utils/RctSectionLoader";
import { IconButton, MenuItem, Select, Tooltip } from "@material-ui/core";
import status from "../../../../lib/constant/RequestStatus";
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CustomClearIndicator from "./MultiSelectViewRequest";


const ViewNetworkRequest = () => {
  const { user } = useContext(AuthContext);
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get('requestId');
  const [formValues, setFormValues] = useState();
  const [inputList, setInputList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showStatusDD, setShowStatusDD] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [previousSelectedStatus, setpreviousSelectedStatus] = useState();
  const [testers, setTesters] = useState([]);
  const [selectedTester, setSelectedTester] = useState([]);
  const [selectedTesterEmail, setSelectedTesterEmail] = useState();
  let testerArray = [];
  let testerArrayEmail = [];
  const [inputIplist, setinputIplist] = useState([]);


  const getBadgeColor = (currStatus) => {
    // test details status
    if (currStatus === "REQUESTED") {
      return "orange";
    }
    if (currStatus === "IN-PROGRESS") {
      return "sky";
    }
    if (currStatus === "COMPLETED") {
      return "green";
    }
  };
  const getRequestById = async () => {
    await AxiosInstance.post(`${NETWORK}/request`, {
      requestId: requestId
    })
      .then(res => {
        setFormValues(res.data);
        getUsers(10, 1, res.data);
        setpreviousSelectedStatus(res.data.status);
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
    setShowLoader(false);
  }

  // code for sending email 

  const sendMail = (changedFrom, changed) => {
    const url = `${WEBREPORT}/sendmail`
    let values1 = {
      companyid: formValues.company,
      requestid: requestId,
      testDetail: formValues
    }
    AxiosInstance.post(url, { name: "statusChanged", network: true, changedFrom: changedFrom, changed: changed, values1 })
      .then((response) => {
        // // console.log(response.data);
      })
  }
  //code for sedning slack message
  const sendSlack = (changedFrom, changed) => {
    if (user.slackChannelId != undefined) {
      AxiosInstance.post(`${WEB}/sendSlack`, {
        type: "reqstatus",
        slackChannelId: user.slackChannelId,
        url: formValues.url,
        applicationname: formValues.application_name,
        changedFrom: changedFrom,
        changed: changed
      })
        .then((response) => {
          // // console.log(response.data);
        })
        .catch((error) => {
          Toaster(error, "error")
        })
    }
  }

  const multiChangeHandler = (val) => {
    let data = []
    for (let ip in val) {
      data.push(val[ip].value)
      setSelectedTester([...selectedTester, val[ip].value[0]])
    }
    setinputIplist(data);
  }

  const onStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  }
  var completeDate = ''
  if (selectedStatus == "COMPLETED") completeDate = new Date().toLocaleDateString();
  else completeDate = new Date().toLocaleDateString() + ' (testing in progress)';

  const saveStatus = async () => {
    let values = { status: selectedStatus };

    await AxiosInstance.post(`${NETWORK}/status`, {
      id: requestId,
      statusvalue: selectedStatus
    })
      .then(res => {
        // console.log(res)
        if (res.status !== 200 && res.status !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          Toaster("Status updated", "success");
          setFormValues(res.data);
          setSelectedStatus(res.data.status);
          setShowStatusDD(false);
          sendMail(previousSelectedStatus, selectedStatus);
          sendSlack(previousSelectedStatus, selectedStatus);

          AxiosInstance.post(`${WEB}/completeDate`, { _id: requestId, completeDate: completeDate, type: 'network' })
            .then((res) => {
              // // console.log(res.data);
            })

          setpreviousSelectedStatus(selectedStatus);

        }
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
  }

  useEffect(() => {
    if (requestId) {
      getRequestById();
    }

  }, [requestId]);

  const setShowPassword = (inp, isShow) => {
    setInputList(prev => prev.map(creds => {
      if (inp._id === creds._id) {
        creds.showPassword = isShow;
      }
      return creds;
    }));
  }

  const getUsers = async (limit, page, formValuesTester) => {
    if (user.role == "cshAdmin") {
      let params = `limit=10000000&page=${page}&role=tester`;

      await AxiosInstance.get(`${USER}/tester?${params}`)
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            for (let i = 0; i < res.data.results.length; i++) {
              testerArray[i] = [res.data.results[i].name];
              testerArrayEmail[i] = [res.data.results[i].email];
            }
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
      setTesters(testerArray);
      let alreadyselectedtester = []
      for (let tester in testerArrayEmail) {
        formValuesTester?.tester?.map((tester1) => {
          if (testerArrayEmail[tester][0] === tester1) {
            alreadyselectedtester.push(testerArray[tester])
          }
        })
      }
      setSelectedTester(alreadyselectedtester)
      setinputIplist(alreadyselectedtester)
      setSelectedTesterEmail(testerArrayEmail);
    }
  }

  const assignTester = (tester) => {
    let email = [];
    for (let i = 0; i < testers.length; i++) {
      tester.map((tester) => {
        if (tester === testers[i][0]) {
          email.push(selectedTesterEmail[i][0])

        }
      })
    }

    AxiosInstance.post(`${NETWORK}/test`, { _id: requestId, name: email })
      .then((res) => {
        // // console.log(res.data);
        Toaster("Tester assigned Successfully", "success")
      })
  }


  const getBadgeColor1 = () => {
    return 'sky'
  }

  // // console.log(formValues);
  return (
    <Fragment>
      {showLoader && <RctSectionLoader />}
      {formValues && <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Request Details
              </h4>
              <Link to={`/report-network?requestId=${requestId}`} className="btn btn-primary text-white">
                View Report
              </Link>
            </div>
            <div className="card-body">
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <th>Fields</th>
                      <th>Values</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Status</td>
                        <td>{showStatusDD ?
                          <div>
                            <Select
                              data-cy="request-details-status-menu"
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={selectedStatus || 'REQUESTED'}
                              onChange={onStatusChange}
                              autoWidth
                            >
                              <MenuItem data-cy="request-details-requested-menu" value={`${status.requested}`}> {status.requested} </MenuItem>
                              <MenuItem data-cy="request-details-inprogress-menu" value={`${status.inProgress}`}>{status.inProgress}</MenuItem>
                              <MenuItem data-cy="request-details-completed-menu" value={`${status.completed}`}>{status.completed}</MenuItem>
                            </Select>

                            <Tooltip title="Save">
                              <IconButton
                                data-cy="save-icon"
                                onClick={() => saveStatus(true)}
                                aria-label="save" color="primary">
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                              <IconButton
                                data-cy="cancel"
                                onClick={() => {
                                  setShowStatusDD(false);
                                  setSelectedStatus(formValues.status);
                                }}
                                aria-label="cancel" color="secondary">
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                          :
                          <div>
                            <span className=''>
                              <Badge variant={getBadgeColor(formValues.status)}>
                                {formValues.status}
                              </Badge>
                            </span>
                            {(user.role === 'cshAdmin' || user.role === 'tester') &&
                              <Tooltip title="Update Status">
                                <IconButton
                                  data-cy="pencil-icon"
                                  onClick={() => setShowStatusDD(true)}
                                  aria-label="update" color="primary">
                                  <CreateIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            }
                          </div>
                        }
                        </td>
                      </tr>
                      <tr>
                        <td>Network Name</td>
                        <td data-cy="request-app-name">{formValues.networkname}</td>
                      </tr>
                      <tr>
                        <td>Scope</td>
                        <td data-cy="request-app-target">{formValues.scope}</td>
                      </tr>
                      {/* <tr>
                        <td>Download csv</td>
                        <td data-cy="request-app-hosted-on"> <a className="btn btn-primary" href={formValues.csv}>Download</a> </td>
                      </tr> */}
                      <tr>
                        <td>No of ips</td>
                        <td data-cy="request-app-hosted-on"> {formValues.no_ips} </td>
                      </tr>
                      {
                        formValues.csvDowload ?
                          <>
                            <tr>
                              <td>Download Csv</td>
                              <td data-cy="request-app-hosted-on"> <a href={formValues.csvDowload} download> <button className="btn btn-primary">Download</button> </a> </td>
                            </tr>
                          </> :
                          <>
                            <tr>
                              <td>Ips List</td>
                              <td>
                                {
                                  formValues.manualIpList.map((ip) => {
                                    return <>
                                      <Badge data-cy="request-status-badge" className="mr-2" variant={getBadgeColor1()}>{ip}</Badge>

                                    </>
                                  })
                                }
                              </td>
                            </tr>
                          </>
                      }


                      {inputList.map((inp, index) => (
                        <tr>
                          <td>Role {index + 1}</td>
                          <td>
                            <strong>Login URL:</strong> {inp.login_url}
                            <br />
                            <strong>Role Name:</strong> {inp.role}
                            <br />
                            <strong>Username:</strong> {inp.user_name}
                            <br />
                            <strong>Password:</strong> {inp.showPassword ? inp.password : '********'}
                            {!inp.showPassword ?
                              <Tooltip title="Show">
                                <IconButton
                                  onClick={() => setShowPassword(inp, true)}
                                  aria-label="show" color="primary">
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              :
                              <Tooltip title="Hide">
                                <IconButton
                                  onClick={() => {
                                    setShowPassword(inp, false);
                                  }}
                                  aria-label="hide" color="primary">
                                  <VisibilityOffIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            }
                          </td>
                        </tr>
                      ))}
                      {(user.role == "cshAdmin") ?
                        <>
                          <tr>
                            <td data-cy="request-app-assignee">
                              Assign a tester
                            </td>
                            <td>
                              <CustomClearIndicator inputIplist={inputIplist} changeHandler={multiChangeHandler} ipslist={testers}></CustomClearIndicator>

                              {/* <select value={selectedTester[0]} data-cy="request-app-change-assignee" onChange={(e) => {
                                if (selectedTester.filter((item)=>{
                                  return item === e.target.value;
                                }).length === 0 ){
                                  setSelectedTester([...selectedTester, e.target.value])
                                }
                              }
                              }>
                                {
                                  testers.map((e) => {
                                    return <option>{e}</option>
                                  })
                                }
                              </select> */}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              {/* {selectedTester?.map((tester)=> {
                            return <span className="mx-2">{tester}</span>
                          } )} */}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <button
                                onClick={() => assignTester(selectedTester)}
                                className="btn btn-primary py-2">
                                Assign
                              </button>
                            </td>
                          </tr></> : null}
                    </tbody>
                  </table>

                </div>
              </Table>
            </div>
          </div>
        </div>
      </Row>
      }
    </Fragment>
  );
}

export default ViewNetworkRequest;