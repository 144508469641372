import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AuthContext } from "../../../../../Context/AuthProvider";
import { AxiosInstance } from "../../../../../lib/config/axiosConfig";
import { AUTH, PLAN, USER, WEBREPORT } from "../../../../../lib/config/URLConstant";
import Toaster from "../../../Toaster";
import swal from "sweetalert";


const AddUsers = ({ setOpenAddUserPopup, addUser, editUserData, setEditUserData, loggedinuser }) => {
  const { user } = useContext(AuthContext);


  const [emailError, setEmailError] = useState("");

  const initialValues = {
    name: "",
    email: "",
    companyname: user.companyName,
    role: "readOnly",
    phone: ''
  }
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    companyname: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
  });
  const [addUserModal, setAddUserModal] = useState(true);

  const close = () => {
    setAddUserModal(prev => prev = false);
    setOpenAddUserPopup(prev => prev = false);
    setEditUserData(null);
  }

  const onSubmit = async (values) => {
    // // console.log(editUserData);
    // verifying email id
    AxiosInstance.post(`${AUTH}/verifyemailid`, { email: values.email })
      .then((response) => {
        Toaster(response.data);

        if (user.role != "cshAdmin" && user.role != "tester") {
            // check the the user plans limit
        let url = `${PLAN}/fetchplan`;
        AxiosInstance.post(url, { id: user.company })
          .then((res) => {
            let allow = false;
            if (values.role == "readOnly") {
              if (res.data.noOfRead < res.data.maxNoOfRead) {
                allow = true;
                Toaster("No of readOnly role has allowed")
              }
              else {
                allow = false;
                swal({
                  title: "No of readOnly role has exhausted",
                  text:
                    "Upgrade the plan to get more roles!",
                  icon: "warning",
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    // // console.log("yes")
                  }
                })
              }
            }
            else if (values.role == "user") {
              if (res.data.noOfUser < res.data.maxNoOfUser) {
                allow = true;
                Toaster("No of user role has allowed")
              }
              else {
                allow = false;
                swal({
                  title: "No of user role has exhausted",
                  text:
                    "Upgrade the plan to get more roles!",
                  icon: "warning",
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    // // console.log("yes")
                  }
                })
              }
            }
            else if (values.role == "admin") {
              if (res.data.noOfAdmin < res.data.maxNoOfAdmin) {
                allow = true;
              }
              else {
                allow = false;
                swal({
                  title: "No of admin role has exhausted",
                  text:
                    "Upgrade the plan to get more roles!",
                  icon: "warning",
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    // // console.log("yes")
                  }
                })
              }
            }
            else {
              allow = false;
              Toaster.error("Role is not allowed")
            }

            if (allow) {
              if (response.data == false){
                setEmailError("Email id is already taken");
              }
              else{
                   //inviting user by email
                   let values1 = {
                    name : btoa(values.name),
                    email : btoa(values.email),
                    companyname : btoa(values.companyname),
                    role : btoa(values.role),
                    phone : btoa(values.phone),
                    company: btoa(user.company),
                    sendemail: values.email
                  }
                   // code for sending email 
      
                  const url = `${WEBREPORT}/sendmail`
      
                  AxiosInstance.post(url,{name:"addnewuser", values1})
                  .then((response)=>{
                    // // console.log(response.data);
                    Toaster("User invited successfully", "success");
                  })
                  .catch((error)=>{
                    Toaster(error, "error");
                  })
                  // addUser(values, !!editUserData);
                  close();
              }
              // // console.log(response);
            }

          })
          .catch((err) => {
            // // console.log(err);
          })
        }

      })
      .catch((error) => {
        Toaster.error(error)
      })


  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (editUserData) {
      editUserData.title = editUserData.title != '' ? editUserData.title : "CEO";

      formik.setValues(editUserData);
    }
  }, [])
  useEffect(() => {
    setEmailError("")
  }, [formik.values.email])


  const onUpdate = () => {
    let url = `${USER}/${formik.values.id}`

    AxiosInstance.patch(url, {  
      name: formik.values.name,
      phone: formik.values.phone,
      primary_contact_name: formik.values.phone,
      companyName: formik.values.companyName,
      role: formik.values.role, 
    })
    .then((res) => {
        Toaster("User details updated successfully", "success")
    })
    .catch((err) => {
      Toaster(err, "error")
    })
  }

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={addUserModal}
      >
        <Modal.Header>
          <Modal.Title>{editUserData ? `Edit User` : `Add User`}</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => close()}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body">
            <div className="basic-form">
              <form action="" onSubmit={formik.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-xs-12 col-sm-6">
                    <label>Name*</label>
                    <input
                      id='name'
                      type="text"
                      className="form-control"
                      name='name'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="formerrormessage">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-xs-12 col-sm-6">
                    <label>Email*</label>
                    <input
                      id='email'
                      type="text"
                      className="form-control"
                      name='email'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="formerrormessage">
                        {formik.errors.email}
                      </div>
                    ) : null}
                    <p className="text-danger">{emailError}</p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-6">
                    <label>Phone Number*</label>
                    <input
                      id='phone'
                      type="text"
                      className="form-control"
                      name='phone'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="formerrormessage">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-xs-12 col-sm-6">
                    <label>Company Name*</label>
                    <input
                      name="companyname"
                      type="text"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.companyname}
                      disabled
                    />
                  </div>
                  <div className="form-group col-xs-12 col-sm-6">
                    <label className="text-label">Role</label>
                    <select
                      id="role"
                      name="role"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.role}
                    >
                      <option value='readOnly'>Read-Only</option>
                      <option value='user'>User</option>
                      <option value='admin'>Admin</option>
                    </select>
                  </div>
                </div>
                <Button
                  variant="danger light"
                  onClick={() => close()}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant={`${!!editUserData ? 'warning' : 'primary'} light`}
                  type="submit"
                  className="btn btn-primary"
                  onClick={ (!!editUserData ? onUpdate: '') }
                >
                  {!!editUserData ? `Update User` : `Add User`}
                </Button>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddUsers;