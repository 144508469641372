import React, { useEffect, useState } from 'react'
import Switch from "react-switch";
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { WEB } from '../../../../lib/config/URLConstant';
import Toaster from '../../Toaster';

const NotificationSetting = () => {

    const [delay, setdelay] = useState(2)
    const [delayFlag, setdelayFlag] = useState(false)

    const handleDelay = (val) => {
        setdelay(val)
    }

    useEffect(()=>{
        AxiosInstance.get(`${WEB}/get-notification-flag`)
        .then((res)=>{
            console.log(res.data)
            setdelayFlag(res.data.flag)
            setdelay(res.data.delay)
        })
        .catch((err)=>{
            console.log(err)
            Toaster("Something went wrong", "error");
        })
    },[])
    
    const updateFlag = ()=>{
        AxiosInstance.post(`${WEB}/update-notification-flag`,{
            flag: delayFlag,
            delay: Number(delay)
        })
        .then((res)=>{
            console.log(res)
            Toaster("Notification Setting Updated Successfully", "success");
        })
        .catch((Err)=>{
            Toaster("Something went wrong", "error");
            console.log(Err)
        })

    }

    return (
        <div className='card p-5'>
            <h3>Notification Setting</h3>

            <div className='d-flex my-3'>
                <label className='w-25' htmlFor="">Vulnerability Alert Notification</label>
                <Switch className='ml-3' onChange={()=> setdelayFlag(!delayFlag)} checked={delayFlag}  />
            </div>
            <div className='d-flex align-items-center my-3'>
                <label className='w-25' htmlFor="">Email notification for vulnerability ageing (In Days)</label>
                <input
                    data-cy="application-name"
                    type="number"
                    min="1"
                    name="application_name"
                    className="form-control w-25"
                    value={delay}
                    onChange={(e) => handleDelay(e.target.value)}
                />
            </div>
            <div className='d-flex mt-3'>
                <div className='w-25'></div>
                <button onClick={()=> updateFlag()} className='w-25 btn btn-primary'>Save</button>
            </div>
        </div>
    )
}

export default NotificationSetting