import React from "react"

export const Checkbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef(null)
      const resolvedRef = ref || defaultRef
      
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} className="form-check-input appearance-none h-[18px] w-[18px] border-2 border-gray-400 rounded-full bg-white checked:bg-orange-500 checked:border-orange-500 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" value="" id="flexCheckDefault3"/>
        </>
      )
    }
  )
  