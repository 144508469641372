import axios from "axios";
import React, { Fragment } from "react";
import { Row, Table } from "react-bootstrap";

const StepFour = ({ inputList, data }) => {
  const submitHandler = (e) => {
    e.preventDefault();
    const webSubmitData = {
      Testdata: "cshsubmit",
      companyname: "CSH",
      email: "akramkhan1790@gmail.com",
      password: "passwordenter",
      phone: "9901024214",
      contact: "888888999999",
      primarycontact: "0000000000",
      application: "www.csh.com",
      host: "csh.com.host",
      targeturl: "www.csh.com1",
      ip: "localhost",
      whitelist: "yes",
      scope: "front",
      role: "admin",
      username: "username",
    };
    // // console.log(webSubmitData);
    axios.post("http://localhost:4000/websubmit", webSubmitData);
  };

  return (
    <Fragment>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Please Confirm the Information submitted for web application
                pentest
              </h4>
            </div>
            <div className="card-body">
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <th>Fields</th>
                      <th>Values</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Company Name</td>
                        <td>{data.name}</td>
                      </tr>
                      <tr>
                        <td>Primary Contact</td>
                        <td>{data.pcontact}</td>
                      </tr>
                      <tr>
                        <td>Primary Contact Email</td>
                        <td>{data.email}</td>
                      </tr>
                      <tr>
                        <td>Primary Phone Number</td>
                        <td>{data.phone}</td>
                      </tr>
                      <tr>
                        <td>Secondary Phone Number</td>
                        <td>{data.secphone}</td>
                      </tr>
                      <tr>
                        <td>Application Name</td>
                        <td>{data.appname}</td>
                      </tr>
                      <tr>
                        <td>Target URL</td>
                        <td>{data.targeturl}</td>
                      </tr>
                      <tr>
                        <td>Application Hosted On</td>
                        <td>{data.host}</td>
                      </tr>
                      <tr>
                        <td>whitelisting Performed</td>
                        <td>{data.whitelist}</td>
                      </tr>
                      <tr>
                        <td>Scope of the pentest</td>
                        <td>{data.scope}</td>
                      </tr>

                      {inputList.map((inp, index) => (
                        <tr>
                          <td>Role {index + 1}</td>
                          <td>
                            <strong>Login URL:</strong> {inp.login_url}
                            <br />
                            <strong>Role Name:</strong> {inp.role}
                            <br />
                            <strong>Username:</strong> {inp.username}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Table>
            </div>
          </div>
        </div>
        {/* <Button className='mr-2' variant='primary btn-square' onSubmit= {submitHandler}>
                  Submit
                </Button>         */}
      </Row>
    </Fragment>
  );
};

export default StepFour;
