const UserRoles = {
    admin: "admin",
    user: "user",
    readOnly: "readOnly",
    cshAdmin: "cshAdmin",
    tester: "tester",
    editor: "editor",
} 
const userRoles = {
    cshAdmin:[UserRoles.cshAdmin],
    tester:[UserRoles.tester, UserRoles.cshAdmin],
    editor:[UserRoles.editor],
    admin:[UserRoles.admin, UserRoles.tester, UserRoles.cshAdmin],
    user:[UserRoles.admin, UserRoles.cshAdmin, UserRoles.tester, UserRoles.user],
    readOnly:[UserRoles.admin, UserRoles.user, UserRoles.readOnly, UserRoles.tester, UserRoles.cshAdmin]
}

export default userRoles;