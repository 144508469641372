import { Button,Modal,Figure } from "react-bootstrap";
import React, {useState} from "react";
import convertToBase64 from "../utils/convertToBase64";

const UploadImgModal = ({showUploadImgModal,setShowUploadImgModal,uploadMultipleFiles}) =>{
  const [image,setImage] = useState();
  const [caption, setCaption] = useState('');
  const [showImgError, setShowImgError] = useState(false);
  const onCaptionChange=(e)=>{
    setCaption(e.target.value);
  }
  const onImageChange=async (e)=>{
    if ( /\.(jpe?g|png|gif)$/i.test(e.target.files[0].name) === false ) { 
      setShowImgError(true);
    }else{
      setImage(await convertToBase64(e.target.files[0]));
      setShowImgError(false);
    }
  }
  return (<Modal
  className="fade bd-example-modal-lg"
  size="lg"
  show={showUploadImgModal}
>
<Modal.Header>
  <Modal.Title>Upload Screenshot</Modal.Title>
    <Button
        variant=""
        className="close"
        onClick={() => setShowUploadImgModal(false)}
    >
        <span>&times;</span>
    </Button>
  </Modal.Header>
  <Modal.Body>
    <div>
    <label className="text-label">Image Caption*</label>
      <textarea
        className="form-control"
        rows="4"
        id="description"
        type="text"
        name='description'
        onChange={onCaptionChange}
        ></textarea>
    </div>
    <div className="custom-file">
      <label className="custom-file-label">Choose Screenshots</label>
      <input placeholder="Choose File" 
        type="file" 
        accept=".jpeg, .png, .jpg" 
        onChange={onImageChange} 
        className="custom-file-input" 
        multiple/>
      {showImgError && <div className="formerrormessage">
        Image format must be .png, .jpg, .jpeg
      </div>
      }
      {image && <Figure>
        <Figure.Image height='100px' width='100px'
          src={`${image}`} thumbnail
        />
      </Figure>}
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="danger light"
      onClick={() => setShowUploadImgModal(false)}
    >
      Close
    </Button>
    <Button
      variant="primary light"
      disabled={!image}
      onClick={()=>uploadMultipleFiles({imgText:caption,imgUrl:image})}
    >
      Add Image
    </Button>
  </Modal.Footer>
  </Modal>
)
}
export default UploadImgModal;