import React, { useContext } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../../../../images/logo-csh.png";


import 'react-phone-number-input/style.css'
import { AUTH } from '../../../../lib/config/URLConstant';
import axios from 'axios';
import Toaster from '../../Toaster';
import { AuthContext } from '../../../../Context/AuthProvider';


const Signin = (props) => {
    const { tokenAuth } = useContext(AuthContext);



    const initialValues = {
        userEmail: '',
        userPassword: '',
    };

    const passwordRegex = RegExp(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    )

    const validationSchema = Yup.object({
        userEmail: Yup.string().email("Invalid email format").required("Required"),
        userPassword: Yup.string()
            .matches(passwordRegex, "Invalid phone password Must Contain One Capital, one special character and one number")
            .min(8, 'Password must be at least 8 characters long')
            .required("Password is required")
    });

    const formik = useFormik({
        initialValues,
        // onSubmit,
        // validate,
        validationSchema,
    });

    const submitHandler = async () => {
        if (formik.errors.length == undefined) {
            // verify login credentials
            // // console.log(formik.values);
            let data = {
                email: formik.values.userEmail,
                password: formik.values.userPassword
            }
            try {
                axios
                    .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/login`, data)
                    .then((res) => {
                        // // console.log(res)
                        if (res.data == false) {
                            Toaster("Incorrect Email Id or Password ", "error")
                        }
                        else {
                            if (res.data.user.company == "none") {
                                Toaster("Login Successful", "success");
                                props.signinStep("application")
                            }
                            else{
                                const {
                                    data: { code = "", msg = "", tokens = {}, user = {} },
                                  } = res;
                        
                                  const newdata = {
                                    token: {
                                      token: tokens.access.token,
                                      userData: user,
                                    },
                                  };
                                  tokenAuth(newdata, user, () => {
                                    Toaster("Login Successful", "success");
                                  });
                                //   if (user.role=="tester"){
                                //     return window.location.href="/requests-web";
                                //   }
                                //   else{
                                     window.location.href = "/dashboard"
                                //   }
                            }
                        }
                    })
                    .catch((error) => {
                        // // console.log(error);
                        // // console.log(typeof error);

                        Toaster(error, 'error');
                        if (error) {
                            // // console.log(error);
                        }
                    });
            } catch (error) {
                Toaster(error, "error");
            }
        }
    }
    return (
        <div>
            <div class="col-11 col-md-8 col-lg-6 multisteps-form__panel js-active" data-animation="slideHorz">
                <div className="form-logo mt-5 pt-4">
                    <img width="150" src={logo} alt="" />
                </div>
                <div class="wizard-forms">
                    <div class="inner pb-10 clearfix">
                        <div class="wizard-title">
                            <h3>Sign In for Threat Scan</h3>
                            <h5 style={{cursor:'pointer'}} onClick={() => props.signinStep("signup")}>New here, Sign Up </h5>
                        </div>

                        <form action="" onSubmit={formik.handleSubmit}>
                            <div class="wizard-form-field mb-50">
                                <div class="wizard-form-input">
                                    <label>Email</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.userEmail}
                                        type="email"
                                        name="userEmail" />
                                    {formik.touched.userEmail && formik.errors.userEmail ? (
                                        <div className="formerrormessage">
                                            {formik.errors.userEmail}
                                        </div>
                                    ) : null}
                                </div>

                                <div class="wizard-form-input">
                                    <label>Password</label>
                                    <input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.userPassword}
                                        type="password"
                                        name="userPassword" />
                                    {formik.touched.userPassword && formik.errors.userPassword ? (
                                        <div className="formerrormessage">
                                            {formik.errors.userPassword}
                                        </div>
                                    ) : null}
                                </div>



                            </div>
                            {/* <div class="wizard-v3-progress">
                            <span>1 to 4 step</span>
                            <h3>25% to complete</h3>
                            <div class="progress">
                                <div class="progress-bar" style={{ width: '25%' }}>
                                </div>
                            </div>
                        </div> */}

                        </form>
                    </div>
                    <div class="actions pb-5">
                        <ul>
                            <li onClick={() => submitHandler()} ><span class="js-btn-next" title="NEXT">NEXT <i class="fa fa-arrow-right"></i></span></li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Signin