import React, { Fragment, useState, useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import UsersList from "./UsersList";
import AddUsers from "./AddUser";
import { USER } from "../../../../../lib/config/URLConstant";
import { AxiosInstance } from "../../../../../lib/config/axiosConfig";
import Toaster from '../../../Toaster';
import { AuthContext } from "../../../../../Context/AuthProvider";

const UserWrapper = () => {
  const context = useContext(AuthContext);
  const { user: loggedinuser } = context;
  const [editUserData, setEditUserData] = useState(null);
  const [openAddUserPopup, setOpenAddUserPopup] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);

  const addUser = async (values, isUpdate) => {
    if (isUpdate) {
      const { name, title, role } = values;
      await AxiosInstance.patch(`${USER}\\${values.id}`, { name, title, role })
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            Toaster("User Updated !!", "success");
            setUpdateTable(prev => !prev);
            setEditUserData(null);
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
    } else {
      await AxiosInstance.post(`${USER}`, values)
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            Toaster("User Added !!", "success");
            Toaster("Reset password Email will be sent to User", "warning");
            setUpdateTable(prev => !prev);
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
    }
  }

  const openEditPopup = (user) => {
    setEditUserData(user);
    setOpenAddUserPopup(true);
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Users</Card.Title>
              {["xadmin", "admin","cshAdmin", "tester"].includes(loggedinuser.role) && <Button
                className="mr-2"
                variant="primary light"
                onClick={() => setOpenAddUserPopup(true)}
              >
                Add New User
              </Button>}
            </Card.Header>
            <Card.Body>
              <UsersList
                loggedinuser={loggedinuser}
                openEditPopup={openEditPopup}
                updateTable={updateTable}
              />
              {openAddUserPopup && <AddUsers
                showModal={true}
                setOpenAddUserPopup={setOpenAddUserPopup}
                addUser={addUser}
                editUserData={editUserData}
                loggedinuser={loggedinuser}
                setEditUserData={setEditUserData} />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
export default UserWrapper;