import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as Yup from "yup";
import { AUTH, WEBREPORT } from "../.../../../../../lib/config/URLConstant";
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import Toaster from '../../Toaster';

const AddCompany = (props) => {

    const [emailError, setemailError] = useState("")

    const initialValues = {
        email: "",
    }
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Required"),
    });

    const onSubmit = async () => {

        let values1 = {
            sendemail: formik.values.email
        }
        setemailError("")
        // verifying email id
        await AxiosInstance.post(`${AUTH}/verifyemailid`, { email: values1.sendemail })
            .then(async (response) => {
                if (response.data == false) {
                    setemailError("Email id already exists")
                }
                else{
                    if (formik.errors.email == undefined && formik.values.email !== "") {

                        // code for sending email 
            
                        const url = `${WEBREPORT}/sendmail`
            
            
                        AxiosInstance.post(url, { name: "addcompany", values1 })
                            .then((response) => {
                                // // console.log(response.data);
                                Toaster("User invited successfully", "success");
                                props.close()
                            })
                            .catch((error) => {
                                Toaster(error, "error");
                            })
                    }
                }
            })
        
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });



    return (
        <div>
            <Modal
                className="fade bd-example-modal-lg"
                size="lg"
                show={props.show}
                onClick={() => props.close}
            >
                <Modal.Header>
                    <Modal.Title>Invite Company</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={props.close}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">
                        <div className="basic-form">
                            <form action="" onSubmit={formik.handleSubmit}>
                                <div className="form-row">

                                    <div className="form-group col-xs-12 col-sm-6">
                                        <label>Email*</label>
                                        <input
                                            id='email'
                                            type="text"
                                            className="form-control"
                                            name='email'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik?.values?.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="formerrormessage">
                                                {formik.errors.email}
                                            </div>
                                        ) : null}
                                        <p className="text-danger">{emailError}</p>
                                    </div>


                                </div>
                                <Button
                                    variant="danger light"
                                    onClick={props.close}
                                >
                                    Cancel
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    variant='primary'
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={() => onSubmit}
                                >
                                    Add Company
                                </Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddCompany