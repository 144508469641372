import axios from "axios";
import React, { useState } from "react";
import { AUTH, WEBREPORT } from "../../lib/config/URLConstant";
import Toaster from "../components/Toaster";

const ForgotPassword = ({ history }) => {

   const [email, setEmail] = useState('');

   const onSubmit = (e) => {
      e.preventDefault();
      axios.post(`${process.env.REACT_APP_API_SERVER}${AUTH}/forgot-password`, {
         email: email
      })
      .then((res) =>{
         // // console.log(res);
         if (res.data.token == false){
            Toaster("Email will be sent if email id exists", 'success');
         }
         else{
            axios.post(`${process.env.REACT_APP_API_SERVER}${WEBREPORT}/sendmail`,{
               name: 'forgotpassword',
               values1: {
                  token:res.data.token,
                  sendemail: email
               }
            })
            .then((res) =>{
               Toaster("Email will be sent if email id exists", 'success');
               // // console.log(res)
            })
            .catch((err) =>{
               // // console.log(err)
            })
         }
      })
      .catch((err) =>{
         // // console.log(err);
      })
   };
   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            {" "}
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <h4 className="text-center mb-4">
                                 Forgot Password
                              </h4>
                              <form onSubmit={(e) => onSubmit(e)}>
                                 <div className="form-group">
                                    <label>
                                       <strong>Email</strong>
                                    </label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       defaultValue="hello@example.com"
                                       onChange={(e) => setEmail(e.target.value)}
                                    />
                                 </div>
                                 <div className="text-center">
                                    <input
                                       type="submit"
                                       value="SUBMIT"
                                       className="btn btn-primary btn-block"
                                    />
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ForgotPassword;
