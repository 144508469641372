import AndroidWizard from "../components/CshPages/mobile-submit/android-submit/Wizard";
import CshHomeDashboard from "../components/CshPages/Dashboard/Csh-dashboard";
import NetworkDashboard from "../components/CshPages/Dashboard/NetworkDashboard";
import JoinDashboard from "../components/CshPages/Dashboard/JoinDashboard";
import Error400 from "../pages/Error400";
import Error401 from "../pages/Error401";
import Error403 from "../pages/Error403";
import Error404 from "../pages/Error404";
import Error500 from "../pages/Error500";
import Error503 from "../pages/Error503";
import IosWizard from "../components/CshPages/mobile-submit/ios-submit/Wizard";
import MyAndroidRequests from "../components/CshPages/MyRequests/myreq-android";
import MyNetworkRequests from "../components/CshPages/MyRequests/myreq-network";
import MyReports from "../components/CshPages/Reports/Reports";
import MyWebRequests from "../components/CshPages/MyRequests/myreq-web";
import MyNetworkrequests from "../components/CshPages/MyRequests/MyNetworkrequests";
import MyiOSRequests from "../components/CshPages/MyRequests/myreq-ios";
import NewDashboard from "../pages/NewDashboard";
import ProfileWrapper from "../components/CshPages/profile/my-profile";
import SearchReport from "../components/CshPages/SearchReport/SearchReport";
import SubmitReport from "../components/CshPages/SubmitReport/SumbitReport";
import Support from "../components/CshPages/Support/support";
import UserWrapper from "../components/CshPages/profile/users";
import ViewWebRequest from "../components/CshPages/MyRequests/view-web-request";
import Vulnerability from "../components/CshPages/Vulnerability/vulnerability";
import WebWizard from "../components/CshPages/web-submit/Wizard";

// import NetworkWizard from "../components/CshPages/network-submit/Wizard";
import NetworkWizard from "../components/CshPages/submit-network/Wizard";


import forgotPassword from "../../jsx/pages/ForgotPassword";
import userRoles from "../../lib/helpers/userRolesHelper";
import Integrations from "../components/CshPages/Integrations/Integrations";
import Jiraintegration from "../components/CshPages/Integrations/Jiraintegration";
import Slackintegration from "../components/CshPages/Integrations/Slackintegration";
import Plans from "../components/CshPages/Plans/Plans";
import Companies from "../components/CshPages/Companies/Companies";
import EditCompany from "../components/CshPages/Companies/EditCompany";
import Submitchecklist from "../components/CshPages/Submitchecklist/Submitchecklist";
import Viewchecklist from "../components/CshPages/Submitchecklist/Viewchecklist";

import Resources from "../components/CshPages/Resources/Resources";
import Resource from "../components/CshPages/Resources/Resource";
import ViewNetworkRequest from "../components/CshPages/MyRequests/view-network-request";
import SubmitNetworkReport from "../components/CshPages/SubmitNetworkReport/SubmitNetworkReport";
import ReportNetwork from "../components/CshPages/Reports/ReportNetwork";
import NetworkVulnerability from "../components/CshPages/Vulnerability/NetworkVulnerability";
import SlackOauth from "../components/CshPages/Integrations/SlackOauth";
import RetestQueue from "../components/CshPages/RetestQueue/RetestQueue";
import CommentsQueue from "../components/CshPages/comments/CommentsQueue";
import NotificationSetting from "../components/CshPages/NotificationSetting/NotificationSetting";


const privateRoutes = [
  { url: "", 
    component: CshHomeDashboard,
    isPermissionsRequired: false,
  },
  { url: "/react", 
    component: CshHomeDashboard,
    isPermissionsRequired: false,
  },
  // { url: "register", component: pageRegister, isPermissionsRequired: false, },
  // { url: "login", component: Login, isPermissionsRequired: false,},
  // { url: "pricing", component: PricingForm, isPermissionsRequired: false,},
  { url: "page-new-dashboard", component: NewDashboard, isPermissionsRequired: false,},
  { url: "page-foggoten-password", component: forgotPassword, isPermissionsRequired: false, },
  { url: "page-error-400", component: Error400, isPermissionsRequired: false, },
  { url: "page-error-403", component: Error403, isPermissionsRequired: false, },
  { url: "page-error-404", component: Error404, isPermissionsRequired: false, },
  { url: "page-error-500", component: Error500, isPermissionsRequired: false, },
  { url: "page-error-503", component: Error503, isPermissionsRequired: false, },
  { url: "page-error-401", component: Error401, isPermissionsRequired: false, },
  { url: "dashboard", 
    component: CshHomeDashboard,
    isPermissionsRequired: false,
  },
  { url: "dashboard-network", 
  component: NetworkDashboard,
  isPermissionsRequired: false,
  },
  { url: "dashboard-overview", 
  component: JoinDashboard,
  isPermissionsRequired: false,
  },
  { url: "reports", 
    component: MyReports,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "report-network", 
    component: ReportNetwork,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "submit-webapp", 
    component: WebWizard,
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "submit-network", 
    component: NetworkWizard,
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "submit-android", 
    component: AndroidWizard,
    isPermissionsRequired: true,
    requiredRoles:userRoles.admin 
  },
  { url: "submit-ios", 
    component: IosWizard,
    isPermissionsRequired: true,
    requiredRoles:userRoles.admin 
  },
  { url: "requests-web", 
    component: MyWebRequests,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "request-network", 
    component: MyNetworkrequests,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "requests-android", 
    component: MyAndroidRequests,
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "requests-ios", 
    component: MyiOSRequests,
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "requests-network", 
    component: MyNetworkRequests,
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "support", 
    component: Support,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "my-profile", 
    component: ProfileWrapper,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "users", 
    component: UserWrapper, 
    isPermissionsRequired: true,
    requiredRoles:userRoles.user 
  },
  { url: "search-report", 
    component: SearchReport,
    isPermissionsRequired: true, 
    requiredRoles:userRoles.admin 
  },
  { url: "submit-report", 
    component: SubmitReport,
    isPermissionsRequired: true,
    requiredRoles:userRoles.admin 
  },
  { url: "submit-network-report", 
    component: SubmitNetworkReport,
    isPermissionsRequired: true,
    requiredRoles:userRoles.admin 
  },

  { url: "vulnerability", 
    component: Vulnerability,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "network-vulnerability", 
    component: NetworkVulnerability,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "view-web-request", 
    component: ViewWebRequest,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "view-network-request", 
    component: ViewNetworkRequest,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "integrations", 
    component: Integrations,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "slackintegration", 
    component: Slackintegration,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "slackoauth", 
    component: SlackOauth,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "retest-queue", 
    component: RetestQueue,
    isPermissionsRequired: true,
    requiredRoles:userRoles.cshAdmin 
  },
  { url: "comments-queue", 
    component: CommentsQueue,
    isPermissionsRequired: true,
    requiredRoles:userRoles.cshAdmin 
  },
  { url: "jiraintegration", 
    component: Jiraintegration,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "plans", 
    component: Plans,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "companies", 
    component: Companies,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "edit-company", 
    component: EditCompany,
    isPermissionsRequired: true,
    requiredRoles:userRoles.readOnly 
  },
  { url: "submitchecklist", 
    component: Submitchecklist,
    isPermissionsRequired: false,
    requiredRoles:userRoles.cshAdmin 
  },
  { url: "viewchecklist", 
    component: Viewchecklist,
    isPermissionsRequired: false,
    requiredRoles:userRoles.cshAdmin 
  },
  { 
    url: "resources",
    component: Resources,
    isPermissionsRequired: false,
    requiredRoles:userRoles.cshAdmin ,
  },
  { 
    url: "notification-settings",
    component: NotificationSetting,
    isPermissionsRequired: false,
    requiredRoles:userRoles.cshAdmin ,
  
  },

  
];

export default privateRoutes;
