import React, { Fragment, useEffect, useContext, useState } from 'react';
import { isEmpty } from "lodash";
import { Button, } from "@material-ui/core";
import { AxiosInstance } from '../../../../../lib/config/axiosConfig';
import { USER } from '../../../../../lib/config/URLConstant';
import Toaster from '../../../Toaster';
import { AuthContext } from "../../../../../Context/AuthProvider";
import { Spinner } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

const ProfileTab = () => {
  const context = useContext(AuthContext);
  const { user } = context;
  const initialValues = {
    companyName: "",
    name: "",
    phone: "",
    email: ""
  }
  const validationSchema = Yup.object({
    companyName: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    phone: Yup.string().required("Required").
      matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/, 'Please enter a valid phone number. i.e: xxxxxxxxxx'),
    role: Yup.string().required("Required"),

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
  });

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getProfileByUserId();
  }, []);

  const onSubmit = async (values) => {
    // // console.log("yes");
    const { companyName,
      name,
      phone,
    } = values;
    setIsLoading(true);
    await AxiosInstance
      .patch(`${USER}/${user.id}`, {
        companyName,
        name,
        phone,
      })
      .then((res) => {
        const { data: { status, msg = "" }, } = res;
        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Profile updated successfully", "success");
          formik.setValues(res.data);
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
    setIsLoading(false);
  }

  

  const getProfileByUserId = async () => {
    await AxiosInstance.get(`${USER}/${user.id}`)
      .then(res => {
        // // console.log("🚀 ~ file: profile-tab.js ~ line 64 ~ getProfileByUserId ~ res", res)

        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          formik.setValues(res.data);
        }
      })
      .catch(err => {
        Toaster(err.message, "error");
      })
    setIsLoading(false);
  }

  return (
    <Fragment>
      <div className="card-body">
        <div className="basic-form">
          <form action="">
            <div className="form-row">
              <div className="form-group col-xs-12 col-sm-6">
                <label>Company Name*</label>
                <input
                  id='companyName'
                  type="text"
                  className="form-control"
                  name='companyName'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.companyName??""}
                />
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div className="formerrormessage">
                    {formik.errors.companyName}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-xs-12 col-sm-6">
                <label>Contact Name*</label>
                <input
                  id='name'
                  type="text"
                  className="form-control"
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.name??""}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="formerrormessage">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-xs-12 col-sm-6">
                <label>Email Id*</label>
                <input
                  id='name'
                  type="text"
                  className="form-control"
                  name='email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.email??""}
                  disabled={true}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="formerrormessage">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-xs-12 col-sm-6">
                <label>Primary Phone Number*</label>
                <input
                  id='phone'
                  type="text"
                  className="form-control"
                  name='phone'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.phone ?? ""}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="formerrormessage">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
             
              <div className="form-group col-xs-12 col-sm-6">
                <label>Role</label>
                <input
                  disabled={true}
                  id='role'
                  type="text"
                  className="form-control"
                  name='role'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.role ?? ""}
                  style={{cursor: "not-allowed"}}
                />
                {formik.touched.role && formik.errors.role ? (
                  <div className="formerrormessage">
                    {formik.errors.role}
                  </div>
                ) : null}
              </div>
            </div>
            <Button 
              variant="contained"
              color="primary"
              type='submit'
              disabled={isLoading}
              onClick={()=> onSubmit(formik.values)}
            >
              {isLoading &&
                <Spinner animation="border" variant="warning" />
              }
              Update Profile
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default ProfileTab;