import axios from "axios";
import isNil from "lodash/isNil";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider";
import avatar from "../../../images/avatar/1.jpg";
import profile_img from "../../../images/avatar/profile.png";
import Widget from 'rasa-webchat';
import { AUTH, NOTIFICATION } from "../../../lib/config/URLConstant";
import { AxiosInstance } from "../../../lib/config/axiosConfig";
import moment from "moment";
import { getTokenItem } from "../../../lib/helpers/localStorage";


const Header = ({ props, onNote, toggle, onProfile, onNotification, onClick }) => {
  var path = window.location.pathname.split("/");
  var locName = path[path.length - 1].split("-");
  var filterName =
    locName.length >= 3 ? locName.filter((n, i) => i > 0) : locName;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;

  const onSubmit = (e) => {
    let res = localStorage.getItem("refresh-token");

    axios.post(`${process.env.REACT_APP_API_SERVER}auth/logout`).then((res) => {
      // history.push("/login");
      // }).catch((error) => {
      //   if (error) {
      //     // // console.log(error.response);
      //     //   this.setState({errorMessage: error.response.data.message});
      //     //   // // console.log(error.response.status);
      //     //   // // console.log(error.response.headers);
      //   }
    });
  };

  const getMode = () => {
    return JSON.parse(localStorage.getItem("dark_mode")) || false
  }
  const [darkMode, setDarkMode] = useState(getMode());
  useEffect(() => {
    localStorage.setItem("dark_mode", JSON.stringify(darkMode))
  }, [darkMode])

  const body = document.querySelector("body");
  if (darkMode) {
    body.setAttribute("data-theme-version", "dark");
  } else {
    body.setAttribute("data-theme-version", "light");
  }

  const { user, logOut, token, logout } = useContext(AuthContext);

  const [userData, setuserData] = useState(user);
  const [notifications, setnotifications] = useState([]);
  const [Unseen, setUnseen] = useState();

  useEffect(() => {
    setuserData(user);
    // getNotification();
  }, [user]);
  // useEffect(() => {
  //   getNotification();
  // }, []);

  // const getNotification = () => {
  //   console.log(getTokenItem())
  //   if(getTokenItem()){
  //     AxiosInstance.post(`${NOTIFICATION}/get_notification`, {
  //       user_id: user.id
  //     })
  //       .then((res) => {
  //         setnotifications(res.data[0].data)
  //         setUnseen(res.data[0].unseen_count)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  useEffect(() => {
    AxiosInstance.post(`${AUTH}/verify-token-session`,)
      .then((res) => {
        if (res.data.msg) {
          logOut();
        }
        else {
          // // console.log(res);
        }
      })
      .catch((err) => {
        // logOut();
        // // console.log(err);
      })
  })

  const seenNotification = ()=>{
    if (Unseen > 0){
      AxiosInstance.post(`${NOTIFICATION}/notification_seen`, {
        user_id: user.id
      })
        .then((res) => {
          console.log(res);
          // getNotification();
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div className="header">

      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName == "dashboard" ? "Web overview dashboard"
                    : finalName[1] == "network" ? "Network overview dashboard"
                      : finalName[1] == "overview" ? "Overview dashboard"


                        : finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              {
                path.includes("dashboard") ?
                  <li className="nav-item dropdown notification_dropdown">
                    <Link to={"#"} onClick={() => setDarkMode(!darkMode)} className={`nav-link bell dz-theme-mode ${darkMode ? 'active' : ''}`}>
                      <i className="far fa-sun i-dark" ></i>
                      <i className="far fa-moon  i-light" ></i>
                    </Link>
                  </li> : null
              }
              {/* // notification icon*/}
              {/* <Dropdown onClick={()=> seenNotification()} className="nav-item dropdown notification_dropdown ml-sm-3">
                <Dropdown.Toggle
                  variant=""
                  className="nav-link  ai-icon i-false"
                  
                >
                  <svg
                    width={26}
                    height={26}
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
                      fill="#6418C3"
                    />
                  </svg>
                  <span className="badge light text-white bg-primary">{Unseen === 0 ? "" : Unseen}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps">
                    <ul className="timeline">
                      {
                        notifications?.map((item) => {
                          return <li>
                            <Link to={item?.link}>
                              <div className="timeline-panel">
                                <div className="media mr-2">
                                  <img alt="image" width={50} src={avatar} />
                                </div>
                                <div className="media-body">
                                  <h6 className="mb-1"> {item?.notification_name} </h6>
                                  <small className="d-block">
                                    {moment(item?.timestamps).format("DD/MM/YYYY HH:MM A")}
                                  </small>
                                </div>
                              </div>
                            </Link>
                          </li>
                        })
                      }

                    </ul>

                  </PerfectScrollbar>
                  <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown> */}

              <Dropdown className="nav-item dropdown header-profile ml-sm-4 ml-2">
                <Dropdown.Toggle
                  data-cy='user-profile-icon'
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black ">
                      Hello,
                      <strong> {!isNil(userData) && userData.name}</strong>
                    </span>
                    <p data-cy="roleName" className="fs-12 mb-0">{userData.role === 'admin' ? 'Admin' : userData.role.charAt(0).toUpperCase() + userData.role.slice(1)}</p>
                  </div>
                  <img
                    // data-cy="user-profile-icon"
                    // src="https://cdn.pixabay.com/photo/2017/11/10/05/46/user-2935524_1280.png"
                    // src="../../images/avatar/my-profile-1.png"'
                    src={profile_img}
                    style={{ border: "1px" }}
                    width={20}
                    alt=""
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu data-cy="data-cy='user-profile-icon" align="right" className="mt-2">
                  <Link to="/my-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span data-cy="profile-menu" className="ml-2">Profile </span>
                  </Link>
                  {(user.role !== "readOnly" && user.role !== "tester") && <Link
                   to = {{
                    pathname: "/integrations", 
                    state: { 
                      userId: user.id
                    }
                   }}
                   className="dropdown-item ai-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width={18}
                      height={18} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                       </svg>    
                    <span data-cy="integrations-menu" className="ml-2">Integrations</span>
                  </Link>
                  }
                  {(user.role !== "cshAdmin" && user.role !== "tester") && <Link to="/plans" className="dropdown-item ai-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-primary"
                      width={20}
                      height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="square" strokeLinejoin="square" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                    <span className="ml-2">Plan Details</span>
                  </Link>
                  }
                  {(user.role !== "cshAdmin" && user.role !== "tester") && <Link to="/notification-settings" className="dropdown-item ai-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-primary"
                      width={20}
                      height={20} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="square" strokeLinejoin="square" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                    <span className="ml-2">Notification Settings</span>
                  </Link>
                  }
                  {(user.role !== "cshAdmin" && user.role !== "tester" && user.role !== "readOnly") && <Link to="/users" className="dropdown-item ai-icon">
                    <svg
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 328.5 328.5"
                      stroke="currentColor"
                      strokeWidth={9}
                      strokeLinecap="square"
                      strokeLinejoin="square"
                      x="0px" y="0px"
                    >

                      <g>
                        <g>
                          <polygon points="96.333,150.918 96.333,135.918 55.667,135.918 55.667,95.251 40.667,95.251 40.667,135.918 0,135.918 0,150.918 
			40.667,150.918 40.667,191.583 55.667,191.583 55.667,150.918 		"/>
                          <path d="M259.383,185.941H145.858c-38.111,0-69.117,31.006-69.117,69.117v39.928H328.5v-39.928
			C328.5,216.948,297.494,185.941,259.383,185.941z M313.5,279.987H91.741v-24.928c0-29.84,24.276-54.117,54.117-54.117h113.524
			c29.84,0,54.117,24.277,54.117,54.117L313.5,279.987L313.5,279.987z"/>
                          <path d="M202.621,178.84c40.066,0,72.662-32.597,72.662-72.663s-32.596-72.663-72.662-72.663s-72.663,32.596-72.663,72.663
			S162.555,178.84,202.621,178.84z M202.621,48.515c31.795,0,57.662,25.867,57.662,57.663s-25.867,57.663-57.662,57.663
			c-31.796,0-57.663-25.868-57.663-57.663S170.825,48.515,202.621,48.515z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>



                      {/* <path d="M 0,0 H 0.5,0.5 1,1 M 0,0 V 0.5,0.5 1,1 M 0,0 H -0.5,-0.5 -1,-1 M 0,0 V -0.5,-0.5 -1,-1" /> */}
                      {/* <line x1="120" y1="160" x2="120" y2="60" stroke="black" />
                      <line x1="60" y1="0" x2="60" y2="120" stroke="black" /> */}
                    </svg>
                    <span data-cy="adduser-menu" className="ml-2">Add User</span>
                  </Link>
                  }
                  {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                    <span className="ml-2">Inbox </span>
                  </Link> */}
                  {/* <Link
                    onClick={() => {
                      logOut();
                      return (
                        <Route>
                          <Redirect to="/dashboard" />
                        </Route>
                      );
                    }}
                    to="/login"
                    className="dropdown-item ai-icon"
                  > */}
                  <button
                    onClick={logOut}
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span data-cy="logout-menu" className="ml-2">Logout </span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
