import React, { Fragment } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
const SearchReport = () => {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const { user } = useContext(AuthContext);
  if (user.role === "user")
    return (
      <Route>
        <Redirect to="/page-error-403" />
      </Route>
    );
  return (
    <Fragment>
      <>
        <div className="form-head d-flex mb-4 mb-md-5 align-items-start">
          <div className="input-group search-area d-inline-flex">
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="flaticon-381-search-2" />
              </span>
            </div>
            <select
              defaultValue={"option"}
              className="form-control form-control-lg"
            >
              <option></option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
          </div>
          <div className="input-group search-area d-inline-flex">
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="flaticon-381-search-2" />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Customer Email"
            />
          </div>
          <div className="input-group search-area d-inline-flex">
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="flaticon-381-search-2" />
              </span>
            </div>
            <select
              defaultValue={"option"}
              className="form-control form-control-lg"
            >
              <option>Primary Report</option>
              <option>Retest</option>
            </select>
          </div>
          <Link to="/" className="btn btn-primary ml-auto">
            Search
          </Link>
        </div>

        <div className="row">
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Recent Payments Queue</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width80">
                        <strong>#</strong>
                      </th>
                      <th>
                        <strong>PATIENT</strong>
                      </th>
                      <th>
                        <strong>DR NAME</strong>
                      </th>
                      <th>
                        <strong>DATE</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>PRICE</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>01</strong>
                      </td>
                      <td>Mr. Bobby</td>
                      <td>Dr. Jackson</td>
                      <td>01 August 2020</td>
                      <td>
                        <Badge variant="success light">Successful</Badge>
                      </td>
                      <td>$21.56</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>02</strong>
                      </td>
                      <td>Mr. Bobby</td>
                      <td>Dr. Jackson</td>
                      <td>01 August 2020</td>
                      <td>
                        <Badge variant="danger light">Canceled</Badge>
                      </td>
                      <td>$21.56</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="danger"
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>03</strong>
                      </td>
                      <td>Mr. Bobby</td>
                      <td>Dr. Jackson</td>
                      <td>01 August 2020</td>
                      <td>
                        <Badge variant="warning light">Pending</Badge>
                      </td>
                      <td>$21.56</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="warning"
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </>
    </Fragment>
  );
};

export default SearchReport;
