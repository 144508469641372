import { Button, Modal, Figure } from "react-bootstrap";
import React from "react";

const PreviewModal = ({showImgModal,setShowImgModal,modalSS,removeImage}) =>{
  return (<Modal
  className="fade bd-example-modal-lg"
  size="lg"
  show={showImgModal}
>
<Modal.Header>
  <Modal.Title>Preview</Modal.Title>
    <Button
        variant=""
        className="close"
        onClick={() => setShowImgModal(false)}
    >
        <span>&times;</span>
    </Button>
  </Modal.Header>
  <Modal.Body>
    <Figure>
      <Figure.Image
        src={`${modalSS.imgUrl}`} height='100%' width='100%' fluid
      />
      <Figure.Caption>
        {modalSS.imgText}
      </Figure.Caption>
    </Figure>
  </Modal.Body>
  <Modal.Footer>
    <Button
      variant="danger light"
      onClick={() => setShowImgModal(false)}
    >
      Close
    </Button>
    <Button
      variant="primary light"
      onClick={()=>removeImage()}
    >
      Remove Screenshot
    </Button>
  </Modal.Footer>
  </Modal>
)
}
export default PreviewModal;