import "./chart.css";
/// Css
import "./index.css";

import React, { Fragment, useContext, useState } from "react";
/// React router dom
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import { AuthContext } from "../Context/AuthProvider";
import Footer from "./layouts/Footer";
/// Layout
import Nav from "./layouts/nav";
import isEmpty from "lodash/isEmpty";
import { map } from "lodash";
import privateRoutes from "./Routes/Private";
import { publicRoutes } from "./Routes/Public";
import Resource from "./components/CshPages/Resources/Resource";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = false;

  if (path.split("-").includes("login") || path.includes("pricing") || path.includes("register") || path.includes("page") || path.includes("sign")) {
    pagePath = true
  }

  const [activeEvent, setActiveEvent] = useState(!path);
  const { loggedIn } = useContext(AuthContext);
  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : ""
            }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ height: "100%" }}
          >
            {/* <Switch><RenderRoutes /></Switch> */}
            <Route render={({ location }) => {
              // // // console.log(location.pathname && 
              //   !privateRoutes.some(x=> `/${x.url}` === location.pathname) &&
              //   !publicRoutes.some(x=> `/${x.url}` === location.pathname));
              if (location.pathname) {
                if (!publicRoutes.some(x => `/${x.url}` === location.pathname)) {
                  if (loggedIn) {
                    if (!privateRoutes.some(x => `/${x.url}` === location.pathname)) {
                      return <Redirect to='/page-error-404' />
                    }
                    else{
                      return <Switch><RenderRoutes /></Switch>
                    }
                  }
                  else{
                    return <Redirect to='/login' />
                  }
                }
                else {
                    if (loggedIn) {
                      if (publicRoutes.some(x => `/${x.url}` === location.pathname)) {
                        return <Redirect to='/dashboard' />
                      }
                      else{
                        return <Redirect to='/page-error-404' />
                      } 
                    }
                    return <Switch><RenderRoutes /></Switch>
                  }
                }
              else if (!privateRoutes.some(x => `/${x.url}` === location.pathname)) {
                  return <Redirect to='/page-error-404' />
              }
              else {
                  return <Switch><RenderRoutes /></Switch>
              }
              

            }} />
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </Router>
  );
};

const RenderPublicRoutes = () => {
  return (
    <>

      {publicRoutes.map((data, i) => (
        <Route exact key={i} path={`/${data.url}`} component={data.component} />
      ))}
      {/* <Route render={() => <Redirect to="/login" />} /> */}
    </>
  );
};

export const RenderRoutes = () => {
  const { loggedIn, token, user } = useContext(AuthContext);

  if (!loggedIn && isEmpty(token)) {
    return (
      <>
        <RenderPublicRoutes />
      </>
    );
  }

  return (privateRoutes.map((data, i) => {
    if (!data.isPermissionsRequired || data.requiredRoles.includes(user.role)) {
      return <Route key={i} exact path={`/${data.url}`} component={data.component} />
    } else {
      return <Route key={i} exact path={`/${data.url}`}
        render={() => <Redirect to="/page-error-401" />}
      />
    }
  }))
};
//
export default Markup;
