import { Button, Table } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Fragment, useContext } from "react";
import { Badge, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Toaster from "../../../components/Toaster";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { NETWORK, PLAN, WEB } from "../../../../lib/config/URLConstant";
import status from "../../../../lib/constant/RequestStatus";
import { AuthContext } from "../../../../Context/AuthProvider";
import { WEBREPORT } from "../../../../lib/config/URLConstant";


const StepFour = ({
  dataValues,
  handleBack,
  setFormValues,
  inputList,
  history,
  requestId = null,
  csvfile,
  csvDowload,
  manualIpList,
  dateedit,
  editdata,
  csvnamewizard,
  selectedCompany
}) => {
  const { password, login_url, user_name, role, ...values } = dataValues;
  const { user } = useContext(AuthContext);

  console.log(editdata)

  const submitHandler = async (e) => {
    e.preventDefault();

    // let url = `${PLAN}/fetchplan`;
    // if (user.role != "cshAdmin" && user.role != "tester") {
    //     AxiosInstance.post(url, { id: .company })
    //         .then((res) => {
    //             setDetails(res.data);
    //             // // console.log(res.data);
    //         })
    //         .catch((err) => {
    //             // // console.log(err);
    //         })
    // }

    if (requestId) {
      updateRequest();
    } else {
      addRequest();
    }
  };
  const addRequest = async () => {

    const formData = {
      csv: csvfile,
      csvDowload: csvDowload,
      csvName: csvnamewizard,
      manualIpList: manualIpList,
      networkname: values.network_name,
      scope: values.test_type,
      top_ips: values.ip_list,
      date: values.date,
      completeDate: values.completeDate,
      tester: values.tester,
      status: values.status,
      user_id: user.id
    }

    if(user?.role === "cshAdmin"){
      formData.company = selectedCompany?.ite?.id
      formData.companyName = selectedCompany?.ite?.company
    }
    else{
      formData.company = user.company;
      formData.companyName = user.companyName;
    }

    if (csvfile == "") delete formData.csv
    else delete formData.manualIpList

    // console.log(csvDowload);

    // // console.log('values1', values);
    // // console.log('values2', user);
    await AxiosInstance
      .post(`${NETWORK}/network`, formData)
      .then(async (res) => {
        const {
          data: { status, msg = "" },
        } = res;

        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Network request created successfully", "success");
          setFormValues({
            network_name: "",

            test_type: "",

            ip_list: "",
          });

          // // code for updating plan
          // AxiosInstance.post(`${PLAN}/updateplan`,{
          //   id: user.company, type: "request"})
          // .then((response)=>{
          //   // // console.log(response.data);
          // })
          // .catch((err)=>{
          //   Toaster(err, "error")
          // })

          // // Code for email sending
          // let user1 = { 
          //   companyname: user.companyName,
          //   appname: values.application_name, 
          //   username: user.name,
          //   sendemail: user.email,
          //   companyid: user.company,
          // }
          // const url = `${WEBREPORT}/sendmail`
          // await AxiosInstance.post(url,{name:"newrequest", values1: user1})
          // .then((response)=>{
          //   // // console.log(response.data);
          // })

          history.push("/request-network");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  }
  const updateRequest = async () => {
    delete values.user_id
    delete values.id
    delete values.no_ips

    let values1 = {
      csv: csvfile,
      csvDowload: csvDowload,
      csvName: csvnamewizard,
      manualIpList: manualIpList,
      networkname: values.network_name,
      scope: values.test_type,
      top_ips: values.ip_list,
      updated_by: user.id
    }
    // console.log(user)
    if (csvfile == "") delete values1.csv
    else delete values1.manualIpList

    delete values1.network_name
    delete values1.test_type


    if (!values.status) { values.status = status.requested; }
    await AxiosInstance
      .put(`${NETWORK}/network/${requestId}`, values1)
      .then((res) => {
        // console.log(res);
        const { data: { status, msg = "" }, } = res;
        if (!status && !isEmpty(msg)) {
          Toaster(msg, "error");
        } else {
          Toaster("Network request Updated successfully", "success");
          setFormValues({
            application_name: "",
            url: "",
            hosted_location: "",
            is_whitelisting: "",
            pentest_scope: "",
            login_url: "",
            credentials: [
              { role: "", user_name: "", password: "", login_url: "" },
            ],
          });
          history.push("/request-network");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  }

  const getBadgeColor = () =>{
    let list = ['sky']
    return list[Math.floor(Math.random() * list.length)]
  }



  // console.log('log', values);
  return (
    <Fragment>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Please Confirm the Information submitted for network
                pentest
              </h4>
            </div>
            <div className="card-body">
              <Table responsive className="w-100">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <th>Fields</th>
                      <th>Values</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Network Name</td>
                        <td>{values.network_name}</td>
                      </tr>
                      <tr>
                        <td>Test Type</td>
                        <td>{values.test_type}</td>
                      </tr>

                      {csvfile != "" ?
                        <>
                          <tr>
                            <td>Csv Download</td>
                            <td> <a href={csvDowload} download>Download</a> </td>

                          </tr>
                          <tr>
                            <td>No of ips detected</td>
                            <td> {++csvfile.length} </td>
                          </tr>
                        </>
                        : <> <tr>
                        <td>No of ips detected</td>
                        <td> {manualIpList.length} </td>
                      </tr>
                      <tr>
                        <td>IP's</td>
                        <td>
                          
                        <div className="d-flex flex-wrap ">
                          {
                            manualIpList.map((ip)=>{
                              return <>
                                  <Badge data-cy="request-status-badge" className="mr-2" variant={getBadgeColor()}>{ip}</Badge>
                                  
                              </>
                            })
                          }
                           </div>

                        </td>
                      </tr>
                      </>
                      }



                      {/* <tr>
                        <td>Application Hosted On</td>
                        <td>{}</td>
                      </tr>
                      <tr>
                        <td>whitelisting Performed</td>
                        <td>{values.is_whitelisting}</td>
                      </tr>
                      <tr>
                        <td>Scope of the pentest</td>
                        <td>{values.pentest_scope}</td>
                      </tr> */}

                      {inputList.map((inp, index) => (
                        <tr>
                          <td>Role {index + 1}</td>
                          <td>
                            <strong>Login URL:</strong> {inp.login_url}
                            <br />
                            <strong>Role Name:</strong> {inp.role}
                            <br />
                            <strong>Username:</strong> {inp.user_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Table>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="default"
            onClick={handleBack}
            style={{ marginRight: 10 }}
          >
            Back
          </Button>
          <Button
            data-cy="submit"
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};
export default withRouter(StepFour);
