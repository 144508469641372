import React, { useState, useEffect, useRef } from "react";
import Comment from "./comment";
import { Button, Spinner } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { WEBREPORT, NOTIFICATION } from "../../../../lib/config/URLConstant";
import Toaster from "../../Toaster";
import RctSectionLoader from "../utils/RctSectionLoader";
import status from "../../../../lib/constant/ReportStatus";

const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ] 
}


const CommentBoxWrapper = ({
  reportId,
  info,
  setshowTestModal,
  commentAdded,
  onStatusChange,
  setcommentAdded,
  saveTestLimit,
  Limit,
  RetestForm,
  selectedStatus,
  vulnername
}) => {
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComments] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [reply, setreply] = useState(false);
  const [replyid, setreplyid] = useState("");
  const [basecommenttext, setbasecommenttext] = useState("");

  


  const onCommentSubmit = (updatedmsg) => {
    if (info) {
      setcommentAdded(false);
    }
    setIsSubmitting(true);
    if (info && updatedmsg) {
      const commentText = `##Updated User Details##
      Target IPs/Urls: ${updatedmsg.urls},
      Whitelisting of IP's: ${updatedmsg.is_whitelisting},
      `;
      var AllRole = "";
      updatedmsg.roles.map(
        (role, idx) =>
          (AllRole =
            AllRole +
            `Role (${idx + 1}),
      Login URL: ${role.login_url},
      Role: ${role.role},
      Username: ${role.username},
      Password: ${role.password},
      `)
      );
      const finalText = commentText + AllRole;
      var data = {
        username: user.name,
        user_id: user.id,
        comment_text: finalText,
        replycomment: `${reply?replyid:"none"}`
      };
    }
    else {
      var data = {
        username: user.name,
        user_id: user.id,
        comment_text: newComment,
        replycomment: `${reply?replyid:"none"}`
      };
      if (reply){
        data = {
          ...data,
          basecomment: basecommenttext
        }
      }

      
    }
    AxiosInstance.post(`${WEBREPORT}/comments/${reportId}`, data)
      .then((res) => {
        if (res.data.code && res.data.code !== 200 && res.data.code !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          if (info) {
            setshowTestModal(false);
            if (selectedStatus !== status.readyToRetest) {
              onStatusChange(status.readyToRetest);
            } else {
              Toaster("Status Updated", "success");
            }
            if (Limit !== "UNLIMITED") {
              saveTestLimit(`${Limit - 1}`);
            }
            setcommentAdded(true);
          }
          getCommentList();
          setNewComments("");
          Toaster("Comment added successfully", "success")

          // code for sending mail
          let data1 = {
              ...data,
              vulnername: vulnername,
              reportId: reportId,
              companyid: user.company,
          }
          const url = `${WEBREPORT}/sendmail`;
          AxiosInstance.post(url,{name:"comment", values1: data1})
          .then((response)=>{
            // // console.log(response.dat
          })

          // code for adding notification

          AxiosInstance.post(`${NOTIFICATION}/create_notification`,{
            user_id: user.id,
            link: `/vulnerability?reportId=${reportId}`,
            notification_name: 'A new comment added'
          })
          .then((response)=>{
            // // console.log(response.dat
          })


        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setIsSubmitting(false);
      });
  };

  const getCommentList = () => {
    setIsLoading(true);
    AxiosInstance.get(`${WEBREPORT}/comments/${reportId}`)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          setComments([...res.data.data.results].reverse());
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (reportId) {
      getCommentList();
    }
  }, [reportId, commentAdded]);

  const deleteComment = (commentId) => {
    AxiosInstance.delete(`${WEBREPORT}/comments/${commentId}`)
      .then((res) => {
        if (res.data.code && res.data.code !== 200 && res.data.code !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          Toaster("Comment Deleted !", "success");
          getCommentList();
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };


  const [commentinput, setcommentinput] = UseFocus();

  useEffect(() => {
    if (reply) setcommentinput();
  },[reply])

  const setreplyinfo = (id, text) => {
      setreply(true);
      setreplyid(id);
      setbasecommenttext(text);
  }

  return (
    <section className='content-item' id='comments'>
      <div className='container ml-0'>
        <div className='row'>
          <div className='col-sm-12'>
            {user.role == "cshAdmin" ||
            user.role == "admin" ||
            user.role == "tester" ||
            user.role == "user" ||
            user.role == "tester" ? (
              info ? (
                <RetestForm
                  saveTestLimit={saveTestLimit}
                  Limit={Limit}
                  onStatusChange={onStatusChange}
                  onCommentSubmit={onCommentSubmit}
                  setshowTestModal={setshowTestModal}
                />
              ) : (
                <form>
                  {info ? null : (
                    <h3 className='pull-left col-sm-12 pl-0'>New Comment</h3>
                  )}

                  <fieldset className='col-sm-12 pl-0'>
                    <div className='row'>
                      <div className='form-group col-xs-12 col-sm-12 col-lg-12'>
                        <textarea
                          onChange={(e) => setNewComments(e.target.value)}
                          value={newComment}
                          className='form-control'
                          ref={commentinput}
                          id='message'
                          placeholder={
                            info
                              ? "Add Environment Variables..."
                              : `${ reply ? "Add reply comment..." : "Add comment..." }`
                          }></textarea>
                      </div>
                    </div>
                  </fieldset>
                  <div className={info ? " text-center w-100" : "w-100"}>
                    <Button
                      className='mr-2'
                      variant='outline-primary btn-xs'
                      onClick={onCommentSubmit}
                      disabled={!newComment}>
                      {isSubmiting ? (
                        <Spinner animation='border' variant='warning' />
                      ) : (
                        <> { reply ? "Add reply comment" : "Submit" } </>
                      )}
                    </Button>
                  </div>
                </form>
              )
            ) : null}
            {info ? null : (
              <>
                {isLoading && <RctSectionLoader />}
                <h3 className='mt-5'>{comments.length} Comments</h3>
                {comments.map((comment, i) => (
                  <Comment setreplyinfo={setreplyinfo} comment={comment} deleteComment={deleteComment} key={i}/>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentBoxWrapper;
