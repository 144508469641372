import { AuthContext } from "../Context/AuthProvider";
import Toaster from "../jsx/components/Toaster"
import { AxiosInstance } from "../lib/config/axiosConfig"
import { COMPANIES } from "../lib/config/URLConstant"

export const networkperm = async (user)=>{

    let networkperm
    await AxiosInstance.post(`${process.env.REACT_APP_API_SERVER}${COMPANIES}/networkpermission`,{
        companyid: user.company
      })
      .then((res)=>{
        networkperm = res.data
      })
      .catch((err)=> {
        Toaster(`${err.message}`, 'error')
      })
    return networkperm
}