import Button from "@material-ui/core/Button";
import React, { Fragment, useState, useEffect} from "react";


const StepThree = ({
  inputList,
  handleBack,
  setInputList,
  handleNext,
  handleChange,
  values: { credentials },
  formErrors,
}) => {
  const [isValid, setIsValid] = useState(false);


  useEffect(() => {
    setIsValid(inputList.every((e, i) => {
      return e.login_url.length > 0 &&
        !formErrors.login_url &&
        e.role.length > 0 &&
        !formErrors.role &&
        e.user_name.length > 0 &&
        !formErrors.user_name &&
        e.password.length > 0 &&
        !formErrors.password;
    }));

  }, [inputList]);



  const handleInputChange = (e, index) => {
    handleChange(e, index);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { login_url: "", role: "", user_name: "", password: "" }]);
  };

  const setError = (index, key) => {
    return formErrors.position == index && !!formErrors[key]
  }
  const setHelperText = (index, key) => {
    return formErrors.position == index && formErrors[key]
  }

  return (
    <>
        <Fragment>
          {inputList.map((x, i) => (
            <div className="row" key={i}>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Login URL*</label>
                  <input
                    data-cy="login-url"
                    type="text"
                    name={`login_url`}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={(e) => handleInputChange(e, i)}
                    value={x.login_url}
                    required
                  />
                  {setError(i, `login_url`) ? (
                    <div className="formerrormessage">
                      {formErrors[`login_url`]}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Role*</label>
                  <input
                    data-cy="role"
                    type="text"
                    className="form-control"
                    id={`role-${i}`}
                    name={`role`}
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={(e) => handleInputChange(e, i)}
                    value={x.role}
                    required
                  />
                  {setError(i, `role`) ? (
                    <div className="formerrormessage">
                      {formErrors[`role`]}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Username *</label>
                  <input
                    data-cy="username-role"
                    type="text"
                    name="user_name"
                    className="form-control"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={(e) => handleInputChange(e, i)}
                    value={x.user_name}
                    required
                  />
                  {setError(i, `user_name`) ? (
                    <div className="formerrormessage">
                      {formErrors[`user_name`]}
                    </div>
                  ) : null}
                </div>

                <div className="d-block w-100 ">
                  {inputList.length !== 1 && (
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </Button>
                  )}
                  {inputList.length - 1 === i && (
                    <Button
                      className="mr-2"
                      variant="contained"
                      onClick={handleAddClick}
                    >
                      Add Another Role
                    </Button>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Password *</label>
                  <input
                    data-cy="password-role"
                    type="password"
                    name="password"
                    className="form-control"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={(e) => handleInputChange(e, i)}
                    value={x.password}
                    required
                  />
                  {setError(i, `password`) ? (
                    <div className="formerrormessage">
                      {formErrors[`password`]}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>)
          )}

          <div className="col-xs-12 col-lg-12 mb-2">
            <div
              style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="default"
                onClick={handleBack}
                style={{ marginRight: 10 }}
              >
                Back
              </Button>
              <Button
                data-cy="submit"
                variant="contained"
                color="primary"
                onClick={() => isValid && handleNext()}
              >
                Next
              </Button>
            </div>
          </div>
        </Fragment> 
 </>
  );
};
export default StepThree;






