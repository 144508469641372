import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext, getUser } from '../../../../Context/AuthProvider';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { WEB } from '../../../../lib/config/URLConstant';
import Toaster from '../../Toaster';
import Switch from "react-switch";

const Integrations = () => {
    const { user } = useContext(AuthContext);

    const [critical, setcritical] = useState(user.criticalNotification);
    const [high, sethigh] = useState(user.highNotification);
    const [medium, setmedium] = useState(user.mediumNotification);
    const [low, setlow] = useState(user.lowNotification);
    const [info, setinfo] = useState(user.infoNotification);
    const [jiraEnable, setjiraEnable] = useState(false);
    const [jiraconnect, setjiraconnect] = useState(false);

    useEffect(() => {
        GetUserData();
        AxiosInstance.post(`${WEB}/getjiraConnectionToggle`, { id: user.company })
            .then((res) => {
                setjiraEnable(res.data.jiraconnection)
                setjiraconnect(res.data.jiraverified)
            })
            .catch((err) => {
                // console.log(err);
            })
    }, [user])


    const disconnectJira = () => {
        AxiosInstance.post(`${WEB}/jiraconnectionverified`, { id: user.company, value: false })
            .then((response) => {
                // console.log(response);
                setjiraconnect(false)
            })
            .catch((error) => {
                // console.log(error);
            })
    }

    const GetUserData = async () => {
        AxiosInstance.post(`${WEB}/getUserData`, { _id: user.id, company: user.company })
            .then((res) => {
                setcritical(res.data.criticalNotification);
                sethigh(res.data.highNotification);
                setmedium(res.data.mediumNotification);
                setlow(res.data.lowNotification);
                setinfo(res.data.infoNotification);
            })
    }


    const optionHandler = (e) => {
        if (e == "critical") setcritical(!critical);
        else if (e == "high") sethigh(!high);
        else if (e == "medium") setmedium(!medium);
        else if (e == "low") setlow(!low);
        else setinfo(!info);
        // // console.log(critical, high, medium, low, info);
    }

    const submitNotificationSetting = () => {
        AxiosInstance.post(`${WEB}/submitnotificationsetting`, { _id: user.company, critical, high, medium, low, info, company: user.company })
            .then((res) => {
                // // console.log(res.data);
                Toaster("Notification Settings Updated Successfully", "success")
            })
            .catch((err) => {
                // // console.log(err)
            })
    }

    const enableJira = (value) => {
        AxiosInstance.post(`${WEB}/jiraConnectionToggle`, { id: user.company, value: value })
            .then((res) => {
                // console.log(res);
                setjiraEnable(!jiraEnable)
            })
            .catch((err) => {
                // console.log(err);
            })
    }

    return (
        <div className="integration-page-container container bg-white p-5 mt-0">
            <div className="row">
                <div className="col">
                    <h2>Notification Settings</h2>
                    <p>Please select the severity for which you want to recieve notifications</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-wrap">
                        <div onClick={() => optionHandler('critical')} className={(critical) ? "my-2 mx-2 optionslack  text-white d-flex w-100 align-items-center optionop" : "my-2 mx-2 optionslack  text-white d-flex w-100 align-items-center"}>
                            <p style={{ marginLeft: '10px', height: '5px' }}>Critical</p>
                        </div>
                        <div onClick={() => optionHandler('high')} style={{}} className={(high) ? "my-2 mx-2 optionslack option1 text-white d-flex w-100 align-items-center optionop" : "my-2 mx-2 optionslack option1 text-white d-flex w-100 align-items-center"}>
                            <p style={{ marginLeft: '10px', height: '5px' }}>High</p>
                        </div>
                        <div onClick={() => optionHandler('medium')} style={{}} className={(medium) ? "my-2 mx-2 optionslack option2 text-white d-flex w-100 align-items-center optionop" : "my-2 mx-2 optionslack option2 text-white d-flex w-100 align-items-center"}>
                            <p style={{ marginLeft: '10px', height: '5px' }}>Medium</p>
                        </div>
                        <div onClick={() => optionHandler('low')} style={{}} className={(low) ? "my-2 mx-2 optionslack option3 text-white d-flex w-100 align-items-center optionop" : "my-2 mx-2 optionslack option3 text-white d-flex w-100 align-items-center"}>
                            <p style={{ marginLeft: '10px', height: '5px' }}>Low</p>
                        </div>
                        <div onClick={() => optionHandler('info')} style={{}} className={(info) ? "my-2 mx-2 optionslack option4 text-white d-flex w-100 align-items-center optionop" : "my-2 mx-2 optionslack option4 text-white d-flex w-100 align-items-center"}>
                            <p style={{ marginLeft: '10px', height: '5px' }}>Info</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button
                        onClick={submitNotificationSetting}
                        style={{ borderRadius: '10px' }} className="mx-2 btn btn-primary my-3">Save</button>
                </div>
            </div>
            <div className="row  mt-5">
                <h3>Integrations</h3>
            </div>
            <div className="row mt-4">
                <div style={jiraEnable ? { boxShadow: '0px 0px 2px black', borderRadius: '20px' } : { boxShadow: '0px 0px 2px black', borderRadius: '20px', opacity: '0.5' }} className="integration-page-card col-md-5 col-12 d-flex flex-column py-4">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <img className="pl-3" style={{ width: '150px' }} src="https://jira.jnj.com/images/atlassian-jira-logo-large.png" />
                        <Switch onChange={() => {
                            enableJira(!jiraEnable);
                        }} checked={jiraEnable} />
                    </div>
                    <h3 className="mt-3 pl-3">Jira Cloud</h3>
                    <p className="pl-3">Integrate with JIRA cloud to creates vulnerabilities in your project and assign it to the developers to fix the the vulnerability.</p>
                    {
                        jiraconnect ?
                            <button className="btn btn-danger text-white  p-2 ml-3"
                                onClick={() => disconnectJira()}
                            >
                                Disconnect with Jira Cloud
                            </button>
                            :
                            <Link className="btn btn-primary  p-2 ml-3"
                                to={{
                                    pathname: './jiraintegration',
                                    state: {
                                        userId: user.id
                                    }
                                }}>
                                Connect with Jira Cloud
                            </Link>


                    }
                </div>
                <div className="col-1"></div>
                <div style={{ boxShadow: '0px 0px 2px black', borderRadius: '20px' }} className="integration-page-card col-md-5 col-12 mt-md-0 mt-4 d-flex flex-column py-4">
                    <img className="pl-3" style={{ width: '150px' }} src="https://aem.dropbox.com/cms/content/dam/dropbox/www/en-us/business/app-integrations/slack/Slack_logo_new.png" />
                    <h3 className="mt-3 pl-3">Slack</h3>
                    <p className="pl-3">Integrate with SLACK for instant notification. Change in the status of any test or vulnerability. Notifications can be received on any number of channel.</p>
                    <Link className="btn btn-primary p-2 ml-3"
                        to={{
                            pathname: '/slackintegration',
                            state: {
                                userId: user.id
                            }
                        }}>
                        Connect with Slack
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default Integrations