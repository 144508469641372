import Button from "@material-ui/core/Button";
import React, { Fragment, useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { COMPANIES, PLAN } from "../../../../lib/config/URLConstant";
import { AuthContext } from "../../../../Context/AuthProvider";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Toaster from "../../Toaster";


const StepTwo = ({
  handleNext,
  handleChange,
  formValues,
  formErrors,
  setFormValues,
  setSelectedCompany,
  selectedCompany
}) => {
  const [network, setNetwork] = useState("");
  const [testType, settestType] = useState("");

  const initialValues = {
    network_name: "",

    test_type: "Internal",
  };

  const { user } = useContext(AuthContext);
  const [allow, setAllow] = useState(true);

  const validationSchema = Yup.object({
    network_name: Yup.string().required("Required").max(12, "Maximum 12 characters allowed"),

    test_type: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (values.test_type == "Internal") {
      swal({
        title: "Our repersentative  from our team will reach out to you soon to access your internal network.",
        text:
          "",
        icon: "info",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { nextStep(values) }
      })
    }
    else {
      nextStep(values)
    }

  }

  const nextStep = (values) => {
    setFormValues((prev) => ({
      ...prev,
      ...values,
    }));
    handleNext();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    formik.setValues({
      network_name: formValues.network_name,
      test_type: formValues.test_type ? formValues.test_type : "Internal",
    });
  }, [formValues]);

  useEffect(() => {
    if (user.role != "cshAdmin" && user.role != "tester") {
      let url = `${PLAN}/fetchplan`;

      AxiosInstance.post(url, { id: user.company })
        .then((res) => {
          // // console.log(res);
          if (res.data.noOfTest < res.data.maxNoOfTest) {
            setAllow(true)
          }
          else {
            setAllow(false)
          }
        })
        .catch((err) => {
          // // console.log(err)
        })
    }
  }, [])

  const [companies, setCompanies] = useState([])

  useEffect(async () => {

    if (user.role === "cshAdmin") {
      let params = `sortBy='desc'&limit=100000&page=1`;

      await AxiosInstance.get(`${COMPANIES}?${params}`)
        .then(res => {
          if (res.data.code && res.data.code !== 200) {
            Toaster(res.data.msg, "error");
          } else {
            setCompanies(res.data.data.results)
            setSelectedCompany({
              ite: res?.data?.data?.results?.[0],
              index: 0
            })
          }
        })
        .catch(err => {
          Toaster(err.message, "error");
        })
    }
  }, [])


  return (
    <>
      {(allow) ?
        <Fragment>
          <form action="" onSubmit={formik.handleSubmit}>
            {
              user.role === "cshAdmin" ?
                <>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6 mb-2">
                      <div className="form-group">
                        <label className="text-label">Select Company</label>
                        <select onChange={(e) => setSelectedCompany({
                          ite: companies?.[e.target.value],
                          index: e.target.value
                        })} value={selectedCompany?.index} defaultValue={companies?.[0]?.id} className="form-control"
                        >
                          {
                            companies?.map((ite, index) => {
                              return <option value={index} key={`${index}_${ite?.company}`}> {ite?.company} </option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </>
                : null
            }
            <div className="row">
              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">Network Name *</label>
                  <input
                    data-cy="network_name"
                    type="text"
                    name="network_name"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.network_name}
                  />
                  {formik.touched.network_name && formik.errors.network_name ? (
                    <div className="formerrormessage">
                      {formik.errors.network_name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-xs-12 col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label">
                    Type of Test *
                  </label>
                  <select
                    data-cy="test_type"
                    id='test_type'
                    name="test_type"
                    className="form-control form-control-lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.test_type}
                  >
                    <option value="External">External</option>
                    <option value="Internal">Internal</option>
                    <option value="Internal and External">Internal and External</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-lg-12 mb-2">
                <div
                  style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
                >
                  <Button
                    data-cy="submit"
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Fragment> :
        <div style={{ height: "500px" }} className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="text-danger">No of test is full</h2>
          <Link
            to="/support"
          >

            <button className="btn btn-primary">Upgrade your plan</button>
          </Link>
        </div>
      }
    </>
  );
};

export default StepTwo;
