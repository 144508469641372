import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { CHECKLIST } from "../../../../lib/config/URLConstant";
import {checklist} from "./checklistdata";
import Toaster from "../../Toaster";
import CustomModal from "./CustomModal";


const Submitchecklist = () => {
  const search = useLocation().search;
  const requestid = new URLSearchParams(search).get("requestId");
  const [ids, setIds] = useState();
  const [status, setstatus] = useState();
  const [showImgModal, setshowImgModal] = useState(false);

  useEffect(() => {
    fetchChecklist();
  }, [])

  const modalHideHandler = ()=>{
      setshowImgModal(!showImgModal);
  }

  const fetchChecklist = ()=>{
    AxiosInstance.post(`${CHECKLIST}/getchecklist`,{
      requestid: requestid
    })
    .then((res)=>{
      let idarray = [];
      let statusarray = [];
      for (let id in res.data.checklist){
          if (res.data.checklist[id].id > checklist.length){
              checklist.push(
                {
                  id: res.data.checklist[id].id,
                  check: res.data.checklist[id].name,
                }
              )
          }
          idarray.push(
            res.data.checklist[id].id
          )
          statusarray.push(
            res.data.checklist[id].status
          )
      }
      setIds(idarray);
      setstatus(statusarray);
      
    // // console.log(idarray, statusarray)
    })
    .catch((err)=>{
      // // console.log(err)
    })
  }

  const saveStatus = async (id, name, status) => {
      await AxiosInstance.post(`${CHECKLIST}/submitchecklist`,{
      
            id: id,
            name:name,
            status: status,
            requestid: requestid,
      })
      .then((res)=>{
        // // console.log(res);
          if (res.status == 200){
            Toaster("Checklist Updated Successfully", "success");
          }
          else{
            Toaster("Checklist Added Successfully", "success");
          }
      })
      .catch(err => {
          // // console.log(err)
      })
      fetchChecklist();
  }


  return (
    <div>
      <CustomModal showImgModal={showImgModal} setshowImgModal={modalHideHandler} saveStatus={saveStatus} checklist={checklist}/>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Checklist</Card.Title>
            <Card.Title> <button onClick={()=> setshowImgModal(!showImgModal)} className="btn btn-primary">Add custom checklist</button> </Card.Title>

          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{minWidth:"68px"}}>
                    <strong>Sr No</strong>
                  </th>
                  <th>
                    <strong>Vulnerability Checklist</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {status && checklist.map((item) => {
                  let statusname = status && status[ids?.indexOf(item.id)];
                  return (
                    <tr key={"tr-"+item.id}>
                      <td>{item?.id}</td>
                      <td>{item?.check}</td>
                      <td>
                        <div className="d-flex">
                          <p
                            onClick={()=> saveStatus(item.id, item.check, "yes")}
                            className={ ids?.includes(item.id) && statusname=="yes"? "btn btn-primary shadow btn-xs sharp mr-1 checklist-border":"btn btn-primary shadow btn-xs sharp mr-1 "}
                          >
                            <i 
                            className="fa fa-check"
                            ></i>
                          </p>
                          <p
                            onClick={()=> saveStatus(item.id, item.check, "no")}
                            className={ids?.includes(item.id) && statusname=="no"? "btn btn-danger shadow btn-xs sharp mr-1 checklist-border":"btn btn-danger shadow btn-xs sharp mr-1  "} 
                          >
                            <i className="fa fa-times"></i>
                          </p>
                          <p
                            onClick={()=> saveStatus(item.id, item.check, "na")}
                            className={ids?.includes(item.id) && statusname=="na"? "btn btn-danger shadow btn-xs sharp checklist-border":"btn btn-danger shadow btn-xs sharp"} 
                          >
                            <i className="fa fa-ban"></i>
                          </p>
                          
                          <p
                            onClick={()=> saveStatus(item.id, item.check, "delete")}
                            className="btn btn-danger shadow btn-xs sharp ml-1"
                          >
                            <i className="fa fa-trash"></i>
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default Submitchecklist;
