import * as Yup from "yup";
import logo from '../../images/logo-csh.png'
import React, { useContext } from "react";


import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";

import {AUTH} from "../../lib/config/URLConstant";
import { AuthContext } from "../../Context/AuthProvider";
import { Link } from "react-router-dom";
import Toaster from "../../jsx/components/Toaster";
import axios from "axios";
import { useFormik } from "formik";

const Login = ({ history }) => {
  const initialValues = {
    email: "",
    password: "",
  };
  const { tokenAuth } = useContext(AuthContext);

  const onSubmit = (values) => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/login`, values)
        .then((res) => {
          if (res.data == false){
             Toaster("Incorrect Email Id or Password ", "error")
          }
          else{

            if (res.data.user.company == "none") {
                history.push({
                  pathname: '/pricing',
                  state:{
                    user: res.data.user,
                    step: 2
                  }
                })
            }
            else{
              const {
                data: { code = "", msg = "", tokens = {}, user = {} },
              } = res;
    
              const newdata = {
                token: {
                  token: tokens.access.token,
                  userData: user,
                },
              };
              tokenAuth(newdata, user, () => {
                Toaster("Login Successful", "success");
              });
              if(user.role=="editor"){
                return window.location.href="/resources";
              }
              else if (user.role=="tester"){
                return window.location.href="/requests-web";
              }
              else{
                return history.push("/dashboard");
              }
            }

          }

          // Toaster(msg, "error");
          // history.push("/login");
        })
        .catch((error) => {
          // // console.log(error);
          // // console.log(typeof error);

          Toaster(error, 'error');
          history.push("/login");

          if (error) {
            // // console.log(error);
            // // // console.log(error.response.data.message);
            // this.setState({errorMessage: error.response.data.message});
            // // // console.log(error.response.status);
            // // // console.log(error.response.headers);
          }
        });
    } catch (error) {
      Toaster(error, "error");
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    // validate,
    validationSchema,
  });

  return (
   
      <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
          <div className="login-wrapper">
              <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
                  <Link to="/dashboard" className="login-logo">
                      <img src={logo} alt="" className="mr-2"/>
                      {/* <img src={logotext} alt="" className="ml-1"/> */}
                    </Link>
                  <div className="login-description">
                      <h2 className="text-black  mb-2">Welcome to ThreatScan</h2>
                      <p className="fs-12">ThreatScan the NextGen Cloud-based, AI-powered Vulnerability Management and Penetration Testing Platform.</p>
                      <ul className="social-icons mt-4">
                          <li><a href="https://www.facebook.com/hivesecurities"><i className="fa fa-facebook"></i></a></li>
                          <li><a href="https://twitter.com/CyberSecHive"><i className="fa fa-twitter"></i></a></li>
                          <li><a href="https://www.linkedin.com/company/cyber-security-hive/"><i className="fa fa-linkedin"></i></a></li>
                      </ul>
                      <div className="mt-5">
                          <a href="https://threatscan.io/privacy-policy/" className="text-black mr-4">Privacy Policy</a>
                          <a href="https://threatscan.io/contact-us/" className="text-black mr-4">Contact</a>
                          <a href={"#"} className="text-black">© 2022 Cyber Security Hive</a>
                      </div>
                  </div>
              </div>
              <div className="login-aside-right">
                  <div className="row m-0 justify-content-center h-100 align-items-center">
                    <div className="col-xl-7 col-xxl-7">
                      <div className="authincation-content">
                        <div className="row no-gutters">
                          <div className="col-xl-12">
                            <div className="auth-form-1">
                              <div className="mb-4">
                                  <h3 className="text-white mb-1">Welcome to Threat Scan</h3>
                                  <p className="text-white">Sign in by entering information below</p>
                              </div>
                              {/* {props.errorMessage && (
                                  <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                      {props.errorMessage}
                                  </div>
                              )}
                              {props.successMessage && (
                                  <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                      {props.successMessage}
                                  </div>
                              )} */}
                              <form action="" onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                  <label className="mb-1">
                                    <strong className="text-white">Email</strong>
                                  </label>
                                  <input
                                    cy-tag="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="jon@domainname.com"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.email}
                                  />
                                  {formik.touched.email && formik.errors.email ? (
                                    <div className="formerrormessage">
                                      {formik.errors.email}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="form-group">
                                  <label className="mb-1">
                                    <strong className="text-white">Password</strong>
                                  </label>
                                  <input
                                  cy-tag="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="*********"
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.password}
                                  />
                                  {formik.touched.password && formik.errors.password ? (
                                    <div className="formerrormessage">
                                      {formik.errors.password}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                  <div className="form-group">
                                    <div className="custom-control custom-checkbox ml-1">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="basic_checkbox_1"
                                      />
                                      <label
                                        className="custom-control-label text-white"
                                        htmlFor="basic_checkbox_1"
                                      >
                                        Remember my preference
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group text-white">
                                    <Link className="text-white darkgreyd" to="/page-forgot-password">Forgot Password?</Link>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <input
                                  cy-tag="submit"
                                    type="submit"
                                    value="Sign Me In"
                                    className="btn btn-primary btn-block"
                                  />
                                </div>
                              </form>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Login;
