import React from "react";
import ReactApexChart from "react-apexcharts";
class OwaspBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Injection',
                data: [this.props?.owaspCounts?.InjectionTotalCount[0]
            ],
                color:'#653600'
            }, 
            {
                name: 'Broken authentication',
                data: [ this.props?.owaspCounts?.brokenTotalCount[0],
            ],
                color:'#b25f00'
            },
            {
                name: 'Sensitive data exposure',
                data: [this.props?.owaspCounts?.sensitiveTotalCount[0],
            ],
                color:'#ff8800'
            }, 
            {
                name: 'XML external entities (XXE)',
                data: [ this.props?.owaspCounts?.XMLTotalCount[0],
            ],
                color:'#ffac4c'
            },  {
                name: 'Broken access control',
                data: [ this.props?.owaspCounts?.brokenaccessTotalCount[0],

            ],
                color:'#fecf99'
            }, 
            {
                name: 'Security misconfigurations',
                data: [this.props?.owaspCounts?.securityTotalCount[0],
            ],
                color:'#005466'
            }, 
            {
                name: 'Cross site scripting (XSS)',
                data: [this.props?.owaspCounts?.crossTotalCount[0],
            ],
                color:'#0095b3'
            }
            , 
            {
                name: 'Insecure deserialization',
                data: [this.props?.owaspCounts?.insecureTotalCount[0],
            ],
                color:'#01d4ff'
            }
            , 
            {
                name: 'Using components with known vulnerabilities',
                data: [this.props?.owaspCounts?.usingTotalCount[0],
            ],
                color:'#4be0fe'
            },
            {
                name: 'Insufficient logging and monitoring',
                data: [this.props?.owaspCounts?.insufficientTotalCount[0],
            ],
                color:'#9aedff'
            } 
           
            
        ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: [this.props.reqapplication_name],
                        labels: {
                            rotate: 0,
                        }
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },


        };
    }

    

    render() {
        // // console.log('owasp', this.props?.owaspCounts);
        return (


            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default OwaspBar;
