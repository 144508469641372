import { Button, Modal, Figure } from "react-bootstrap";
import React, { useState } from "react";

const CustomModal = (props) => {

  const [name, setname] = useState("");
  const [action, setaction] = useState("yes");

  return (<Modal
    className="fade bd-example-modal-lg"
    size="lg"
    show={props.showImgModal}
  >
    <Modal.Header>
      <Modal.Title>Custom checklist</Modal.Title>

    </Modal.Header>
    <Modal.Body>
        <form className="w-100">
            <label className="w-100" htmlFor="">Enter the checklist name</label>
            <input onChange={(e)=> setname(e.target.value)} className="py-2 form-control" type="text" />
            <label className="w-100 mt-3" htmlFor="">Select Action</label>
            <select onChange={(e)=> setaction(e.target.value)} className="form-control" name="" id="">
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">NA</option>
            </select>
        </form>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={() => {
          props.saveStatus(props.checklist.length+1,name, action );
          props.setshowImgModal(false)
        }}
      >
        Submit
      </Button>
      <Button
        variant="danger light"
        onClick={() => props.setshowImgModal(false)}
      >
        Close
      </Button>

    </Modal.Footer>
  </Modal>
  )
}
export default CustomModal;