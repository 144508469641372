import React from "react";
import { Button } from "react-bootstrap";

const StepThree = ({ inputList, setInputList }) => {
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { role: "", username: "", password: "" }]);
  };

  // 	inputList.map(roleinfo => {
  //       console.warn(roleinfo.role);
  // 		console.warn(roleinfo.username);
  // 		console.warn(roleinfo.password);
  //    })

  return (
    <section>
      {inputList.map((x, i) => {
        return (
          <div className="row" key={i}>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label className="text-label">Login URL</label>
                <input
                  className="form-control"
                  name="login_url"
                  placeholder="www.cybersecurityhive.com/login"
                  value={x.login_url}
                  onChange={(e) => handleInputChange(e, i)}
                />
              </div>
            </div>

            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label className="text-label">Role</label>
                <input
                  className="form-control"
                  name="role"
                  placeholder="Admin, Sales, Operations"
                  value={x.role}
                  onChange={(e) => handleInputChange(e, i)}
                />
              </div>
            </div>

            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label className="text-label">Enter Username*</label>
                <input
                  name="username"
                  className="form-control"
                  placeholder="testrole"
                  value={x.username}
                  onChange={(e) => handleInputChange(e, i)}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label className="text-label">Password*</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="*******"
                  value={x.password}
                  onChange={(e) => handleInputChange(e, i)}
                />
              </div>
            </div>

            <div className="card-body">
              {inputList.length !== 1 && (
                <Button
                  className="mr-2"
                  variant="primary btn-rounded"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              )}
              {inputList.length - 1 === i && (
                <Button
                  className="mr-2"
                  variant="primary btn-rounded"
                  onClick={handleAddClick}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
    </section>
  );
};

export default StepThree;
