import React from "react";

const StepTwo = ({ setData, data }) => {
  const callBack = (p, field) => {
    setData({
      ...data,
      [field]: p,
    });
  };
  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Application Name*</label>
            <input
              type="text"
              name="appname"
              value={data.appname}
              onChange={(e) => callBack(e.target.value, "appname")}
              className="form-control"
              placeholder="Phish Defend"
              required
            />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Play Store URL</label>
            <input
              type="text"
              name="targeturl"
              value={data.targeturl}
              onChange={(e) => callBack(e.target.value, "targeturl")}
              className="form-control"
              placeholder="www.playstore.com/cybersecurityhive"
              optional
            />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Type of Application</label>
            <select
              defaultValue={"option"}
              value={data.host}
              className="form-control form-control-lg"
              onChange={(e) => callBack(e.target.value, "host")}
            >
              <option>Native Android</option>
              <option>Hybrid</option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
        <div className="form-group">
            <label className="text-label">Is whitelisting required before pentest*</label>
            <select
               name="whitelist"
              defaultValue={"option"}
              value={data.whitelist}
              className="form-control form-control-lg"
              onChange={(e) => callBack(e.target.value, "whitelist")}
              required
            >
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
        <div className="form-group">
        <label className="text-label">Scope of pentest*</label>
            <select
               onChange={(e) => callBack(e.target.value, "whitelist")}
               name="whitelist"
              defaultValue={"option"}
              value={data.scope}
              className="form-control form-control-lg"
              onChange={(e) => callBack(e.target.value, "scope")}
              required
            >
              <option>Frontend</option>
              <option>Backend</option>
              <option>Frontend and Backend</option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Please Upload APK</label>

            <div className="input-group mb-3">
              <div className="custom-file">
                <input type="file" className="custom-file-input" />
                <label className="custom-file-label">Choose file</label>
              </div>
              <div className="input-group-append">
                <span className="input-group-text">Upload</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
