import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Figure,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import RetestForm from "./form";
import { AuthContext } from "../../../../Context/AuthProvider";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CommentBoxWrapper from "../comments";
import { IconButton } from "@material-ui/core";
import LenthNShow from "../utils/LenthNShow";
import RctSectionLoader from "../utils/RctSectionLoader";
import Toaster from "../../Toaster";
import Tooltip from "@material-ui/core/Tooltip";
import {
  NETWORKREPORT,
  USER,
  WEB,
  WEBREPORT,
} from "../../../../lib/config/URLConstant";
import { isEmpty } from "lodash";
import status from "../../../../lib/constant/ReportStatus";
import { useContext } from "react";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import InfoIcon from "@mui/icons-material/Info";
import * as Yup from "yup";
import { useFormik } from "formik";

/// Scroll
import CryptoJS from "crypto-js";
import PerfectScrollbar from "react-perfect-scrollbar";
const NetworkVulnerability = (props) => {
  const { user } = useContext(AuthContext);
  const search = useLocation().search;
  const reportId = new URLSearchParams(search).get("reportId");
  const [data, setData] = useState({});
  const [showImgModal, setShowImgModal] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [statusshowLoader, setstatusshowLoader] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [previousSelectedStatus, setpreviousSelectedStatus] = useState();
  const requestDetail = props.location.state.requestDetail;
  const [observation1, setobservation1] = useState("");
  const [redemption1, setredemption1] = useState("");
  const [description1, setdescription1] = useState("");
  const [commentAdded, setcommentAdded] = useState(false);
  const [Limit, setLimit] = useState(0);


  const onsubmit = () => {
    saveTestLimit(formik.values.testLimit.toUpperCase());
  };

  const validationSchema = Yup.object({
    testLimit: Yup.string().required("Required").matches(/^(unlimited|[0-9]+)$/i, "Only 'UNLIMITED' Or numbers allowed"),
  });

  const formik = useFormik({
    initialValues: {
      testLimit: Limit,
    },
    validationSchema,
  });
  const onImageClick = (img) => {
    setModalImgUrl(img);
    setShowImgModal(true);
  };

  const getWebReportById = async (reportId) => {
    setShowLoader(true);
    await AxiosInstance.get(`${NETWORKREPORT}/${reportId}`)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          setData(res.data.data);
          setLimit(res?.data?.data?.testLimit);
          setSelectedStatus(res.data.data.status);
        }
        const getUsers = async () => {
          await AxiosInstance.get(`${USER}/${res.data.data.updated_by}`)
            .then((res) => {
              if (res.data.code && res.data.code !== 200) {
                // // console.log(res.data.msg,"error");
              } else {
                // // console.log(res.data);
              }
            })
            .catch((err) => {
              // // console.log(err.message,"error");
            });
        };
        getUsers();
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
    setShowLoader(false);
  };

  // const getWebReportByRequestId = async(requestid) =>{
  //   setShowLoader(true);
  //   let params = `limit=${reportQuery.limit}&page=${reportQuery.page}`;
  //   await AxiosInstance.get(`${WEBREPORT}/request/${requestid}?${params}`)
  //   .then(res=>{
  //     if(res.data.code && res.data.code!==200){
  //       Toaster(res.data.msg,"error");
  //     }else{
  //       let temppage = res.data.data.totalResults==0 ? [1] :
  //                       Array(Math.ceil(res.data.data.reports.totalResults / reportQuery.limit))
  //                       .fill()
  //                       .map((_, i) => i + 1);
  //       setReportQuery(prev => ({...prev,
  //         page: res.data.data.reports.page,
  //         limit: res.data.data.reports.limit,
  //         totalPages: res.data.data.reports.totalPages,
  //         totalResults: res.data.data.reports.totalResults,
  //         pagination: temppage
  //       }))
  //       setReports(res.data.data.reports.results);
  //       setRequestDetail(res.data.data.requestDetail);
  //     }
  //   })
  //   .catch(err=>{
  //     Toaster(err.message,"error");
  //   })
  //   setShowLoader(false);
  // }

  const sendMail = (changedFrom, changed) => {
    const url = `${WEBREPORT}/sendmail`;
    let values1 = {
      sendemail: user.email,
      companyid: data.company,
      companyname: requestDetail.companyName,
      vulnername: data.title,
    };
    AxiosInstance.post(url, {
      name: "vulner",
      changedFrom: changedFrom,
      changed: changed,
      appName: data.title,
      company: requestDetail.company,
      status: requestDetail.status,
      values1,
    })
      .then((response) => {
        // // console.log(response.data);
      })
      .catch((err) => {
        Toaster(err, "error");
      });
  };

  const sendSlack = async (changedFrom, changed) => {
    // // console.log("ues");
    if (user.slackChannelId != undefined) {
      await AxiosInstance.post(`${WEB}/sendSlack`, {
        type: "vulnerstatus",
        slackChannelId: user.slackChannelId,
        changedFrom: changedFrom,
        changed: changed,
        applicationname: requestDetail.application_name,
      })
        .then((response) => {
          // // console.log(response.data);
        })
        .catch((err) => {
          Toaster(err, "error");
        });
    }
  };

  useEffect(() => {
    getWebReportById(reportId);
  }, []);

  const onStatusChange = async (value) => {
    setstatusshowLoader(true);
    await setpreviousSelectedStatus(selectedStatus);
    setSelectedStatus(value);
  };

  useEffect(() => {
    if (previousSelectedStatus != undefined) {
      saveStatus(true);
    }
  }, [selectedStatus]);

  const saveStatus = async () => {
    let values = { status: selectedStatus };
    await AxiosInstance.patch(`${NETWORKREPORT}/status/${reportId}`, values)
      .then((res) => {
        if (res.data.code !== 200 && res.data.code !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          Toaster("Status updated", "success");
          setData(res.data.data);
          setSelectedStatus(res.data.data.status);
          setstatusshowLoader(false);
          sendMail(previousSelectedStatus, selectedStatus);
          sendSlack(previousSelectedStatus, selectedStatus);
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };
  const saveTestLimit = async (val) => {
    let values = { testLimit: val };
    await AxiosInstance.patch(`${NETWORKREPORT}/testLimit/${reportId}`, values)
      .then((res) => {
        if (res.data.code !== 200 && res.data.code !== 201) {
          Toaster(res.data.msg, "error");
        } else {
          setData(res.data.data);
          setLimit(res.data.data.testLimit);
          Toaster("Restest Updated", "success");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };
  // // console.log("requestDetail", data);

  let kjk = data.observation;

  return showLoader ? (
    <RctSectionLoader />
  ) : (
    <>
      <Row>
        <Col lg={12}>
          {isEmpty(data) ? (
            <p> Report Not Found </p>
          ) : (
            <Card>
              <Card.Header>
                <Card.Title className='w-100'>
                  <div className='w-100 d-flex flex-column flex-sm-row justify-content-between'>
                    <h3> {data.title}</h3>
                    <h5>
                      <span className='text-black-50'> Created by</span>
                      <br />
                      {data.updated_by_name}
                    </h5>
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={6}></Col>
                </Row>
                <Row>
                  <Col
                    lg={5}
                    xl={4}
                    style={{ height: "auto" }}
                    className='p-3 mb-5 bg-body'>
                    <section className='p-3'>
                      <Row>
                        <Col xs={12} className='text-left'>
                          <h3> Vulnerability Details </h3>
                        </Col>
                      </Row>
                      <Row className='pt-3 mb-3'>
                        <Col>
                          <h4>Status</h4>
                        </Col>

                        <div className='d-flex col-sm-12 align-items-center'>
                          {/* <h3 className="text-black-50">{`#${data.company}`}</h3> */}
                          <div className='status'>
                            {statusshowLoader ? (
                              <RctSectionLoader />
                            ) : (
                              <Dropdown>
                                <Dropdown.Toggle
                                  data-cy='none-vuln-satus'
                                  variant='outline-primary'
                                  size='sm'
                                  className='mt-1 mb-2'
                                  onSelect={onStatusChange}
                                  disabled={
                                    user.role === "readOnly" ? true : false
                                  }>
                                  {selectedStatus || "OPEN"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {(user.role === "cshAdmin" ||
                                    user.role === "tester") && (
                                    <>
                                      {selectedStatus !== status.open ? (
                                        <Dropdown.Item
                                          data-cy='open-vuln-satus'
                                          onClick={() => {
                                            if (
                                              selectedStatus !== status.open
                                            ) {
                                              onStatusChange(status.open);
                                            }
                                          }}>
                                          {status.open}
                                        </Dropdown.Item>
                                      ) : null}
                                    </>
                                  )}
                                  {user.role === "admin" && (
                                    <>
                                      {selectedStatus !== status.open ? (
                                        <Dropdown.Item
                                          data-cy='open-vuln-satus'
                                          onClick={() => {
                                            if (
                                              selectedStatus !== status.open
                                            ) {
                                              onStatusChange(status.open);
                                            }
                                          }}>
                                          {status.open}
                                        </Dropdown.Item>
                                      ) : null}
                                    </>
                                  )}
                                  {user.role === "user" && (
                                    <>
                                      {selectedStatus !== status.open ? (
                                        <Dropdown.Item
                                          data-cy='open-vuln-satus'
                                          onClick={() => {
                                            if (
                                              selectedStatus !== status.open
                                            ) {
                                              onStatusChange(status.open);
                                            }
                                          }}>
                                          {status.open}
                                        </Dropdown.Item>
                                      ) : null}
                                    </>
                                  )}

                                  {selectedStatus !== status.inProgress ? (
                                    <Dropdown.Item
                                      data-cy='fix-progress-vuln-satus'
                                      onClick={() => {
                                        if (
                                          selectedStatus !== status.inProgress
                                        ) {
                                          onStatusChange(status.inProgress);
                                        }
                                      }}>
                                      {status.inProgress}
                                    </Dropdown.Item>
                                  ) : null}
                                  {selectedStatus !== status.readyToRetest &&
                                  Limit !== "0" ? (
                                    <Dropdown.Item
                                      data-cy='ready-retest-vuln-satus'
                                      onClick={() => {
                                        if (Limit === 'UNLIMITED' || Limit > 0) {
                                          if(Limit !== 'UNLIMITED'){
                                            saveTestLimit(`${Limit - 1}`);
                                          }
                                          onStatusChange(status.readyToRetest);
                                        } else {
                                          Toaster(
                                            "No More Retests Left , Buy Plans for more Retests",
                                            "error"
                                          );
                                        }
                                      }}>
                                      {status.readyToRetest}
                                    </Dropdown.Item>
                                  ) : null}
                                  {(user.role === "cshAdmin" ||
                                    user.role === "tester") && (
                                    <>
                                      {selectedStatus !== status.closed ? (
                                        <Dropdown.Item
                                          data-cy='closed-vuln-satus'
                                          onClick={() => {
                                            if (
                                              selectedStatus !== status.closed
                                            ) {
                                              onStatusChange(status.closed);
                                            }
                                          }}>
                                          {status.closed}
                                        </Dropdown.Item>
                                      ) : null}
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                            {/* {
                                
                                StatusDD && (
                                <>
                                  <Tooltip title="Save">
                                    <IconButton
                                    data-cy="save-icon"
                                      onClick={() => saveStatus(true)}
                                      aria-label="save"
                                      color="primary"
                                    >
                                      <CheckIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel">
                                    <IconButton
                                    data-cy="cancel-icon"
                                      onClick={() => {
                                        setShowStatusDD(false);
                                        setSelectedStatus(data.status);
                                      }}
                                      aria-label="cancel"
                                      color="secondary"
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}   */}
                          </div>
                        </div>
                      </Row>
                      {Limit === "UNLIMITED" || Limit > 0 ? (
                        <Row className='mb-4'>
                          <Col>
                            <h4>Re-Tests Left</h4>
                            <Button
                              variant={
                                Limit <= 3
                                  ? "outline-danger"
                                  : "outline-success"
                              }
                              disabled
                              className='py-1 '
                              style={{ fontSize: "18px" }}>
                              {Limit}
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row className='mb-4'>
                          <Col>
                            <div className='badge-outline-warning p-2'>
                              <div className='d-flex align-items-center'>
                                <InfoIcon className='text-warning fs-12' />
                                <span className='ml-2 fs-16'>
                                  Your retests have expired, Please Contact
                                  Support!
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {user.role==="cshAdmin" && 
                      <Row className='mb-4 '>
                        <Col>
                          <form
                            onSubmit={formik.handleSubmit}
                            className='d-flex'>
                            <div className='form-group'>
                              <h4>No Of Retest</h4>
                              <Row>
                                <Col className='col-8'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    name='testLimit'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik?.values?.testLimit ?? ""}
                                  />
                                </Col>
                                <Col className='col-4 p-0'>
                                  <button
                                    type='submit'
                                    disabled={!isEmpty(formik.errors || formik.isSubmitting)}
                                    className='btn btn-info'
                                    onClick={onsubmit}>
                                    Update
                                  </button>
                                </Col>
                              </Row>
                              {formik.touched.testLimit &&
                              formik.errors.testLimit ? (
                                <div className='formerrormessage'>
                                  {formik.errors.testLimit}
                                </div>
                              ) : null}
                            </div>
                          </form>
                        </Col>
                      </Row>}
                      <Row>
                        <Col>
                          <h4>Ease of exploitation</h4>
                        </Col>
                        <Table className={"table-padding mb-4"} bordered>
                          <tbody>
                            <tr className='text-center'>
                              <td
                                className={
                                  data.easeOfExploitation === "High"
                                    ? "table-high-color"
                                    : null
                                }>
                                High
                              </td>
                              <td
                                className={
                                  data.easeOfExploitation === "Medium"
                                    ? "table-medium-color"
                                    : null
                                }>
                                Medium
                              </td>
                              <td
                                className={
                                  data.easeOfExploitation === "Low"
                                    ? "table-low-color"
                                    : null
                                }>
                                Low
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        <Col>
                          <h4>Severity</h4>
                        </Col>
                        <Table
                          className={
                            "table-padding mb-4 vulnerability-severity-table"
                          }
                          bordered>
                          <tbody>
                            <tr className='text-center vulnerability-severity-details'>
                              <td
                                className={
                                  data.severity === "Critical"
                                    ? "table-critical-color"
                                    : null
                                }>
                                Critical
                              </td>
                              <td
                                className={
                                  data.severity === "High"
                                    ? "table-high-color"
                                    : null
                                }>
                                High
                              </td>
                              <td
                                className={
                                  data.severity === "Medium"
                                    ? "table-medium-color"
                                    : null
                                }>
                                Medium
                              </td>

                              <td
                                className={
                                  data.severity === "Low"
                                    ? "table-low-color"
                                    : null
                                }>
                                Low
                              </td>
                              <td
                                className={
                                  data.severity === "Info"
                                    ? "table-info-color"
                                    : null
                                }>
                                Info
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        <Col>
                          <h4>Impact</h4>
                        </Col>
                        <Table className={"table-padding mb-4"} bordered>
                          <tbody>
                            <tr className='text-center'>
                              <td
                                className={
                                  data.impact === "High"
                                    ? "table-high-color"
                                    : null
                                }>
                                High
                              </td>
                              <td
                                className={
                                  data.impact === "Medium"
                                    ? "table-medium-color"
                                    : null
                                }>
                                Medium
                              </td>
                              <td
                                className={
                                  data.impact === "Low"
                                    ? "table-low-color"
                                    : null
                                }>
                                Low
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        <Col>
                          <h4>IP's List</h4>
                        </Col>
                        <Table className={"table-padding"}>
                          <tbody>
                            <tr>
                              <td className='border-0'>
                                <div className='d-flex flex-wrap'>
                                  {data?.system.map((ip) => {
                                    return <Badge className="mr-2 my-2" variant={'sky'}>
                                     {ip}
                                   </Badge>
                                  })}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                      {/* <Row>
                          <Col> <h4>: </h4>
                           
                              <a class="pl-1" target="_blank" href={data.url}>
                                {data.url}
                              </a>
                            </Col>
                        </Row> */}
                    </section>
                  </Col>
                  <Col
                    lg={{ span: 7, order: "first" }}
                    xl={8}
                    className='vuln-details border-right border-light'>
                    <PerfectScrollbar className='vuln-details-scroll'>
                      <section className='p-3'>
                        <h3>Observation</h3>
                        <p dangerouslySetInnerHTML={{__html: CryptoJS.AES.decrypt(data.observation, "cshsecret").toString(CryptoJS.enc.Utf8)}} />
                      </section>
                      <section className='p-3'>
                        <h3>Description</h3>
                        <p dangerouslySetInnerHTML={{__html: CryptoJS.AES.decrypt(data.description, "cshsecret").toString(CryptoJS.enc.Utf8)}} />
                      </section>
                      <section className='p-3'>
                        <h3>Remediation</h3>
                        <p dangerouslySetInnerHTML={{__html: CryptoJS.AES.decrypt(data.remediation, "cshsecret").toString(CryptoJS.enc.Utf8)}} />
                      </section>
                      <section className='p-3'>
                        <h3>Screenshots</h3>
                        <Row>
                          {data.screenshots.map((ss, i) => (
                            <Col style={{ cursor: "pointer" }} lg={2} xs={2}>
                              <Figure>
                                <Figure.Image
                                  style={{ cursor: "pointer" }}
                                  src={`${ss.imgUrl}`}
                                  onClick={() => onImageClick(ss)}
                                  thumbnail
                                />
                                <Figure.Caption>
                                  <Tooltip title={`${ss.imgText}`}>
                                    <LenthNShow value={ss.imgText} n={10} />
                                  </Tooltip>
                                </Figure.Caption>
                              </Figure>
                            </Col>
                          ))}
                        </Row>
                      </section>
                      <Row className='col-sm-12 p-0'>
                        <Col xs={12}>
                          <CommentBoxWrapper
                            reportId={reportId}
                            commentAdded={commentAdded}
                          />
                        </Col>
                      </Row>
                    </PerfectScrollbar>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Modal
        className='fade bd-example-modal-lg'
        size='lg'
        show={showImgModal && modalImgUrl}>
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
          <Button
            variant=''
            className='close'
            onClick={() => setShowImgModal(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={`${modalImgUrl?.imgUrl}`}
            height='100%'
            width='100%'
            fluid
          />
          <div className='pt-3'>
            <p>{modalImgUrl?.imgText}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NetworkVulnerability;
