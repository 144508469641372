import React from "react";
import ReactApexChart from "react-apexcharts";
class StatusBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: true,
            series: [{
                name: 'Critical',
                data: [this.props?.statudVulcounts?.criticalCount[0]],
                color:'#FF3333'
              }, {
                name: 'High',
                data: [this.props?.statudVulcounts?.highCount[0] ],
                color:'#FF7A56'
              }, {
                name: 'Medium',
                data: [this.props?.statudVulcounts?.mediumCount[0] ],
                color:'#FFA64F'
              }, {
                name: 'Low',
                data: [this.props?.statudVulcounts?.lowCount[0] ],
                color:'#FFD759'
              }
                ,
              {
                name: 'Info',
                data: [this.props?.statudVulcounts?.infoCount[0] ],
                color:'#4392DE'
              }
              ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    fontFamily: 'Montserrat, Poppins',
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: [this.props.reqapplication_name],
                    labels: {
                        rotate: 0,
                    }
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                

                },
                fill: {
                    opacity: 1
                }
            },


        };
    }



  



    render() {
        // // // console.log(this.props?.statudVulcounts);
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default StatusBar;
