import { SearchRounded } from "@mui/icons-material";
import React from "react";

const Search = ({ filter, setFilter }) => {
  return (
    <div>
      <input type='text' value={filter || ""} 
      onChange={(e) => {setFilter(e.target.value)}} placeholder="Search" className=" pl-3 p-2 rounded-pill appearance-none outline-none pr-10 font-w500"/>
    </div>
  );
};

export default Search;
