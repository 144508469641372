import React, { Fragment, useState, useEffect } from "react";
import { map, reverse, size } from "lodash";
import { Tab, Spinner, Table, Badge, Dropdown } from "react-bootstrap";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import {
  NETWORK,
  NETWORKREPORT,
  WEB,
  WEBREPORT,
} from "../../../../lib/config/URLConstant";
// Chart
import NetworkDashBoard from "./NetworkDashboard";
import HomeDashBoard from "./Csh-dashboard";

import ApexBar3 from "../../charts/apexcharts/Bar3";
import ApexRedialBar2 from "../../charts/apexcharts/RadialBar";
import WidetChart2 from "../../Dhrev/Home/chart/WidetChart2";
import WidetChart3 from "../../Dhrev/Home/chart/WidetChart3";
import WidetChart4 from "../../Dhrev/Home/chart/WidetChart4";
import WidgetChart1 from "../../Dhrev/Home/chart/WidgetChart1";
import Toaster from "../../Toaster";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import RctSectionLoader from "../utils/RctSectionLoader";
import status from "../../../../lib/constant/RequestStatus";
import VulnerabilitiesBar from "../../charts/apexcharts/VulnerabilitiesBar";
import OwaspBar from "../../charts/apexcharts/owaspBar";
import { MenuItem, Select } from "@material-ui/core";
import StatusBar from "../../charts/apexcharts/StatusBar";
import StatusBar2 from "../../charts/apexcharts/StatusBar2";
import StatusBar3 from "../../charts/apexcharts/StatusBar3";

const CshHomeDashboard = (props) => {
  const { user } = useContext(AuthContext);
  const [showCountLoader, setShowCountLoader] = useState(true);
  const [statusForData, setstatusForData] = useState("ALL");
  const [statusForOwaspData, setstatusForOwaspData] = useState("ALL");
  const [statudVulcounts, setstatudVulcounts] = useState();
  const [vulCountsNet, setVulCountsNet] = useState();
  const [vulCountsWeb, setVulCountsWeb] = useState();
  const [owaspCounts, setOwaspCounts] = useState();
  const [webRequests, setWebRequests] = useState();
  const [netRequests, setNetRequests] = useState();
  const [allRequests, setAllRequests] = useState();
  const [graphData, setGraphData] = useState();
  const [switchDashboard, setSwitchDashboard] = useState("OVERVIEW DASHBOARD");
  const [showViewAllRequestButton, setShowViewAllRequestButton] =
    useState(false);
  const [statudVulcountsstatus, setstatudVulcountsstatus] = useState(false);
  const [statudOwaspstatus, setstatudOwaspstatus] = useState(false);
  const [percentage, setpercentage] = useState(0);

  let openVul = { name: "Open Vulnerability", data: [] };
  let fixedVul = { name: "Closed Vulnerability", data: [] };
  let xaxis = [];

  useEffect(() => {
    if (user.role == "tester") {
      props.history.push("/requests-web");
    }
    fetchWebRequests();
    getCounts();
    getGraphData();
  }, []);

  useEffect(() => {
    if (vulCountsNet && vulCountsNet) {
      callRiskScore();
    }
  });

  const callRiskScore = () => {
    let criticalsev =
      (vulCountsWeb?.criticalCount + vulCountsNet?.criticalCount) * 33.3;
    let highsev =
      (vulCountsWeb?.highOpenCount + vulCountsNet?.highOpenCount) * 26.664;
    let mediumsev =
      (vulCountsWeb?.mediumOpenCount + vulCountsNet?.mediumOpenCount) * 19.998;
    let lowsev =
      (vulCountsWeb?.lowOpenCount + vulCountsNet?.lowOpenCount) * 13.332;
    let infosev =
      (vulCountsWeb?.infoOpenCount + vulCountsNet?.infoOpenCount) * 6.666;

    let highease =
      (vulCountsWeb?.easehighOpenCount + vulCountsNet?.easehighOpenCount) *
      33.33;
    let mediumease =
      (vulCountsWeb?.easemediumOpenCount + vulCountsNet?.easemediumOpenCount) *
      22.217778;
    let lowease =
      (vulCountsWeb?.easelowOpenCount + vulCountsNet?.easelowOpenCount) *
      11.108889;
    // let infoease = vulCountsWeb?.easeinfoOpenCount * 8.3325;

    let highimpact =
      (vulCountsWeb?.impacthighOpenCount + vulCountsNet?.impacthighOpenCount) *
      33.33;
    let mediumimpact =
      (vulCountsWeb?.impactmediumOpenCount +
        vulCountsNet?.impactmediumOpenCount) *
      22.217778;
    let lowimpact =
      (vulCountsWeb?.impactlowOpenCount + vulCountsNet?.impactlowOpenCount) *
      11.108889;
    // let infoimpact = vulCounts?.impactlowOpenCount * 8.3325;

    let total =
      criticalsev +
      highsev +
      mediumsev +
      lowsev +
      highease +
      mediumease +
      lowease +
      highimpact +
      mediumimpact +
      lowimpact +
      infosev;

    // // console.log("percent", total, vulCounts?.totalCount);

    let percent;
    if (vulCountsWeb?.totalCount + vulCountsNet?.totalCount === 0) {
      percent = 0;
    } else {
      percent = total / (vulCountsWeb?.totalCount + vulCountsNet?.totalCount);
    }
    // // console.log(percent);
    percent = percent.toFixed(2);
    percent = parseInt(percent);
    setpercentage(percent);
  };

  // const getCountsOwasp = (dataweb, status) => {
  //   setstatudOwaspstatus(false);
  //   let url = `${NETWORKREPORT}/request/count/allowasp`;

  //   let data = [];
  //   dataweb.map((web) => {
  //     data.push(web.id);
  //   });
  //   AxiosInstance.post(url, { ids: data, status: status })
  //     .then((res) => {
  //       if (res.data.code && res.data.code !== 200) {
  //         Toaster(res.data.msg, "error");
  //       } else {
  //         setOwaspCounts(res.data.data);
  //         // // console.log("hijam", res.data.data);
  //       }
  //       setstatudOwaspstatus(true);
  //     })
  //     .catch((err) => {
  //       Toaster(err.message, "error");
  //       setstatudOwaspstatus(false);
  //     });
  // };

  const getCounts = () => {
    let urlWeb = "";
    let urlNet = "";
    if (user.role == "cshAdmin" || user.role == "tester") {
      urlWeb = `${WEBREPORT}/request/count/all`;
      urlNet = `${NETWORKREPORT}/request/count/all`;
    } else {
      urlNet = `${NETWORKREPORT}/request/count/user_reports`;
      urlWeb = `${WEBREPORT}/request/count/user_reports`;
    }
    AxiosInstance.post(urlNet)
      .then(async (res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          AxiosInstance.get(urlWeb)
            .then(async (res) => {
              if (res.data.code && res.data.code !== 200) {
                Toaster(res.data.msg, "error");
              } else {
                setVulCountsWeb(res.data.data);
              }
            })
            .catch((err) => {
              Toaster(err.message, "error");
              setShowCountLoader(false);
            });
          setVulCountsNet(res.data.data);
        }
        setShowCountLoader(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setShowCountLoader(false);
      });
  };

  // get counts according to status selected
  const getCountsByStatus = (val, dataweb) => {
    setstatudVulcountsstatus(false);
    let url = "";
    if (user.role == "cshAdmin" || user.role == "tester") {
      url = `${NETWORKREPORT}/request/count/status/join`;
    } else {
      url = `${NETWORKREPORT}/request/count/status/join/user_reports`;
    }
    AxiosInstance.post(url, { status: val, datas: dataweb })
      .then(async (res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          setstatudVulcounts(res.data.data);
          setstatudVulcountsstatus(true);
        }
        setShowCountLoader(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setShowCountLoader(false);
      });
  };

  const getGraphData = () => {
    let url = "";
    if (user.role == "cshAdmin" || user.role === "tester") {
      url = `${NETWORKREPORT}/request/join/graph_data`;
    } else {
      url = `${NETWORKREPORT}/request/join/graph_data?company=${user.company}`;
    }
    AxiosInstance.post(url)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          // for(let gd of res.data.data){
          //   openVul.data = [...openVul.data,gd.openCount];
          //   fixedVul.data = [...fixedVul.data,gd.closedCount];
          //   xaxis = [...xaxis, gd.application_name];
          // }
          let len;
          if (res?.data?.data?.length == undefined) {
            len = 0;
          } else if (res?.data?.data?.length > 5) len = 5;
          else len = res?.data?.data?.length;
          // // console.log(res.data.data[0].application_name);
          for (let i = 0; i < len; i++) {
            openVul.data = [...openVul.data, res?.data?.data[i]?.openCount];
            fixedVul.data = [...fixedVul.data, res?.data?.data[i]?.closedCount];
            if (res.data?.data[i]?.networkname) {
              if (res.data?.data[i]?.networkname == undefined) {
                xaxis = [...xaxis, " "];
              } else {
                xaxis = [...xaxis, res.data?.data[i]?.networkname];
              }
            } else {
              if (res.data?.data[i]?.application_name == undefined) {
                xaxis = [...xaxis, " "];
              } else {
                xaxis = [...xaxis, res.data?.data[i]?.application_name];
              }
            }
          }
          if (len > 0)
            setGraphData({ series: [openVul, fixedVul], categories: xaxis });
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };

  const fetchWebRequests = async () => {
    let urlNet = "";
    let urlWeb = "";
    var allArr = [];
    let data;
    if (user.role == "cshAdmin") {
      urlNet = `${NETWORK}/get/admin`;
      urlWeb = `${WEB}/get/admin`;
      data = {};
    } else {
      urlNet = `${NETWORK}/get`;
      urlWeb = `${WEB}/get`;
      data = {
        company: user.company,
      };
    }

    // // console.log(url);
    await AxiosInstance.post(urlWeb, { data })
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.data.code && res.data.data.code !== 200) {
          Toaster(res.msg, "error");
        } else {
          // // console.log('dash',res);
          let array1 = res.data.data.reverse().slice(0, 5);
          setWebRequests(array1);
          // getCountsOwasp(array, "ALL");
          await AxiosInstance.post(urlNet, { data })
            .then((res) => {
              // console.log(res.data.data);
              if (res.data.data.code && res.data.data.code !== 200) {
                Toaster(res.msg, "error");
              } else {
                // // console.log('dash',res);
                let array = res.data.data.reverse().slice(0, 5);
                setNetRequests(array);
                // getCountsOwasp(array, "ALL");
                allArr = [...array1, ...array];
                allArr.sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                );
                setAllRequests(allArr.slice(0, 5).reverse());
              }
            })
            .catch((err) => {
              Toaster(err.message, "error");
            });
          getCountsByStatus("ALL", allArr.slice(0, 5));
          if (allArr.length > 0) {
            setShowViewAllRequestButton(true);
          }
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };

  const getBadgeColor = (currStatus) => {
    if (currStatus === status.requested) {
      return "orange";
    }
    if (currStatus === status.inProgress) {
      return "sky";
    }
    if (currStatus === status.completed) {
      return "green";
    }
  };
  return (
    <Fragment>
      <div className='d-flex justify-content-end w-100'>
        <div className='card-header d-sm-flex d-block pb-0 border-0 m-0 p-0'>
          <div className='d-flex justify-content-between w-100 pr-1'>
            <Dropdown dropright>
              <Dropdown.Toggle variant='outline-primary text-black'>
                {switchDashboard}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setSwitchDashboard("OVERVIEW DASHBOARD");
                  }}
                  value={"OVERVIEW DASHBOARD"}>
                  OVERVIEW DASHBOARD
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSwitchDashboard("WEB DASHBOARD");
                  }}
                  value={"WEB DASHBOARD"}>
                  WEB DASHBOARD
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSwitchDashboard("NETWORK DASHBOARD");
                  }}
                  value={"NETWORK DASHBOARD"}>
                  NETWORK DASHBOARD
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {switchDashboard === "NETWORK DASHBOARD" ? (
        <NetworkDashBoard />
      ) : switchDashboard === "WEB DASHBOARD" ? (
        <HomeDashBoard />
      ) : (
        <>
          <div className='row px-3 pl-lg-3 flex-lg-nowrap'>
            <div className='col-12 col-sm-12 col-lg-4 mx-auto mx-sm-0 flex-grow-1 createdash p-3'>
              <div className='mb-4 createdash-child'>
                <h3>Submit a New Test</h3>
                <p className='mb-5'>Manage all your tests in a single click</p>
                <span
                  // href='/dashboard'
                  onClick={() => {
                    setSwitchDashboard("WEB DASHBOARD")
                  }}
                  style={{ cursor: "pointer" }}
                  className='py-3 px-3 text-nowrap color-orange mr-3 text-white '>
                  View Web
                </span>
                <span
                  // href='/dashboard-network'
                  onClick={() => {
                    setSwitchDashboard("NETWORK DASHBOARD")
                  }}
                  style={{ cursor: "pointer" }}
                  className='py-3 px-3 text-nowrap color-sky ml-3 text-white view-all-test-btn'>
                  View Network
                </span>
              </div>
            </div>
            <div className='col-12 sol-sm-12 col-lg-8 px-0 flex-grow-1'>
              <div className='cardbox d-flex flex-wrap align-items-center'>
                <div className='carddash bg-white mx-3  d-flex align-items-center flex-grow-1 ml-sm-0 ml-lg-3'>
                  <div className='skytape tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsNet.totaltotalCount +
                        vulCountsWeb.totaltotalCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total Vulnerabilities</p>
                  </div>
                </div>
                <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-md-3'>
                  <div className='color-red tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsWeb.totalcriticalCount +
                        vulCountsNet.totalcriticalCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total Critical Vulnerabilities</p>
                  </div>
                </div>
                <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 ml-sm-0 ml-md-3 mr-md-0 mr-lg-3 mr-xl-0'>
                  <div className='color-orangesecond tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsWeb.totalhighCount +
                        vulCountsNet.totalhighCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total High Vulnerabilities</p>
                  </div>
                </div>
                <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-md-3 ml-md-0 ml-lg-3'>
                  <div className='color-orangethird tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsWeb.totalmediumCount +
                        vulCountsNet.totalmediumCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total Medium Vulnerabilities</p>
                  </div>
                </div>
                <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 ml-sm-0 ml-md-3'>
                  <div className='color-yellow tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsWeb.totallowCount +
                        vulCountsNet.totallowCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total Low Vulnerabilities</p>
                  </div>
                </div>
                <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-lg-3 mr-xl-0'>
                  <div className='color-bluesecond tape'></div>
                  <div className='pl-3'>
                    <h3>
                      {vulCountsWeb && vulCountsNet
                        ? vulCountsWeb.totalinfoCount +
                        vulCountsNet.totalinfoCount
                        : "-"}
                    </h3>
                    <p className='m-0'>Total Info Vulnerabilities</p>
                  </div>
                </div>
              </div>
            </div>
            {/* // filter data  */}
            {/* <div style={{fomtFamily: 'montserrat'}} className="col-12 mt-3">
            <select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="form-control form-control-lg float-right"
              value={statusForData}
              label="STATUS"
              onChange={(e) => {
                setstatusForData(e.target.value);
                getCountsByStatus(e.target.value);
              }
            }
            >
              <option value={"OPEN"}>OPEN</option>
              <option value={"CLOSED"}>CLOSED</option>
              <option value={"FIX-IN-PROGRESS"}>FIX-IN-PROGRESS</option>
              <option value={"READY-TO-RETEST"}>READY-TO-RETEST</option>
            </select>
          </div> */}

            {/* <div className="col-md-12 col-12 mb-3">
            <div className="cardbox d-flex flex-wrap align-items-center">
              <div className="carddash carddash1 bg-white mx-3  d-flex align-items-center">
                <div className="skytape tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.totalStatusCount : '-'}</h3>
                  <p className="m-0">Total Vulnerabilities</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-red tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.criticalStatusCount : '-'}</h3>
                  <p className="m-0">Total Critical Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-orangesecond tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.highStatusCount : '-'}</h3>
                  <p className="m-0">Total High Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-orangethird tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.mediumStatusCount : '-'}</h3>
                  <p className="m-0">Total Medium Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-yellow tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.lowStatusCount : '-'}</h3>
                  <p className="m-0">Total Low Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-bluesecond tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.infoStatusCount : '-'}</h3>
                  <p className="m-0">Total Info Vulnerability</p>
                </div>
              </div>

            </div>
          </div> */}
          </div>

          {/* <div className="row mt-4">
        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
          <div className="card currency-bx overflow-hidden relative background-widget-1">
            <div className="card-body p-4">
              <div className="media align-items-center">
                <div className="media-body">
                  <h5 data-cy="dashboard-total-open-vuln" className="text-white fs-20">Total Open Vulnerabilities</h5>
                  {showCountLoader ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <h1 className="text-white mb-0">{vulCounts ? vulCounts.totalCount : '-'}</h1>
                  }
                </div>
                <div className="currency-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width={45}
                    height={45}
                    x="0px"
                    y="0px"
                    fill="#E78121"
                    viewBox="0 0 48 48"
                  >
                    <path class="a" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm7.78,29a2,2,0,0,1-1.42,3.41A2,2,0,0,1,29,31.78l-4.95-5-4.95,5a2,2,0,0,1-2.83,0,2,2,0,0,1,0-2.83l5-4.95-5-4.95a2,2,0,1,1,2.83-2.83l4.95,5,4.95-5a2,2,0,1,1,2.83,2.83l-5,4.95Z" />
                  </svg>
                </div>
              </div>
            </div>
            <img className="bg-img" src="images/money/bitcoin.svg" alt="" />
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
          <div className="card currency-bx overflow-hidden relative background-widget-2">
            <div className="card-body p-4">
              <div className="media align-items-center">
                <div className="media-body">
                  <h5 data-cy="dashboard-total-critical-vuln" className="text-white fs-20">Total Critical Vulnerabilities</h5>
                  {showCountLoader ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <h1 className="text-white mb-0">{vulCounts ? vulCounts.criticalCount : '-'}</h1>
                  }
                </div>
                <div className="currency-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width={45}
                    height={45}
                    fill="#95160C"
                    viewBox="0 0 512 512"
                    viewBox="0 0 47.57 38.84"
                  >

                    <path class="a" d="M46.76,31.11,28,2.27a5,5,0,0,0-8.38,0L.82,31.11A5,5,0,0,0,5,38.84H42.57A5,5,0,0,0,46.76,31.11Zm-23,.73a2,2,0,1,1,2-2A2,2,0,0,1,23.79,31.84Zm2-9a2,2,0,0,1-4,0v-11a2,2,0,1,1,4,0Z" />

                    <g>
                      <g>
                        <path
                          d="M256,0C114.624,0,0,114.624,0,256s114.624,256,256,256s256-114.624,256-256S397.376,0,256,0z M272,366.72V384
			c0,8.832-7.168,16-16,16c-8.832,0-16-7.168-16-16v-17.344c-16.384-2.688-31.392-9.376-42.624-19.328
			c-6.624-5.888-7.2-15.968-1.344-22.592c5.856-6.624,15.968-7.168,22.592-1.344C227.648,331.392,241.28,336,256,336
			c26.016,0,48-14.656,48-32s-21.984-32-48-32c-44.096,0-80-28.704-80-64c0-30.912,27.52-56.768,64-62.72V128
			c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16v17.344c16.384,2.688,31.392,9.376,42.624,19.328
			c6.592,5.888,7.232,16,1.344,22.592s-15.968,7.168-22.592,1.344C284.352,180.608,270.72,176,256,176c-26.016,0-48,14.656-48,32
			s21.984,32,48,32c44.128,0,80,28.704,80,64C336,334.912,308.48,360.768,272,366.72z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <img className="bg-img" src="images/money/dollar.svg" alt="" />
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
          <div className="card currency-bx overflow-hidden relative background-widget-3">
            <div className="card-body p-4">
              <div className="media align-items-center">
                <div className="media-body">
                  <h5 data-cy="dashboard-total-high-vuln" className="text-white fs-20">Total High Open Vulnerabilities</h5>
                  {showCountLoader ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <h1 className="text-white mb-0">{vulCounts ? vulCounts.highOpenCount : '-'}</h1>
                  }
                </div>
                <div className="currency-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width={45}
                    height={45}
                    fill="#d46363"
                    viewBox="0 0 512 512"
                    viewBox="0 0 48 48"
                  >
                    <path class="a" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm2,34a2,2,0,0,1-4,0V21a2,2,0,0,1,4,0ZM24,16a2,2,0,1,1,2-2A2,2,0,0,1,24,16Z" />
                    <g>
                      <g>
                        <path
                          d="M256,0C114.624,0,0,114.624,0,256s114.624,256,256,256s256-114.624,256-256S397.376,0,256,0z M272,208
			c8.832,0,16,7.168,16,16c0,8.832-7.168,16-16,16h-78.656c-0.736,5.216-1.344,10.528-1.344,16s0.608,10.784,1.344,16H256
			c8.832,0,16,7.168,16,16c0,8.832-7.168,16-16,16h-52.864c13.856,28.544,39.392,48,68.864,48c13.952,0,27.072-4.128,39.008-12.256
			c7.264-4.928,17.248-3.104,22.208,4.192c4.96,7.296,3.104,17.248-4.224,22.24C311.904,377.824,292.192,384,272,384
			c-46.88,0-87.008-33.184-103.68-80H144c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h17.408
			c-0.576-5.312-1.408-10.528-1.408-16s0.832-10.688,1.408-16H144c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h24.32
			c16.672-46.816,56.8-80,103.68-80c20.192,0,39.904,6.176,56.992,17.824c7.328,4.992,9.184,14.944,4.224,22.24
			c-4.96,7.36-14.976,9.152-22.208,4.192C299.072,164.128,285.952,160,272,160c-29.472,0-55.008,19.456-68.864,48H272z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <img className="bg-img" src="images/money/euro.svg" alt="" />
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
          <div className="card currency-bx overflow-hidden relative background-widget-4">
            <div className="card-body p-4">
              <div className="media align-items-center">
                <div className="media-body">
                  <h5 data-cy="dashboard-total-medium-vuln" className="text-white fs-20">Total Medium Open Vulnerabilities</h5>
                  {showCountLoader ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <h1 className="text-white mb-0">{vulCounts ? vulCounts.mediumOpenCount : '-'}</h1>
                  }
                </div>
                <div className="currency-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width={45}
                    height={45}
                    fill="#D9571A"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    viewBox="0 0 20 20"
                  >

                    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Rounded" transform="translate(-204.000000, -332.000000)">
                        <g id="Action" transform="translate(100.000000, 100.000000)">
                          <g id="-Round-/-Action-/-offline_bolt" transform="translate(102.000000, 230.000000)">
                            <g>
                              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                              <path d="M12,2.02 C6.49,2.02 2.02,6.49 2.02,12 C2.02,17.51 6.49,21.98 12,21.98 C17.51,21.98 21.98,17.51 21.98,12 C21.98,6.49 17.51,2.02 12,2.02 Z M11.48,17.88 L11.48,13.74 L8.82,13.74 C8.45,13.74 8.2,13.34 8.38,13.01 L12.06,5.84 C12.29,5.37 13,5.54 13,6.07 L13,10.26 L15.54,10.26 C15.91,10.26 16.15,10.65 15.99,10.98 L12.43,18.1 C12.19,18.58 11.48,18.41 11.48,17.88 Z" id="🔹Icon-Color" fill="#D9571A"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M256,0C114.624,0,0,114.624,0,256s114.624,256,256,256s256-114.624,256-256S397.376,0,256,0z M381.408,312.704
										c-41.568,63.968-101.632,71.968-129.152,71.968c-8.448,0-13.792-0.736-14.656-0.864c-7.808-1.184-13.6-7.904-13.6-15.808v-91.52
										l-44.128,11.04c-1.28,0.32-2.592,0.48-3.872,0.48c-7.2,0-13.696-4.832-15.52-12.128c-2.144-8.544,3.072-17.248,11.648-19.392
										L224,243.52v-31.04l-44.128,11.04c-1.28,0.32-2.592,0.48-3.872,0.48c-7.2,0-13.696-4.832-15.52-12.128
										c-2.144-8.544,3.072-17.248,11.648-19.392L224,179.52V144c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16v27.52l44.128-11.04
										c8.384-2.24,17.248,3.072,19.392,11.648c2.112,8.576-3.072,17.248-11.648,19.392L256,204.48v31.04l44.128-11.04
										c8.384-2.208,17.248,3.072,19.392,11.648c2.112,8.576-3.072,17.248-11.648,19.392L256,268.48v84.192
										c22.24-0.704,66.944-8.704,98.592-57.408c4.832-7.392,14.816-9.44,22.112-4.672C384.128,295.392,386.208,305.312,381.408,312.704z
										"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <img className="bg-img" src={turkish} alt="" />
          </div>
        </div>
      </div> */}

          {/* Old design */}
          <>
            <div className='row mt-5'>
              <div
                style={{ display: "none" }}
                className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                <div className='card overflow-hidden'>
                  <div className='card-header border-0 pb-0'>
                    <div className='mr-auto'>
                      <h2 className='text-black mb-2 font-w600'>7</h2>
                      <p className='mb-1 fs-13'>
                        <svg
                          width={21}
                          height={15}
                          viewBox='0 0 21 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5'
                            stroke='#2BC155'
                            strokeWidth={2}
                          />
                          <path d='M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z' />
                          <defs>
                            <linearGradient
                              x1='10.25'
                              y1={3}
                              x2={11}
                              y2='13.5'
                              gradientUnits='userSpaceOnUse'>
                              <stop
                                stopColor='#2BC155'
                                offset={1}
                                stopOpacity='0.73'
                              />
                              <stop
                                offset={1}
                                stopColor='#2BC155'
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        Total Open Vulnerabilities
                      </p>
                    </div>
                    {/* <img src={bit_1} alt="" /> */}
                  </div>
                  <div className='card-body p-0'>
                    {/* <canvas id="widgetChart1" height={75} /> */}
                    <WidgetChart1 />
                  </div>
                </div>
              </div>
              <div
                style={{ display: "none" }}
                className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                <div className='card overflow-hidden'>
                  <div className='card-header border-0 pb-0'>
                    <div className='mr-auto'>
                      <h2 className='text-black mb-2 font-w600'>5</h2>
                      <p className='mb-1 fs-13'>
                        <svg
                          width={21}
                          height={15}
                          viewBox='0 0 21 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5'
                            stroke='#2BC155'
                            strokeWidth={2}
                          />
                          <path d='M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z' />
                          <defs>
                            <linearGradient
                              x1='10.25'
                              y1={3}
                              x2={11}
                              y2='13.5'
                              gradientUnits='userSpaceOnUse'>
                              <stop
                                stopColor='#2BC155'
                                offset={1}
                                stopOpacity='0.73'
                              />
                              <stop
                                offset={1}
                                stopColor='#2BC155'
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        Total Critical Vulnerabilities
                      </p>
                    </div>
                    {/* <img src={bit_2} alt="" /> */}
                  </div>
                  <div className='card-body p-0'>
                    {/* <canvas id="widgetChart2" height={75} /> */}
                    <WidetChart2 />
                  </div>
                </div>
              </div>
              <div
                style={{ display: "none" }}
                className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                <div className='card overflow-hidden'>
                  <div className='card-header border-0 pb-0'>
                    <div className='mr-auto'>
                      <h2 className='text-black mb-2 font-w600'>3</h2>
                      <p className='mb-1 fs-13'>
                        <svg
                          width={21}
                          height={15}
                          viewBox='0 0 21 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5'
                            stroke='#2BC155'
                            strokeWidth={2}
                          />
                          <path d='M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z' />
                          <defs>
                            <linearGradient
                              x1='10.25'
                              y1={3}
                              x2={11}
                              y2='13.5'
                              gradientUnits='userSpaceOnUse'>
                              <stop
                                stopColor='#2BC155'
                                offset={1}
                                stopOpacity='0.73'
                              />
                              <stop
                                offset={1}
                                stopColor='#2BC155'
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        Total High Open Vulnerabilities
                      </p>
                    </div>
                    {/* <img src={bit_3} alt="" /> */}
                  </div>
                  <div className='card-body p-0'>
                    {/* <canvas id="widgetChart3" height={75} /> */}
                    <WidetChart3 />
                  </div>
                </div>
              </div>
              <div
                style={{ display: "none" }}
                className='col-xl-6 col-lg-6 col-sm-12 col-12'>
                <div className='card overflow-hidden'>
                  <div className='card-header border-0 pb-0'>
                    <div className='mr-auto'>
                      <h2 className='text-black mb-2 font-w600'>2</h2>
                      <p className='mb-1 fs-13'>
                        <svg
                          width={21}
                          height={15}
                          viewBox='0 0 21 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5'
                            stroke='#2BC155'
                            strokeWidth={2}
                          />
                          <path d='M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z' />
                          <defs>
                            <linearGradient
                              x1='10.25'
                              y1={3}
                              x2={11}
                              y2='13.5'
                              gradientUnits='userSpaceOnUse'>
                              <stop
                                stopColor='#2BC155'
                                offset={1}
                                stopOpacity='0.73'
                              />
                              <stop
                                offset={1}
                                stopColor='#2BC155'
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        Total Medium Open Vulnerabilities
                      </p>
                    </div>
                    {/* <img src={bit_4} alt="" /> */}
                  </div>
                  <div className='card-body p-0'>
                    {/* <canvas id="widgetChart4" height={75} /> */}
                    <WidetChart4 />
                  </div>
                </div>
              </div>
              <div className='col-xl-7  col-lg-12 col-md-12 col-sm-12 col-12'>
                <div className='rounded card'>
                  <div className='card-header d-sm-flex d-block pb-0 border-0'>
                    <div className='d-flex justify-content-between w-100'>
                      <div>
                        <h4 className='text-black fs-20'>
                          Vulnerabilities by Severity
                        </h4>
                        <p>Vulnerabilities by Severity of top 5 tests</p>

                      </div>
                      <Dropdown
                        alignRight
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // className="form-control form-control-lg float-right w-25"
                      // label="STATUS"
                      >
                        <Dropdown.Toggle disabled={!statudVulcountsstatus} variant='custom-1 text-black'>
                          {statusForData}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setstatusForData("ALL");
                              getCountsByStatus("ALL", allRequests);
                            }}
                            value={"ALL"}>
                            ALL
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setstatusForData("OPEN");
                              getCountsByStatus("OPEN", allRequests);
                            }}
                            value={"OPEN"}>
                            OPEN
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setstatusForData("CLOSED");
                              getCountsByStatus("CLOSED", allRequests);
                            }}
                            value={"CLOSED"}>
                            CLOSED
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setstatusForData("FIX-IN-PROGRESS");
                              getCountsByStatus("FIX-IN-PROGRESS", allRequests);
                            }}
                            value={"FIX-IN-PROGRESS"}>
                            FIX-IN-PROGRESS
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setstatusForData("READY-TO-RETEST");
                              getCountsByStatus("READY-TO-RETEST", allRequests);
                            }}
                            value={"READY-TO-RETEST"}>
                            READY-TO-RETEST
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className='card-body' id='user-activity'>
                    <Tab.Container defaultActiveKey='all'>
                      <Tab.Content className='tab-content' id='myTabContent'>
                        {!statudVulcountsstatus && <RctSectionLoader />}
                        {statudVulcountsstatus && (
                          <StatusBar3
                            statudVulcounts={statudVulcounts}
                            webRequests={allRequests}
                          />
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>

              {percentage ? (
                <div className='col-xl-5  col-lg-6 col-md-12 col-sm-12 col-12'>
                  <div className='rounded card'>
                    <Tab.Container defaultActiveKey='monthly'>
                      <div className='card-header d-block d-sm-flex border-0'>
                        <div>
                          <h4
                            data-cy='dashboard-total-open-vuln'
                            className='fs-20 text-black'>
                            Risk Score
                          </h4>
                          <p className='mb-0 fs-13'>
                            Risk Score of top 5 tests
                          </p>
                        </div>
                      </div>
                      <div className='card-body tab-content p-0'>
                        <Tab.Content data-cy='dashboard-risk-score'>
                          <ApexRedialBar2 riskPercentage={percentage} />
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              ) : null}
              <div className='col-xl-5  col-lg-6 col-md-12 col-sm-12 col-12'>
                <div className='rounded card'>
                  <div className='card-header d-sm-flex d-block pb-0 border-0'>
                    <div>
                      <h4 className='text-black fs-20'>
                        Vulnerability Distribution by Severity
                      </h4>
                      <p>Vulnerability Distribution by Severity of top 5 tests</p>
                    </div>
                  </div>
                  <div className='card-body' id='user-activity'>
                    <Tab.Container defaultActiveKey='all'>
                      <Tab.Content className='tab-content' id='myTabContent'>
                        {!vulCountsNet ||
                          (!vulCountsWeb && <RctSectionLoader />)}
                        {vulCountsNet && vulCountsWeb && (
                          <VulnerabilitiesBar
                            vulCountsNet={vulCountsNet}
                            vulCountsWeb={vulCountsWeb}
                          />
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>

              <div className='col-xl-7  col-lg-12 col-md-12 col-sm-12 col-12'>
                <div className='rounded card'>
                  <div className='card-header d-sm-flex d-block pb-0 border-0'>
                    <div>
                      <h4 className='text-black fs-20'>
                        Open V/S Closed Vulnerabilities
                      </h4>
                      <p
                        data-cy='dashboard-open-vs-fixed'
                        className='fs-13 mb-0'>
                        Bar Chart Represents Open V/S Closed Vulnerabilities For
                        Each Test (Top 5 tests)
                      </p>
                    </div>
                  </div>
                  <div className='card-body' id='user-activity'>
                    <Tab.Container defaultActiveKey='all'>
                      <Tab.Content className='tab-content' id='myTabContent'>
                        {!graphData && <RctSectionLoader />}
                        {graphData && <ApexBar3 graphData={graphData} />}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>

              {/* <div className="col-12  col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="rounded card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div className="d-flex w-100 justify-content-between">
                  <h4 className="text-black fs-20">OWASP Category Count</h4>
                  <Dropdown alignRight
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    // className="form-control form-control-lg float-right w-25"
                    // label="STATUS"
                   
                  >
                    <Dropdown.Toggle variant='custom-1 text-black'>
                    {statusForOwaspData}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>{setstatusForOwaspData("ALL"); getCountsOwasp(webRequests,"ALL")}} value={"ALL"}>ALL</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{setstatusForOwaspData("OPEN"); getCountsOwasp(webRequests,"OPEN")}} value={"OPEN"}>OPEN</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{setstatusForOwaspData("CLOSED"); getCountsOwasp(webRequests,"CLOSED")}} value={"CLOSED"}>CLOSED</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{setstatusForOwaspData("FIX-IN-PROGRESS"); getCountsOwasp(webRequests,"FIX-IN-PROGRESS")}} value={"FIX-IN-PROGRESS"}>FIX-IN-PROGRESS</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{setstatusForOwaspData("READY-TO-RETEST"); getCountsOwasp(webRequests,"READY-TO-RETEST")}} value={"READY-TO-RETEST"}>READY-TO-RETEST</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body" id="user-activity">
                <Tab.Container defaultActiveKey="all">
                  <Tab.Content className="tab-content" id="myTabContent">
                    {!statudOwaspstatus && <RctSectionLoader />}
                    {statudOwaspstatus && (
                      <OwaspBar
                        owaspCounts={owaspCounts}
                        webRequests={webRequests}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div> */}

              <div className='col-xl-12 col-lg-12'>
                <div className='rounded card'>
                  <div className='card-header border-0 pb-0 d-block d-md-flex'>
                    <div>
                      <h4 className='fs-20 text-black'>My Recent Tests</h4>
                      <p
                        data-cy='dashboard-recent-pentest'
                        className='mb-0 fs-13'>
                        Top 5 Latest Tests
                      </p>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='basic-form'>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>Network/Web Name</strong>
                            </th>
                            <th>
                              <strong>Scope/Pentent Scope</strong>
                            </th>
                            <th>
                              <strong>No of ips/Url</strong>
                            </th>
                            <th>
                              <strong>Status</strong>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {size(allRequests) !== 0 &&
                            allRequests.map((allRequest, i) => (
                              <>
                                {allRequest.networkname ? (
                                  <tr>
                                    <td>{allRequest.networkname}</td>
                                    <td className='clickable'>
                                      {allRequest.scope}
                                    </td>
                                    <td className='clickable'>
                                      {allRequest.no_ips}
                                    </td>
                                    <td>
                                      <Badge
                                        variant={getBadgeColor(
                                          allRequest.status
                                        )}>
                                        {allRequest.status}
                                      </Badge>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={i}>
                                    <td>{allRequest.application_name}</td>
                                    <td className='clickable'>
                                      {allRequest.pentest_scope}
                                    </td>
                                    <td className='clickable'>
                                      {allRequest.url}
                                    </td>
                                    <td>
                                      <Badge
                                        variant={getBadgeColor(
                                          allRequest.status
                                        )}>
                                        {allRequest.status}
                                      </Badge>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </Fragment>
  );
};

export default CshHomeDashboard;
