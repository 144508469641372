import React, { useEffect } from "react";

import Select from "react-select";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import { NETWORK } from "../../../../lib/config/URLConstant";
import { colourOptions } from "./data";

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
   const {
      children = <CustomClearText />,
      getStyles,
      ipslist,
      inputIplist,
      innerProps: { ref, ...restInnerProps },
   } = props;


 

   return (
      <div
         {...restInnerProps}
         ref={ref}
         style={getStyles("clearIndicator", props)}
      >
         <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
   );
};

const ClearIndicatorStyles = (base, state) => ({
   ...base,
   cursor: "pointer",
   color: state.isFocused ? "blue" : "black",
});

export default function CustomClearIndicator(props) {
   let data = [];
   for (let ip in props.ipslist){
      let obj = {
         value: props.ipslist[ip],
         label: props.ipslist[ip],
      }
      data.push(obj)
   }
   console.log(data)
   return (
      <Select
         closeMenuOnSelect={false}
         components={{ ClearIndicator }}
         value={props.inputIplist}
         styles={{ clearIndicator: ClearIndicatorStyles }}
         isMulti
         onChange={(e)=> props.changeHandler(e)}
         options={data}
      />
   );
}
