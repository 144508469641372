import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { Button } from 'react-bootstrap';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { COMPANIES } from '../../../../lib/config/URLConstant';
import Toaster from '../../Toaster';
import Switch from "react-switch";

const EditCompany = (props) => {


    const { applicationName,
        applicationPurpose,
        applicationTotalApi,
        applicationTotalRoles,
        applicationTotlPages,
        company,
        applicationFormFields,
        applicationAverageNoParameterApi,
        applicationAverageNoMethodApi,
        noOfAdmin,
        noOfUser,
        noOfTest,
        noOfRetest,
        noOfRead,
        maxNoOfAdmin,
        maxNoOfRead,
        maxNoOfRetest,
        maxNoOfTest,
        maxNoOfUser,
        planName,
        network
    } = props?.location?.state?.company;

    const [networkperm, setnetworkperm] = useState(network)

    const initialValues = {
        applicationName: applicationName,
        applicationPurpose: applicationPurpose,
        applicationTotalApi: applicationTotalApi,
        applicationTotalRoles: applicationTotalRoles,
        applicationTotlPages: applicationTotlPages,
        company: company,
        applicationFormFields: applicationFormFields,
        applicationAverageNoMethodApi: applicationAverageNoMethodApi,
        applicationAverageNoParameterApi: applicationAverageNoParameterApi,
        noOfAdmin: noOfAdmin,
        noOfUser: noOfUser,
        noOfTest: noOfTest,
        noOfRetest: noOfRetest,
        noOfRead: noOfRead,
        maxNoOfAdmin: maxNoOfAdmin,
        maxNoOfRead: maxNoOfRead,
        maxNoOfRetest: maxNoOfRetest,
        maxNoOfTest: maxNoOfTest,
        maxNoOfUser: maxNoOfUser,
        planName: planName,
        network: network
    }

    const validationSchema = Yup.object({
        applicationName: Yup.string().required("Required"),
        applicationPurpose: Yup.string().required("Required"),
        applicationTotalApi: Yup.string().required("Required"),
        applicationTotalRoles: Yup.string().required("Required"),
        applicationTotlPages: Yup.string().required("Required"),
        company: Yup.string().required("Required"),
        applicationFormFields: Yup.string().required("Required"),
        applicationAverageNoMethodApi: Yup.string().required("Required"),
        applicationAverageNoParameterApi: Yup.string().required("Required"),
        noOfUser: Yup.string().required("Required"),
        noOfTest: Yup.string().required("Required"),
        noOfRetest: Yup.string().required("Required"),
        noOfRead: Yup.string().required("Required"),
        noOfAdmin: Yup.string().required("Required"),
        maxNoOfAdmin: Yup.string().required("Required"),
        maxNoOfRead: Yup.string().required("Required"),
        maxNoOfRetest: Yup.string().required("Required").matches(/^(unlimited|[0-9]+)$/i,"Only 'UNLIMITED' Or numbers allowed"),
        maxNoOfTest: Yup.string().required("Required"),
        maxNoOfUser: Yup.string().required("Required"),
        planName: Yup.string().required("Required"),
        // network: Yup.string().required("Required"),
    });

    const onSubmitData = async () => {
        let values2 = {
            ...formik.values,
            network: networkperm
        }
        await AxiosInstance.post(`${COMPANIES}/updatedetails`,{id: props?.location?.state?.company?.id, values: values2})
        .then(res=>{
            // // console.log(res);
          if(res.data.status==201){
            Toaster("Companies Details Saved", "success");
          }else{
            Toaster("Report Saved", "success");
            Toaster(res.data.msg, "error");  
          }
        })
        .catch(err=>{
          Toaster(err.message,"error");
        })
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
    });
    return (
        <div>
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Application Name*</label>
                        <input
                            type="text"
                            className="form-control"
                            name='applicationName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationName ??""}
                        />
                        {formik.touched.applicationName && formik.errors.applicationName ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationName}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Application Purpose*</label>
                        <input
                            type="text"
                            className="form-control"
                            name='applicationPurpose'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationPurpose??""}
                        />
                        {formik.touched.applicationPurpose && formik.errors.applicationPurpose ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationPurpose}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-label">Application Total Api</label>
                        <input
                            name="applicationTotalApi"
                            type="number"
                            className="form-control form-control-lg"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationTotalApi??""}
                        />
                        {formik.touched.applicationTotalApi && formik.errors.applicationTotalApi ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationTotalApi}
                            </div>
                        ) : null}

                    </div>
                    <div className="form-group col-md-6">
                        <label>Application Total Roles</label>
                        <input
                            type="number"
                            className="form-control"
                            name='applicationTotalRoles'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationTotalRoles??""}
                        />
                        {formik.touched.applicationTotalRoles && formik.errors.applicationTotalRoles ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationTotalRoles}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Application Total Pages</label>
                        <input
                            type="number"
                            className="form-control"
                            name='applicationTotlPages'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationTotlPages??""}
                        />
                        {formik.touched.applicationTotlPages && formik.errors.applicationTotlPages ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationTotlPages}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Company</label>
                        <input
                            type="text"
                            className="form-control"
                            name='company'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.company??""}
                        />
                        {formik.touched.company && formik.errors.company ? (
                            <div className="formerrormessage">
                                {formik.errors.company}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Plan Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name='planName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.planName??""}
                        />
                        {formik.touched.planName && formik.errors.planName ? (
                            <div className="formerrormessage">
                                {formik.errors.planName}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Application Form Fields</label>
                        <input
                            type="number"
                            className="form-control"
                            name='applicationFormFields'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationFormFields??""}
                        />
                        {formik.touched.applicationFormFields && formik.errors.applicationFormFields ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationFormFields}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Application Average No Method Api</label>
                        <input
                            type="number"
                            className="form-control"
                            name='applicationAverageNoMethodApi'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.applicationAverageNoMethodApi??""}
                        />
                        {formik.touched.applicationAverageNoMethodApi && formik.errors.applicationAverageNoMethodApi ? (
                            <div className="formerrormessage">
                                {formik.errors.applicationAverageNoMethodApi}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>No Of User</label>
                        <input
                            type="number"
                            className="form-control"
                            name='noOfUser'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.noOfUser??""}
                        />
                        {formik.touched.noOfUser && formik.errors.noOfUser ? (
                            <div className="formerrormessage">
                                {formik.errors.noOfUser}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>No Of Test</label>
                        <input
                            type="number"
                            className="form-control"
                            name='noOfTest'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.noOfTest??""}
                        />
                        {formik.touched.noOfTest && formik.errors.noOfTest ? (
                            <div className="formerrormessage">
                                {formik.errors.noOfTest}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>No Of Retest</label>
                        <input
                            type="number"
                            className="form-control"
                            name='noOfRetest'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.noOfRetest??""}
                        />
                        {formik.touched.noOfRetest && formik.errors.noOfRetest ? (
                            <div className="formerrormessage">
                                {formik.errors.noOfRetest}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>No Of Read</label>
                        <input
                            type="number"
                            className="form-control"
                            name='noOfRead'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.noOfRead??""}
                        />
                        {formik.touched.noOfRead && formik.errors.noOfRead ? (
                            <div className="formerrormessage">
                                {formik.errors.noOfRead}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>No Of Admin</label>
                        <input
                            type="number"
                            className="form-control"
                            name='noOfAdmin'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.noOfAdmin??""}
                        />
                        {formik.touched.noOfAdmin && formik.errors.noOfAdmin ? (
                            <div className="formerrormessage">
                                {formik.errors.noOfAdmin}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Max No Of Admin</label>
                        <input
                            type="number"
                            className="form-control"
                            name='maxNoOfAdmin'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.maxNoOfAdmin??""}
                        />
                        {formik.touched.maxNoOfAdmin && formik.errors.maxNoOfAdmin ? (
                            <div className="formerrormessage">
                                {formik.errors.maxNoOfAdmin}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Max No Of Read</label>
                        <input
                            type="number"
                            className="form-control"
                            name='maxNoOfRead'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.maxNoOfRead??""}
                        />
                        {formik.touched.maxNoOfRead && formik.errors.maxNoOfRead ? (
                            <div className="formerrormessage">
                                {formik.errors.maxNoOfRead}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Max No Of Retest</label>
                        <input
                            type="text"
                            className="form-control"
                            name='maxNoOfRetest'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.maxNoOfRetest??""}
                        />
                        {formik.touched.maxNoOfRetest && formik.errors.maxNoOfRetest ? (
                            <div className="formerrormessage">
                                {formik.errors.maxNoOfRetest}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Max No Of Test</label>
                        <input
                            type="number"
                            className="form-control"
                            name='maxNoOfTest'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.maxNoOfTest??""}
                        />
                        {formik.touched.maxNoOfTest && formik.errors.maxNoOfTest ? (
                            <div className="formerrormessage">
                                {formik.errors.maxNoOfTest}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Max No Of User</label>
                        <input
                            type="number"
                            className="form-control"
                            name='maxNoOfUser'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik?.values?.maxNoOfUser??""}
                        />
                        {formik.touched.maxNoOfUser && formik.errors.maxNoOfUser ? (
                            <div className="formerrormessage">
                                {formik.errors.maxNoOfUser}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                        <label>Network Permission</label>
                        <Switch onChange={() => {
                            setnetworkperm(!networkperm);
                        }} checked={networkperm} />
                       
                    </div>
                </div>

                <Button
                    variant="primary"
                    className="mb-2 mr-2"
                    disabled={!isEmpty(formik.errors)}
                    type="submit"
                    onClick={onSubmitData}
                >
                    UPDATE
                </Button>
            </form>
        </div>
        
    )
}
export default EditCompany