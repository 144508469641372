import Axios from "axios";

const AxiosInstance = Axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`, 
  timeout: 2000000,
  mode: 'no-cors',
  crossDomain: true
});

AxiosInstance.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem('token')).token.token;
  config.headers.common['accept'] = 'application/json'
  config.headers.common['authorization'] = `Bearer ${token}`;
    return config;
  }, function (error) {
    return Promise.reject(error);
 });
 const CancelToken = Axios.CancelToken;

export {AxiosInstance, CancelToken};