import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal, Tab } from "react-bootstrap";
import { Badge, Card, Col, Dropdown, Table, ProgressBar } from "react-bootstrap";
import { Columns } from "./columns";
import TestTable from "./Table";
// imeges
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
// Chart
import ApexBar3 from "../../charts/apexcharts/Bar3";
import ApexRedialBar2 from "../../charts/apexcharts/RadialBar";
import WidetChart2 from "../../Dhrev/Home/chart/WidetChart2";
import WidetChart3 from "../../Dhrev/Home/chart/WidetChart3";
import WidetChart4 from "../../Dhrev/Home/chart/WidetChart4";
import WidgetChart1 from "../../Dhrev/Home/chart/WidgetChart1";

import { useLocation, useHistory, Link } from "react-router-dom";
import { AxiosInstance } from "../../../../lib/config/axiosConfig";
import Toaster from "../../Toaster";
import { WEBREPORT, PDF } from "../../../../lib/config/URLConstant";
import RctSectionLoader from "../utils/RctSectionLoader";
import status from "../../../../lib/constant/ReportStatus";
import { saveAs } from "file-saver";
import VulnerabilitiesBar from "../../charts/apexcharts/VulnerabilitiesBar";
import OwaspBar1 from "../../charts/apexcharts/owaspBar1";
import StatusBar1 from "../../charts/apexcharts/StatusBar1";

const MyReports = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const search = useLocation().search;
  const [statusForData, setstatusForData] = useState("ALL");
  const [owaspStatusForData, setowaspStatusForData] = useState("ALL");
  // const [statudVulcounts, setstatudVulcounts] = useState({
  //   criticalCount: [
  //     0
  //   ],
  //   highCount: [
  //     0
  //   ],
  //   mediumCount: [
  //     0
  //   ],
  //   lowCount: [
  //     0
  //   ],
  //   infoCount: [
  //     0
  //   ]
  // });
  const [statudVulcounts, setstatudVulcounts] = useState();
  const requestId = new URLSearchParams(search).get("requestId");
  const [owaspCounts, setOwaspCounts] = useState();
  const [reports, setReports] = useState([]);
  const [requestDetail, setRequestDetail] = useState();
  const [reportQuery, setReportQuery] = useState({
    page: 0,
    limit: 100000000000,
    totalPages: 0,
    totalResults: 0,
    pagination: 0,
  });
  const [showLoader, setShowLoader] = useState(true);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [showCountLoader, setShowCountLoader] = useState(true);
  const [vulCounts, setVulCounts] = useState();
  const [graphData, setGraphData] = useState();
  const [statudVulcountsstatus, setstatudVulcountsstatus] = useState(false);
  const [statudOwaspsstatus, setstatudOwaspsstatus] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const [bulkSaveLoader, setbulkSaveLoader] = useState(false);
  const [reportLoader, setreportLoader] = useState(false);
  const [certLoader, setcertLoader] = useState(false);
  const [downloadDisable, setdownloadDisable] = useState(true);
  const [retestModal, setretestModal] = useState(false);

  let openVul = { name: "Open Vulnerability", data: [] };
  let fixedVul = { name: "Closed Vulnerability", data: [] };
  let xaxis = [];
  let allVulnerabilityData = [];

  useEffect(() => {
    if (requestId) {
      getCounts(requestId);
      getWebReportByRequestId(requestId);
      getGraphData(requestId);
    }
  }, [requestId]);


  const callRiskScore = (vulner) => {

    // old flow
    // let criticalsev = vulCounts?.criticalCount * 33.3;
    // let highsev = vulCounts?.highOpenCount * 26.664;
    // let mediumsev = vulCounts?.mediumOpenCount * 19.998;
    // let lowsev = vulCounts?.lowOpenCount * 13.332;
    // let infosev = vulCounts?.infoOpenCount * 6.666;

    // let highease = vulCounts?.easehighOpenCount * 33.33;
    // let mediumease = vulCounts?.easemediumOpenCount * 22.217778;
    // let lowease = vulCounts?.easelowOpenCount * 11.108889;
    // // let infoease = vulCounts?.easeinfoOpenCount * 8.3325;

    // let highimpact = vulCounts?.impacthighOpenCount * 33.33;
    // let mediumimpact = vulCounts?.impactmediumOpenCount * 22.217778;
    // let lowimpact = vulCounts?.impactlowOpenCount * 11.108889;
    // // let infoimpact = vulCounts?.impactlowOpenCount * 8.3325;

    // let total =
    //   criticalsev +
    //   highsev +
    //   mediumsev +
    //   lowsev +
    //   highease +
    //   mediumease +
    //   lowease +
    //   highimpact +
    //   mediumimpact +
    //   lowimpact +
    //   infosev;
    // // // // console.log(
    // //   criticalsev,
    // //   highsev,
    // //   mediumsev,
    // //   lowsev,
    // //   highease,
    // //   mediumease,
    // //   lowease,
    // //   highimpact,
    // //   mediumimpact,
    // //   lowimpact,
    // //   infosev
    // // );
    // // // // console.log(
    // //   "percent",
    // //   total,
    // //   vulCounts?.totalCount,
    // //   vulCounts?.easehighOpenCount
    // // );

    // let percent = total / vulCounts?.totalCount;
    // if (vulCounts?.totalCount == 0) {
    //   percent = 0;
    // } else {
    //   percent = total / vulCounts?.totalCount;
    // }


    // new flow

    let totalpercent = 0;
    let totalnum = 0;
    // let criticalCount = 0
    // let highCount = 0
    // let mediumCount = 0
    // let lowCount = 0
    // let infoCount = 0
    // let totalcount = 0
    vulner.map((data) => {
      totalpercent += Number(data.riskScore);
      totalnum += 1
      // if (data?.severity === "Critical") criticalCount += 1
      // else if (data?.severity === "High") highCount += 1
      // else if (data?.severity === "Medium") mediumCount += 1
      // else if (data?.severity === "Low") lowCount += 1
      // else if (data?.severity === "Info") infoCount += 1
      // totalcount += 1
    })

    // setVulCounts({
    //   totalcriticalCount: criticalCount,
    //   totalhighCount: highCount,
    //   totalinfoCount: infoCount,
    //   totallowCount: lowCount,
    //   totalmediumCount: mediumCount,
    //   totaltotalCount: totalcount
    // })
    let percent = totalpercent / totalnum;

    percent = percent.toFixed(2);
    percent = parseInt(percent);
    console.log(percent);
    setpercentage(percent);
  };

  const getCountsOwasp = (dataweb, status) => {
    setstatudOwaspsstatus(false);
    let url = `${WEBREPORT}/request/count/allowasp/report`;

    let data = [];
    dataweb.map((web) => {
      data.push(web.id);
    });
    // // console.log("jisam", data);
    AxiosInstance.post(url, { ids: data, status: status })
      .then(async (res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          await setOwaspCounts(res.data.data);
          setdownloadDisable(false);
          setstatudOwaspsstatus(true);
        }
        setShowCountLoader(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setShowCountLoader(false);
      });
  };

  const getCounts = (requestid) => {
    AxiosInstance.get(`${WEBREPORT}/request/count/${requestid}`)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          setVulCounts(res.data.data);
        }
        setShowCountLoader(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setShowCountLoader(false);
      });
  };

  // get counts according to status selected
  const getCountsByStatus = (val, dataweb) => {
    console.log("check", val, dataweb);
    setstatudVulcountsstatus(false);
    let url = "";
    url = `${WEBREPORT}/status/report`;
    let data = [];
    dataweb.map((web) => {
      data.push(web.id);
    });
    AxiosInstance.post(url, { status: val, ids: data })
      .then(async (res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          setstatudVulcounts(res.data.data);
          setstatudVulcountsstatus(true);
        }
        setShowCountLoader(false);
      })
      .catch((err) => {
        Toaster(err.message, "error");
        setShowCountLoader(false);
      });

    // let result = {
    //   criticalCount: [
    //     0
    //   ],
    //   highCount: [
    //     0
    //   ],
    //   mediumCount: [
    //     0
    //   ],
    //   lowCount: [
    //     0
    //   ],
    //   infoCount: [
    //     0
    //   ]
    // }

    //  dataweb.map(item => {
    //   if (val === "ALL") {
    //     if (item?.severity === "Critical") {
    //       result = {
    //         ...result,
    //         criticalCount: [Number(result?.criticalCount[0] + 1)]
    //       }
    //     }
    //     else if (item?.severity === "High") {
    //       result = {
    //         ...result,
    //         highCount: [result?.highCount + 1]
    //       }
    //     }
    //     else if (item?.severity === "Medium") {
    //       result = {
    //         ...result,
    //         mediumCount: [result?.mediumCount + 1]
    //       }
    //     }
    //     else if (item?.severity === "Low") {
    //       result = {
    //         ...result,
    //         lowCount: [result?.lowCount + 1]
    //       }
    //     }
    //     else if (item?.severity === "Info") {
    //       result = {
    //         ...result,
    //         infoCount: [result?.infoCount + 1]
    //       }
    //     }
    //   }
    //   return null
    // })
    // console.log(result)
    //  setstatudVulcounts(result);
    //  setstatudVulcountsstatus(true);



  };

  const getWebReportByRequestId = async (requestid) => {
    setShowLoader(true);
    let params = `limit=${reportQuery.limit}&page=${reportQuery.page}`;
    await AxiosInstance.get(`${WEBREPORT}/request/${requestid}?${params}`)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          let temppage =
            res.data.data.totalResults == 0
              ? [1]
              : Array(
                Math.ceil(
                  res.data.data.reports.totalResults / reportQuery.limit
                )
              )
                .fill()
                .map((_, i) => i + 1);
          setReportQuery((prev) => ({
            ...prev,
            page: res.data.data.reports.page,
            limit: res.data.data.reports.limit,
            totalPages: res.data.data.reports.totalPages,
            totalResults: res.data.data.reports.totalResults,
            pagination: temppage,
          }));
          setReports(res.data.data.reports.results);
          callRiskScore(res.data.data.reports.results);
          getCountsByStatus("ALL", res.data.data.reports.results);
          setRequestDetail(res.data.data.requestDetail);
          getCountsOwasp(res.data.data.reports.results, "ALL");

          sortReports(res.data.data.reports.results);
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
    setShowLoader(false);
  };

  const sortReports = (data) =>{
    data.sort((a,b)=>{
      return b.riskScore - a.riskScore;
    })
    setReports(data)
  }

  const saveBulkStatus = (data) => {
    setbulkSaveLoader(true);
    let url = `${WEBREPORT}/bulkstatus/update`;

    let idArr = [];
    data.map((web) => {
      return idArr.push(web.original.id);
    });
    AxiosInstance.patch(url, { data: idArr })
      .then(async (res) => {
        if (res.data.code && res.data.code !== 200) {
          setbulkSaveLoader(false);
          Toaster(res.data.msg, "error");
        } else {
          Toaster("Status Updated", "success");
          setbulkSaveLoader(false);
          getWebReportByRequestId(requestId);
        }
      })
      .catch((err) => {
        setbulkSaveLoader(false);
        Toaster(err.message, "error");
      });
  };
  const viewReport = (report) => {
    history.push({
      pathname: `/vulnerability`,
      search: `?reportId=${report.id}`,
      state: { requestDetail: requestDetail },
    });
  };
  const editReport = (report) => {
    history.push(
      `/submit-report?requestId=${requestDetail.id}&userId=${requestDetail.user_id}&appName=${requestDetail.application_name}&reportId=${report.id}&company=${requestDetail.company}`
    );
  };
  const deleteReport = (report) => {
    setReportToDelete(report);
    setDeleteConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setReportToDelete(null);
    setDeleteConfirmModal(false);
  };
  const onConfirmDelete = async () => {
    setShowLoader(true);
    await AxiosInstance.delete(`${WEBREPORT}/${reportToDelete.id}`)
      .then((res) => {
        if (res.data.code !== 200 && res.data.code !== 204) {
          Toaster(res.data.msg, "error");
        } else {
          getWebReportByRequestId(requestId);
          Toaster("Vulnerability Deleted", "success");
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
    setReportToDelete(null);
    setDeleteConfirmModal(false);
  };

  // // console.log(reports);

  const getGraphData = (requestId) => {
    let url = "";
    url = `${WEBREPORT}/request/graph_data?request_id=${requestId}&user_id=${user.id}`;
    AxiosInstance.get(url)
      .then((res) => {
        if (res.data.code && res.data.code !== 200) {
          Toaster(res.data.msg, "error");
        } else {
          // // console.log(res.data.data);
          for (let gd of res.data.data) {
            if (gd.application_name != undefined) {
              openVul.data = [...openVul.data, gd?.openCount];
              fixedVul.data = [...fixedVul.data, gd?.closedCount];
              xaxis = [...xaxis, gd.application_name];
            }
          }
          setGraphData({ series: [openVul, fixedVul], categories: xaxis });
        }
      })
      .catch((err) => {
        Toaster(err.message, "error");
      });
  };

  const getBadgeColor = (currStatus) => {
    if (currStatus === status.open) {
      return "ocean";
    }
    if (currStatus === status.inProgress) {
      return "sky";
    }
    if (currStatus === status.readyToRetest) {
      return "orange";
    }
    if (currStatus === status.closed) {
      return "green";
    }
    if (currStatus === "Critical") {
      return "color-critical";
    }
    if (currStatus === "Info") {
      return "color-info";
    }
    if (currStatus === "High") {
      return "color-high";
    }
    if (currStatus === "Low") {
      return "color-low";
    }
    if (currStatus === "Medium") {
      return "color-medium";
    }
    // test details status
    if (currStatus === "REQUESTED") {
      return "orange";
    }
    if (currStatus === "IN-PROGRESS") {
      return "sky";
    }
    if (currStatus === "COMPLETED") {
      return "green";
    }
  };

  //create all VulnerabilityData
  const createData = () => {
    setreportLoader(true);
    // for (let i = 0; i < reports.length; i++) {
    //   AxiosInstance.get(`${WEBREPORT}/${reports[i].id}`)
    //     .then((res) => {
    //       if (res.data.code && res.data.code !== 200) {
    //         Toaster(res.data.msg, "error");
    //       } else {
    //         allVulnerabilityData = [...allVulnerabilityData, res.data.data];
    //         // // console.log(allVulnerabilityData);
    //         if (allVulnerabilityData.length == reports.length) {
    //           createPdf();
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       Toaster(err.message, "error");
    //     });
    // }
    createPdf();
    // // console.log(requestDetail);
  };
  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  //pdf routes
  const createPdf = async (i) => {
    // // console.log(requestDetail, reports, i);
    const url = `${PDF}/create-pdf`;
    const url1 = `${PDF}/fetch-pdf`;

    // reports.map(async (report, index) => {
    //   await AxiosInstance.get(`${WEBREPORT}/${report.id}`)
    //     .then((res) => {
    //       if (res.data.code && res.data.code !== 200) {
    //         Toaster(res.data.msg, "error");
    //         return "";
    //       } else {
    //         if (typeof i != "undefined") {
    //           if (index == i) allVulnerabilityData = [res.data.data];
    //         } else {
    //           allVulnerabilityData = [...allVulnerabilityData, res.data.data];
    //         }
    //         return ``;
    //       }
    //     })
    //     .catch((err) => {
    //       Toaster(err.message, "error");
    //       return "";
    //     });
    // });

    let allVulnerabilityData = []

    for (let report in reports) {
      allVulnerabilityData = [
        ...allVulnerabilityData,
        {
          observation: decodeHtml(reports[report].observation),
          description: decodeHtml(reports[report].description),
          remediation: decodeHtml(reports[report].remediation),
          result: reports[report]
        }
      ]

    }

    await AxiosInstance.post(url, {
      type: "Web",
      companyName: user.companyName,
      request: requestDetail,
      reports: reports,
      vulCounts: vulCounts,
      allVulnerabilityData: allVulnerabilityData,
    });
    console.log(reports, allVulnerabilityData)
    await AxiosInstance.get(url1, { responseType: "blob" }).then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "newPdf.pdf");
      allVulnerabilityData = [];
      setreportLoader(false);
    });
  };

  const createCertificate = async () => {
    setcertLoader(true);
    // // console.log('detail', requestDetail);
    const url = `${PDF}/create-certificate`;
    const url1 = `${PDF}/fetch-certificate`;

    await AxiosInstance.post(url, {
      companyname: user.companyName,
      appname: requestDetail?.application_name,
      completeDate: requestDetail.completeDate,
    });

    await AxiosInstance.get(url1, { responseType: "blob" }).then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "newPdf.pdf");
      allVulnerabilityData = [];
      setcertLoader(false);
    });
  };

  const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect x='0' y='0' width='24' height='24'></rect>
        <circle fill='#000000' cx='5' cy='12' r='2'></circle>
        <circle fill='#000000' cx='12' cy='12' r='2'></circle>
        <circle fill='#000000' cx='19' cy='12' r='2'></circle>
      </g>
    </svg>
  );
  // // console.log(graphData);
  // // console.log('requestdetail', requestDetail);
  return (
    <Fragment>
      <>
        <div className='row px-3 pl-lg-3 flex-lg-nowrap'>
          <div className='col-12 col-sm-12 col-lg-4 mx-auto mx-sm-0 flex-grow-1 createdash p-3'>
            <div className='mb-4 createdash-child'>
              <p className='m-0'>Application Name</p>
              <h3>{requestDetail?.application_name}</h3>
              <p className='m-0'>URL</p>
              <h3
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {requestDetail?.url}
              </h3>
              <br />
              <a
                href='/requests-web'
                className='py-3 px-3 text-nowrap color-sky text-white'>
                View All Test
              </a>
            </div>
          </div>
          <div className='col-12 sol-sm-12 col-lg-8 px-0 flex-grow-1'>
            <div className='cardbox d-flex flex-wrap align-items-center'>
              <div className='carddash bg-white mx-3  d-flex align-items-center flex-grow-1 ml-sm-0 ml-lg-3'>
                <div className='skytape tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totaltotalCount : "-"}</h3>
                  <p className='m-0'>Total Vulnerabilities</p>
                </div>
              </div>
              <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-md-3'>
                <div className='color-red tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totalcriticalCount : "-"}</h3>
                  <p className='m-0'>Total Critical Vulnerabilities</p>
                </div>
              </div>
              <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 ml-sm-0 ml-md-3 mr-md-0 mr-lg-3 mr-xl-0'>
                <div className='color-orangesecond tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totalhighCount : "-"}</h3>
                  <p className='m-0'>Total High Vulnerabilities</p>
                </div>
              </div>
              <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-md-3 ml-md-0 ml-lg-3'>
                <div className='color-orangethird tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totalmediumCount : "-"}</h3>
                  <p className='m-0'>Total Medium Vulnerabilities</p>
                </div>
              </div>
              <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 ml-sm-0 ml-md-3'>
                <div className='color-yellow tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totallowCount : "-"}</h3>
                  <p className='m-0'>Total Low Vulnerabilities</p>
                </div>
              </div>
              <div className='carddash bg-white mx-3 d-flex align-items-center flex-grow-1 mr-sm-0 mr-lg-3 mr-xl-0'>
                <div className='color-bluesecond tape'></div>
                <div className='pl-3'>
                  <h3>{vulCounts ? vulCounts.totalinfoCount : "-"}</h3>
                  <p className='m-0'>Total Info Vulnerabilities</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* // filter data  */}
          {/* <div style={{ fomtFamily: 'montserrat' }} className="col-12 mt-3">
            <select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="form-control form-control-lg float-right"
              value={statusForData}
              label="STATUS"
              onChange={(e) => {
                setstatusForData(e.target.value);
                getCountsByStatus(e.target.value);
              }
              }
            >
              <option value={"OPEN"}>OPEN</option>
              <option value={"CLOSED"}>CLOSED</option>
              <option value={"FIX-IN-PROGRESS"}>FIX-IN-PROGRESS</option>
              <option value={"READY-TO-RETEST"}>READY-TO-RETEST</option>
            </select>
          </div>
          <div className="col-md-12 col-12 mb-3">
            <div className="cardbox d-flex flex-wrap align-items-center">
              <div className="carddash carddash1 bg-white mx-3  d-flex align-items-center">
                <div className="skytape tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.totalStatusCount : '-'}</h3>
                  <p className="m-0">Total Vulnerabilities</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-red tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.criticalStatusCount : '-'}</h3>
                  <p className="m-0">Total Critical Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-orangesecond tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.highStatusCount : '-'}</h3>
                  <p className="m-0">Total High Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-orangethird tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.mediumStatusCount : '-'}</h3>
                  <p className="m-0">Total Medium Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-yellow tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.lowStatusCount : '-'}</h3>
                  <p className="m-0">Total Low Vulnerability</p>
                </div>
              </div>
              <div className="carddash carddash1 bg-white mx-3 d-flex align-items-center">
                <div className="color-bluesecond tape">

                </div>
                <div className="pl-3">
                  <h3>{statudVulcounts ? statudVulcounts.infoStatusCount : '-'}</h3>
                  <p className="m-0">Total Info Vulnerability</p>
                </div>
              </div>

            </div>
          </div> */}
        </div>

        <div className='report-test-details rounded row bg-white mt-4 mx-1'>
          <div className='col p-3'>
            <div className='d-flex flex-wrap align-items-center mt-2'>
              <h3 className='mx-3 text-nowrap'>Test Details</h3>
              <Link to={`/view-web-request?requestId=${requestId}`}>
                <button className='btn btn-outline-primary py-2 mx-3'>
                  View Test Details
                </button>
              </Link>
            </div>
            <div className='test-details mt-3 d-flex justify-content-between flex-column flex-sm-row'>
              <div className='mx-3 mb-3'>
                <p className='m-0'>Status</p>
                {/* <h4>{requestDetail?.status}</h4> */}
                <h4>
                  <Badge variant={getBadgeColor(requestDetail?.status)}>
                    {requestDetail?.status}
                  </Badge>
                </h4>
              </div>
              <div className='mx-3 mb-3'>
                <p className='m-0'>Scope of the pentest</p>
                <h4>{requestDetail?.pentest_scope}</h4>
              </div>
              <div className='mx-3 mb-3'>
                <p className='m-0'>Is whitelisting needed?</p>
                <h4>{requestDetail?.is_whitelisting}</h4>
              </div>
              <div className='mx-3 mb-3'>
                <p className='m-0'>Application hosted on</p>
                <h4>{requestDetail?.hosted_location}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-xl-7  col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='rounded card'>
              <div className='card-header d-sm-flex d-block pb-0 border-0'>
                <div className='d-flex justify-content-between w-100'>
                  <h4 className='text-black fs-20'>
                    Vulnerabilities by Severity
                  </h4>
                  <Dropdown
                    alignRight
                  // labelId="demo-simple-select-label"
                  // id="demo-simple-select"

                  // onChange={(e) => {
                  //   setstatusForData(e.target.value);
                  //   getCountsByStatus(e.target.value, reports);
                  // }}
                  >
                    <Dropdown.Toggle disabled={!statudVulcountsstatus} variant='custom-1 text-black'>
                      {statusForData}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setstatusForData("ALL");
                          getCountsByStatus("ALL", reports);
                        }}
                        value={"ALL"}>
                        ALL
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setstatusForData("OPEN");
                          getCountsByStatus("OPEN", reports);
                        }}
                        value={"OPEN"}>
                        OPEN
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setstatusForData("CLOSED");
                          getCountsByStatus("CLOSED", reports);
                        }}
                        value={"CLOSED"}>
                        CLOSED
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setstatusForData("FIX-IN-PROGRESS");
                          getCountsByStatus("FIX-IN-PROGRESS", reports);
                        }}
                        value={"FIX-IN-PROGRESS"}>
                        FIX-IN-PROGRESS
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setstatusForData("READY-TO-RETEST");
                          getCountsByStatus("READY-TO-RETEST", reports);
                        }}
                        value={"READY-TO-RETEST"}>
                        READY-TO-RETEST
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className='card-body' id='user-activity'>
                <Tab.Container defaultActiveKey='all'>
                  <Tab.Content className='tab-content' id='myTabContent'>
                    {!statudVulcountsstatus && <RctSectionLoader />}
                    {statudVulcountsstatus && (
                      <StatusBar1
                        statudVulcounts={statudVulcounts}
                        webRequests={reports}
                        reqapplication_name={requestDetail?.application_name}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>


          <div className='col-xl-5  col-lg-6 col-md-12 col-sm-12 col-12'>
            <div className='rounded card'>
              <Tab.Container defaultActiveKey='monthly'>
                <div className='card-header d-block d-sm-flex border-0'>
                  <div>
                    <h4
                      data-cy='dashboard-total-open-vuln'
                      className='fs-20 text-black'>
                      Risk Score
                    </h4>
                    <p className='mb-0 fs-13'>Risk Score of the Test</p>
                  </div>
                </div>
                <div className='card-body tab-content p-0'>
                  <Tab.Content data-cy='dashboard-risk-score'>
                    {
                      percentage ?
                        <ApexRedialBar2 riskPercentage={percentage} />
                        : <RctSectionLoader />
                    }
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>


          {/* </div> */}

          {/* <div className="row">
          <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
            <div className="card currency-bx overflow-hidden relative background-widget-1">
              <div className="card-body p-4">
                <div className="media align-items-center">
                  <div className="media-body">
                    <h5 data-cy="report-dash-total-open-vuln" className="text-white fs-20">Total Open Vulnerabilities</h5>
                    {showCountLoader ?
                      <Spinner animation="border" variant="primary" />
                      :
                      <h1 className="text-white mb-0">{vulCounts ? vulCounts.totalCount : '-'}</h1>
                    }

                  </div>
                  <div className="currency-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width={45}
                      height={45}
                      x="0px"
                      y="0px"
                      fill="#ffab2d"
                      viewBox="0 0 48 48"
                    >
                      <path class="a" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm7.78,29a2,2,0,0,1-1.42,3.41A2,2,0,0,1,29,31.78l-4.95-5-4.95,5a2,2,0,0,1-2.83,0,2,2,0,0,1,0-2.83l5-4.95-5-4.95a2,2,0,1,1,2.83-2.83l4.95,5,4.95-5a2,2,0,1,1,2.83,2.83l-5,4.95Z" />
                    </svg>
                  </div>
                </div>
              </div>
              <img className="bg-img" src="images/money/bitcoin.svg" alt="" />
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
            <div className="card currency-bx overflow-hidden relative background-widget-2">
              <div className="card-body p-4">
                <div className="media align-items-center">
                  <div className="media-body">
                    <h5 data-cy="report-dash-total-open-critical-vuln" className="text-white fs-20">Total Critical Vulnerabilities</h5>
                    {showCountLoader ?
                      <Spinner animation="border" variant="primary" />
                      :
                      <h1 className="text-white mb-0">{vulCounts ? vulCounts.criticalCount : '-'}</h1>
                    }
                  </div>
                  <div className="currency-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={45}
                      height={45}
                      fill="#dc3ccc"
                      viewBox="0 0 47.57 38.84"
                    >
                      <path class="a" d="M46.76,31.11,28,2.27a5,5,0,0,0-8.38,0L.82,31.11A5,5,0,0,0,5,38.84H42.57A5,5,0,0,0,46.76,31.11Zm-23,.73a2,2,0,1,1,2-2A2,2,0,0,1,23.79,31.84Zm2-9a2,2,0,0,1-4,0v-11a2,2,0,1,1,4,0Z" />
                    </svg>

                  </div>
                </div>
              </div>
              <img className="bg-img" src="images/money/dollar.svg" alt="" />
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
            <div className="card currency-bx overflow-hidden relative background-widget-3">
              <div className="card-body p-4">
                <div className="media align-items-center">
                  <div className="media-body">
                    <h5 data-cy="report-dash-total-open-high-vuln" className="text-white fs-20">Total High Open Vulnerabilities</h5>
                    {showCountLoader ?
                      <Spinner animation="border" variant="primary" />
                      :
                      <h1 className="text-white mb-0">{vulCounts ? vulCounts.highOpenCount : '-'}</h1>
                    }
                  </div>
                  <div className="currency-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={45}
                      height={45}
                      fill="#319bd7"
                      viewBox="0 0 48 48"
                    >
                      <path class="a" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm2,34a2,2,0,0,1-4,0V21a2,2,0,0,1,4,0ZM24,16a2,2,0,1,1,2-2A2,2,0,0,1,24,16Z" />

                    </svg>
                  </div>
                </div>
              </div>
              <img className="bg-img" src="images/money/euro.svg" alt="" />
            </div>
          </div>
          <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6 ">
            <div className="card currency-bx overflow-hidden relative background-widget-4">
              <div className="card-body p-4">
                <div className="media align-items-center">
                  <div className="media-body">
                    <h5 data-cy="report-dash-total-open-medium-vuln" className="text-white fs-20">Total Medium Open Vulnerabilities</h5>
                    {showCountLoader ?
                      <Spinner animation="border" variant="primary" />
                      :
                      <h1 className="text-white mb-0">{vulCounts ? vulCounts.mediumOpenCount : '-'}</h1>
                    }
                  </div>
                  <div className="currency-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width={45}
                      height={45}
                      fill="#5f5f5f"
                      x="0px"
                      y="0px"
                      viewBox="0 0 20 20"
                    >
                      <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Rounded" transform="translate(-204.000000, -332.000000)">
                          <g id="Action" transform="translate(100.000000, 100.000000)">
                            <g id="-Round-/-Action-/-offline_bolt" transform="translate(102.000000, 230.000000)">
                              <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12,2.02 C6.49,2.02 2.02,6.49 2.02,12 C2.02,17.51 6.49,21.98 12,21.98 C17.51,21.98 21.98,17.51 21.98,12 C21.98,6.49 17.51,2.02 12,2.02 Z M11.48,17.88 L11.48,13.74 L8.82,13.74 C8.45,13.74 8.2,13.34 8.38,13.01 L12.06,5.84 C12.29,5.37 13,5.54 13,6.07 L13,10.26 L15.54,10.26 C15.91,10.26 16.15,10.65 15.99,10.98 L12.43,18.1 C12.19,18.58 11.48,18.41 11.48,17.88 Z" id="🔹Icon-Color" fill="#D9571A"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>

                    </svg>
                  </div>
                </div>
              </div>
              {/* <img className="bg-img" src={turkish} alt="" /> */}

          <>
            {/* <div className="row"> 
            <div style={{ display: 'none' }} className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0 pb-0">
                  <div className="mr-auto">
                    <h2 className="text-black mb-2 font-w600">10</h2>
                    <p className="mb-1 fs-13">
                      <svg
                        width={21}
                        height={15}
                        viewBox="0 0 21 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5"
                          stroke="#2BC155"
                          strokeWidth={2}
                        />
                        <path d="M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z" />
                        <defs>
                          <linearGradient
                            x1="10.25"
                            y1={3}
                            x2={11}
                            y2="13.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              stopColor="#2BC155"
                              offset={1}
                              stopOpacity="0.73"
                            />
                            <stop
                              offset={1}
                              stopColor="#2BC155"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      Total Open Vulnerabilities
                    </p>
                  </div>
                  {/* <img src={bit_1} alt="" /> */}
            {/* </div>
                <div className="card-body p-0"> */}
            {/* <canvas id="widgetChart1" height={75} /> */}
            {/* <WidgetChart1 />
                </div>
              </div>
            </div>  */}
            {/* <div style={{ display: 'none' }} className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0 pb-0">
                  <div className="mr-auto">
                    <h2 className="text-black mb-2 font-w600">3</h2>
                    <p className="mb-1 fs-13">
                      <svg
                        width={21}
                        height={15}
                        viewBox="0 0 21 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5"
                          stroke="#2BC155"
                          strokeWidth={2}
                        />
                        <path d="M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z" />
                        <defs>
                          <linearGradient
                            x1="10.25"
                            y1={3}
                            x2={11}
                            y2="13.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              stopColor="#2BC155"
                              offset={1}
                              stopOpacity="0.73"
                            />
                            <stop
                              offset={1}
                              stopColor="#2BC155"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      Total Critical Vulnerabilities
                    </p>
                  </div>
                  {/* <img src={bit_2} alt="" /> */}
            {/* </div> */}
            {/* // <div className="card-body p-0"> */}
            {/* <canvas id="widgetChart2" height={75} /> */}
            {/* <WidetChart2 />
                </div>
              </div>
            </div>  */}
            {/* <div style={{ display: 'none' }} className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0 pb-0">
                  <div className="mr-auto">
                    <h2 className="text-black mb-2 font-w600">3</h2>
                    <p className="mb-1 fs-13">
                      <svg
                        width={21}
                        height={15}
                        viewBox="0 0 21 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5"
                          stroke="#2BC155"
                          strokeWidth={2}
                        />
                        <path d="M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z" />
                        <defs>
                          <linearGradient
                            x1="10.25"
                            y1={3}
                            x2={11}
                            y2="13.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              stopColor="#2BC155"
                              offset={1}
                              stopOpacity="0.73"
                            />
                            <stop
                              offset={1}
                              stopColor="#2BC155"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      Total High Open Vulnerabilities
                    </p>
                  </div>
                  {/* <img src={bit_3} alt="" /> */}
            {/* </div>
                <div className="card-body p-0"> */}
            {/* <canvas id="widgetChart3" height={75} /> */}
            {/* <WidetChart3 />
                </div>
              </div>
            </div> */}
            {/* <div style={{ display: 'none' }} className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
              <div className="card overflow-hidden">
                <div className="card-header border-0 pb-0">
                  <div className="mr-auto">
                    <h2 className="text-black mb-2 font-w600">4</h2>
                    <p className="mb-1 fs-13">
                      <svg
                        width={21}
                        height={15}
                        viewBox="0 0 21 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 13.5C1.91797 12.4157 4.89728 9.22772 6.5 7.5L12.5 10.5L19.5 1.5"
                          stroke="#2BC155"
                          strokeWidth={2}
                        />
                        <path d="M6.5 7.5C4.89728 9.22772 1.91797 12.4157 1 13.5H19.5V1.5L12.5 10.5L6.5 7.5Z" />
                        <defs>
                          <linearGradient
                            x1="10.25"
                            y1={3}
                            x2={11}
                            y2="13.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              stopColor="#2BC155"
                              offset={1}
                              stopOpacity="0.73"
                            />
                            <stop
                              offset={1}
                              stopColor="#2BC155"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      Total Medium Open Vulnerabilities
                    </p>
                  </div>
                  {/* <img src={bit_4} alt="" /> */}
            {/* </div>
                <div className="card-body p-0"> */}
            {/* <canvas id="widgetChart4" height={75} /> */}
            {/* <WidetChart4 />
                </div>
              </div>
            </div>   */}
            {/* <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-24">
              <div className="card">
                <div className="card-body">
                  {(showLoader) ? <RctSectionLoader />
                    : requestDetail && <div className="row">

                      <div className="col-sm-5 mb-4">
                        <p className="mb-2">Application Name</p>
                        <h4 className="text-black">{requestDetail.application_name}</h4>
                      </div>
                      <div className="col-sm-7 mb-4">
                        <p className="mb-2">Target URL</p>
                        <h4 className="text-black">{requestDetail.url}</h4>
                      </div>
                      <div className="col-sm-5 mb-4">
                        <p className="mb-2">Is whitelisting needed</p>
                        <h4 className="text-black">{requestDetail.is_whitelisting}</h4>
                      </div>
                      <div className="col-sm-7 mb-4">
                        <p className="mb-2">Scope of the pentest</p>
                        <h4 className="text-black">{requestDetail.pentest_scope}</h4>
                      </div>
                      {requestDetail.credentials.map((creds, i) =>
                      (<div className="col-sm-5">
                        <p className="mb-2">Role {i + 1}</p>
                        <h4 className="text-black">{creds.role}</h4>
                        <h4 className="text-black">{creds.user_name}</h4>
                      </div>)
                      )}
                    </div>
                  }
                </div>
              </div>
            </div> 
            </div>*/}

            <div className='col-xl-5  col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='rounded card'>
                <div className='card-header d-sm-flex d-block pb-0 border-0'>
                  <div>
                    <h4 className='text-black fs-20'>
                      Vulnerabilities by Distribution
                    </h4>
                  </div>
                </div>
                <div className='card-body' id='user-activity'>
                  <Tab.Container defaultActiveKey='all'>
                    <Tab.Content className='tab-content' id='myTabContent'>
                      {!vulCounts && <RctSectionLoader />}
                      {vulCounts && (
                        <VulnerabilitiesBar vulCounts={vulCounts} />
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>

            <div className='col-xl-7  col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='rounded card'>
                <div className='card-header d-sm-flex d-block pb-0 border-0'>
                  <div>
                    <h4 className='text-black fs-20'>
                      Open V/S Closed Vulnerabilities
                    </h4>
                    <p data-cy='dashboard-open-vs-fixed' className='fs-13 mb-0'>
                      Bar Chart Represents Open V/S Closed Vulnerabilities For
                      Each Test
                    </p>
                  </div>
                </div>
                <div className='card-body' id='user-activity'>
                  <Tab.Container defaultActiveKey='all'>
                    <Tab.Content className='tab-content' id='myTabContent'>
                      {!graphData && <RctSectionLoader />}
                      {graphData && <ApexBar3 graphData={graphData} />}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>

            <div className='col-12  col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='rounded card'>
                <div className='card-header d-sm-flex d-block pb-0 border-0'>
                  <div className='d-flex w-100 justify-content-between'>
                    <h4 className='text-black fs-20'>OWASP Category Count</h4>
                    <Dropdown
                      alignRight
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"

                    // onChange={(e) => {
                    //   setowaspStatusForData(e.target.value);
                    //   getCountsOwasp(reports, e.target.value);
                    // }}
                    >
                      <Dropdown.Toggle disabled={!statudOwaspsstatus} variant='custom-1 text-black'>
                        {owaspStatusForData}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setowaspStatusForData("ALL");
                            getCountsOwasp(reports, "ALL");
                          }}
                          value={"ALL"}>
                          ALL
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setowaspStatusForData("OPEN");
                            getCountsOwasp(reports, "OPEN");
                          }}
                          value={"OPEN"}>
                          OPEN
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setowaspStatusForData("CLOSED");
                            getCountsOwasp(reports, "CLOSED");
                          }}
                          value={"CLOSED"}>
                          CLOSED
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setowaspStatusForData("FIX-IN-PROGRESS");
                            getCountsOwasp(reports, "FIX-IN-PROGRESS");
                          }}
                          value={"FIX-IN-PROGRESS"}>
                          FIX-IN-PROGRESS
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setowaspStatusForData("READY-TO-RETEST");
                            getCountsOwasp(reports, "READY-TO-RETEST");
                          }}
                          value={"READY-TO-RETEST"}>
                          READY-TO-RETEST
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className='card-body' id='user-activity'>
                  <Tab.Container defaultActiveKey='all'>
                    <Tab.Content className='tab-content' id='myTabContent'>
                      {!statudOwaspsstatus && <RctSectionLoader />}
                      {statudOwaspsstatus && (
                        <OwaspBar1
                          owaspCounts={owaspCounts}
                          webRequests={reports}
                          reqapplication_name={requestDetail.application_name}
                        />
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>

            <div
              style={{ display: "none" }}
              className='col-xl-6 col-xxl-8 col-lg-8'>
              <div className='rounded card'>
                <Tab.Container defaultActiveKey='monthly'>
                  <div className='card-header d-block d-sm-flex border-0'>
                    <div>
                      <h4 className='fs-20 text-black'>Risk Score</h4>
                      <p className='mb-0 fs-13'>
                        Risk Score of all the Applications Submitted For Pentest
                      </p>
                    </div>
                  </div>
                  <div className='card-body tab-content p-0'>
                    <Tab.Content>
                      <ApexRedialBar2 />
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>

            <Col lg={12}>
              <Card className='rounded'>
                <Card.Header>
                  <Card.Title>Vulnerability Details</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className='width80'>
                          <strong>#</strong>
                        </th>
                        <th>
                          <strong>Vulnerability Name</strong>
                        </th>
                        {/* <th>
                          <strong>Threat</strong>
                        </th> */}
                        <th>
                          <strong>Severity</strong>
                        </th>
                        <th>
                          <strong>Impact</strong>
                        </th>
                        <th>
                          <strong>Risk Score</strong>
                        </th>
                        <th>
                          <strong>Status</strong>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {showLoader ? (
                        <tr>
                          <td>
                            (
                            <RctSectionLoader />)
                          </td>
                        </tr>
                      ) : (
                        reports.map((report, i) => (
                          <tr key={i} className="border-bottom">
                            <td className="border-0">
                              <strong>{++i}</strong>
                            </td>
                            <td
                              style={{
                                maxWidth: "200px",
                                display: "block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                border: 'none'
                              }}>
                              {report.title}
                            </td>
                            {/* <td>{report.threat}</td> */}
                            <td className="border-0">
                              <Badge
                                data-cy='vuln-status-badge'
                                variant={getBadgeColor(report.severity)}>
                                {report.severity}
                              </Badge>
                            </td>
                            <td className="border-0">
                              <Badge
                                data-cy='vuln-status-badge'
                                variant={getBadgeColor(report.impact)}>
                                {report.impact}
                              </Badge>
                            </td>
                            <td className="border-0">
                              {`${Number(report.riskScore).toFixed(2)} %`}
                              <ProgressBar now={report.riskScore} variant={report.riskScore >= 65 ? 'danger' : report.riskScore >= 25 ? 'info' : 'success'} striped />
                            </td>
                            <td className="border-0">
                              <Badge
                                data-cy='vuln-status-badge'
                                variant={getBadgeColor(report.status)}>
                                {report.status}
                              </Badge>
                            </td>
                            <td className="border-0">
                              <Dropdown>
                                <Dropdown.Toggle
                                  data-cy='vulnerability-open-menu'
                                  variant='success'
                                  className='light sharp i-false'>
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    data-cy='report-dash-view-vuln'
                                    onClick={() => viewReport(report)}>
                                    View
                                  </Dropdown.Item>
                                  {user.role === "cshAdmin" ||
                                    user.role === "tester" ? (
                                    <Dropdown.Item
                                      data-cy='report-dash-edit-vuln'
                                      onClick={() => editReport(report)}>
                                      Edit
                                    </Dropdown.Item>
                                  ) : null}
                                  {user.role === "cshAdmin" ||
                                    user.role === "tester" ? (
                                    <Dropdown.Item
                                      data-cy='report-dash-delete-vuln'
                                      onClick={() => deleteReport(report)}>
                                      Delete
                                    </Dropdown.Item>
                                  ) : null}
                                  {/* <Dropdown.Item
                                    data-cy="report-dash-download-vuln"
                                    onClick={() => createPdf(i)}
                                  >
                                    Download Report
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <div className='col-12 col-lg-8 d-flex flex-wrap '>
              {user.role == "cshAdmin" || user.role == "tester" ? (
                ""
              ) : (
                <div className=''>
                  <Button
                    onClick={createData}
                    disabled={downloadDisable}
                    className='d-flex col-12 col-sm-auto mb-2 mx-auto mr-md-2 ml-md-0'
                    variant='primary'>
                    Download Report
                    {reportLoader ? <RctSectionLoader /> : null}
                  </Button>
                </div>
              )}

              {user.role == "cshAdmin" || user.role == "tester" ? (
                ""
              ) : (
                <Button
                  onClick={() => createCertificate()}
                  className='d-flex col-12 col-sm-auto mb-2 mx-auto mr-md-2 ml-md-0'
                  variant='primary'
                  disabled={downloadDisable}>
                  Download Certificate
                  {certLoader ? <RctSectionLoader /> : ""}
                </Button>
              )}

              <Link
                className='col-12 col-sm-auto mb-2 mx-auto mr-md-2 ml-md-0 p-0'
                to={`/viewchecklist?requestid=${requestId}`}>
                <Button variant='primary' className='col-12'>
                  View checklist
                </Button>
              </Link>
              <Button
                variant='primary'
                className='col-12 col-sm-auto mb-2 mx-auto mr-md-2 ml-md-0'
                onClick={() => {
                  setretestModal(true);
                }}>
                Submit For Retest
              </Button>
            </div>

            <Modal
              className='fade bd-example-modal-lg '
              show={deleteConfirmModal}
              size='lg'>
              <Modal.Header>
                <Modal.Title>Delete Vulnerability</Modal.Title>
                <Button
                  data-cy='report-dash-delete-vuln'
                  variant=''
                  className='close'
                  onClick={() => closeConfirmModal()}>
                  <span>&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body>
                Are you sure want to delete Vulnerability ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  data-cy='report-dash-confirm'
                  variant='danger light'
                  onClick={() => closeConfirmModal(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => onConfirmDelete()}
                  data-cy='report-dash-confirm-no-vuln'
                  variant='primary light'>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              fullscreen={"md-down"}
              dialogClassName='modal-90w'
              className='fade bd-example-modal-lg overflow-auto'
              show={retestModal}
              size='xl'>
              <Modal.Header>
                <Modal.Title>
                  Submit READY-T0-RETEST
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='pb-0 overflow-auto'>
                <TestTable
                  Columns={Columns}
                  Rows={reports}
                  getBadgeColor={getBadgeColor}
                  setretestModal={setretestModal}
                  saveBulkStatus={saveBulkStatus}
                  bulkSaveLoader={bulkSaveLoader}
                />
              </Modal.Body>
            </Modal>
          </>
        </div>
      </>
    </Fragment>
  );
};

export default MyReports;
