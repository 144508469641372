import ForgotPassword from "../../jsx/pages/ForgotPassword";
import Login from "../../jsx/pages/Login";
import NewDashboard from "../pages/NewDashboard";
import pageRegister from "../../jsx/pages/Registration";
import PricingForm from "../components/CshPages/pricingForm/pricingForm";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../components/CshPages/Signup/step/SignUp";


export const publicRoutes = [
  { url: "register", component: pageRegister },
  { url: "login", component: Login },
  { url: "pricing", component: PricingForm},
  { url: "page-forgot-password", component: ForgotPassword },
  { url: "page-reset-password", component: ResetPassword },
  { url: "page-new-dashboard", component: NewDashboard },
  { url: "signup", component: SignUp },
];
