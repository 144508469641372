import React, { useContext, useEffect, useState } from 'react'
import { useLocation  } from 'react-router-dom';
import { AuthContext } from '../../../../Context/AuthProvider';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { WEB } from '../../../../lib/config/URLConstant';
import Toaster from '../../Toaster';

const Slackintegration = () => {

    
    const location = useLocation();
    const { user } = useContext(AuthContext);

    const userId = location.state.userId;

    useEffect(() => {
        GetUserData();
        window.scrollTo(0, 0);
    },[])
    
    const GetUserData = async () => {
        AxiosInstance.post(`${WEB}/getUserData`, {_id: userId})
        .then((res)=>{
            setChannelId(res.data.slackChannelId);
        })
    }

    const [channelId, setChannelId] = useState();
    const [error, setError] = useState('');
    const [connected, setConnected] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');

    const setchannelIdFunction = (val)=>{
        setChannelId(val);
        if(!channelId==""){
            setError("");
        }
    }

    const validate = ()=>{
        if(channelId==""){
            setError("Enter your channel id first");
        }
        else{
            addChannelId();
        }
    }

    const addChannelId = (tester) =>{
        AxiosInstance.post(`${WEB}/channelid`, {_id: userId, channelId: channelId, company: user.company})
            .then((res)=>{
              // // console.log(res.data);
              window.open("https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=204400178055.3074283545030&scope=chat:write,chat:write.public&user_scope=","_blank");
              setConnected(true);
            })
      }
      

      const sendSlackMessage = ()=>{
        AxiosInstance.post(`${WEB}/sendSlack`, {
            _id: userId, 
            channelId: channelId
        })
        .then((res)=>{
          // // console.log(res.data);
            if(res.data=="error"){
                setSuccessMessage("Slack is not connected. You might have entered wrong channel id or app is not installed on your workplace")
            }
            else{
                setSuccessMessage("Slack is connected.")
            }
        })
        .catch((err)=>{
            Toaster(err, 'error')
        })
      }

    return (
        <div>
            <form>
                <div>
                    <label for="exampleInputEmail1">Enter your channel id</label>
                    <input value={channelId} onChange={(e)=>setchannelIdFunction(e.target.value)} type="text" class="form-control" placeholder="C0303V5G2D6" />
                    <p className="text-danger">{error}</p>
                </div>
               
               {(connected)?<p onClick={sendSlackMessage} className="px-4 py-2 btn btn-primary">Verify Slack Connection</p>
               :<p onClick={validate} className="px-4 py-2 btn btn-primary"> Connect with slack</p>}
               {(successMessage=="Slack is connected.")? <p className="text-success">{successMessage}</p>:(successMessage=="")?null:<p className="text-danger">{successMessage}</p> }

            </form>
            <div>
            </div>
        </div>
    )
}

export default Slackintegration