import axios from "axios";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { AuthContext } from "../../../../../Context/AuthProvider";
import { AUTH } from "../../../../../lib/config/URLConstant";
import Toaster from "../../../../components/Toaster";
import Countries from "../../pricingForm/countries.json"

const SignUp = ({ history }) => {
    const { tokenAuth } = useContext(AuthContext);
    const [country, setCountry] = useState("United States");
    const [valid, setvalid] = useState("");

    
    const queryParams = new URLSearchParams(window.location.search);

    const token = queryParams.get('token');
    const urlemail = queryParams.get('email');

    const initialValues = {
        name: "",
        email: urlemail,
        password: "",
        companyName: "",
        phone: "",
        role: 'admin'
    };

    useEffect(()=>{
          // check token validaty
          axios
          .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/verify-addcompany-token`, {token: token, email: urlemail})
          .then(async (res) => {
              if (res.data == true) {
                setvalid(true);
              }
              else{
                setvalid(false);
              }
          })
          .catch((error) => {
              // // console.log(error)
          });
    },[])

    const onSubmit = () => {
           // submiting company data
           try {

            let companyData = {
                email: formik.values.email,
                company: formik.values.companyName,
                applicationName: "none",
                applicationPurpose: "none",
                applicationTotlPages: "0",
                applicationTotalRoles: "0",
                applicationFormFields: "0",
                applicationTotalApi: "0",
                applicationAverageNoMethodApi: "0",
                applicationAverageNoParameterApi: "0",
            }

            axios
                .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/registerCompany`, companyData)
                .then(async (res) => {
                    if (res.status == 201) {
                        // // console.log(res);
                        let values = {
                            ...formik.values,
                            company: res.data.id
                        }
                        // // console.log('values', values);
                        if (res.data == false){
                            Toaster("Email id already exists", "error")
                        }
                        else{
                            Toaster(`Company Details Submit Successfully${res.statusText}`, "success");
                            axios
                        .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/register`, values)
                        .then((res) => {
                            const {
                                data: { code = "", msg = "", tokens = {}, user = {} },
                            } = res;
        
                            if (isEmpty(code) && isEmpty(msg)) {
                                const newdata = {
                                    token: {
                                        token: tokens.access.token,
                                        userData: user,
                                    },
                                };
                                tokenAuth(newdata, user, () => {
                                    Toaster("Login Successful", "success");
                                });
                                history.push("/");
                            } else {
                                Toaster(msg, "error");
                            }
                        })
                        .catch((error) => {
                            if (error) {
                                Toaster("Error 404", "error");
                            }
                        });
                        }
                    }
                    else {
                        Toaster("Fill all the details", res.msg, "error");
                    }
                    // // console.log('company response', res);
                })
                .catch((error) => {
                    if (error) {
                        Toaster("Error 404", "error");
                    }
                });
        }
        catch (error) {
            Toaster(error, "error");

        }
    };

    const phoneRegex = RegExp(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    );

    const passwordRegex = RegExp(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      )

    const validationSchema = Yup.object({
        companyName: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        password: Yup.string()
        .matches(passwordRegex, "Invalid password Must Contain One Capital, one special character and one number")
        .min(8, 'Password must be at least 8 characters long')
        .required("Password is required"),
        email: Yup.string().email("Invalid email format").required("Required"),
        phone: Yup.string()
            .matches(phoneRegex, "Invalid phone")
            .required("Phone is required"),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        // validate,
        validationSchema,
    });

    return (
            (valid)?
            <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center mb-4">
                                            {/* <img className="logo-compact" src={logoText} alt="" /> */}
                                            Sign up your account
                                        </h4>
                                        <form action="" onSubmit={formik.handleSubmit}>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Name</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="username"
                                                    name="name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    values={formik.values.name}
                                                />
                                                {formik.touched.name && formik.errors.name ? (
                                                    <div className="formerrormessage">
                                                        {formik.errors.name}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Email</strong>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="hello@example.com"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    disabled= {true}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    values={formik.values.password}
                                                />
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className="formerrormessage">
                                                        {formik.errors.password}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Confirm Password</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Company Name</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="company"
                                                    name="companyName"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    values={formik.values.companyName}
                                                />
                                                {formik.touched.companyName && formik.errors.companyName ? (
                                                    <div className="formerrormessage">
                                                        {formik.errors.companyName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Phone Number</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="phone"
                                                    name="phone"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    values={formik.values.phone}
                                                />
                                                {formik.touched.phone && formik.errors.phone ? (
                                                    <div className="formerrormessage">
                                                        {formik.errors.phone}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Country</strong>
                                                </label>
                                                <select
                                                
                                                values={country}
                                                    
                                                onChange={(e)=>{
                                                    setCountry(e.target.value);
                                                }}
                                                    name="userCountry"
                                                    className="coustomSelect">
                                                    {Countries.map((val) => {
                                                        return <option value={val.name}>{val.name}</option>

                                                    })}
                                                </select>
                                            </div>
                                            <div className="text-center mt-4">
                                                <input
                                                    type="submit"
                                                    value=" Sign me up"
                                                    className="btn btn-primary btn-block"
                                                    onClick={()=> onSubmit}
                                                />
                                            </div>
                                        </form>
                                        <div className="new-account mt-3">
                                            <p>
                                                Already have an account?{" "}
                                                <Link className="text-primary" to="/login">
                                                    Sign in
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : valid === "" ? null
        : 
        <div className="d-flex flex-column justify-content-center align-items-center " style={{height: "100vh"}}>
            <h2 className="text-danger">Token is invalid Or Expires</h2>
            <h3>Please contact to <a href="mailto:support@threatscan.com">support@threatscan.com</a> </h3>
        </div>
    )
};

export default SignUp;
