import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css'; 


const CkeditorComponent = (props) => {
  const [content, setcontent] = useState("")


  const handleChange = (html)=> {
    // props.setdata(props.name ,html);
    setcontent(html)
    props.setdata(props.name ,html);
    // console.log(html, props.setdata);
  }

  useEffect(() => {
      setcontent(props.value)
  }, [props.value])

  const handleBlur = (html)=> {
    props.setdata(props.name ,content);
  }

  return (
    <>
    <div>
        <ReactQuill
          onChange={handleChange}
          onBlur={(e)=> handleBlur(e)}
          value={content || ""}
          modules={CkeditorComponent.modules}
          formats={CkeditorComponent.formats}
          theme="snow"
        />
    </div>

    </>
  )
}
CkeditorComponent.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video', 'code-block'],

  ['clean'] 
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill CkeditorComponent formats
   * See https://quilljs.com/docs/formats/
   */
  CkeditorComponent.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align'
  ]
export default CkeditorComponent


  
  