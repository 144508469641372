import React, { useState } from "react";
import {
  addItem,
  addToken,
  clearItem,
  getToken,
} from "../lib/helpers/localStorage";
import { useHistory } from "react-router-dom";

export const AuthContext = React.createContext();

export const LogIn = (params, callback) => {
  let { auth_token, preferences } = params.meta;

  addToken(
    JSON.stringify({
      token: auth_token,
      userData: params,
      preferences: preferences,
    })
  );

  callback();
};

export const Logout = () => {
  clearItem("token");
};

export const getUser = () => {
  const data = localStorage.getItem("token");

  if (data) {
    if (JSON.parse(data).token.userData) return JSON.parse(data).token.userData;
    else return { name: "", role: "" };
  }
  return { name: "", role: "" };
};

const isValidToken = () => {
  /**
   *  Using the local storage code....
   */
  const token = localStorage.getItem("token");

  if (token) return true;
  return false;
};

const AuthProvider = (props) => {
  const [loggedIn, setLoggedIn] = useState(isValidToken());
  // const [loggedOut, setLoggedOut] = useState(true);
  const [user, setUser] = useState(getUser());
  const [token, setToken] = useState(getToken());
  const history = useHistory();

  const tokenAuth = (token, user = {}, callback) => {
    setUser(user);
    setToken(token);
    addItem("token", JSON.stringify(token));
    setLoggedIn(true);
    callback();
  };

  const forgetPass = (params) => {
    // // console.log(params, "forget password form Props");
  };
  const changePass = (params) => {
    // // console.log(params, "change password form Props");
  };

  const logOut = () => {
    setUser({ name: "", role: "" });
    setToken(null);
    clearItem("token");
    setLoggedIn(false);
    window.location.href = '/login';
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        forgetPass,
        changePass,
        tokenAuth,
        user,
        token,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
