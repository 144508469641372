import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../Context/AuthProvider';
import { AxiosInstance } from '../../../../lib/config/axiosConfig';
import { PLAN } from '../../../../lib/config/URLConstant'

const Plans = () => {

    const { user } = useContext(AuthContext);

    const [details, setDetails] = useState();

    // const [percent, setpercent] = useState({
    //     first: {(details?.noOfTest * 100) / details?.maxNoOfTest},
    //     second: '',
    //     third: '',
    //     fourth: '',
    //     fifth: ''
    // });


    useEffect(() => {
        let url = `${PLAN}/fetchplan`;
        if (user.role != "cshAdmin" && user.role != "tester") {
            AxiosInstance.post(url, { id: user.company })
                .then((res) => {
                    setDetails(res.data);
                    // // console.log(res.data);
                })
                .catch((err) => {
                    // // console.log(err);
                })
        }
    }, [])

    // // console.log(details);
    return (
        <div>
            <h1 className="plan-details-heading ml-0 ml-md-5">Plan Details</h1>
            <div className="plan-details-container bg-white py-4 p-2 p-md-5 d-flex flex-wrap justify-content-around">
                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div style={{ background: '#E99A0A' }} className="widget-stat card bg-">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="las la-clipboard-check"></i>
                                </span>
                                <div className="media-body ">
                                    <p className="mb-1">Plan Name</p>
                                    <h3 className="text-white">{(details?.planName)?.toUpperCase()}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div style={{backgroundColor: '#dc3ccc'}} className="widget-stat card bg-">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                    <i className="las la-chart-bar"></i>
                                </span>
                                <div className="media-body text-white">
                                    <p className="mb-1">No of request</p>
                                    <h3 className="text-white">{details?.noOfTest} / {details?.maxNoOfTest}</h3>
                                    <div className="progress mb-2 bg-secondary">
                                        <div
                                            className="progress-bar progress-animated bg-light"
                                            style={{ width: "80%" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-danger">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                    <i className="las la-users-cog"></i>
                                </span>
                                <div className="media-body text-white">
                                    <p className="mb-1">No of admin</p>
                                    <h3 className="text-white">{details?.noOfAdmin} / {details?.maxNoOfAdmin}</h3>
                                    <div className="progress mb-2 bg-secondary">
                                        <div
                                            className="progress-bar progress-animated bg-light"
                                            style={{ width: "80%" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div style={{backgroundColor: "#6418c3"}} className="widget-stat card bg-">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                    <i className="la la-users"></i>
                                </span>
                                <div className="media-body text-white">
                                    <p className="mb-1">No of user</p>
                                    <h3 className="text-white">{details?.noOfUser} / {details?.maxNoOfUser}</h3>
                                    <div className="progress mb-2 bg-secondary">
                                        <div
                                            className="progress-bar progress-animated bg-light"
                                            style={{ width: "80%" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-primary">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="las la-user-lock"></i>
                                </span>
                                <div className="media-body text-white">
                                    <p className="mb-1">No of read only</p>
                                    <h3 className="text-white">{details?.noOfRead} / {details?.maxNoOfRead}</h3>
                                    <div className="progress mb-2 bg-secondary">
                                        <div
                                            className="progress-bar progress-animated bg-light"
                                            style={{ width: "80%" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-primary">
                        <div className="card-body  p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="las la-stopwatch"></i>
                                </span>
                                <div className="media-body text-white">
                                    <p className="mb-1">Plan duration</p>
                                    <h3 className="text-white">Half Yearly</h3>
                                    <div className="progress mb-2 bg-secondary">
                                        <div
                                            className="progress-bar progress-animated bg-light"
                                            style={{ width: "80%" }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans