import React from "react";
import Widget from 'rasa-webchat';

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      {/* <Widget
      initPayload={"/greet"}
      socketUrl={"https://diana-prod.threatscan.io/"}
      embedded={false}
      socketPath={"/socket.io/"}
      customData={{"language": "en"}} // arbitrary custom data. Stay minimal as this will be added to the socket
      title={"Diana"}
      profileAvatar={"https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"}
    /> */}
      <div className="copyright">
        <p>
          Copyright © All Rights Reserved By Cyber Security Hive {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
