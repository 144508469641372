import React from "react";
import ReactApexChart from "react-apexcharts";
class OwaspBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Injection',
                data: [this.props?.owaspCounts?.InjectionTotalCount[0]==undefined?"0":this.props?.owaspCounts?.InjectionTotalCount[0],
                this.props?.owaspCounts?.InjectionTotalCount[1]==undefined?"0":this.props?.owaspCounts?.InjectionTotalCount[1],
                this.props?.owaspCounts?.InjectionTotalCount[2]==undefined?"0":this.props?.owaspCounts?.InjectionTotalCount[2],
                this.props?.owaspCounts?.InjectionTotalCount[3]==undefined?"0":this.props?.owaspCounts?.InjectionTotalCount[3],
                this.props?.owaspCounts?.InjectionTotalCount[4]==undefined?"0":this.props?.owaspCounts?.InjectionTotalCount[4],
            ],
                color:'#653600'
            }, 
            {
                name: 'Broken authentication',
                data: [ this.props?.owaspCounts?.brokenTotalCount[0]==undefined ? "0": this.props?.owaspCounts?.brokenTotalCount[0],this.props?.owaspCounts?.brokenTotalCount[1]==undefined ? "0": this.props?.owaspCounts?.brokenTotalCount[1],
                this.props?.owaspCounts?.brokenTotalCount[2]==undefined ? "0": this.props?.owaspCounts?.brokenTotalCount[2],
                this.props?.owaspCounts?.brokenTotalCount[3]==undefined ? "0": this.props?.owaspCounts?.brokenTotalCount[3],
                this.props?.owaspCounts?.brokenTotalCount[4]==undefined ? "0": this.props?.owaspCounts?.brokenTotalCount[4],
            ],
                color:'#b25f00'
            },
            {
                name: 'Sensitive data exposure',
                data: [this.props?.owaspCounts?.sensitiveTotalCount[0]==undefined ? "0":this.props?.owaspCounts?.sensitiveTotalCount[0],this.props?.owaspCounts?.sensitiveTotalCount[1]==undefined ? "0":this.props?.owaspCounts?.sensitiveTotalCount[1],
                this.props?.owaspCounts?.sensitiveTotalCount[2]==undefined ? "0":this.props?.owaspCounts?.sensitiveTotalCount[2],
                this.props?.owaspCounts?.sensitiveTotalCount[3]==undefined ? "0":this.props?.owaspCounts?.sensitiveTotalCount[3],
                this.props?.owaspCounts?.sensitiveTotalCount[4]==undefined ? "0":this.props?.owaspCounts?.sensitiveTotalCount[4],
            ],
                color:'#ff8800'
            }, 
            {
                name: 'XML external entities (XXE)',
                data: [ this.props?.owaspCounts?.XMLTotalCount[0]==undefined ? "0": this.props?.owaspCounts?.XMLTotalCount[0],this.props?.owaspCounts?.XMLTotalCount[1]==undefined ? "0": this.props?.owaspCounts?.XMLTotalCount[1],
                this.props?.owaspCounts?.XMLTotalCount[2]==undefined ? "0": this.props?.owaspCounts?.XMLTotalCount[2],
                this.props?.owaspCounts?.XMLTotalCount[3]==undefined ? "0": this.props?.owaspCounts?.XMLTotalCount[3],
                this.props?.owaspCounts?.XMLTotalCount[4]==undefined ? "0": this.props?.owaspCounts?.XMLTotalCount[4],
            ],
                color:'#ffac4c'
            },  {
                name: 'Broken access control',
                data: [ this.props?.owaspCounts?.brokenaccessTotalCount[0]==undefined ? "0":this.props?.owaspCounts?.brokenaccessTotalCount[0],this.props?.owaspCounts?.brokenaccessTotalCount[1]==undefined ? "0":this.props?.owaspCounts?.brokenaccessTotalCount[1],
                this.props?.owaspCounts?.brokenaccessTotalCount[2]==undefined ? "0":this.props?.owaspCounts?.brokenaccessTotalCount[2],
                this.props?.owaspCounts?.brokenaccessTotalCount[3]==undefined ? "0":this.props?.owaspCounts?.brokenaccessTotalCount[3],
                this.props?.owaspCounts?.brokenaccessTotalCount[4]==undefined ? "0":this.props?.owaspCounts?.brokenaccessTotalCount[4],

            ],
                color:'#fecf99'
            }, 
            {
                name: 'Security misconfigurations',
                data: [this.props?.owaspCounts?.securityTotalCount[0]==undefined? "0": this.props?.owaspCounts?.securityTotalCount[0],
                this.props?.owaspCounts?.securityTotalCount[1]==undefined? "0": this.props?.owaspCounts?.securityTotalCount[1],
                this.props?.owaspCounts?.securityTotalCount[2]==undefined? "0": this.props?.owaspCounts?.securityTotalCount[2],
                this.props?.owaspCounts?.securityTotalCount[3]==undefined? "0": this.props?.owaspCounts?.securityTotalCount[3],
                this.props?.owaspCounts?.securityTotalCount[4]==undefined? "0": this.props?.owaspCounts?.securityTotalCount[4],
            ],
                color:'#005466'
            }, 
            {
                name: 'Cross site scripting (XSS)',
                data: [this.props?.owaspCounts?.crossTotalCount[0]==undefined? "0": this.props?.owaspCounts?.crossTotalCount[0],this.props?.owaspCounts?.crossTotalCount[1]==undefined? "0": this.props?.owaspCounts?.crossTotalCount[1],
                this.props?.owaspCounts?.crossTotalCount[2]==undefined? "0": this.props?.owaspCounts?.crossTotalCount[2],
                this.props?.owaspCounts?.crossTotalCount[3]==undefined? "0": this.props?.owaspCounts?.crossTotalCount[3],
                this.props?.owaspCounts?.crossTotalCount[4]==undefined? "0": this.props?.owaspCounts?.crossTotalCount[4],
            ],
                color:'#0095b3'
            }
            , 
            {
                name: 'Insecure deserialization',
                data: [this.props?.owaspCounts?.insecureTotalCount[0]==undefined? "0":this.props?.owaspCounts?.insecureTotalCount[0],this.props?.owaspCounts?.insecureTotalCount[1]==undefined? "0":this.props?.owaspCounts?.insecureTotalCount[1],
                this.props?.owaspCounts?.insecureTotalCount[2]==undefined? "0":this.props?.owaspCounts?.insecureTotalCount[2],
                this.props?.owaspCounts?.insecureTotalCount[3]==undefined? "0":this.props?.owaspCounts?.insecureTotalCount[3],
                this.props?.owaspCounts?.insecureTotalCount[4]==undefined? "0":this.props?.owaspCounts?.insecureTotalCount[4],
            ],
                color:'#01d4ff'
            }
            , 
            {
                name: 'Using components with known vulnerabilities',
                data: [this.props?.owaspCounts?.usingTotalCount[0]==undefined? "0": this.props?.owaspCounts?.usingTotalCount[0],this.props?.owaspCounts?.usingTotalCount[1]==undefined? "0": this.props?.owaspCounts?.usingTotalCount[1],
                this.props?.owaspCounts?.usingTotalCount[2]==undefined? "0": this.props?.owaspCounts?.usingTotalCount[2],
                this.props?.owaspCounts?.usingTotalCount[3]==undefined? "0": this.props?.owaspCounts?.usingTotalCount[3],
                this.props?.owaspCounts?.usingTotalCount[4]==undefined? "0": this.props?.owaspCounts?.usingTotalCount[4],
            ],
                color:'#4be0fe'
            },
            {
                name: 'Insufficient logging and monitoring',
                data: [this.props?.owaspCounts?.insufficientTotalCount[0]==undefined ? "0": this.props?.owaspCounts?.insufficientTotalCount[0],this.props?.owaspCounts?.insufficientTotalCount[1]==undefined ? "0": this.props?.owaspCounts?.insufficientTotalCount[1],
                this.props?.owaspCounts?.insufficientTotalCount[2]==undefined ? "0": this.props?.owaspCounts?.insufficientTotalCount[2],
                this.props?.owaspCounts?.insufficientTotalCount[3]==undefined ? "0": this.props?.owaspCounts?.insufficientTotalCount[3],
                this.props?.owaspCounts?.insufficientTotalCount[4]==undefined ? "0": this.props?.owaspCounts?.insufficientTotalCount[4],
            ],
                color:'#9aedff'
            } 
           
            
        ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: [(this.props?.webRequests == undefined) ? '':(this.props?.webRequests[0]?.application_name==undefined)?"":this.props?.webRequests[0]?.application_name, (this.props?.webRequests[1]?.application_name==undefined)?"":this.props?.webRequests[1]?.application_name, (this.props?.webRequests[2]?.application_name==undefined)?"":this.props?.webRequests[2]?.application_name,
                    (this.props?.webRequests[3]?.application_name==undefined)?"":this.props?.webRequests[3]?.application_name, (this.props?.webRequests[4]?.application_name==undefined)?"":this.props?.webRequests[4]?.application_name],
                        labels: {
                            rotate: 0,
                        }
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },


        };
    }

    

    render() {
        // // console.log('owasp', this.props?.owaspCounts);
        return (


            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}

export default OwaspBar;
