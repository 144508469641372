import axios from 'axios';
import React, { useEffect, useState } from 'react'
import logo from "../../../../images/logo-csh.png";
import { AUTH, PAYMENT } from '../../../../lib/config/URLConstant';
import Toaster from '../../Toaster';
import Countries from './CountriesZone.json';

const PlansDetails = (props) => {

    const [oneScanPrice, setoneScanPrice] = useState(0);
    const [twoScanPrice, settwoScanPrice] = useState(0);
    const [fourScanPrice, setfourScanPrice] = useState(0);

    useEffect(() => {

        let region;

        for (let i = 0; i < Countries.length; i++) {
            if ((props.user.country || props.returnUser.country) == Countries[i].name) {
                region = Countries[i].id;
            }
        }
        let total;

        if (region == "APAC") {
            total = Math.round(((props.applicationDetail.applicationTotlPages * 1000) + (props.applicationDetail.applicationTotalApi * 3000) + (props.applicationDetail.applicationTotalRoles * 13000)) / 70)
            setoneScanPrice(total);
        }
        else if (region == "AMERICAS" || region == "EMEA") {
            total = Math.round(((props.applicationDetail.applicationTotlPages * 2000) + (props.applicationDetail.applicationTotalApi * 4500) + (props.applicationDetail.applicationTotalRoles * 15000)) / 70)
            setoneScanPrice(total);
        }




        let twototal = total * 2;
        let fourtotal = total * 4;

        settwoScanPrice(Math.round(twototal - ((twototal * 15) / 100)));
        setfourScanPrice(Math.round(fourtotal - ((fourtotal * 20) / 100)));

        // // console.log(oneScanPrice, twoScanPrice, fourScanPrice);
    })

    const loadScript = (src) => {
        return new Promise((res) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                res(true);
            };
            script.onerror = () => {
                res(false);
            }
            document.body.appendChild(script);
        })
    }

    const displayRazorpay = async (price, planName) => {
        price *= 100;
        try {
            const res = loadScript('https://checkout.razorpay.com/v1/checkout.js');
            if (!res) {
                Toaster("Network error","error");
                return;
            }


            const url = `${process.env.REACT_APP_API_SERVER}/${PAYMENT}/orders`

            const { data } = await axios.post(url, { amount: price.toString() })
            if (!data) {
                Toaster("Network error", "error");
                return;
            }
            const { id: order_id, currency } = data;

            const options = {
                key: process.env.RAZORPAY_KEY,
                amount: price.toString(),
                name: "Silver Plan",
                description: "CSH payment page",
                image: "",
                order_id,
                handler: async function (response) {
                    try {
                        const verifyUrl = `${process.env.REACT_APP_API_SERVER}/${PAYMENT}/verify`
                        await axios.post(verifyUrl, { response, amount: price.toString(), currency: "USD" })
                            .then((response) => {
                                if (response.status === 200) {
                                    Toaster("Registration Successfully", "success");

                                    // // submiting company data
                                    try {

                                        let companyData = {
                                            email: props.user.email || props.returnUser.email,
                                            company: props.user.company || props.returnUser.company,
                                            applicationName: props.applicationDetail.applicationName,
                                            applicationPurpose: props.applicationDetail.applicationPurpose,
                                            applicationTotlPages: props.applicationDetail.applicationTotlPages,
                                            applicationTotalRoles: props.applicationDetail.applicationTotalRoles,
                                            applicationFormFields: props.applicationDetail.applicationFormFields,
                                            applicationTotalApi: props.applicationDetail.applicationTotalApi,
                                            applicationAverageNoMethodApi: props.applicationDetail.applicationAverageNoMethodApi,
                                            applicationAverageNoParameterApi: props.applicationDetail.applicationAverageNoParameterApi,
                                        }

                                        axios
                                            .post(`${process.env.REACT_APP_API_SERVER}${AUTH}/registerCompany`, companyData)
                                            .then(async (res) => {
                                                if (res.status == 201) {
                                                    Toaster(`Company Details Submit Successfully${res.statusText}`, "success");
                                                    // // console.log(res);
                                                    let companyDocumentId = res.data.id
                                                    // updating company id in user sign up 
                                                    axios.post(`${process.env.REACT_APP_API_SERVER}/${AUTH}/updateUserId`, {
                                                        userid: (props.userid=="")?props.returnUser.userid:props.userid,
                                                        companyid: companyDocumentId
                                                    })
                                                        .then((res) => {
                                                            // // console.log(res);
                                                            if (res.status == 200){
                                                                
                                                            // updating the plan
                                                            axios.post(`${process.env.REACT_APP_API_SERVER}/${PAYMENT}/updatePlan`, {
                                                                plan: planName,
                                                                company: companyDocumentId
                                                            })
                                                                .then((res) => {
                                                                    // // console.log(res);
                                                                })
                                                            props.nextStep();
                                                            }
                                                        })


                                                }
                                                else {
                                                    Toaster("Fill all the details", res.msg, "error");
                                                }
                                                // // console.log('company response', res);
                                            })
                                            .catch((error) => {
                                                if (error) {
                                                    Toaster("Error 404", "error");
                                                }
                                            });
                                    }
                                    catch (error) {
                                        Toaster(error, "error");

                                    }

                                    
                                }
                                else {
                                    Toaster("Error", "error");
                                }
                                // // console.log(response);
                            })
                    } catch (error) {
                        // // console.log(error);
                    }
                }
            }
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

        } catch (error) {
            // // console.log(error);
        }
    }


    const { submitPrice, nextStep, previousStep } = props;

    return (
        <div>
            <div className="mt-3 ml-5">
                <img width="150" src={logo} alt="" />
            </div>
            <table style={{ width: '90%', border: "1px solid lightgrey", borderCollapse: 'collapse' }} className="mx-auto mt-4 mb-4">
                <tr style={{ backgroundColor: "#F5F6F8", width: "200px" }} className="text-center ">
                    <td className="py-3"><h3>Features</h3></td>
                    <hr />
                    <td style={{ backgroundColor: "white" }}><h3>Silver</h3></td> <hr />
                    <td><h3>Gold</h3></td> <hr />
                    <td><h3>Platinum</h3> </td> <hr />
                    <td><h3>Enterprise</h3></td> <hr />


                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Vulnerability Engine</h4></td> <hr />

                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>VA Scans</h4></td> <hr />

                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Diana Chatbot</h4></td> <hr />

                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Track Vulnerabilities</h4></td><hr />

                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Business logic testing</h4></td><hr />


                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Zero false positives</h4></td><hr />


                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Checklist</h4></td><hr />


                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>On Demand Report</h4></td><hr />


                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Manage User</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Retest</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Roles</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td>
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Support</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td>
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Submit wizard</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td>
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td><h4>Comments</h4></td><hr />



                    <hr />
                    <td style={{ backgroundColor: "white" }}>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td><hr />
                    <td>✓</td>
                </tr>
                <tr style={{ backgroundColor: "#F5F6F8", border: "1px solid lightgrey", width: "200px" }} className="text-center px-5 py-3">
                    <td style={{ padding: "15px 0px 10px 10px", backgroundColor: '#3A3D50', color: 'white' }}><h3 className="text-white">Price</h3></td><hr />



                    <hr />
                    <td onClick={() => {
                        submitPrice(oneScanPrice);
                        displayRazorpay(oneScanPrice, "silver");
                    }} style={{ padding: "15px 0px 10px 10px", backgroundColor: '#AFDE78', color: 'white', cursor: "pointer" }}> <h3>${oneScanPrice} </h3> </td><hr />
                    <td onClick={() => {
                        submitPrice(twoScanPrice);
                        displayRazorpay(twoScanPrice, "gold");
                    }} style={{ padding: "15px 0px 10px 10px", backgroundColor: '#7CE2C0', color: 'white', cursor: "pointer" }}>  <h3>${twoScanPrice} </h3> </td><hr />
                    <td onClick={() => {
                        submitPrice(fourScanPrice);
                        displayRazorpay(fourScanPrice, "platinum");
                    }} style={{ padding: "15px 0px 10px 10px", backgroundColor: '#82BDE9', color: 'white', cursor: "pointer" }}> <h3>${fourScanPrice} </h3> </td><hr />
                    <td onClick={nextStep} style={{ padding: "15px 0px 10px 10px", backgroundColor: '#A388E7', color: 'white', cursor: "pointer" }}> <h3>Contact Us</h3> </td><hr />
                </tr>

            </table>
            <p onClick={()=>previousStep()} class="text-white btn btn-secondary mb-5 float-right mr-5">
                Previous
            </p>
            <div style={{ marginTop: "200px" }} class="wizard-v3-progress">
                <span>3 to 4 step</span>
                <h3>75% to complete</h3>
                <div class="progress">
                    <div class="progress-bar" style={{ width: '0%' }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansDetails