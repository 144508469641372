import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const PreviewSubmit = ({showPreview, previewClose, redemallTags, descallTags, allTags}) => {
   
  
   
  return (
    <>
  
        <Modal dialogClassName="w-75" show={showPreview} onHide={previewClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Observation</h2>
            <p dangerouslySetInnerHTML={{__html:  allTags}} ></p>
            <h2>Description</h2>
            <p dangerouslySetInnerHTML={{__html:  descallTags}}></p>
            <h2>Remediation</h2>

            <p dangerouslySetInnerHTML={{__html:  redemallTags}}></p>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={previewClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default PreviewSubmit