export const checklist=[
     {
      "id": 1,
      "check": "Review the user registration process in the application. Check if the application does not perform an email\/phone number check before registering the user."
     },
     {
      "id": 2,
      "check": "Check for password complexity on the creating a new password for accessing the application. (Password complexity according to industry standards)"
     },
     {
      "id": 3,
      "check": "Check whether the application is sending the error message on entering wrong username and wrong password.  "
     },
     {
      "id": 4,
      "check": "Check whether the application is sending a descriptive error message on entering wrong username\/user id. "
     },
     {
      "id": 5,
      "check": "Check whether the application requires old password to change the password.  "
     },
     {
      "id": 6,
      "check": "Check whether the application validates the old password, if application uses old to change the password."
     },
     {
      "id": 7,
      "check": "If application does not require old password, check whether application uses CSRF protection on the functionality."
     },
     {
      "id": 8,
      "check": "Check for password complexity on the password functionalities in the application. (Password complexity according to industry standards)"
     },
     {
      "id": 9,
      "check": "Check if the application is sending username\/userid from client side to change the password. If change password functionality does not require old password to change the password, then by enumerating the user id can we actually change the password for another user."
     },
     {
      "id": 10,
      "check": "If password complexity is implemented, check whether it is implemented both on client as well as server side."
     },
     {
      "id": 11,
      "check": "Check if application sends an email on forgot password.  "
     },
     {
      "id": 12,
      "check": "Check if application sends an email with password on forgot password. (Password displayed in plain text)"
     },
     {
      "id": 13,
      "check": "Check for the duration of the reset password link, if application sends a reset password link on forgot password. (ideally 30 minutes)"
     },
     {
      "id": 14,
      "check": "Check if the reset password link is guessable\/enumerated to reset password of other users. "
     },
     {
      "id": 15,
      "check": "On reseting the password, check if the application sends username\/userid from client side to reset the password."
     },
     {
      "id": 16,
      "check": "If application prompts for forgot questions on reseting the password, check whether the challenge questions are guessable\/can be bypassed. "
     },
     {
      "id": 17,
      "check": "Check whether application sets the same session cookie before and after login. (for session fixation)"
     },
     {
      "id": 18,
      "check": "Check for SECURE & HTTPOnly attributes for session cookie"
     },
     {
      "id": 19,
      "check": "Check whether the value of the session cookie is static for multiple sessions.  "
     },
     {
      "id": 20,
      "check": "Check for the length of the session cookie value and whether the value is guessable."
     },
     {
      "id": 21,
      "check": "Check if the session cookie is not invalidated from the server side after log off. "
     },
     {
      "id": 22,
      "check": "Check if session timeout is implemented"
     },
     {
      "id": 23,
      "check": "Check for X-XSS header."
     },
     {
      "id": 24,
      "check": "Check for X-Frame options header."
     },
     {
      "id": 25,
      "check": "Check for HSTS header."
     },
     {
      "id": 26,
      "check": "Check for other security headers - CSP etc."
     },
     {
      "id": 27,
      "check": "Check for CORS policy"
     },
     {
      "id": 28,
      "check": "Check for Reflected Cross Site Scripting."
     },
     {
      "id": 29,
      "check": "Check for Persistent Cross Site Scripting."
     },
     {
      "id": 30,
      "check": "Check for DOM based Cross Site Scripting."
     },
     {
      "id": 31,
      "check": "Check for SQL Injection."
     },
     {
      "id": 32,
      "check": "Check for Blind SQL Injection."
     },
     {
      "id": 33,
      "check": "Check for Open Redirect."
     },
     {
      "id": 34,
      "check": "Check for CSRF."
     },
     {
      "id": 35,
      "check": "If CSRF checks are implemented, check for the validation the CSRF protection."
     },
     {
      "id": 36,
      "check": "Check for function level access control issues within the application. "
     },
     {
      "id": 37,
      "check": "Check for Vertical Authorization bypass."
     },
     {
      "id": 38,
      "check": "Check for Horization Authorization bypass."
     },
     {
      "id": 39,
      "check": "Check for Authentication bypass. Validate that each of the sensitive resource is not accessible without authentication. "
     },
     {
      "id": 40,
      "check": "Check for Logical bypass."
     },
     {
      "id": 41,
      "check": "Check for OS Commanding."
     },
     {
      "id": 42,
      "check": "Check for LDAP Injections."
     },
     {
      "id": 43,
      "check": "Check for XEE."
     },
     {
      "id": 44,
      "check": "Check for XML Injections."
     },
     {
      "id": 45,
      "check": "Check for HTTP Response Splitting."
     },
     {
      "id": 46,
      "check": "Check for CSV\/Formula Injection"
     },
     {
      "id": 47,
      "check": "Check whether the application is throwing sensitive information in unhandled exception."
     },
     {
      "id": 48,
      "check": "Check whether the application uses file upload functionality."
     },
     {
      "id": 49,
      "check": "If yes, check for the file types that are being allowed on the server."
     },
     {
      "id": 50,
      "check": "Check whether file type checks can be bypassed. "
     },
     {
      "id": 51,
      "check": "Check whether virus file type can be uploaded."
     },
     {
      "id": 52,
      "check": "Check if the uploaded file is downloaded or executed on the web server. "
     },
     {
      "id": 53,
      "check": "If it is downloaded, check if the download link is accessible without authentication. "
     },
     {
      "id": 54,
      "check": "If it is executed on the web server, check if code execution is possible on the web server. "
     },
     {
      "id": 55,
      "check": "Check for the (Server\/Framework\/Technology) used by the application."
     },
     {
      "id": 56,
      "check": "Check for the vulnerabilites related to the server (Server\/Framework\/Technology)."
     },
     {
      "id": 57,
      "check": "Check whether the application uses SSL\/encrypted channel."
     },
     {
      "id": 58,
      "check": "If yes, then check for the version of the SSL\/TLS used."
     },
     {
      "id": 59,
      "check": "Check for ciphers with less than 128 bits. "
     },
     {
      "id": 60,
      "check": "Check for version of SSL\/TLS used in the web server. "
     },
     {
      "id": 61,
      "check": "Check if the SSL\/TLS certificate is valid and issued from a certified CA. "
     },
     {
      "id": 62,
      "check": "Check for open ports\/services on the web server.  "
     },
     {
      "id": 63,
      "check": "If any open service is identified, perform a VA on the service to check for any open vulnerabilities related to it. "
     },
     {
      "id": 64,
      "check": "Run a VA scan on the web server using an enterprise security tool (for eg: Nessus). \nNote: Please confirm with the client before running the scan. "
     },
     {
      "id": 65,
      "check": "Check for application lockout. (5\/10 bad attempts)"
     },
     {
      "id": 66,
      "check": "Check for default\/known user credentials on the application. "
     },
     {
      "id": 67,
      "check": "If passwords are stored locally or on the client, check if proper and strong hashing is performed. "
     },
     {
      "id": 68,
      "check": "Check for directory listing. "
     },
     {
      "id": 69,
      "check": "Check for directories, files or folders on the root of the web server for any misconfigurations. "
     },
     {
      "id": 70,
      "check": "Check for URL and directory guessing using the installed framework\/software in the application. "
     },
     {
      "id": 71,
      "check": "Perform LFI & RFI checks if the application is serving files\/folders using the application. "
     },
     {
      "id": 72,
      "check": "Perform directory traversal attacks. "
     },
     {
      "id": 73,
      "check": "Check for components with known vulnerabilities on the technology stack. "
     },
     {
      "id": 74,
      "check": "Check for unnecessary methods enabled on the server. (TRACE\/TRACK)"
     },
     {
      "id": 75,
      "check": "If the application uses HTTPS, check whether the application deprecates to HTTP channel."
     },
     {
      "id": 76,
      "check": "If MFA is implemented in the application, check for the strength of the MFA."
     },
     {
      "id": 77,
      "check": "Check whether the application displays sensitive information such as SSN, Credit Card Number, Password, Token etc. "
     },
     {
      "id": 78,
      "check": "Authenticated VA scan performed on the specific URL"
     },
     {
      "id": 79,
      "check": "Unauthenticated VA scan performed on the specific URL"
     }
    ];